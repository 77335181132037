import React from 'react';
import styled from 'styled-components';
import { useDispatch, useSelector } from 'react-redux';

import { Text, Button, Icon, ViewOptions, ItemBlock, Pressable, DateSelector, Tooltip, BarChart, Spinner } from 'components'
import { Virtuoso } from 'react-virtuoso'

import { getDailyMetrics } from 'utils'
import moment from 'moment-timezone';
import _ from 'lodash'
import { eachDayOfInterval } from 'date-fns'
import { useHotkeys } from 'react-hotkeys-hook';
import { SHORTCUTS } from 'constants'
import { AppContext } from 'screens';
import * as actions from 'store/actions'

const OPTIONS = [
    { label: 'Scheduled', leading: 'CalendarClock', value: 'scheduled' },
    { label: 'Completed', leading: 'FormatListChecks', value: 'completed' },
    { label: 'Created', leading: 'FormatListCheckbox', value: 'created' }
]

const { shortcuts, actions: shortcut_actions, shortcut_keys } = SHORTCUTS['DayModule']
export const DayModule = props => {
    const {
        chart_length=25
    } = props;
    const dispatch = useDispatch()
    const [view, setView] = React.useState({ 
        schedule: 'custom',
        start: moment().startOf('day'), 
        end: moment().endOf('day'), 
        display: ['sub_items','list','schedule','tags'],
        sort: 'start'
    })
    const [focus, setFocus] = React.useState('scheduled') // scheduled, completed
    const data = useSelector(state => state.db.data)
    const user = useSelector(state => state.db.user)
    const loaded_dates = useSelector(state => state.db.loaded_dates)
    const [chart_date, setChartDate] = React.useState(moment().format('YYYY-MM-DD'))
    const [highlight, setHighlight] = React.useState(null)
    const [loading, setLoading] = React.useState(true)
    const [metrics, setMetrics] = React.useState(null)
    const items_ref = React.createRef();
    const { module={}, setModule } = React.useContext(AppContext)
    useHotkeys(shortcuts, (event, handler) => {
        const key = [...Object.keys(_.pickBy(handler, x => x === true)), ...handler.keys].join('+')
        const action = shortcut_actions[key]
        switch(action){
            case 'Next day':
                onChangeDate(moment(view.start).add(1,'days'))
                break
            case 'Previous day':
                onChangeDate(moment(view.start).subtract(1,'days'))
                break
            case 'Switch to scheduled tab':
                onChangeFocus('scheduled')
                break
            case 'Switch to completed tab':
                onChangeFocus('completed')
                break
            case 'Switch to created tab':
                onChangeFocus('created')
                break
            case 'Next item':
                items_ref?.current?.length > 0 &&
                setHighlight(highlight === null ? 0 
                            : highlight === items_ref.current.length - 1 ? 0
                            : highlight + 1)
                break
            case 'Previous item':
                items_ref?.current?.length > 0 &&
                setHighlight(highlight === null ? items_ref.current.length - 1
                            : highlight === 0 ? items_ref.current.length - 1
                            : highlight - 1)
                break
            case 'Select item':
                const item_id = items_ref.current?.[highlight]
                if(item_id) {
                    setModule(null)
                    setTimeout(() => {
                        setModule({target: 'ItemModule', extinfo: { item_id, type: 'module' }})
                    }, 300)
                }
                break
        }
    });

    React.useEffect(() => {
        const start = moment(chart_date).subtract(chart_length, 'days').utc().format()
        const end = moment(chart_date).utc().format()
        const dates = eachDayOfInterval({ start, end }).map(x => moment(x).format('YYYY-MM-DD'))
        const need_download = _.difference(dates, loaded_dates).length > 0
        if(need_download) {
            !loading && setLoading(true)
            dispatch(actions.loadData(user, 'by_dates',{ start, end }, (success, updated_data) => {
                if(!success || !updated_data) {
                    setLoading(false)
                } else {
                    const updated_metrics = getDailyMetrics(updated_data?.items)
                    setMetrics(updated_metrics)
                    setLoading(false)
                }
            }))
        }
    }, [chart_date])

    React.useEffect(() => {
        setTimeout(() => {
            const initial_metrics = getDailyMetrics(data.items)
            setMetrics(initial_metrics)
            setLoading(false)
        }, 300)
    }, [])

    const onChangeDate = new_date => {
        setView(old_view => ({
            ...old_view,
            start: moment(new_date).startOf('day'),
            end: moment(new_date).endOf('day')
        }))
        const new_target = moment(new_date).startOf('day')
        if(Math.abs(moment(chart_date).diff(new_target,'days')) >= chart_length) {
            setChartDate(new_target.format('YYYY-MM-DD'))
        } else if (new_target.isAfter(moment(chart_date),'day')) {
            setChartDate(moment(new_target).add(chart_length - 1, 'days').format('YYYY-MM-DD')) // Need to -1 because the new target alr +1
        }
    }

    const onChangeFocus = (new_focus) => {
        setFocus(new_focus)
        setView(old_view => ({
            ...old_view,
            is_completed: new_focus === 'completed' ? true : null,
            target_time: new_focus === 'created' ? 'create_time' 
                        : new_focus === 'completed' ? 'complete_time'
                        : null,
        }))
    }

    const getHeader = () => {
        const { start } = view
        return (
            <Header>
                <Pressable 
                    popover={<DateSelector initial={moment(view['start']).toDate()} onSelect={onChangeDate} style={{backgroundColor: 'var(--surface-container)'}}/>}
                    anchorReference='anchorEl'
                    disabled={!metrics}
                    >
                    <Text type='title'>{moment(start).format(`ddd, ${user.preferences['date_format']}`)}</Text>
                </Pressable>

                <HeaderSection>
                    {loading && <Spinner size={'1rem'} margin={'0 var(--spacing)'} color={'var(--on-surface-variant)'}/>}
                    <Button
                        icon='ChevronLeft' icon_color='var(--on-surface-variant)'
                        onClick={() => onChangeDate(moment(view.start).subtract(1,'days'))}
                        tooltip={{title: 'Previous day', shortcut: '←'}}
                        disabled={!metrics}
                        />
                    <Button
                        icon='ChevronRight' icon_color='var(--on-surface-variant)'
                        onClick={() => onChangeDate(moment(view.start).add(1,'days'))}
                        tooltip={{title: 'Next day', shortcut: '→'}}
                        disabled={!metrics}
                        />
                </HeaderSection>
            </Header>
        )
    }

    const getChart = (metrics) => {
        // const metrics = getDailyMetrics(data.items) // Need to call here in order to ensure most updated values are returned
        const start = moment(chart_date).subtract(chart_length-1,'days') // Need to -1 to offset end date
        const end = moment(chart_date).endOf('day')
        const target = focus === 'scheduled' ? 'start'
                        : focus === 'completed' ? 'complete_time'
                        : focus === 'created' ? 'create_time'
                        : null
        let filtered = _.pickBy(metrics[target]['counts'], (value, date) => moment(date).isBetween(start,end, undefined, '[]'))

        const dates = eachDayOfInterval({start: moment(start).toDate(), end: moment(end).toDate()}).map(date => moment(date).format('YYYY-MM-DD'))
        const missing_dates = _.difference(dates, Object.keys(filtered))
        if(missing_dates.length > 0) {
            filtered = {...filtered, ..._.mapValues(_.keyBy(missing_dates, date => date), x => 0)}
        }
        const labels = Object.keys(filtered).sort()
        const dataset = {
            label: focus,
            data: labels.map(date => filtered[date]),
        }
        return <BarChart labels={labels} dataset={dataset} onClick={onChangeDate} selected={moment(view.start).format('YYYY-MM-DD')}/>
    }

    const getControls = (metrics) => {
        let output = []
        OPTIONS.forEach((option, index) => {
            const { label, icon, value } = option
            const target = value === 'scheduled' ? 'start'
                        : value === 'completed' ? 'complete_time'
                        : value === 'created' ? 'create_time'
                        : null
            const n = metrics[target]['counts'][moment(view.start).format('YYYY-MM-DD')] || '-'
            const color = focus === value ? 'var(--on-surface)' : 'var(--on-surface-variant)'
            const is_selected = focus === value
            output.push(
                <Tooltip title={`Switch to ${label.toLowerCase()}`} shortcut={`t+${index+1}`}>
                    <ControlOption onClick={() => onChangeFocus(value)} is_selected={is_selected}>
                        <Text style={{fontWeight: is_selected && 'bold', color}}>{label}</Text>
                        <Text type='subtitle' style={{gridColumn: 1, gridRow: '1/3'}}>{n}</Text>
                    </ControlOption>
                </Tooltip>
            )
            if(index !== 2) {
                output.push(<Icon icon={'CircleSmall'} color={'var(--on-surface-variant)'} size={0.7}/>)
            }
        })

        return (
            <ControlsContainer>
                <div style={{display: 'flex', alignItems: 'center'}}>{output}</div>
                <Button
                    icon='DotsHorizontal' icon_color='var(--on-surface-variant)'
                    style={{alignSelf: 'flex-end'}}
                    context_menu={
                        <ViewOptions 
                            view={view} 
                            setView={setView}
                            options={['sort','display','filter']} 
                            sort_options={focus === 'completed' ? ['complete_time','priority','start','create_time','update_time'] : ['start','priority','create_time','update_time']}
                            filter_options={['priorities','tags','lists']}
                        />
                    }
                    />
            </ControlsContainer>
        )
    }

    const getItems = (metrics) => {
        const target = focus === 'scheduled' ? 'start'
                        : focus === 'completed' ? 'complete_time'
                        : focus === 'created' ? 'create_time'
                        : null
        const item_ids = metrics[target]['items'][moment(view.start).format('YYYY-MM-DD')]
        const filtered = item_ids && item_ids.filter(item_id => data.items[item_id])
        let sorted = _.sortBy(filtered, item_id => {
            const item = data.items[item_id]
            return item.start ? moment(item.start).unix() : 9999999999
        })
        sorted = [
            ...sorted.filter(item_id => !data.items[item_id]['is_completed']),
            ...sorted.filter(item_id => data.items[item_id]['is_completed']),
        ]
        items_ref.current = sorted
        if(sorted.length === 0) {
            return <Text type='placeholder' style={{marginLeft: 'var(--spacing-small)'}}>{`No ${focus} items`}</Text>
        } else {
            return (
                <Virtuoso
                    key={focus}
                    data={sorted}
                    itemContent={(i) => renderItem(sorted[i],i)}
                    computeItemKey={(i) => {
                        const { id, type } = sorted[i]
                        return `${id}_${type}`
                    }}
                    totalCount={sorted.length}
                    style={{overflowX: 'hidden'}}
                    />
            )
        }
    }

    const renderItem = (id,index) => {
        // Handle items w/ repeat
        let defaults = {}
        const item = data.items[id]
        if(!_.isEmpty(item['repeat']) && !moment(item['start']).isSame(view['start'],'day')) {
            const start = moment(item['start'])
            const time = { hour: start.hour(), minute: start.minute() }
            defaults['start'] = moment(view['start']).set(time)
            const duration = moment(item.end).diff(moment(item.start))
            defaults['end'] = moment(defaults['start']).add(duration, 'millisecond').utc().format()
        } else {
            defaults = _.pick(item,['start','end','is_all_day'])
        }

        return (
            <Wrapper is_highlighted={index === highlight}>
                <ItemBlock 
                    id={`DayModule_${id}`} 
                    item_id={id} 
                    labels={view['display']} 
                    disableDND={true} 
                    disableHandle={true} 
                    defaults={defaults}
                    />
            </Wrapper>
       )
    }

    const render = () => {
        const header = getHeader()
        const chart = getChart(metrics)
        const blocks = getItems(metrics)
        const controls = getControls(metrics)
        return [header, chart, controls, blocks]
    }

    if(!metrics) {
        return (
            <LoadingContainer>
                {getHeader()}
                <Text type='placeholder'>Loading...</Text>
            </LoadingContainer>
        )
    } else {
        return (
            <Container>
                {render()}
            </Container>
        )
    }
    
}

const LoadingContainer = styled.div`
    height: 100vh;
    padding: var(--spacing);
    padding-right: 0;
    gap: var(--spacing-small);
    display: grid;
    grid-template-rows: auto 1fr;
`

const Container = styled.div`
    height: 100vh;
    padding: var(--spacing);
    padding-right: 0;
    display: grid;
    grid-template-rows: auto auto auto 1fr;
    gap: var(--spacing-small);
`

const Header = styled.div`
    display: grid;
    grid-template-columns: 1fr auto;
    align-items: center;
`

const HeaderSection = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
`

const ControlsContainer = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    border-bottom: 1px solid var(--outline-variant);
    margin-top: var(--spacing-small);
`

const ControlOption = styled.div`
    display: flex;
    align-items: center;
    column-gap: var(--spacing-small);
    padding: var(--spacing-small);
    border-bottom: ${props => props.is_selected ? '1px solid var(--primary)' : '1px solid transparent'};
    transition: border-bottom 0.6s ease; 
    cursor: pointer;
    &:hover {
        border-bottom: 1px solid var(--primary);
    }
`

const Wrapper = styled.div`
    border-radius: var(--border-radius);
    background-color: ${props => props.is_highlighted ? 'var(--surface-container)' : 'transparent'};
`