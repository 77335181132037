import React from 'react'
import styled from 'styled-components'
import Popover, { popoverClasses } from '@mui/material/Popover';
import { useHotkeys } from 'react-hotkeys-hook';
import _ from 'lodash'
import { Tooltip } from './Tooltip';

export const Pressable = props => {
    const {
        onClick,
        onContextMenu,
        onClose,
        onOpen,
        popover,
        anchorOrigin={ vertical: 'bottom', horizontal: 'left' },
        transformOrigin={ vertical: 'top', horizontal: 'left' },
        anchorReference='anchorEl',
        onClickShortcuts=[],
        onContextMenuShortcuts=[],
        disabled=false,
        tooltip,
        style
    } = props;
    const [open, setOpen] = React.useState(props.open || false)
    const [anchorEl, setAnchorEl] = React.useState(null);
    const [anchorPosition, setAnchorPosition] = React.useState(null)
    const ref = React.createRef()
    useHotkeys([...onClickShortcuts, ...onContextMenuShortcuts], (event,handler) => {
        const key = [...Object.keys(_.pickBy(handler, x => x === true)), ...handler.keys].join('+')
        if(disabled) {
            return 
        } else if(onClickShortcuts.includes(key)) {
            onClickHandler(event)
        } else if (onContextMenuShortcuts.includes(key)) {
            onContextMenuHandler(event)
        }
    }, { preventDefault: true });

    React.useEffect(() => {
        ref.current && setAnchorEl(ref.current)
        return setAnchorEl(ref.current)
    },[])

    React.useEffect(() => {
        open ? onOpen?.() : onClose?.()
    }, [open])

    React.useEffect(() => {
        open !== props.open && setOpen(props.open)
    }, [props.open])
    
    const onClickHandler = e => {
        if(!disabled) {
            onClick ? onClick(e) : popover && setOpen(!open)
        }
    }

    const onContextMenuHandler = (e) => {
        if (anchorReference === 'anchorPosition') {
            const { clientX:left, clientY:top } = e
            setAnchorPosition({top,left})
        }

        if(!disabled) {
            e.preventDefault()
            onContextMenu ? onContextMenu(e) : popover && setOpen(!open)
        }  
    }

    const onCloseHandler = () => {
        setOpen(false)
        onClose?.()
    }

    if(tooltip) {
        return (
            <Container style={style}>
                <Tooltip {...tooltip}>
                    <Wrapper 
                        ref={ref} 
                        onClick={onClickHandler}
                        onContextMenu={onContextMenuHandler}
                        disabled={disabled}
                        >
                        {props.children}
                    </Wrapper>
                </Tooltip>
                
                <Popover
                    open={open}
                    anchorEl={anchorEl}
                    onClose={onCloseHandler}
                    anchorOrigin={anchorOrigin}
                    transformOrigin={transformOrigin}
                    anchorReference={anchorReference}
                    anchorPosition={anchorPosition}
                    slotProps={slotProps}
                    >
                    {popover && React.cloneElement(popover, { close: onCloseHandler })}
                </Popover>
            </Container>
        )
    } else {
        return (
            <Container style={style}>
                <Wrapper 
                    ref={ref} 
                    onClick={onClickHandler}
                    onContextMenu={onContextMenuHandler}
                    disabled={disabled}
                    >
                    {props.children}
                </Wrapper>
                
                <Popover
                    open={open}
                    anchorEl={anchorEl}
                    onClose={onCloseHandler}
                    anchorOrigin={anchorOrigin}
                    transformOrigin={transformOrigin}
                    anchorReference={anchorReference}
                    anchorPosition={anchorPosition}
                    slotProps={slotProps}
                    >
                    {popover && React.cloneElement(popover, { close: onCloseHandler })}
                </Popover>
            </Container>
        )
    }
    
    
}

const Container = styled.div`
    
`

const Wrapper = styled.div`
    cursor: ${props => props.disabled ? 'default' : 'pointer'};
    flex-grow: 1;
`

const slotProps = {
    paper: {
        sx: {
            [`&.${popoverClasses.paper}`]: { backgroundColor: 'transparent', marginBlock: 'var(--spacing-small)' }
        }
    }
}