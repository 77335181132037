import React from 'react';
import styled from 'styled-components';
import { ContextMenu } from '../options'

import { sort_options } from 'constants'

const Container = styled.div`

`

export const SortSelector = props => {
    const {
        selected='default',
        onSelect,
        options=['default','priority','start','create_time','update_time']
    } = props;

    const getOptions = () => {
        let output = {}
        for (const [key, values] of Object.entries(sort_options)) {
            const { label, subtitle } = values;
            output[key] = {
                ...values,
                trailing: selected === key && 'Check',
                onClick: () => onSelect?.(key === 'default' ? null : key, (success,err_msg) => {
                    success && props.close()
                })
            }
        }
        return output
    }

    const data = getOptions()
    return (
        <ContextMenu
            options={options}
            data={data}
            />
    )
}