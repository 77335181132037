import React from 'react';
import styled from 'styled-components';

import { Labels, ListTile, Text, Collapsible, Icon } from 'components'
import { ContextMenu } from '../options'
import { PrioritySelector } from './PrioritySelector';
import { TagSelector } from './TagSelector';
import { DropdownSelector } from './DropdownSelector';

import { filter_options, priorities, PRIORITY_OPTIONS, schedule_options } from 'constants'
import { useSelector } from 'react-redux';

const Container = styled.div`
    width: 18rem;
    border: 1px solid var(--outline-variant);
    border-radius: var(--border-radius);
    background-color: var(--surface-container);
`

export const FilterSelector = props => {
    const {
        selected={},
        options=['priorities','tags','schedule','lists'], // priorities, tags, lists, schedule (presets, custom)
        onSelect
    } = props;
    const [focus, setFocus] = React.useState(null)
    const data = useSelector(state => state.db.data)
    const user = useSelector(state => state.db.user)

    const getSubtitle = (option) => {
        switch(option) {
            case 'priorities':
                if(!selected[option] || selected[option].length === 0) {
                    return <Labels labels={[{label: 'None selected'}]}/>
                } else {
                    return <Labels labels={selected[option].map(priority => {
                        return {
                            label: priorities[priority]['label']
                        }
                    })}/>
                }
            case 'tags':
                if(!selected[option] || selected[option].length === 0) {
                    return <Labels labels={[{label: 'None selected'}]}/>
                } else {
                    return <Labels labels={selected[option].map(tag_id => {
                        const tag = data.tags[tag_id]
                        if(tag) {
                            return {
                                label: tag['title'],
                                color: tag['color']
                            }
                        }
                    })}/>
                }
            case 'schedule':
                if(!selected[option]) {
                    return <Labels labels={[{label: 'None selected'}]}/>
                } else {
                    return <Labels labels={[{label: schedule_options[selected[option]]?.['label']}]}/>
                }
            case 'lists':
                if(!selected[option] || selected[option].length === 0) {
                    return <Labels labels={[{label: 'None selected'}]}/>
                }
            case 'status':
                return <Labels labels={[{label: 'None selected'}]}/>
        }
    }

    const getSelector = () => {
        let dropdown_options = []
        switch(focus) {
            case 'priorities':
                dropdown_options = PRIORITY_OPTIONS.map(option => {
                    const { label, color_key, value } = option
                    return {
                        label,
                        value,
                        leading: <Icon icon='FlagOutline' size={0.8} color={`var(${color_key})`}/>,
                    }
                })
                break
            case 'tags':
                dropdown_options = user['tags'].map(tag_id => {
                    const tag = data.tags[tag_id]
                    return {
                        value: tag['tag_id'],
                        label: tag['title'],
                        leading: <Icon icon='Pound' size={0.6} color={tag['color']}/>,
                    }
                })
                break
            case 'schedule':
                dropdown_options = Object.values(schedule_options).map(option => {
                    return {
                        value: option['key'],
                        label: option['label'],
                        leading: option['icon']
                    }
                })
                break
            case 'lists':
                dropdown_options = [user['inbox_id'], ...user['lists']].map(list_id => {
                    const list = data.lists[list_id]
                    if(!list) { return }

                    const is_active = !list['is_archived']
                    if(!is_active) { return }
                    return { 
                        value: list_id, 
                        label: list['title'], 
                        leading: <Icon icon='SquareRounded' size={0.6} color={list['color'] || 'var(--on-surface-variant)'}/> 
                    }
                    
                })
                break
            case 'status':
                dropdown_options = [
                    { value: 'is_completed', label: 'Completed', leading: 'CalendarCheckOutline' },
                    { value: 'is_archived', label: 'Archived', leading: 'CalendarImport' }
                ]
        }
        return (
            <DropdownSelector
                key={`${focus}_Dropdown`}
                multi={['priorities','tags','status','lists'].includes(focus) ? true : false}
                options={dropdown_options}
                initial={selected[focus]}
                onSelect={onSelectHandler}
                required={false}
                style={{border: '1px solid transparent', borderRadius: 0, padding: 'var(--spacing-smallest)', borderTop: '0px solid var(--outline-variant)', borderBottom: '1px solid var(--outline-variant)'}}
                />
        )
    }

    const onFocus = option => {
        setFocus(focus === option ? null : option)
    }

    const onSelectHandler = (value) => {
        let updated_selection = {
            ...selected,
        }
        if(focus === 'status') {
            updated_selection['is_completed'] = value.includes('is_completed')
            updated_selection['is_archived'] = value.includes('is_archived')
        } else {
            updated_selection[focus] = value
        }
        onSelect?.(updated_selection)
    }


    return (
        <Container>
            {options.map(option => {
                const { label, icon } = filter_options[option]
                const subtitle = getSubtitle(option)
                return (
                    <div>
                        <ListTile
                            label={label} leading={icon} subtitle={subtitle}
                            onClick={() => onFocus(option)}
                            style={{padding: 'var(--spacing-small)', backgroundColor: 'var(--surface-container)', margin: 'var(--spacing-smallest)'}}
                            />
                        <Collapsible collapsed={focus !== option}>
                            {getSelector(option)}
                        </Collapsible>
                    </div>
                )
            })}
        </Container>
    )
}