// import * as icons from '@mui/icons-material';
import { default as MdiIcon } from '@mdi/react';
import * as mdiPaths from '@mdi/js';

export const Icon = props => {
    const {
        icon='FileOutline',
        size=1,
        color='var(--on-surface)',
        spin=false
    } = props;

    const path = mdiPaths[`mdi${icon}`]
    if(!path) {
        return <div>?</div>
    } else {
        return (
            <MdiIcon 
                path={path}
                size={size}
                color={color}
                spin={spin}
                style={props.style}
                />
        )
    }
    
}