import React from 'react'
import styled from 'styled-components'
import { useSelector } from 'react-redux'

import { Text } from './Text'
import { Tooltip } from './Tooltip'
import { Spinner } from './Spinner'
import { Icon } from './Icon'
import Popover, { popoverClasses } from '@mui/material/Popover';
import { colorTheme, rgbaToHex } from 'utils'

const Container = styled.div`
    background-color: ${props => props.style?.backgroundColor || 'var(--surface)'};
    padding-block: var(--spacing-small);
    padding-inline: var(--spacing-small);
    /* border: 1px solid var(--outline-variant);  */
    border-radius: var(--border-radius);
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    box-sizing: content-box;
    pointer-events: ${props => props.disabled || props.loading ? 'none' : 'auto'};
    opacity: ${props => props.disabled ? 0.60 : 1};
    width: fit-content;
    gap: var(--spacing-smallest);
    /* transition: filter 0.3s ease;  */
    &:hover {
        filter: brightness(${props => props.isDark ? 1.5 : 0.95});
    }
`

export const Button = props => {
    const {
        label,
        tooltip,
        shortcut,
        icon,
        icon_color='var(--on-surface)',
        icon_size=0.8,
        trailing,
        trailing_color,
        loading=false,
        onClick,
        context_menu,
        disabled=false,
        style={},
        labelStyles={},
        anchorOrigin={ vertical: 'bottom', horizontal: 'left' },
        transformOrigin={ vertical: 'top', horizontal: 'left' },
        zIndex=1500 // Set to 1500 to prevent popper from being covered when nested in another popper (e.g. ItemOptions)
    } = props;
    const user = useSelector(state => state.db.user)
    const [anchorEl, setAnchorEl] = React.useState(null);
    const [is_dark, setDark] = React.useState(true);
    const [context, setContext] = React.useState(context_menu)

    React.useEffect(() => {
        // To ensure hover brightness adjusts properly
        let isDark = false
        let target = style?.backgroundColor || '--surface'
        if(target.includes('var')) {
            target = target.replace('var(','').replace(')','')
        }
        if(target.includes('--')) {
            target = document.documentElement.style.getPropertyValue(target)
        }
        if(target.includes('rgb')) {
            target = rgbaToHex(target)
        }
        isDark = colorTheme(target) === 'dark'
        setDark(isDark)
    }, [user && user.preferences?.['is_dark']])

    // React.useEffect(() => {
    //     if(!anchorEl) {
    //         setTimeout(() => {
    //             context_menu && setContext(context_menu)
    //         }, 300)
    //     }
    // }, [anchorEl])

    // React.useEffect(() => {
    //     setContext(context_menu)
    // },[context_menu])

   

    React.useEffect(() => {
        if(!anchorEl) {
            setTimeout(() => {
                context_menu && setContext(context_menu)
            }, 300)
        }
    }, [anchorEl])

    const onClickHandler = (e) => {
        if(onClick) {
            onClick()
        } else if (context_menu && !onClick && !anchorEl && context) {
            onContextMenu(e)
        }
    }

    const onContextMenu = (e) => {
        if(!context_menu) { return }
        e.preventDefault()
        setAnchorEl(e.currentTarget);
    }

    const context_menu_w_handler = context && React.cloneElement(context, {setContext, resetContext: () => setContext(context), close: () => setAnchorEl(null)})
    const content = 
    <Container 
        onClick={onClickHandler} 
        onContextMenu={onContextMenu} 
        disabled={disabled}
        loading={loading}
        style={style}
        isDark={is_dark}
        >
        {loading && <Spinner size={'1rem'} color={'var(--on-surface)'}/>}
        {loading ? null : icon && typeof icon === 'string' ? <Icon icon={icon} color={icon_color} size={icon_size}/> : icon}
        {label && <Text style={labelStyles}>{label}</Text>}
        {trailing && typeof trailing === 'string' ? <Icon icon={trailing} color={trailing_color} size={icon_size}/> : trailing}
        <Popover
            open={anchorEl}
            anchorEl={anchorEl}
            onClose={() => {
                setAnchorEl(null)
            }}
            anchorOrigin={anchorOrigin}
            transformOrigin={transformOrigin}
            style={{zIndex}}
            slotProps={{
                paper: {
                    sx: {
                        [`&.${popoverClasses.paper}`]: { backgroundColor: 'transparent', marginBlock: 'var(--spacing-small)' }
                    }
                }
            }}
            >
            {context_menu_w_handler}
        </Popover>
    </Container>

    if(tooltip) {
        return (
            <Tooltip {...tooltip}>
                {content}
            </Tooltip>
        )
    } else {
        return content
    }
}