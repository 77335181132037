// import * as React from 'react';
import { styled } from '@mui/material/styles';
import {default as ToolTipMUI, tooltipClasses} from '@mui/material/Tooltip';
import { Text } from './Text'

export const Tooltip = styled(({ className, ...props }) => {
  const {
    title,
    shortcut
  } = props;
  let content
  if(shortcut) {
    content = 
    <div style={{display: 'flex', flexDirection: 'row', gap: '0.3rem', alignItems: 'center'}}>
      <Text type='subtitle'>{title}</Text>
      <Text type='code' style={{fontSize: '0.8rem'}}>{shortcut}</Text>
    </div>
  } else {
    content = <Text type='subtitle'>{title}</Text>
  }
  return (
    <ToolTipMUI 
        classes={{ popper: className }}
        arrow={true}
        {...props} 
        title={content}
        slotProps={{
            popper: {
              sx: {
                [`&.${tooltipClasses.popper}[data-popper-placement*="bottom"] .${tooltipClasses.tooltip}`]: { marginTop: '0.5rem' },
                [`&.${tooltipClasses.popper}[data-popper-placement*="top"] .${tooltipClasses.tooltip}`]: { marginBottom: '0.5rem' },
                [`&.${tooltipClasses.popper}[data-popper-placement*="right"] .${tooltipClasses.tooltip}`]: { marginLeft: '0.5rem' },
                [`&.${tooltipClasses.popper}[data-popper-placement*="left"] .${tooltipClasses.tooltip}`]:{ marginRight: '0.5rem' }
              },
            },
          }}
        >
        <span>{props.children}</span>
    </ToolTipMUI>
  )
})(({ theme }) => {
    return {
        [`& .${tooltipClasses.tooltip}`]: {
            backgroundColor: 'var(--surface-container-high)',
            color: 'var(--on-surface-variant)',
            // border: '1px solid var(--outline-variant)',
            fontSize: '0.8rem',
            fontFamily: 'var(--font-family)',
            display:'flex',
            alignItems:'center',
        },
        [`& .${tooltipClasses.arrow}`]: {color:'var(--surface-container-high)'},
    }
});

