import moment from 'moment-timezone'
import _ from 'lodash'
import { parseExpression } from 'cron-parser'
import { eachDayOfInterval, eachWeekOfInterval, eachMonthOfInterval, eachYearOfInterval, getWeeksInMonth } from 'date-fns'

const days = ['SU','MO','TU','WE','TH','FR','SA']
const freq_values = {
    DAILY: 'day',
    WEEKLY: 'week',
    MONTHLY: 'month',
    YEARLY: 'year'
}

export const getCron = ({schedule, freq, interval, byday}) => {
    const byday_sorted = freq === 'WEEKLY' && days.filter(x => byday.includes(x))

    const minute = schedule.minute()
    const hour = schedule.hour()
    let day = '*'
    let month = '*'
    let week = '*'

    // 1. Get cron expression
    switch(freq) {
        case 'DAILY':
            day = `1/${interval}`
            break
        case 'WEEKLY':
            const day_values = byday_sorted.map(x => days.indexOf(x))
            week = day_values.join(',')
            day = interval === 1 ? '*' : `*/${interval*7}`
            break
        case 'MONTHLY':
            if(byday?.length > 0) {
                if(byday[0].includes('L')) {
                    week = byday[0]
                } else {
                    const day_part = byday[0].slice(1,3)
                    const month_part = parseInt(byday[0].slice(0,1))
                    week = `${month_part}#${days.indexOf(day_part)}`
                }
            } else {
                day = schedule.date() 
            }
            month = interval === 1 ? '*' : `*/${interval}`
            break
        case 'YEARLY':
            month = schedule.month() + 1
            day = interval === 0 ? `${schedule.day()}` : `${schedule.day()}/${interval*365}`

            month = interval === 0 ? `${schedule.month() + 1}` : `${schedule.month() + 1}/${interval*12}`
            day = `${schedule.day()}`
            break
    }

    // 2. Get nextDate
    const cron_expression = `${minute} ${hour} ${day} ${month} ${week}`
    return cron_expression
}

export const getDates = (cron_expression, currentDate=moment().utc().format(), endDate=moment().add(2,'month').utc().format(), count) => {
    let dates = []
    const options = {
        currentDate,
        endDate: count > 0 ? null : endDate,
        tz: 'UTC'
    }
    let cronTime = parseExpression(cron_expression, options);
    while(true) {
        try {
            if(count > 0) {
                if(dates.length >= count) {
                    break
                } else if(cronTime.hasNext()) {
                    dates.push(cronTime.next().toDate())
                } else {
                    break
                }
            } else {
                if(cronTime.hasNext()) {
                    dates.push(cronTime.next().toDate())
                } else {
                    break
                }
            }
        } catch (e) {
            break
        }
    }

    return dates.map(x => moment(x).utc().format()) // 'YYYY-MM-DDTHH:mm:ss.SSS[Z]'
}

export const computeReminder = (item, current_date=moment().endOf('day').utc().format()) => {
    const { freq, interval, until, byday:byday_unsorted, count, exdate:exdate_raw=[] } = item['repeat']
    const exdate = exdate_raw.map(x => moment(x).utc().format())
    const byday = freq === 'WEEKLY' && days.filter(x => byday_unsorted.includes(x))
    const schedule = moment(item['start']).utc()

    // 2. Get nextDate
    const cron_expression = getCron({...item['repeat'], schedule: moment(item['start']).utc()})
    const options = {
        currentDate: current_date,
        endDate: until ? moment(current_date).isBefore(until) && moment(until).utc().format() : null,
        tz: 'UTC'
    }
    let cronTime = parseExpression(cron_expression, options);
    let nextDate = cronTime.next().toDate(); // [TODO] Next to take into account exdate
    while(exdate.includes(moment(nextDate).utc().format())) {
        try {
            nextDate = cronTime.next().toDate()
        } catch (e) {
            break
        }
    }

    // 3. Check if still within range
    let lastDate;
    
    const end_type = until ? 'on'
                : count > 0 ? 'after'
                : 'never'
    switch(end_type) {
        case 'on':
            cronTime.next()
            if(cronTime.hasNext()) {
                lastDate = until
            } else {
                lastDate = nextDate
            }
            break
        case 'after':
            if(['DAILY','MONTHLY','YEARLY'].includes(freq)) {
                lastDate = moment(schedule).add(interval*(count-1),freq_values[freq]).utc().format()
            } else if (freq === 'WEEKLY') {
                let first_week_n = byday.map(x => days.indexOf(x)).filter(x => x >= schedule.day()).length
                if(!byday.includes(moment(schedule).format('dd').toUpperCase())) {
                    first_week_n += 1
                }
                const n_weeks = Math.floor((count - first_week_n) / byday.length)
                const last_week_n = count - first_week_n - n_weeks*byday.length
                lastDate = moment(schedule).add(n_weeks*interval, 'week')
                if(last_week_n > 0) {
                    lastDate.add(interval, 'week')
                }
                lastDate.subtract(lastDate.day(),'day').add(days.indexOf(byday[last_week_n-1]),'day')
                lastDate = lastDate.utc().format()
            }
            break
        case 'never':
            break
    }

    // const dates = getDates(cron_expression, current_date, lastDate && moment(lastDate).utc().format(), count).map(x => moment(x).utc().format())
    return { cron_expression, nextDate: moment(nextDate).utc().format(), lastDate: lastDate ? moment(lastDate).utc().format() : null }
}

export const parseRepeat = ({start, freq, interval=1, byday=[], count, until, exdate=[], current_date=moment().endOf('day').utc().format(), end}) => {
    let next_date
    let dates = [start]
    const byday_sorted = freq === 'WEEKLY' && days.filter(x => byday.includes(x))
    const frequency = freq_values[freq]
    const end_min = moment(current_date).add(interval,frequency).utc().format()
    const exdate_max = exdate.length > 0 && moment(_.max(exdate)).add(interval, frequency).utc().format()
    const query_end = end ? end 
                    : count ? moment(start).add(count, frequency).utc().format()
                    : exdate_max && moment(exdate_max).isAfter(end_min,'day') ? moment(exdate_max).add(interval, frequency).utc().format()
                    : end_min 

    // 1. Generate intervals
    let intervals;
    switch(freq) {
        case 'DAILY':
            intervals = eachDayOfInterval({ start, end: query_end }, { step: interval })
            dates = intervals.map(date => moment(date))
            break
        case 'WEEKLY':
            intervals = eachWeekOfInterval({ start, end: query_end }, { step: interval })
            intervals.forEach(date => {
                byday_sorted.forEach(day => {
                    dates.push(moment(date).add(days.indexOf(day),'day'))
                })
            })
            break
        case 'MONTHLY':
            intervals = eachMonthOfInterval({ start, end: query_end }, { step: interval })
            intervals.forEach(date => {
                if(byday.length > 0) {
                    byday.forEach(day => {
                        let weeks
                        let day_part
                        if(day.includes('-1')) {
                            day_part = day.replace('-1','')
                            weeks = eachWeekOfInterval({ start: moment(date).startOf('month').toDate(), end: moment(date).endOf('month').toDate() })
                            weeks = weeks.map(x => moment(x).add(days.indexOf(day_part), 'day'))
                            weeks = weeks.filter(x => moment(x).isSame(moment(date),'month'))
                            dates.push(weeks.slice(-1)[0])
                        } else if (day.length === 3) {
                            const week_part = parseInt(day[0]) - 1
                            day_part = day.slice(1,3)
                            weeks = eachWeekOfInterval({ start: moment(date).startOf('month').toDate(), end: moment(date).endOf('month').toDate() })
                            weeks = weeks.map(x => moment(x).add(days.indexOf(day_part), 'day'))
                            weeks = weeks.filter(x => moment(x).isSame(moment(date),'month'))
                            dates.push(weeks.slice(week_part)[0])
                        }
                    })
                } else {
                    dates.push(moment(date).add(moment(start).day() - 1,'day'))
                }
            })
            break
        case 'YEARLY':
            intervals = eachYearOfInterval({ start, end: query_end }, { step: interval })
            intervals.forEach(date => {
                dates.push(moment(date).set({ 
                    month: moment(start).month(),
                    date: moment(start).date()
                }))
            })
            break
    }

    // 2. Filter out dates before start
    dates = dates.filter(date => moment(date).isSameOrAfter(moment(start),'day') && moment(date).isSameOrBefore(query_end,'day'))

    // 3. Limit to count/until
    if(count) {
        dates = dates.slice(0,count)
    } else if (until) {
        dates = dates.filter(date => moment(date).isSameOrBefore(moment(until),'day'))
    }

    // 4. Filter out exdates
    if(exdate.length > 0) {
        const exdate_str = exdate.map(date => moment(date).format('YYYY-MM-DD'))
        dates = dates.filter(date => !exdate_str.includes(moment(date).format('YYYY-MM-DD')))
    }

    // 5. Add the time
    dates = _.uniq(dates.map(date => moment(date).set({ hour: moment(start).hour(), minute: moment(start).minute() }).utc().format()))

    // 5. Get next_date
    const future = dates.filter(date => moment(date).isSameOrAfter(moment(current_date),'day'))
    next_date = future?.length > 0 ? future[0] : null

    return { next_date, dates }
}