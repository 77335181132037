
import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend,
    TimeScale,
    TimeSeriesScale
  } from 'chart.js';
import { Bar } from 'react-chartjs-2';
import styled from 'styled-components';
import 'chartjs-adapter-moment';
import moment from 'moment-timezone'
import _ from 'lodash'

ChartJS.register(
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend,
    TimeScale,
    TimeSeriesScale
);

export const BarChart = props => {
    ChartJS.defaults.font.family = 'Space Grotesk'
    ChartJS.defaults.color = getComputedStyle(document.documentElement).getPropertyValue('--on-surface-variant')
    ChartJS.defaults.borderColor = getComputedStyle(document.documentElement).getPropertyValue('--outline-variant')
    ChartJS.defaults.backgroundColor = getComputedStyle(document.documentElement).getPropertyValue('--primary-container')
    const backgroundColor = getComputedStyle(document.documentElement).getPropertyValue('--primary-container')
    const selectedColor = getComputedStyle(document.documentElement).getPropertyValue('--primary')
    const {
        selected,
        dataset,
        labels,
        options={
            aspectRatio: 2,
            responsive: true,
            maintainAspectRatio: true,
            onClick: e => {
                const index = Math.floor((e.x - e.chart.chartArea.left) / (e.chart.chartArea.width / labels.length))
                props.onClick(labels[index])
            },
            plugins: {
                legend: {
                    display: false
                },
                tooltip: {
                    enabled: true,
                    displayColors: false,
                    titleMarginBottom: 1
                }
            },
            scales: {
                x: {
                    type: 'time',
                    time: { tooltipFormat: "ddd, D-MMM" },
                    grid: { display: false },
                    border: {color: 'transparent'},
                    ticks: {
                        maxRotation: 0,
                        minRotation: 0,
                        align: 'center',
                        callback: (value, index, ticks) => {
                            return moment(value).format('D') === '1' || index === 0 ? moment(value).format('MMM') : null
                        },
                    },
                },
                y: {
                    type: 'linear',
                    border: {color: 'transparent'},
                    grid: { display: true },
                    ticks: {
                        beginAtZero: false,
                        stepSize: Math.round(_.max(dataset['data']) / 3),
                        padding: 6
                    }
                }
            },
        }
    } = props;

    return (
        <Container>
            <Bar
                data={{
                    datasets: [{
                        ...dataset, 
                        backgroundColor: labels.map(label => label === selected ? selectedColor : backgroundColor ), 
                        hoverBackgroundColor: selectedColor, 
                    }],
                    labels
                }}
                options={options}                
                />
        </Container>
    )
}

const Container = styled.div`

`