import React from 'react';

import { useDispatch, useSelector } from 'react-redux';
import { ContextMenu } from './ContextMenu'

import * as actions from 'store/actions'
import moment from 'moment-timezone'
import { useToast } from 'hooks';

const ref = {
    edit: {
        label: 'Edit',
        icon: 'SquareEditOutline'
    },
    delete: {
        label: 'Delete',
        icon: 'TrashCanOutline'
    },
    archive: {
        label: 'Archive',
        icon: 'ArchiveOutline'
    },
}

export const SectionOptions = props => {
    const {
        section_id,
        options=['delete','archive'], // delete, archive,
    } = props;
    const dispatch = useDispatch()
    const { showToast } = useToast()
    const data = useSelector(state => state.db.data)
    const user = useSelector(state => state.db.user)
    const section = data.sections[section_id]
    if(!section) { return }

    const onConfirm = (option, callback) => {
        switch(option){
            case 'delete':
                setTimeout(() => {
                    dispatch(actions.deleteSection(section_id, (success, err_msg) => {
                        callback?.(success)
                        success && props.onClose?.()
                        !success && showToast({ title: 'Section not deleted', subtitle: 'Please try again', type: 'error' })
                    }))
                }, 300)
                break
            case 'archive':
                const updated_section = {
                    ...section, 
                    is_archived: !section['is_archived'],
                    archive_time: !section['is_archived'] ? moment() : null
                }
                setTimeout(() => {
                    dispatch(actions.updateSection(updated_section,(success,err_msg) => {
                        callback?.(success)
                        success && props.onClose?.()
                        !success && showToast({ title: `Section not ${section['is_archived'] ? 'unarchived' : 'archived'}`, subtitle: 'Please try again', type: 'error' })
                    }))
                }, 300)
                break
        }
    }

    return (
        <ContextMenu
            options={options}
            data={{
                edit: {
                    label: 'Edit',
                    icon: 'SquareEditOutline'
                },
                delete: {
                    label: 'Delete',
                    icon: 'TrashCanOutline',
                    inline_selector: true,
                    onConfirm
                },
                archive: {
                    label: section['is_archived'] ? 'Unarchive' : 'Archive',
                    confirm_subtitle: `Section will be ${section['is_archived'] ? 'unarchived' : 'archived'}`,
                    icon: 'ArchiveOutline',
                    inline_selector: true,
                    onConfirm
                },
            }}
            />
    )
}