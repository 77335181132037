"use client";
import { useMotionValue } from "framer-motion";
import React, { useState, useEffect } from "react";
import { useMotionTemplate, motion } from "framer-motion";
// import { cn } from "@/utils/cn";
import styled from 'styled-components';
import { twMerge } from "tailwind-merge";
import { ClassValue, clsx } from "clsx";
import './tailwind.css';

export function cn(...inputs) {
  return twMerge(clsx(inputs));
}

const Container = styled.div`
    width: 100vw;
    height: 100vh;
`

const HOVER_SIZE = '200vw'

export const EvervaultCard = ({
  text,
  className,
  children
}) => {
  let mouseX = useMotionValue(0);
  let mouseY = useMotionValue(0);

  const [randomString, setRandomString] = useState("");

  useEffect(() => {
    let str = generateRandomString(1500);
    setRandomString(str);
  }, []);

  function onMouseMove({ currentTarget, clientX, clientY }) {
    let { left, top } = currentTarget.getBoundingClientRect();
    mouseX.set(clientX - left);
    mouseY.set(clientY - top);

    const str = generateRandomString(1500);
    setRandomString(str);
  }

  return (
    <Container
      className={cn(
        "bg-transparent aspect-square  flex items-center justify-center w-full h-full relative",
        className
      )}
    >
      <div
        onMouseMove={onMouseMove}
        className="group/card w-full relative overflow-hidden bg-transparent flex items-center justify-center h-full "
      >
        <CardPattern
          mouseX={mouseX}
          mouseY={mouseY}
          randomString={randomString}
        />
        <div className="relative z-10 flex items-center justify-center">
          <div className="relative flex items-center justify-center">
            {children}
          </div>
        </div>
      </div>
    </Container>
  );
};

export function CardPattern({ mouseX, mouseY, randomString }) {
  let maskImage = useMotionTemplate`radial-gradient(${HOVER_SIZE} at ${mouseX}px ${mouseY}px, white, transparent)`;
  let style = { maskImage, WebkitMaskImage: maskImage };

  return (
    <div className="pointer-events-none">
      {/* <div className="absolute inset-0 [mask-image:linear-gradient(white,transparent)] 0 group-hover/card:opacity-50"></div>
      <motion.div
        className="absolute inset-0 bg-gradient-to-r from-green-500 to-blue-700 0 group-hover/card:opacity-100 backdrop-blur-xl transition duration-500"
        style={style}
      /> */}
      <motion.div
        className="absolute inset-0 mix-blend-overlay group-hover/card:opacity-100 "
        style={style}
      >
        <p className="absolute inset-x-0 text-5xl h-full break-words whitespace-pre-wrap text-white font-mono font-bold transition duration-1000 opacity-30 block m-0">
          {randomString}
        </p>
      </motion.div>
    </div>
  );
}

const characters =
  "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
export const generateRandomString = (length) => {
  let result = "";
  for (let i = 0; i < length; i++) {
    result += characters.charAt(Math.floor(Math.random() * characters.length));
  }
  return result;
};

export const Icon = ({ className, ...rest }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="0 0 24 24"
      strokeWidth="1.5"
      stroke="currentColor"
      className={className}
      {...rest}
    >
      <path strokeLinecap="round" strokeLinejoin="round" d="M12 6v12m6-6H6" />
    </svg>
  );
};
