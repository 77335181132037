import React from 'react';
import styled from 'styled-components';
import { useSelector, useDispatch } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';

import { Button, Text, ListOptions, Footer } from 'components'
import { AppContext } from 'screens';
import { WorkspaceModule, CalendarModule, PastModule, FullCalendarModule, UpgradeModule, PlaceholderModule } from 'modules'
import Hotkeys from 'react-hot-keys';
import { ToastContainer } from 'react-toastify';

import _ from 'lodash'
import { useToast } from 'hooks';
import { useHotkeys } from 'react-hotkeys-hook';
import { SHORTCUTS } from 'constants'
import * as actions from 'store/actions'
import moment from 'moment-timezone'

const { shortcuts, actions: shortcut_actions } = SHORTCUTS['ContentModule']
const Container = styled.div`
    height: 100vh;
    border: 0px solid yellow;
    overflow: hidden;
`

const Body = styled.div`
    /* padding: var(--spacing); */
`

export const ContentModule = props => {
    const {

    } = props;
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const { showToast } = useToast()
    const { type, id } = useParams()
    const data = useSelector(state => state.db.data)
    const user = useSelector(state => state.db.user)
    const loaded_dates = useSelector(state => state.db.loaded_dates)
    const { showNavigation, setNavigation, setModule, module } = React.useContext(AppContext)
    useHotkeys(shortcuts, (event,handler) => {
        const { target, extinfo } = module || {};
        const key = [...Object.keys(_.pickBy(handler, x => x === true)), ...handler.keys].join('+')
        const action = shortcut_actions[key]
        switch(action){
            case 'New item':
                const extinfo = {
                    defaults: { 
                        list_id : type === 'list' ? id || user['inbox_id'] : user['inbox_id'], 
                        tags: type === 'tag' ? [id] : [] 
                    }
                }
                onSide(target === 'ItemModule' ? null : 'ItemModule', extinfo)
                break
            case 'Calendar':
                onSide(target === 'CalendarModule' ? null : 'CalendarModule')
                break
            case 'Day':
                onSide(target === 'DayModule' ? null : 'DayModule')
                break
            case 'Search':
                onSide(target === 'SearchModule' ? null : 'SearchModule')
                break
            case 'Settings':
                onSide(target === 'SettingsModule' ? null : 'SettingsModule')
                break
            case 'Toggle side bar':
                setNavigation(!showNavigation)
                break
        }
    });

    React.useEffect(() => {
        type === 'item' && data.items[id] && setModule({target: 'ItemModule', extinfo: { item_id: id, type: 'module' }})
        if(type !== 'item' && module && module.target === 'ItemModule') {
            // setModule(null) // not sure why i have this?
        }
    }, [type])

    React.useEffect(() => {
        if(loaded_dates && loaded_dates.length === 0) {
            const start = moment().subtract(1,'week').startOf('day').utc().format()
            const end = moment().endOf('day').utc().format()
            dispatch(actions.loadData(user, 'by_dates', { start, end }))
        }
    }, [user])

    if(!user) { return }
    
    const onSide = (key, extinfo) => {
        const { target } = module || {};
        const is_selected = target === key
        if(is_selected) {
            setModule(null)
        } else if (!is_selected && target) {
            setModule(null)
            setTimeout(() => {
                setModule({ target: key, extinfo })
            }, 300)
        } else {
            setModule?.(is_selected ? null : { target: key, extinfo })
        }
    }

    const getContent = () => {
        let content;
        let title;
        switch(type) {
            case 'list':
                const list = data.lists[id]
                const list_view = list && data.views[list['view_id']]
                content = <WorkspaceModule key={id} view={list_view}/>
                break
            case 'item':
                content = <WorkspaceModule key={id} view={null}/>
                break
            case 'tag':
                const tag = data.tags[id]
                let tag_view = data.views[tag['view_id']]
                if(!tag) { return }
                if(!tag_view) {
                    tag_view = {
                        tags: [id],
                        type: 'list',
                        is_completed: false,
                        is_archived: false,
                        sort: 'start',
                        group: 'priority',
                        display: ['list','tags','schedule','sub_items']
                    }
                }
                content = <WorkspaceModule key={id} view={tag_view}/>
                break
            case 'past':
                content = <PastModule/>
                break
            case 'calendar':
                content = <FullCalendarModule/>
                break
            case 'upgrade':
                content = <UpgradeModule/>
                break
            case 'google_calendar':
                const gcal = data.integrations[id]
                const gcal_view = data.views[gcal?.['view_id']]
                content = <WorkspaceModule key={id} view={gcal_view}/>
                break
            default:
                content = <PlaceholderModule/>
                break
        }

        return content
    }

    const content = getContent()

    return (
        <Container>           
            {content}
            <Footer/>
            <ToastContainer/>
        </Container>
    )
}
