export const USER_TYPES = {
    FREE: 'FREE',
    PRO: 'PRO',
    LOCAL: 'LOCAL'
}

export const USER_ITEM_LIMITS = {
    FREE: 100,
    LOCAL: 50
}

export const USER_REMINDER_LIMITS = {
    FREE: 50,
}