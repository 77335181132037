import React from 'react';
import styled from 'styled-components';

import { DayPicker } from 'react-day-picker';

import moment from 'moment-timezone'

const Container = styled.div`

`

export const DateSelector = props => {
    const {
        showOutsideDays=false,
        onSelect,
        initial,
        max,
        min,
        closeOnSelect=true
    } = props;
    const [selected, setSelected] = React.useState(moment(initial).toDate())

    const onSelectHandler = (value) => {
        const new_date = moment(initial).set({ date: moment(value).date(), month: moment(value).month(), year: moment(value).year() })
        onSelect?.(new_date)
        setSelected(new_date.toDate())
        closeOnSelect && props.onClose?.()
        closeOnSelect && props.close?.()
    }

    return (
        <Container style={props.style}>
            <style>{css}</style>
            <DayPicker
                mode='single'
                defaultMonth={moment(initial).startOf('month').toDate()}
                onSelect={onSelectHandler}
                showOutsideDays={showOutsideDays}
                selected={selected}
                disabled={(value) => {
                    const new_date = moment(initial).set({ date: moment(value).date(), month: moment(value).month(), year: moment(value).year() })
                    if(max) {
                        return !moment(new_date).isBefore(max,'minute')
                    } else if (min) {
                        return !moment(new_date).isAfter(min,'minute')
                    } else {
                        return false
                    }
                }}
                />
        </Container>
    )
}

const css = `
.rdp {
    --rdp-cell-size: 40px; /* Size of the day cells. */
    --rdp-caption-font-size: 18px; /* Font size for the caption labels. */
    --rdp-accent-color: var(--surface); /* Accent color for the background of selected days. */
    --rdp-background-color: var(--surface); /* Background color for the hovered/focused elements. */
    --rdp-accent-color-dark: var(--surface); /* Accent color for the background of selected days (to use in dark-mode). */
    --rdp-background-color-dark: var(--surface); /* Background color for the hovered/focused elements (to use in dark-mode). */
    --rdp-outline: 2px solid var(--rdp-accent-color); /* Outline border for focused elements */
    --rdp-outline-selected: 3px solid var(--rdp-accent-color); /* Outline border for focused _and_ selected elements */
    --rdp-selected-color: var(--on-surface); /* Color of selected day text */

    padding: var(--spacing-smallest);
}
  
/* Hide elements for devices that are not screen readers */
.rdp-vhidden {
    box-sizing: border-box;
    padding: 0;
    margin: 0;
    background: transparent;
    border: 0;
    -moz-appearance: none;
    -webkit-appearance: none;
    appearance: none;
    position: absolute !important;
    top: 0;
    width: 1px !important;
    height: 1px !important;
    padding: 0 !important;
    overflow: hidden !important;
    clip: rect(1px, 1px, 1px, 1px) !important;
    border: 0 !important;
}
  
/* Buttons */
.rdp-button_reset {
    appearance: none;
    position: relative;
    margin: 0;
    padding: 0;
    cursor: default;
    color: inherit;
    background: none;
    font: inherit;

    -moz-appearance: none;
    -webkit-appearance: none;
}
  
.rdp-button_reset:focus-visible {
    /* Make sure to reset outline only when :focus-visible is supported */
    outline: none;
}
  
.rdp-button {
    border: 2px solid transparent;
}
  
.rdp-button[disabled]:not(.rdp-day_selected) {
    opacity: 0.25;
}
  
.rdp-button:not([disabled]) {
    cursor: pointer;
}
  
.rdp-button:focus-visible:not([disabled]) {
    color: inherit;
    background-color: var(--rdp-background-color);
    border: var(--rdp-outline);
}
  
.rdp-button:hover:not([disabled]):not(.rdp-day_selected) {
    background-color: var(--rdp-background-color);
}
  
.rdp-months {
    display: flex;
}
  
.rdp-month {
    margin: 0 1em;
}
  
.rdp-month:first-child {
    margin-left: 0;
}
  
.rdp-month:last-child {
    margin-right: 0;
}
  
.rdp-table {
    margin: 0;
    max-width: calc(var(--rdp-cell-size) * 7);
    border-collapse: collapse;
}
  
.rdp-with_weeknumber .rdp-table {
    max-width: calc(var(--rdp-cell-size) * 8);
    border-collapse: collapse;
}
  
.rdp-caption {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0;
    text-align: left;
}
  
.rdp-multiple_months .rdp-caption {
    position: relative;
    display: block;
    text-align: center;
}
  
.rdp-caption_dropdowns {
    position: relative;
    display: inline-flex;
}
  
.rdp-caption_label {
    position: relative;
    z-index: 1;
    display: inline-flex;
    align-items: center;
    margin: 0;
    padding: 0 0.25em;
    white-space: nowrap;
    color: currentColor;
    border: 0;
    border: 2px solid transparent;
    font-family: inherit;
    font-size: var(--rdp-caption-font-size);
    font-weight: bold;
}
  
.rdp-nav {
    white-space: nowrap;
}
  
.rdp-multiple_months .rdp-caption_start .rdp-nav {
    position: absolute;
    top: 50%;
    left: 0;
    transform: translateY(-50%);
}
  
  .rdp-multiple_months .rdp-caption_end .rdp-nav {
    position: absolute;
    top: 50%;
    right: 0;
    transform: translateY(-50%);
  }
  
.rdp-nav_button {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    width: var(--rdp-cell-size);
    height: var(--rdp-cell-size);
    padding: 0.25em;
    border-radius: 100%;
}
  
/* ---------- */
/* Dropdowns  */
/* ---------- */

.rdp-dropdown_year,
.rdp-dropdown_month {
    position: relative;
    display: inline-flex;
    align-items: center;
}

.rdp-dropdown {
    appearance: none;
    position: absolute;
    z-index: 2;
    top: 0;
    bottom: 0;
    left: 0;
    width: 100%;
    margin: 0;
    padding: 0;
    cursor: inherit;
    opacity: 0;
    border: none;
    background-color: transparent;
    font-family: inherit;
    font-size: inherit;
    line-height: inherit;
}

.rdp-dropdown[disabled] {
    opacity: unset;
    color: unset;
}

.rdp-dropdown:focus-visible:not([disabled]) + .rdp-caption_label {
    background-color: var(--rdp-background-color);
    border: var(--rdp-outline);
    border-radius: 6px;
}

.rdp-dropdown_icon {
    margin: 0 0 0 5px;
}

.rdp-head {
    border: 0;
}

.rdp-head_row,
.rdp-row {
    height: 100%;
}

.rdp-head_cell {
    vertical-align: middle;
    font-size: 0.75em;
    font-weight: 700;
    text-align: center;
    height: 100%;
    height: var(--rdp-cell-size);
    padding: 0;
    text-transform: uppercase;
}

.rdp-tbody {
    border: 0;
}

.rdp-tfoot {
    margin: 0.5em;
}

.rdp-cell {
    width: var(--rdp-cell-size);
    height: 100%;
    height: var(--rdp-cell-size);
    padding: 0;
    text-align: center;
}

.rdp-weeknumber {
    font-size: 0.75em;
}

.rdp-weeknumber,
.rdp-day {
    display: flex;
    overflow: hidden;
    align-items: center;
    justify-content: center;
    box-sizing: border-box;
    width: var(--rdp-cell-size);
    max-width: var(--rdp-cell-size);
    height: var(--rdp-cell-size);
    margin: 0;
    border: 2px solid transparent;
    border-radius: 100%;
}

.rdp-weeknumber,
.rdp-day:hover {
    background-color: var(--surface-container);
}


.rdp-day_today:not(.rdp-day_outside) {
    font-weight: bold;
}

.rdp-day_today {
    // background-color: var(--primary);
    color: var(--primary);
}

.rdp-day_selected,
.rdp-day_selected:focus-visible,
.rdp-day_selected:hover {
    color: var(--rdp-selected-color);
    opacity: 1;
    // background-color: var(--rdp-accent-color);
    background-color: var(--primary-container);
}

.rdp-day_outside {
    opacity: 0.5;
}

.rdp-day_selected:focus-visible {
    /* Since the background is the same use again the outline */
    outline: var(--rdp-outline);
    outline-offset: 2px;
    z-index: 1;
}

.rdp:not([dir='rtl']) .rdp-day_range_start:not(.rdp-day_range_end) {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
}

.rdp:not([dir='rtl']) .rdp-day_range_end:not(.rdp-day_range_start) {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
}

.rdp[dir='rtl'] .rdp-day_range_start:not(.rdp-day_range_end) {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
}

.rdp[dir='rtl'] .rdp-day_range_end:not(.rdp-day_range_start) {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
}

.rdp-day_range_end.rdp-day_range_start {
    border-radius: 100%;
}

.rdp-day_range_middle {
    border-radius: 0;
}

/*# sourceMappingURL=style.css.map */
`