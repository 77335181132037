import React from 'react';
import styled from 'styled-components';
import { useSelector } from 'react-redux';
import { AppContext } from 'screens';
import { useParams } from 'react-router-dom'

import { Button, Text, ListTile, PlanBlock } from 'components'

import { api } from 'services'
import { Collapsible } from 'components';
import { FREQUENCY_OPTIONS, STRIPE_PRICES, USER_ITEM_LIMITS, USER_REMINDER_LIMITS } from 'constants';
import { useToast } from 'hooks';

const plan_options = {
    free: { 
        title: 'Free', 
        subtitle: 'The start of your productivity journey', 
        price: 0, 
        features_title: 'The Free plan includes:',
        features: [
            { label: 'Up to 100 items' },
            { label: 'Up to 50 reminders' },
            { label: 'Unlimited lists' },
            { label: 'Daily agenda' },
            { label: 'Item search' },
            { label: 'Tags' },
            { label: 'Item scheduling' },
            { label: 'Push notifications', subtitle: 'iOS app available' }
        ]
    },
    pro_monthly: { 
        title: 'Pro', 
        subtitle: 'Unlock a new level of personal productivity',
        price: 4 ,
        features_title: 'Everything in Free, plus:',
        features: [
            { label: 'Unlimited items' },
            { label: 'Unlimited reminders' },
            { label: 'Calendar' },
            { label: 'Drag and drop time blocking' },
            { label: 'Google Calendar sync', subtitle: 'Coming soon' },
            { label: 'Recurring items', subtitle: 'Coming soon' }
        ],
    },
    pro_yearly: { 
        title: 'Pro', 
        subtitle: 
        'Unlock a new level of personal productivity', 
        price: 3,
        features_title: 'Everything in Free, plus:',
        features: [
            { label: 'Unlimited items' },
            { label: 'Unlimited reminders' },
            { label: 'Calendar' },
            { label: 'Drag and drop time blocking' },
            { label: 'Google Calendar sync', subtitle: 'Coming soon' },
            { label: 'Recurring items', subtitle: 'Coming soon' }
        ],
    },
}

export const UpgradeModule = props => {
    const {

    } = props;
    const { type: screen_type, id: outcome } = useParams()
    const { showToast } = useToast()
    const is_success = outcome === 'success'
    const { renderHeader, setModule, setNavigation } = React.useContext(AppContext)
    const [frequency, setFrequency] = React.useState('yearly')

    React.useEffect(() => {
        is_success && showToast({title: '🎉 Welcome on board!', subtitle: "Cheers to scaling up your day"})
    }, [])

    const header = renderHeader?.()
    return (
        <Container>
            {header}
            <Body>
                <Banner>
                    <Label>Pricing</Label>
                    <Text style={{fontSize: '2rem', fontWeight: 'bold'}}>Start for free and upgrade to unlock more features.</Text>
                    <div style={{marginTop: 'var(--spacing)', display: 'flex', flexDirection: 'row', gap: 'var(--spacing)'}}>
                        {FREQUENCY_OPTIONS.map(option => {
                        const { value, label, color, backgroundColor } = option
                        const is_selected = value === frequency
                        return (
                            <TabBlock 
                                is_selected={is_selected}
                                color={color}
                                backgroundColor={backgroundColor}
                                onClick={() => setFrequency(value)}>
                                {label}
                            </TabBlock>
                        )
                    })}
                    </div>
                </Banner>

                <Row style={{marginBottom: '10vh'}}>{['free','pro'].map(option => <PlanBlock type={option} frequency={frequency} upgrade/>)}</Row>
            </Body>
            
        </Container>
    )
}

const Container = styled.div`
    height: 100%;
    display: grid;
    grid-template-rows: auto 1fr;
`

const Body = styled.div`
    padding: var(--spacing) var(--spacing) var(--spacing) var(--spacing);
    display: flex;
    align-items: center;
    flex-direction: column;
    gap: var(--spacing);
    overflow-y: scroll;
`

const Row = styled.div`
    display: flex;
    gap: var(--spacing);
    align-items: center;
    justify-content: center;
    @media (max-width: 768px) {
        flex-direction: column;
    } 
`

const Banner = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-top: 5vh;
    margin-bottom: 5vh;
    text-align: center;
    width: 30rem;
    @media (max-width: 768px) {
        width: 70vw;
    } 
`

const Label = styled.div`
    background-image: linear-gradient(309deg, rgb(166, 221, 255) 2.25225%, rgb(51, 194, 255) 48.0785%, rgb(0, 119, 255) 100%);
    display: inline-block;
    background-clip: text;
    -webkit-text-fill-color: transparent;
    font-weight: bold;
    font-size: 1.2rem;
`

const TabBlock = styled.div`    
    border-radius: 2rem;
    padding: var(--spacing-small) var(--spacing);
    border: ${props => `2px solid ${props.is_selected ? props.color : 'transparent'}`};
    transition: all 0.3s ease;
    cursor: pointer;
    font-weight: ${props => props.is_selected ? 'bold' : 'normal'};
    font-size: 1rem;
    background-color: ${props => props.is_selected ? props.backgroundColor : 'transparent'};
    color: ${props => props.is_selected ? props.color : 'var(--on-surface-variant)'};
    &:hover {
        border: ${props => `2px solid ${props.is_selected ? props.color : props.backgroundColor}`};
        color: ${props => props.color};
        font-weight: bold;
    }
`