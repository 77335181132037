
import { spacing, layouts, dark_theme, light_theme, default_colors, typography, material_colors, on_material_colors } from '../constants'
import _ from 'lodash'

export const hextoRGB = (value, opacity=1, shade=1, tint=1) => {
    if(value.length != 7){
        // throw "Only six-digit hex colors are allowed.";
		return value
    }
	const hex = value.replace('#','')
    const aRgbHex = hex.match(/.{1,2}/g);

	let rgb = {
		r: parseInt(aRgbHex[0], 16),
		g: parseInt(aRgbHex[1], 16),
		b: parseInt(aRgbHex[2], 16)
	}
	Object.keys(rgb).forEach(key => {
		if(shade > 0 && shade !== 1) {
			rgb[key] = Math.round(rgb[key] + ((255 - rgb[key]) * shade))
		} else if (tint > 0 && tint !== 1) {
			rgb[key] = Math.round(rgb[key] * (1 - tint))
		}
	})

    const aRgb = `rgba(${rgb['r']},${rgb['g']},${rgb['b']},${opacity || 1})`
    return aRgb;
}

const inputToRGB = (value) => {
	if(value.length != 7){
        // throw "Only six-digit hex colors are allowed.";
		return value
    }
	const hex = value.replace('#','')
    const aRgbHex = hex.match(/.{1,2}/g);

	let rgb = {
		r: parseInt(aRgbHex[0], 16),
		g: parseInt(aRgbHex[1], 16),
		b: parseInt(aRgbHex[2], 16)
	}

	return rgb
}

const getBrightness = (value) => {
	const rgb = inputToRGB(value)
	return (rgb.r * 299 + rgb.g * 587 + rgb.b * 114) / 1000
}

export const colorTheme = (value) => {
	const brightness = getBrightness(value)
	return brightness < 128 ? 'dark' : 'light'
}

export const createPalette = (colors) => {
	const debug = false
    const { bgColor, primaryColor, secondaryColor } = colors
	let output = {
		'--bg-color': bgColor,
		'--primary-color': primaryColor,
		'--secondary-color': secondaryColor
	}
	for (const [key, value] of Object.entries(output)) {
		if(value) {
			const theme = colorTheme(value)
			const percents = [...Array(9).keys()].map(x => ((x+1)*0.1).toFixed(1))

			// 1. Get the shades
			percents.forEach(percent => {
				output[`${key}-${percent*1000}`] = theme === 'dark' ? hextoRGB(value,1,percent,1) : hextoRGB(value,1,1,1-percent)
			})

			// 2. Get the text color
			const prefix = key.split('-')[2]
			const textKey = prefix === 'bg' ? '--text-color' : `--${prefix}-text-color`
			output[textKey] = theme === 'dark' ? '#F5F5F5' : '#1f1f29'

			// 3. Get the shades for the text color
			percents.forEach(percent => {
				output[`${textKey}-${percent*1000}`] = theme === 'dark' ? hextoRGB(output[textKey],1,1,percent) : hextoRGB(output[textKey],1,percent,1)
			})
			
		}
	}
	debug && console.log(JSON.stringify(output, undefined, 2))
	return output
}

export const getTextColor = (color, shade) => {
    const theme = colorTheme(color)
    const textColor = theme === 'dark' ? '#F5F5F5' : '#1f1f29'
    if(shade > 0) {
        return theme === 'dark' ? hextoRGB(textColor,1,1,shade) : hextoRGB(textColor,1,shade,1)
    } else {
        return textColor
    }
}

export const getShadeColor = (color, shade=1, opacity=1, tint=1) => {
	const isHex = color.includes('#')
	const isRGB = color.includes('rgb')
	if(isHex) {
		return hextoRGB(color,opacity,shade,tint)
	} else if (isRGB) {
		return hextoRGB(rgbaToHex(color),opacity,shade,tint)
	} else {
		const parts = color.split(',')
		return `${[parts[0],parts[1],parts[2],opacity].join(',')})`
	}
	// const theme = colorTheme(color)
	// return theme === 'dark' ? hextoRGB(color,opacity,1,shade) : hextoRGB(color,opacity,shade,1)
}

export const createTheme = (theme='dark') => {
	const defaults = {
		...spacing,
		...layouts,
		...typography,
		...default_colors,
		...material_colors,
		on_material_colors
	}
	return theme === 'dark' ? {...defaults, ...dark_theme} : {...defaults, ...light_theme}
}

export const randomTagColor = () => {
	return _.sample(Object.values(material_colors))
}

export const rgbaToHex = (value) => {
	const rgbaMatch = value.match(/\d+(\.\d+)?/g); // Extract numbers from the string
	if (!rgbaMatch || rgbaMatch.length !== 4) {
		// throw new Error('Invalid RGBA string format');
		return value
	}

	const clamp = (value) => Math.min(255, Math.max(0, value));
	
	const red = clamp(parseFloat(rgbaMatch[0]));
	const green = clamp(parseFloat(rgbaMatch[1]));
	const blue = clamp(parseFloat(rgbaMatch[2]));

	const hex = (num) => num.toString(16).padStart(2, '0');
	
	return `#${hex(red)}${hex(green)}${hex(blue)}`.toUpperCase();
}