import { store } from 'store'
import DayscaledLogo from 'resources/icons/dayscaled_icon_red.png'

export const setNoti = ({ title='Test', subtitle, data={} }) => {
    const { item_id } = data
    if(!item_id) { return }
    const _data = store.getState().db.data
    const item = _data.items[item_id]
    const section = item && item['section_id'] && _data.sections[item['section_id']]
    const list = item && item['list_id'] && _data.lists[item['list_id']]
    const calendar = item && item['calendar_id'] && _data.calendars[item['calendar_id']]
    const is_valid_list = list && !list['is_archived']
    const is_valid_section = is_valid_list && (!section || section && !section['is_archived'])
    const is_valid_calendar = item['calendar_id'] ? calendar : true
    if(!is_valid_list && !is_valid_section && !is_valid_calendar) { return }    

    const options = { 
        body: subtitle, 
        icon: DayscaledLogo,
        data // { item_id: 'test item id' }
    }
    const noti = new Notification(title, options) 
    noti.onclick = (event) => {
        const { item_id } = event.target.data || {}
    }
}

export const getNotiPermission = async() => {
    if (!("Notification" in window)) {
        console.log("Browser does not support desktop notification");
        return 'unsupported'
    } else {
        return Notification.requestPermission()
        .then(result => {
            return result
        })
        .catch(err => {
            console.log(err)
        })
    }
}