import React from 'react';
import styled from 'styled-components';

import { Button, Text, Collapsible } from '../base'

const Container = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background-color: var(--surface);
    padding: var(--spacing);
    border-radius: var(--border-radius);
    /* margin: var(--spacing-smallest); */
    border: 1px solid var(--outline-variant);
`

const Row = styled.div`
    margin-top: var(--spacing-small);
    display: flex;
    align-items: center;
    justify-content: center;
    gap: var(--spacing-small);
`

export const ConfirmSelector = props => {
    const {
        onConfirm,
        onCancel,
        title='Are you sure?',
        subtitle='Action cannot be undone',
        confirm_label='Confirm',
        cancel_label='Cancel',
        default_err_msg='Something went wrong, please try again'
    } = props;
    const [loading, setLoading] = React.useState(false)

    const onConfirmHandler = () => {
        setLoading(true)
        onConfirm?.((success, err_msg) => {
            setLoading(false)
            success && props.close?.()
        })
    }

    const onCancelHandler = () => {
        props.close?.()
        onCancel?.()
    }

    return (
        <Container style={props.style}>
            <Text>{title}</Text>
            <Text type='subtitle' style={{textAlign: 'center'}}>{subtitle}</Text>
            <Row>
                <Button 
                    label={confirm_label} 
                    style={{backgroundColor: 'var(--primary)'}} 
                    labelStyles={{color: 'var(--on-primary)'}}
                    onClick={onConfirmHandler} loading={loading}
                    />
                <Button label={cancel_label} onClick={onCancelHandler}/>
            </Row>
        </Container>
    )
}