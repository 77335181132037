import { toast, Slide } from 'react-toastify';
import { Text } from 'components'
import 'react-toastify/dist/ReactToastify.css';
import styled from 'styled-components';
import { isMobile } from 'react-device-detect';

const default_style = {
    backgroundColor: 'var(--surface-container)',
    color: 'var(--on-surface)',
    border: '1px solid var(--outline-variant)',
    borderRadius: 'var(--border-radius)',
    margin: isMobile && 'var(--spacing)',
    padding: 'var(--spacing-small)'
}

const Container = styled.div`

`

export const useToast = () => {
    
    const showToast = (props) => {
        const { title, subtitle } = props;
        if(!title || title === '') { return }
        const options = {
            autoClose: 3000,
            position: 'top-center',
            hideProgressBar: true,
            transition: Slide,
            closeButton: false,
            closeOnClick: true,
            pauseOnHover: true,
            ...props,
            style: {...default_style, ...props.style}
        }
        const content = 
        <Container>
            <Text type={subtitle && 'bold'}>{title}</Text>
            <Text type='subtitle'>{subtitle}</Text>
        </Container>
        toast(content, options)
    }

    return { showToast }
}