import React from 'react';
import styled from 'styled-components';

import { TextField, Icon, Collapsible, Text, Button } from '../base'
import { HexColorPicker } from "react-colorful";

import { rgbaToHex } from 'utils'
import { material_colors } from 'constants'; 

const Container = styled.div`
    border-radius: var(--border-radius);
    border: 1px solid var(--outline-variant);
    background-color: var(--surface-container);
    display: flex;
    flex-direction: column;
    width: 18rem;
    /* gap: var(--spacing); */
`

const TextSection = styled.div`
    display: grid;
    grid-template-columns: auto 1fr;
    padding: var(--spacing-small);
    gap: var(--spacing-small);
    width: auto;
    border-bottom: 1px solid var(--outline-variant);
`

const DefaultSection = styled.div`
    display: flex;
    flex-wrap: wrap;
    gap: var(--spacing-small);
    padding: 0 var(--spacing);
`

const ColorOption = styled.div`
    border-radius: var(--border-radius);
    width: var(--spacing-large);
    aspect-ratio: 1;
    /* height: 1rem; */
    cursor: pointer;
    background-color: ${props => props.color};
`



const validateHex = (color) => {
    // Define regular expression patterns for both 3 and 6 characters hex codes
    const hexRegex3 = /^#[0-9A-Fa-f]{3}$/;
    const hexRegex6 = /^#[0-9A-Fa-f]{6}$/;

    // Check if the input string matches either 3 or 6 characters hex code pattern
    return hexRegex3.test(color) || hexRegex6.test(color);
}

const getColor = (color) => {
    if(!color) { return }
    let output = color

    if(output.includes('var')) {
        output = output.replace('var(','').replace(')','')
    }

    if(output.includes('--')) {
        output = document.documentElement.style.getPropertyValue(output)
    }

    if(output.includes('rgb')) {
        output = rgbaToHex(output)
    }

    const is_valid = validateHex(output)
    return is_valid ? output.toUpperCase() : null
}

export const ColorSelector = props => {
    const {
        initial='var(--primary)',
        onSelect,
        onSubmit
    } = props;
    const ref = React.useRef()
    const [value, setValue] = React.useState(getColor(initial))
    const [loading, setLoading] = React.useState(false)
    const [error, setError] = React.useState(null)

    React.useEffect(() => {
        error && setTimeout(() => {
            setError(null)
        }, 1000)
    }, [error])

    const onSelectHandler = (new_color) => {
        const new_hex = new_color.replace('#','').toUpperCase()
        ref.current.value = new_hex
        setValue(`#${new_hex}`)
        onSelect?.(`#${new_hex}`)
    }

    const onSubmitHandler = () => {
        if(!validateHex(value)) {
            setError('Invalid colour')
        } else {
            setLoading(true)
            setTimeout(() => {
                onSubmit?.(value, (success, err_msg) => {
                    setLoading(false)
                    !success && setError(err_msg)
                })
            }, 300)
        }
    }

    const renderDefaults = () => {
        let defaults = []
        for (const [key, value] of Object.entries(material_colors)) {
            if(key.includes('300')) {
                defaults.push(getColor(value))
            }
        }

        return (
            <DefaultSection>
                {defaults.map(hex_code => {
                    return  <ColorOption color={hex_code} onClick={() => onSelectHandler(hex_code)}/>
                })}
            </DefaultSection>
        )
    }


    return (
        <Container>
            <TextSection>
                <Icon icon='SquareRounded' color={value}/>
                <TextField 
                    placeholder='Search or create...'
                    onChange={onSelectHandler}
                    initial={value.replace('#','')}
                    ref={ref}
                    />
            </TextSection>
            <Collapsible collapsed={!error}>
                <Text type='error' style={{marginTop: 'var(--spacing-small)', marginLeft: 'var(--spacing-small)'}}>{`*${error}`}</Text>
            </Collapsible>

            <HexColorPicker 
                color={value} 
                onChange={onSelectHandler} 
                style={{ width: '100%', height: 'auto', aspectRatio: 1, padding: 'var(--spacing)' }}
                />

            {renderDefaults()}

            <Button
                label='Select'
                style={{backgroundColor: 'var(--primary)', margin: 'var(--spacing)', width: 'auto'}}
                labelStyles={{color: 'var(--on-primary)'}}
                onClick={onSubmitHandler}
                loading={loading}
                />
            
        </Container>
    )
}