import React from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { useSelector } from 'react-redux'
import styled from 'styled-components'
import { DndContext } from '@dnd-kit/core';

import { Button, Text } from 'components'
import {
    ContentModule,
    NavigationModule,
    SideModule
} from 'modules'
import { isMobile } from 'react-device-detect';
import { useToast } from 'hooks'

const Container = styled.div`
    overflow: hidden;
    width: 100vw;
    height: 100vh;
    border: 0px solid red;
    display: grid;
    grid-template-columns: min-content 1fr min-content;
    /* display: flex;
    flex-direction: row; */
`

const Header = styled.div`
    display: grid;
    grid-template-columns: auto 1fr;
    align-items: center;
    gap: var(--spacing-small);
    padding: var(--spacing);
`

export const AppContext = React.createContext(null);

export const AppScreen = props => {
    const {

    } = props;
    const navigate = useNavigate()
    const user = useSelector(state => state.db.user)
    const [showNavigation, setNavigation] = React.useState(!isMobile)
    const [module, setModule] = React.useState(null)
    // const [module, setModule] = React.useState({target: 'ItemModule', extinfo: { item_id: "666e8ddf23e72bb8aef4c5de", type: 'module' }})
    // const [module, setModule] = React.useState({target: 'TestModule'})
    const url_params = useParams()
    const { type: screen_type, id: screen_id } = url_params
    const { showToast } = useToast()

    React.useEffect(() => {
        const ext = [screen_type, screen_id, url_params['*']].filter(x => x && x !== '').join('/')        
        !user && navigate(`/loading/${ext}`)
        user && isMobile && showToast({title: 'Dayscaled is optimized for the desktop', subtitle: 'Some features on mobile might work as well'})
    }, [])

    const renderHeader = (content) => {
        return (
            <Header>
                <Button 
                    icon={showNavigation ? 'MenuOpen' : 'MenuClose'} 
                    icon_color='var(--on-surface-variant)'
                    onClick={() => setNavigation(!showNavigation)}
                    tooltip={{title: showNavigation ? 'Close navigation' : 'Open navigation', shortcut: '`'}}
                    />
                {content}
            </Header>
        )
    }

    const context = {
        showNavigation, setNavigation, 
        module, setModule, 
        renderHeader,
    }

    if(!user) {return}
    return (
        <AppContext.Provider value={context}>
            <DndContext>
                <Container showNavigation={showNavigation} showSide={module !== null}>
                    <NavigationModule/>
                    <ContentModule/>
                    <SideModule/>
                </Container>
            </DndContext>
        </AppContext.Provider>
    )
}