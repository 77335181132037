import React from 'react'
import { styled } from 'styled-components'

import { Pressable } from 'components'

export const DaySelector = props => {
    const {
        initial=[],
        onSelect        
    } = props;
    const [selected, setSelected] = React.useState(initial)

    const onSelectHandler = value => {
        const is_selected = selected.includes(value.toUpperCase())
        const new_selection = is_selected ? selected.filter(x => x !== value.toUpperCase()) : [...selected, value.toUpperCase()]
        setSelected(new_selection)
        onSelect?.(new_selection)
    }

    const getBlocks = () => {
        let output = []
        const daysOfWeek = ["Su", "Mo", "Tu", "We", "Th", "Fr", "Sa"];

        daysOfWeek.forEach((label,index) => {
            const is_selected = selected.includes(label.toUpperCase())
            output.push(
                <Pressable onClick={() => onSelectHandler(label.toUpperCase())}>
                    <Option is_selected={is_selected}>{label}</Option>
                </Pressable>
            )
        })
        return output
    }


    const blocks = getBlocks()
    return (
        <Container>
            {blocks}
        </Container>
    )
}

const Container = styled.div`
    display: flex;
    align-items: center;
    justify-content: flex-start;
    gap: var(--spacing-small);
    padding: var(--spacing-small) 0;
`

const Option = styled.div`
    border: 1px solid var(--outline-variant);
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: var(--spacing-small);
    border-radius: 50%;
    background-color: ${props => props.is_selected ? 'var(--primary-container)' : 'transparent'};
    color: ${props => props.is_selected ? 'var(--on-primary-container)' : 'var(--on-surface-variant)'};
    font-size: 0.8rem;
    transition: all 0.3s ease; 
    width: 2rem;
    aspect-ratio: 1/1;
    &:hover {
        color: var(--on-primary-container);
    }
`