import axios from 'axios'

const api = axios.create({
    baseURL: process.env.REACT_APP_API_ENDPOINT
})
const x = ''
const headers = {
    Authorization: `Bearer ${process.env.REACT_APP_CLIENT_SECRET}`
}
const objects = ['views','lists','sections','items','tags','payments','reminders','integrations','calendars']
let service = {
    createUser: payload => api.post('/user', payload),
    updateUser: (id, payload, token) => api.put(`/user/${id}`, payload, { headers: { Authorization: `Bearer ${token}${x}` }}),
    deleteUser: (id, token) => api.delete(`/user/${id}`, { headers: { Authorization: `Bearer ${token}${x}` } }),
    getUser: params => api.get('/user/', { params, headers }),
    createItemReminder: (payload, token) => api.post('/noti/item_reminder', payload, { headers: { Authorization: `Bearer ${token}${x}` } }),
    createItemReminders: (payload, token) => api.post('/noti/item_reminders', payload, { headers: { Authorization: `Bearer ${token}${x}` } }),
    deleteItemReminders: (params, token) => api.delete('/noti/item_reminders', { data: params, headers: { Authorization: `Bearer ${token}${x}` } }),
    getItemReminders: params => api.get('/noti/item_reminders', { params, headers }),
    getStripeCheckout: payload => api.post('/stripe/session/checkout', payload),
    getStripePortal: payload => api.post('/stripe/session/portal', payload),
    getGcalAuthLink: payload => api.post('/gcal', payload),
    getGcalCalendars: params => api.get('/gcal/calendars', { params, headers }),
    watchGcalCalendars: payload => api.post('/gcal/calendars', payload),
    unwatchGcalCalendars: params => api.delete('/gcal/calendars', { params, headers }),
}
Object.values(objects).forEach(object => {
    const target = object.charAt(0).toUpperCase() + object.slice(1)
    service[`create${target}`] = (payload, token) => api.post(`/${object}`, payload, { headers: { Authorization: `Bearer ${token}${x}` } })
    service[`get${target}`] = (params, token) => api.get(`/${object}`, { params, headers: { Authorization: `Bearer ${token}${x}` } })
    service[`update${target}`] = (payload, token) => api.put(`/${object}`, payload, { headers: { Authorization: `Bearer ${token}${x}` } })
    service[`delete${target}`] = (params, token) => api.delete(`/${object}`, { data: params, headers: { Authorization: `Bearer ${token}${x}` } })
})

export default service