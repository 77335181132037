import React from 'react';
import styled from 'styled-components';
import { useDispatch, useSelector } from 'react-redux';

import { ListTile, TextInput, Pressable, Icon, Text, SortableList } from 'components'
import { ContextMenu } from './ContextMenu'
import { ColorSelector, SortSelector, FilterSelector, DisplaySelector, GroupSelector } from '../selectors'

import * as actions from 'store/actions'
import { useNavigate, useParams } from 'react-router-dom';
import _ from 'lodash'
import { useToast } from 'hooks';
import { VIEW_TYPES, sort_options, group_options } from 'constants'
import { getFilterSubtitle } from 'utils'

export const TagOptions = props => {
    const {
        tag_id,
        options=['edit','color','delete'], // delete, archive,
    } = props;
    const { type: screen_type, id: screen_id } = useParams()
    const navigate = useNavigate()
    const dispatch = useDispatch()
    const { showToast } = useToast()
    const data = useSelector(state => state.db.data)
    const user = useSelector(state => state.db.user)
    const [view, setView] = React.useState(props.view || {})
    const tag = data.tags[tag_id]
    if(!tag) { return }

    const onEdit = (edits, callback) => {
        const updated_tag = {...tag, ...edits}
        dispatch(actions.updateTag(updated_tag, (success, err_msg) => {
            callback?.(success, err_msg)
            !success && showToast({ title: 'Tag not updated', subtitle: 'Please try again', type: 'error' })
        }))
    }

    const onEditView = (edits, callback) => {
        let updated_view = {...view, ...edits}
        if(updated_view['group'] === 'none') {
            updated_view['group'] = null
        }
        props.setView?.(updated_view)
        setView(updated_view)
    }

    const onConfirm = (option, callback) => {
        const is_same_screen = screen_type === 'tag' && screen_id === tag_id
        switch(option){
            case 'delete':
                dispatch(actions.deleteTag(tag_id, (success, err_msg) => {
                    callback?.(success)
                    success && props.onClose?.()
                    success && is_same_screen && navigate('/')
                    !success && showToast({ title: 'Tag not deleted', subtitle: 'Please try again', type: 'error' })
                }))
                break
        }
    }

    return (
        <ContextMenu key={`${tag_id}_Options`}
            options={options}
            data={{
                edit: {
                    label: 'Edit',
                    icon: 'SquareEditOutline',
                    trigger: 'click',
                    popper: <TextInput
                                placeholder='Edit tag...' 
                                border
                                initial={tag['title']}
                                clearOnSubmit={false}
                                onSubmit={(title, callback) => {
                                    const updated_tag= {...tag, title}
                                    if(title && title !== '') {
                                        dispatch(actions.updateTag(updated_tag, success => {
                                            callback?.(success, !success && 'Something went wrong')
                                            success && props.onClose?.()
                                            // !success && showToast({ title: 'Tag not updated', subtitle: 'Please try again', type: 'error' })
                                        }))
                                    } else {
                                        callback(false, 'Cannot be empty')
                                    }
                                    
                                }}
                                />,
                },
                color: {
                    label: 'Change color',
                    icon: 'Pound',
                    trailing:'ChevronRight',
                    popper: <ColorSelector 
                                initial={tag['color'] || 'var(--on-surface-variant)'} // var(--on-surface-variant) is the default in NavigationModule
                                onSubmit={(new_color, callback) => {
                                    onEdit({ color: new_color }, callback)
                                }}
                                />
                },
                direction: {
                    content: <Row style={{backgroundColor: 'var(--surface)', borderRadius: 'var(--border-radius)', padding: 'var(--spacing-small)', margin: 'var(--spacing-small)'}}>
                                {['list','kanban'].map(view_type => {
                                    const { label, icon } = VIEW_TYPES[view_type]
                                    const is_selected = view_type === view['type']
                                    const color = !is_selected ? 'var(--on-surface-variant)' : 'var(--on-surface)'
                                    return (
                                        <Pressable 
                                            onClick={() => onEditView({ type: view_type })} 
                                            style={{
                                                backgroundColor: is_selected ? 'var(--surface-container)' : 'var(--surface)',
                                                padding: 'var(--spacing-small)', 
                                                borderRadius: 'var(--border-radius)',
                                                border: is_selected && '1px solid var(--outline-variant)'
                                            }}>
                                            <div style={{display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center'}}>
                                                <Icon icon={icon} color={color}/>
                                                <Text style={{color}}>{label}</Text>
                                            </div>
                                        </Pressable>
                                    )
                                })}
                            </Row>
                },
                group: {
                    label: 'Group',
                    icon: 'GridLarge',
                    trailing:'ChevronRight',
                    divider: true,
                    subtitle: group_options[view['group'] || 'none']?.['label'],
                    popper: <GroupSelector 
                                onSelect={(new_group) => onEditView({ group: new_group })} 
                                selected={view['group'] || 'none'} 
                                options={['priority','schedule','create_date']}
                                />
                },
                sort: {
                    label: 'Sort',
                    icon: 'Sort',
                    trailing:'ChevronRight',
                    subtitle: sort_options[view['sort'] || 'default']['label'],
                    popper: <SortSelector 
                                selected={view['sort'] || 'default'} 
                                onSelect={(new_sort, callback) => 
                                    onEditView({ sort: new_sort }, callback)}
                                />
                },
                display: {
                    label: 'Labels',
                    icon: 'LabelMultipleOutline',
                    trailing:'ChevronRight',
                    subtitle: `${view['display']?.length > 0 ? view['display'].length : 'None'} selected`,
                    popper: <DisplaySelector 
                                onSelect={(new_display) => onEditView({ display: new_display })} 
                                selected={view['display']}
                                />
                },
                filter: {
                    label: 'Filter',
                    icon: 'FilterOutline',
                    trailing:'ChevronRight',
                    subtitle: getFilterSubtitle(view),
                    popper: <FilterSelector 
                                onSelect={(new_filters) => onEditView(new_filters)} 
                                selected={_.pick(view, ['priorities','schedule','tags','start','end'])}
                                />
                },
                delete: {
                    label: 'Delete',
                    icon: 'TrashCanOutline',
                    inline_selector: true,
                    divider: true,
                    onConfirm
                },
            }}
            />
    )
}

const Row = styled.div`
    display: grid;
    grid-template-columns: 1fr 1fr;
    align-items: center;
`