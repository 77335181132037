import React from 'react';
import styled from 'styled-components';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom'

import { ContextMenu } from './ContextMenu'
import { 
    PrioritySelector, 
    TagSelector, 
    ScheduleSelector, 
    SectionSelector,
    ReminderSelector,
    RepeatSelector
} from '../selectors'
import { EditRepeatModal } from '../modals'
import { Modal } from '../base'

import * as actions from 'store/actions'
import _ from 'lodash'
import { AppContext } from 'screens';
import { useParams } from 'react-router-dom';
import moment from 'moment-timezone'
import { useToast } from 'hooks';

export const ItemOptions = props => {
    const {
        item_id,
        options=['edit','shift','priority','schedule','tags','delete','archive'], // 'open','edit','shift','priority','schedule','reminders','tags','delete','archive'
        defaults={}
    } = props;
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const { showToast } = useToast()
    const { module={}, setModule } = React.useContext(AppContext)
    const { type: screen_type, id: screen_id } = useParams()
    const data = useSelector(state => state.db.data)
    const user = useSelector(state => state.db.user)
    const item = data.items[item_id]
    const [modal, setModal] = React.useState(null)
    if(!item) { return }
    const start = defaults['start'] || item['start']
    const end = defaults['end'] || item['end']

    const onDelete = (callback) => {
        dispatch(actions.deleteItem(item_id, success => {
            callback?.(success)
            !success && showToast({ title: 'Item not deleted', subtitle: 'Please try again', type: 'error' })
            success && props.onClose?.()
            if(success && module?.extinfo?.item_id === item_id) {
                if (screen_type === 'calendar') {
                    setModule({target: 'ItemModule', extinfo: { defaults: { start: null, end: null, is_all_day: false, list_id: user['inbox_id'] }, type: 'module_create' }})
                } else if(module.target === 'ItemModule') {
                    setModule(null)
                } 
            }
        }))
    }

    const onConfirm = (option, callback) => {
        switch(option){
            case 'delete':
                if(!_.isEmpty(item['repeat'])) {
                    setModal(
                        <EditRepeatModal
                            onConfirm={(selection, modal_callback) => {
                                switch(selection) {
                                    case 'current_only':
                                        let edits = {}
                                        const current_date = moment(start).utc().format()
                                        edits['repeat'] = {
                                            ...item['repeat'],
                                            exdate: item['repeat']['exdate'] ? [...item['repeat']['exdate'], current_date]: [current_date]
                                        }
                                        
                                        dispatch(actions.updateItem(item_id, edits, (success, err_code, updated_item) => {
                                            callback?.(success, err_code)
                                            modal_callback?.(success, err_code)
                                            setModal(null)
                                            if(!success) {
                                                let title = 'Item not updated'
                                                let subtitle = 'Please try again'
                                                showToast({ title, subtitle, type: 'error' })
                                            } else {
                                                props.onClose?.()
                                                props.close?.()
                                                const is_module_opened = module?.extinfo?.item_id === item_id
                                                is_module_opened && setModule()
                                            }
                                        }))
                                        break
                                    case 'current_and_future':
                                        onDelete(callback)
                                        break
                                }
                            }}
                            onCancel={() => setModal(null)}
                            />
                    )
                } else {
                    onDelete()
                }
                break
            case 'archive':
                onEdit({ is_archived: !item['is_archived'] }, callback)
                break
        }
    }

    const onEdit = (edits, callback) => {
        let updated_edits = {...edits}
        const trigger_modal = edits['repeat'] === null ? false 
                            : edits['repeat'] ? false
                            : edits['start'] === null ? false
                            : edits['is_completed'] ? false
                            : item['repeat']
        if(trigger_modal) {
            setModal(
                <EditRepeatModal
                    onConfirm={(selection, modal_callback) => {
                        if(selection === 'current_only') {
                            const current_date = moment(start).utc().format()
                            updated_edits['repeat'] = {
                                ...item['repeat'],
                                exdate: item['repeat']['exdate'] ? [...item['repeat']['exdate'], current_date]: [current_date]
                            }
                            updated_edits = {start, end, ...updated_edits} // To ensure start & end is reflected in the branch item
                        } else if (selection === 'current_and_future' && edits['start']) {
                            const duration = moment(item.end).diff(moment(item.start))
                            const difference = moment(edits['start']).diff(start, 'millisecond')
                            const new_start = moment(item['start']).add(difference, 'millisecond')
                            const new_end = moment(new_start).add(duration,'millisecond')
                            updated_edits['start'] = new_start
                            updated_edits['end'] = new_end
                            let new_exdate = item.repeat['exdate'].map(date => moment(date).add(difference,'millisecond').utc().format())
                            updated_edits['repeat'] = {...item.repeat, exdate: new_exdate}
                        }

                        dispatch(actions.updateItem(item_id, updated_edits, (success, err_code, updated_item) => {
                            callback?.(success, err_code)
                            modal_callback?.(success, err_code)
                            setModal(null)
                            if(!success) {
                                let title = 'Item not updated'
                                let subtitle = 'Please try again'
                                showToast({ title, subtitle, type: 'error' })
                            } else {
                                props.onClose?.()
                                props.close?.()
                                const is_module_opened = module?.extinfo?.item_id === item_id
                                is_module_opened && edits['is_archived'] && setModule()
                            }
                        }))
                    }}
                    onCancel={() => {
                        setModal(null)
                    }}
                    />
            )
        } else {
            if(edits['start'] === null) {
                updated_edits['repeat'] = null
                updated_edits['reminders'] = []
            }

            dispatch(actions.updateItem(item_id, updated_edits, (success, err_code) => {
                callback?.(success, err_code)
                if(!success) {
                    let title = 'Item not updated'
                    let subtitle = 'Please try again'
                    showToast({ title, subtitle, type: 'error' })
                } else {
                    props.onClose?.()
                    props.close?.()
                }
            }))
        }

        false && dispatch(actions.updateItem(item_id, edits, (success, err_code) => {
            callback?.(success, err_code)
            if(success) {
                props.onClose?.()
                props.close?.()
            } else {
                let title = 'Item not updated'
                let subtitle = 'Please try again'
                showToast({ title, subtitle, type: 'error' })
            }
        }))
    }

    const upgradePrompt = () => {
        showToast({title: "You've discovered a pro feature!", subtitle: 'Click here to find out more', onClick: () => navigate('/upgrade')})
    }

    return (
        <Container>
            <ContextMenu 
                options={options} 
                data={{
                    edit: {
                        label: 'Edit',
                        icon: 'SquareEditOutline',
                        onClick: () => props.setEdit?.(item_id)
                    },
                    open: {
                        label: 'Open',
                        icon: 'OpenInNew',
                        // onClick: () => props.setEdit?.(item_id)
                    },
                    shift: {
                        label: 'Shift',
                        icon: 'SwapHorizontal',
                        trailing:'ChevronRight',
                        popper: <SectionSelector
                                    initial={item['section_id'] || item['list_id']}
                                    onSelect={onEdit}
                                    />
                    },
                    priority: {
                        label: 'Priority',
                        icon: 'FlagOutline',
                        divider: true,
                        trailing:'ChevronRight',
                        popper: <PrioritySelector
                                    initial={item['priority']}
                                    onSelect={(new_priority, callback) => {
                                        onEdit?.({priority: new_priority}, callback)
                                    }}
                                    />
                    },
                    schedule: {
                        label: 'Schedule',
                        icon: 'CalendarOutline',
                        trailing:'ChevronRight',
                        popper: <ScheduleSelector 
                                    initial={{start, end, is_all_day: item['is_all_day'], ...defaults}}
                                    onSelect={(schedule, callback) => {
                                        const is_same = moment(schedule.start).isSame(moment(start)) && moment(schedule.end).isSame(moment(end)) && schedule.is_all_day === item.is_all_day
                                        if(is_same) {
                                            callback?.()
                                        } else {
                                            onEdit(schedule, callback)
                                        }
                                    }}
                                    />
                    },
                    reminders: {
                        label: 'Reminders',
                        icon: 'BellOutline',
                        trailing:'ChevronRight',
                        onClick: !user['is_pro'] && upgradePrompt,
                        popper: user['is_pro'] && <ReminderSelector
                                    initial={item['reminders']}
                                    onSelect={(new_reminders, callback) => {
                                        onEdit({reminders: new_reminders}, callback)
                                    }}
                                    />
                    },
                    repeat: {
                        label: 'Repeat',
                        icon: 'RepeatVariant',
                        trailing: 'ChevronRight',
                        onClick: !user['is_pro'] && upgradePrompt,
                        popper: user['is_pro'] && <RepeatSelector 
                                    initial={item['repeat']} 
                                    reference_date={start}
                                    onSelect={(new_repeat, callback) => onEdit({repeat: new_repeat}, callback)}
                                    />
                    },
                    tags: {
                        label: 'Tags',
                        icon: 'TagOutline',
                        trailing:'ChevronRight',
                        popper: <TagSelector
                                    initial={item['tags']} 
                                    onSelect={(new_tags, callback) => {
                                        onEdit({tags: new_tags}, callback)
                                    }}
                                    />
                    },
                    delete: {
                        label: 'Delete',
                        icon: 'TrashCanOutline',
                        divider: true,
                        inline_selector: true,
                        onConfirm
                    },
                    archive: {
                        label: item['is_archived'] ? 'Unarchive' : 'Archive',
                        confirm_subtitle: `Item will be ${item['is_archived'] ? 'unarchived' : 'archived'}`,
                        icon: 'ArchiveOutline',
                        inline_selector: true,
                        onConfirm
                    },
                }}
                />
            <Modal open={!!modal} onClose={() => setModal(null)}>{modal || <div/>}</Modal>
        </Container>
        
    )
}

const Container = styled.div`

`