import React from "react";
import styled from "styled-components";
import { getShadeColor, colorTheme } from 'utils'
import { Icon } from './Icon'

const Container = styled.div`
    border: ${props => `2px solid ${props.color}`};
    width: ${props => props.size};
    height: ${props => props.size};
    border-radius: 4px;
    background-color: ${props => props.is_selected ? getShadeColor(props.color,1,0.3) : 'transparent'};
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    &:hover {
        filter: brightness(${props => props.isDark ? 1.5 : 0.95});
        transition: filter 0.3s ease; 
    }
`

export const Check = props => {
    const {
        is_selected=false,
        onClick,
        disabled=false,
        size='1.2rem',
        label,
        labelPosition='after',
        selectedColor='rgba(0, 230, 118, 1.0)',
        style
    } = props;
    let color = is_selected && selectedColor ? selectedColor : props.color || 'var(--on-surface)'
    color = color.includes('var(') ? document.documentElement.style.getPropertyValue(color.replace('var(','').replace(')','')) : color
    const is_dark = colorTheme(color) === 'dark'
    return (
        <Container 
            color={color} 
            size={size} 
            is_dark={is_dark} 
            onClick={!disabled && onClick} 
            style={style}
            is_selected={is_selected}
            >
            {is_selected && <Icon icon='Check' color={color}/>}
        </Container>
    )
}