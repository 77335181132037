import React from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { ContextMenu } from './ContextMenu'
import { GroupSelector, SortSelector, FilterSelector, DisplaySelector } from '../selectors'
import { CalendarSelectorModal } from '../modals'

import * as actions from 'store/actions'
import { group_options, sort_options } from 'constants'
import _ from 'lodash'
import { getFilterSubtitle } from 'utils'

export const IntegrationOptions = props => {
    const {
        integration_id,
        options=['group','sort','display','filter','calendars','unlink']
    } = props;
    const dispatch = useDispatch()
    const user = useSelector(state => state.db.user)
    const data = useSelector(state => state.db.data)
    const { date_format, time_format } = user.preferences
    const integration = data.integrations[integration_id]
    const view = data.views[integration['view_id']]

    const onConfirm = (option, callback) => {
        switch(option) {
            case 'unlink':
                dispatch(actions.unlinkGcal(integration_id, success => {
                    callback?.(true)
                }))
                break
        }
    }

    const onEditView = edits => {
        let updated_view = {...view, ...edits}
        if(updated_view['group'] === 'none') {
            updated_view['group'] = null
        }
        dispatch(actions.updateView(updated_view, (success,err_msg) => {
            props.setView?.(updated_view)
        }))
    }

    return (
        <ContextMenu
            options={options}
            data={{
                group: {
                    label: 'Group',
                    subtitle: group_options[view['group'] || 'none']?.['label'],
                    icon: 'GridLarge',
                    trailing:'ChevronRight',
                    popper: <GroupSelector 
                                selected={view['group'] || 'none'} 
                                onSelect={(new_group) => onEditView({ group: new_group })} 
                                options={['none','calendars','tags','priority','schedule','create_date']}/>,
                },
                sort: {
                    label: 'Sort',
                    subtitle: sort_options[view['sort'] || 'default']['label'],
                    icon: 'Sort',
                    trailing:'ChevronRight',
                    popper: <SortSelector 
                                selected={view['sort'] || 'default'} 
                                onSelect={(new_sort, callback) => onEditView({ sort: new_sort }, callback)}
                                options={['priority','start','create_time','update_time']}
                                />,
                },
                display: {
                    label: 'Labels',
                    subtitle: `${view['display']?.length > 0 ? view['display'].length : 'None'} selected`,
                    icon: 'LabelMultipleOutline',
                    trailing:'ChevronRight',
                    popper: <DisplaySelector 
                                onSelect={(new_display) => onEditView({ display: new_display })} 
                                selected={view['display']}
                                options={['calendar','tags','schedule','repeat','sub_items','create_time','complete_time','archive_time','notes']}
                            />,
                },
                filter: {
                    label: 'Filter',
                    subtitle: getFilterSubtitle(view),
                    icon: 'FilterOutline',
                    trailing:'ChevronRight',
                    popper: <FilterSelector 
                                selected={_.pick(view, ['priorities','schedule','tags','start','end'])}
                                onSelect={(new_filters) => onEditView(new_filters)}
                                options={['priorities','tags','schedule']} 
                                />,
                },
                calendars: {
                    label: 'Calendars',
                    icon: 'CalendarBlankMultiple',
                    trailing:'ChevronRight',
                    divider: true,
                    modal: <CalendarSelectorModal integration_id={integration_id}/>
                },
                unlink: {
                    label: 'Unlink',
                    icon: 'LinkOff',
                    confirm_subtitle: 'Action cannot be undone, all linked items will be removed',
                    inline_selector: true,
                    onConfirm
                },
            }}
            />
    )
}