import _ from 'lodash'
import * as actionTypes from 'store/actions/actionTypes';

export const commit = ({ data, user, data_updates, user_updates, commit_earlier, callback, promises=[], dispatch }) => {
    if(!dispatch || !data || !user || promises.length === 0) { 
        return
    }
    if(commit_earlier) {
        data_updates && dispatch({ type: actionTypes.UPDATE_DATA, data: data_updates })
        user_updates && dispatch({ type: actionTypes.UPDATE_USER, user: {...user, ...user_updates} })
        callback?.(true)
    }
    if(process.env.REACT_APP_DISABLE_COMMIT === 'true') { 
        return !commit_earlier && callback?.(true) 
    } else {
        const promise_list = promises
        return Promise.all(promise_list)
        .then(res => {
            if(!commit_earlier) {
                data_updates && dispatch({ type: actionTypes.UPDATE_DATA, data: data_updates })
                user_updates && dispatch({ type: actionTypes.UPDATE_USER, user: {...user, user_updates} })
                callback?.(true)
            }
        })
        .catch(err => {
            console.log(err)
            if(commit_earlier) {
                data_updates && dispatch({ type: actionTypes.UPDATE_DATA, data })
                user_updates && dispatch({ type: actionTypes.UPDATE_USER, user })
                callback?.(false)
            }
        })
    }
}