import React from 'react'
import { useNavigate } from 'react-router-dom'
import styled from 'styled-components'

import { Button, Text, Icon, Spinner, BackgroundBeams } from 'components'
import DayscaledLogo from 'resources/icons/dayscaled_icon.png'
import GoogleIcon from 'resources/icons/google_icon.png'
import { getAuth } from "@firebase/auth";

import { googleLogin, appleLogin } from 'utils'
import { useDispatch } from 'react-redux'
import * as actions from 'store/actions'
import { useToast } from 'hooks'
import { persistor } from 'store'

const Container = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100vh;
    flex-direction: column;
`

const Section = styled.div`
    // background-color: var(--surface-container);
    border-radius: var(--border-radius);
    width: 30%;
    aspect-ratio: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
`

const debug = false
export const AuthScreen = props => {
    const {

    } = props;
    const navigate = useNavigate()
    const dispatch = useDispatch()
    const { showToast } = useToast()
    const [loading, setLoading] = React.useState(false)

    React.useEffect(() => {
        const auth = getAuth()
        const subscriber = auth.onAuthStateChanged(onAuth);
        return subscriber; // unsubscribe on unmount
    }, []);

    const onAuth = (values) => {
        const onboarding_data = values && {
            uid: values['uid'],
            email: values['email'] || values.providerData[0]['email'],
            displayName: values['displayName'],
            providerId: values.providerData[0]['providerId']
        }
        debug && console.log('[onAuthStateChanged]', onboarding_data)
        values && navigate('/loading')
    }

    const onSocialLogin = async(provider) => {
        if(!loading) {
            setLoading(true)
            switch(provider){
                case 'google':
                    return googleLogin()
                    .then(res => {
                        setLoading(false)
                    })
                    .catch(err => {
                        setLoading(false)
                        showToast({ title: 'Something weent wrong', subtitle: 'Please try again or refresh the screen', type: 'error' })
                    })
                case 'apple':
                    return appleLogin()
                    .then(res => {
                        setLoading(false)
                    })
                    .catch(err => {
                        showToast({ title: 'Something weent wrong', subtitle: 'Please try again or refresh the screen', type: 'error' })
                        setLoading(false)
                    })
            }
        } else {
            setLoading(false)
        }
    }

    return (
        <Container>
            
            <Button icon='ChevronLeft' 
                style={{backgroundColor: 'var(--surface)', borderWidth: '0px', position: 'absolute', top: 'var(--spacing-small)', left: 'var(--spacing-small)'}} 
                onClick={() => window.open('https://dayscaled.com','_self')}/>
            <Section>
                <img src={DayscaledLogo} style={{width: '3rem', marginBottom: 'var(--spacing)'}}/>
                <Text type='title'>Dayscaled</Text>
                <Text type='subtitle' style={{width: '300px'}}>Productivity for individuals to get full control of your time and improve your workflow</Text>

                <div style={{display: 'flex', flexDirection: 'column', gap: '1rem', marginTop: 'var(--spacing)', border: '0px solid red', width: '80%', alignItems: 'center'}}>
                    <Button 
                        label='Continue with Google' 
                        icon={<img src={GoogleIcon} style={{width: '1rem'}}/>}
                        style={{backgroundColor: '#FFF', width: '300px'}}
                        labelStyles={{color: '#000'}}
                        onClick={() => onSocialLogin('google')}
                        disabled={loading}
                        />
                    <Button 
                        label='Continue with Apple' 
                        icon='Apple' icon_color='black'
                        style={{backgroundColor: '#FFF', width: '300px'}}
                        labelStyles={{color: '#000'}}
                        onClick={() => onSocialLogin('apple')}
                        disabled={loading}
                        />
                    {loading && <Spinner/>}
                </div>
            </Section>
            {/* <BackgroundBeams/> */}
        </Container>
    )
}