import React from 'react'
import styled from 'styled-components'

import { Text, DropdownSelector, Button, NumberInput, DateSelector, DaySelector, Collapsible } from 'components'

import _ from 'lodash'
import moment from 'moment-timezone'

const default_values = {
    freq: 'WEEKLY',
    interval: 1,
    byday: [],
    // end_type: 'never',
    until: null,
    count: null,
    exdate: []
}
const freq_options = [
    { value: 'DAILY', label: 'Day' },
    { value: 'WEEKLY', label: 'Week' },
    { value: 'MONTHLY', label: 'Month' },
    { value: 'YEARLY', label: 'Year' },
]

const freq_labels = {
    DAILY: 'day',
    WEEKLY: 'week',
    MONTHLY: 'month',
    YEARLY: 'year'
}

const ends_options = [
    { value: 'never', label: 'Never' },
    { value: 'on', label: 'On' },
    { value: 'after', label: 'After' },
]

const default_after = 5

export const RepeatSelector = props => {
    const {
        initial={},
        onSelect,
        reference_date=moment()
    } = props;
    const [values, setValues] = React.useState({...default_values, byday: [moment(reference_date).format('dd').toUpperCase()], ...initial})
    const [loading, setLoading] = React.useState(false)
    const [error, setError] = React.useState(null)
    React.useEffect(() => {
        error && setTimeout(() => {
            setError(null)
        }, 1000)
    }, [error])

    const onSubmit = () => {
        setLoading(true)
        onSelect?.(values, (success, err_msg) => {
            setLoading(false)
            !success && err_msg && setError(err_msg)
            props?.close()
        })
    }

    const onCancelHandler = () => {
        if(_.isEmpty(initial)) {
            props?.close()
        } else {
            onSelect?.(null, (success, err_msg) => {
                setLoading(false)
                !success && err_msg && setError(err_msg)
                props?.close()
            })
        }
    }

    const getBlocks = () => {  
        let output = []
        const keys = Object.values(['WEEKLY','MONTHLY']).includes(values['freq']) ? ['frequency','on','ends'] : ['frequency','ends']
        keys.forEach(key => {
            let title;
            let selector;
            const { freq, interval } = values;
            const end_type = values['until'] ? 'on'
                            : values['count'] > 0 ? 'after'
                            : 'never'
            switch(key) {
                case 'frequency':
                    title = 'Every'
                    selector = 
                    <Row>
                        <NumberInput
                            initial={interval}
                            min={1}
                            max={99}
                            onChange={new_interval => setValues(old_values => { return { 
                                ...old_values, 
                                interval: new_interval,
                                // until: moment(reference_date).add(default_after*new_interval, freq_labels[old_values['freq']])
                            }})}
                            />
                        <Button
                            key={values['freq']}
                            label={`${freq_labels[freq]}${interval > 1 ? 's' : ''}`}
                            trailing='ChevronDown'
                            trailing_color={'var(--on-surface-variant)'}
                            style={{backgroundColor: 'var(--surface-container)', padding: 'var(--spacing-small)', border: '1px solid var(--outline-variant)'}}
                            context_menu={
                                <DropdownSelector 
                                    options={freq_options} 
                                    initial={values['freq']}
                                    onSelect={(new_freq) => setValues(old_values => { return {
                                        ...old_values, 
                                        freq: new_freq, 
                                        // until: moment(reference_date).add(default_after*old_values['interval'], freq_labels[new_freq])
                                    } })}
                                    />
                            }
                            />
                    </Row>
                    break
                case 'on':
                    title = 'On'
                    if(freq === 'WEEKLY') {
                        selector = <DaySelector 
                                    initial={values['byday']} 
                                    onSelect={value => setValues(old_values => { return {...old_values, byday: value} })}
                                    />
                    } else if (freq === 'MONTHLY') {
                        selector = <Button
                                    label={`${moment(reference_date).format('Do')} of each month`}
                                    disabled={true}
                                    style={{backgroundColor: 'var(--surface-container)', padding: 'var(--spacing-small) 0', border: '1px solid transparent', opacity: 1}}
                                    trailing={'InformationOutline'}
                                    trailing_color='var(--on-surface-variant)'
                                    tooltip={{title: 'Last available date of the month will be used if not available (e.g. 29th, 30th, 31st)'}}
                                    />
                    }
                    break
                case 'ends':
                    title = 'Ends'
                    let end_selector
                    switch(end_type) {
                        case 'on':
                            end_selector = 
                            <Row>
                                <Button 
                                    icon='CalendarToday'
                                    icon_color='var(--on-surface-variant)'
                                    label={moment(values['until']).format('ddd, D-MMM YYYY')}
                                    style={{backgroundColor: 'var(--surface-container)', padding: 'var(--spacing-small)', border: '1px solid var(--outline-variant)'}}
                                    context_menu={
                                        <DateSelector 
                                            initial={moment(values['until'])} 
                                            min={moment(reference_date).add(values['interval'], freq_labels[values['freq']])} 
                                            style={{backgroundColor: 'var(--surface-container)'}}
                                            onSelect={(new_value) => setValues(old_values => { return {...old_values, until: moment(new_value).utc().format()} })}
                                            />
                                    }
                                    />
                            </Row>
                            break
                        case 'after':
                            const initial_count = values['count'] || default_after
                            end_selector = 
                            <Row>
                                <NumberInput
                                    initial={initial_count}
                                    min={1}
                                    max={99}
                                    onChange={new_value => setValues(old_values => { return {...old_values, count: new_value} })}
                                    />
                                <Text>{initial_count > 1 ? 'times' : 'time'}</Text>
                            </Row>
                            break
                    }
                    selector = 
                    <Row>
                        <Button
                            key={end_type}
                            label={end_type.charAt(0).toUpperCase() + end_type.slice(1)}
                            trailing='ChevronDown'
                            trailing_color={'var(--on-surface-variant)'}
                            style={{backgroundColor: 'var(--surface-container)', padding: 'var(--spacing-small)', border: '1px solid var(--outline-variant)'}}
                            context_menu={
                                <DropdownSelector 
                                    options={ends_options} 
                                    initial={end_type}
                                    onSelect={(new_end) => setValues(old_values => { 
                                        return {
                                            ...old_values, 
                                            // end_type: new_end, 
                                            count: new_end === 'after' ? default_after : null,
                                            until: new_end === 'on' ? moment(reference_date).add(default_after*old_values['interval'], freq_labels[freq]) : null 
                                        } 
                                    })}
                                    />
                            }
                            />
                            {end_selector}
                    </Row>
                    break
            }
            output.push(
                <tr>
                    <td style={{whiteSpace: 'nowrap'}}><Text>{title}</Text></td>
                    <td style={{padding: 'var(--spacing-smallest) var(--spacing-small)'}}>{selector}</td>
                </tr>
            )
        })

        return output
    }

    const blocks = getBlocks()

    return (
        <Container>
            <table style={{padding: 'var(--spacing-small) var(--spacing)'}}>
                <tbody>
                   {blocks}
                </tbody>
            </table>

            <Footer>
                <Collapsible collapsed={!error}>
                    <Text type='error'>{`*${error}`}</Text>
                </Collapsible>

                <ButtonSection>
                    <Button
                        label={_.isEmpty(initial) ? 'Cancel' : 'Clear'}
                        style={{backgroundColor: 'var(--surface-container)'}} 
                        onClick={onCancelHandler}

                        />
                    <Button
                        label='Select'
                        style={{backgroundColor: 'var(--primary)'}}
                        onClick={onSubmit}
                        loading={loading}
                        />
                </ButtonSection>
            </Footer>

            
        </Container>
    )
}

const Container = styled.div`
    border-radius: var(--border-radius);
    border: 1px solid var(--outline-variant);
    background-color: var(--surface-container);
    min-width: 25rem;
`

const Footer = styled.div`
    display: grid;
    grid-template-columns: 1fr auto;
    /* align-items: center; */
    padding: var(--spacing-small);
    border-top: 1px solid var(--outline-variant);
`

const ButtonSection = styled.div`
    display: flex;
    justify-content: flex-end;
    align-items: center;
    gap: var(--spacing-small);
`

const Row = styled.div`
    display: flex;
    align-items: center;
    gap: var(--spacing-small);
`