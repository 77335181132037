export const default_colors = {
    '--error-color': '#ff0033',
    '--success-color': '#00E676',
    '--app-color': '#4361ee',
    '--app-text-color': '#f9f4f4',
    '--calendar-selection': 'rgba(0, 119, 182, 0.6)'
}

export const light_theme = {
    // '--primary': 'rgba(151, 47, 255, 1.0)',
    // '--on-primary': 'rgba(243, 244, 248, 1)',
    // '--primary-container': 'rgba(214, 173, 255, 1)',
    // '--on-primary-container': 'rgba(10, 0, 20, 1)',
    '--primary': 'rgba(255, 52, 10, 1.0)',
    '--on-primary': 'rgba(255, 255, 255, 1)',
    '--primary-container': 'rgba(224, 37, 0, 0.2)',
    '--on-primary-container': 'rgba(255, 255, 255, 1)',
    '--surface-dim': 'rgba(225, 226, 229, 1)',
    '--surface': 'rgba(247, 248, 252, 1)',
    '--surface-hex': '#F7F8FC',
    '--surface-bright': 'rgba(250, 251, 255, 1)',
    '--surface-container-lowest': 'rgba(245, 246, 250, 1)',
    '--surface-container-low': 'rgba(240, 241, 245, 1)',
    '--surface-container': 'rgba(232, 233, 237, 1)',
    '--surface-container-high': 'rgba(227, 228, 232, 1)',
    '--surface-container-highest': 'rgba(222, 223, 227, 1)',
    '--on-surface': 'rgba(34, 34, 35, 1.0)',
    '--on-surface-variant': 'rgba(82, 82, 82, 1.0)',
    '--outline': 'rgba(141, 141, 141, 1)',
    '--outline-variant': 'rgba(218, 220, 224, 1.0)',
    '--shadow': {
        shadowColor: "#000",
        shadowOffset: {
            width: 0,
            height: 1,
        },
        shadowOpacity: 0.18,
        shadowRadius: 1.00,
        
        elevation: 1,
        borderWidth: 0
    },
    '--priority-4': 'rgba(213, 0, 0, 1.0)',
    '--priority-4-container': 'rgba(213, 0, 0, 0.2)',
    '--priority-3': 'rgba(255, 214, 0, 1.0)',
    '--priority-3-container': 'rgba(255, 214, 0, 0.2)',
    '--priority-2': 'rgba(41, 121, 255, 1.0)',
    '--priority-2-container': 'rgba(41, 121, 255, 0.2)',
    '--priority-1': 'rgba(128, 128, 128, 1)',
    '--priority-1-container': 'rgba(31, 32, 33, 1)',
    '--error': 'rgba(255, 0, 51, 1.0)',
    '--error-container': 'rgba(255, 0, 51, 0.2)',
    '--success': 'rgba(77, 216, 149, 1.0)',
    '--success-container': 'rgba(0, 230, 118, 0.2)',
}

export const dark_theme = {
    // '--primary': 'rgba(112, 0, 224, 1.0)',
    // '--on-primary': 'rgba(245, 235, 255, 1)',
    // '--primary-container': 'rgba(51, 0, 102, 1)',
    // '--on-primary-container': 'rgba(245, 235, 255, 1)',
    '--primary': 'rgba(255, 52, 10, 1.0)',
    // '--primary': 'rgba(9, 9, 11, 1.0)',
    '--on-primary': 'rgba(255, 255, 255, 1)',
    '--primary-container': 'rgba(255, 88, 10, 0.2)',
    '--on-primary-container': 'rgba(255, 255, 255, 1)',
    '--surface-dim': 'rgba(12, 12, 13, 1)',
    '--surface': 'rgba(24, 24, 26, 1)',
    '--surface-hex': '#18181A',
    '--surface-bright': 'rgba(78, 78, 82, 1)',
    '--surface-container-lowest': 'rgba(12, 13, 13, 1)',
    '--surface-container-low': 'rgba(24, 25, 26, 1)',
    '--surface-container': 'rgba(31, 32, 33, 1)',
    '--surface-container-high': 'rgba(46, 46, 51, 1)',
    '--surface-container-highest': 'rgba(65, 66, 77, 1)',
    '--on-surface': 'rgba(243, 244, 248, 1)',
    '--on-surface-variant': 'rgba(172, 172, 172, 1)',
    '--outline': 'rgba(100, 100, 100, 1)',
    '--outline-variant': 'rgba(204, 204, 204, 0.11)',
    '--shadow': {
        shadowColor: "#fff",
        shadowOffset: {
            width: 0,
            height: 1,
        },
        shadowOpacity: 0.18,
        shadowRadius: 1.00,
        
        elevation: 1,
        borderWidth: 0
    },
    '--priority-4': 'rgba(213, 0, 0, 1.0)',
    '--priority-4-container': 'rgba(213, 0, 0, 0.2)',
    '--priority-3': 'rgba(255, 214, 0, 1.0)',
    '--priority-3-container': 'rgba(255, 214, 0, 0.2)',
    '--priority-2': 'rgba(41, 121, 255, 1.0)',
    '--priority-2-container': 'rgba(41, 121, 255, 0.2)',
    '--priority-1': 'rgba(172, 172, 172, 1)',
    '--priority-1-container': 'rgba(31, 32, 33, 1)',
    '--error': 'rgba(255, 0, 51, 1.0)',
    '--error-container': 'rgba(255, 0, 51, 0.2)',
    '--success': 'rgba(0, 230, 118, 1.0)',
    '--success-container': 'rgba(0, 230, 118, 0.2)',
}

export const accent_colors = {
    '--priority-high': 'rgba(213, 0, 0, 1.0)',
    '--priority-high-container': 'rgba(213, 0, 0, 0.2)',
    '--priority-medium': 'rgba(255, 214, 0, 1.0)',
    '--priority-medium-container': 'rgba(255, 214, 0, 0.2)',
    '--priority-low': 'rgba(41, 121, 255, 1.0)',
    '--priority-low-container': 'rgba(41, 121, 255, 0.2)',
    '--error': 'rgba(255, 0, 51, 1.0)',
    '--error-container': 'rgba(255, 0, 51, 0.2)',
    '--success': 'rgba(0, 230, 118, 1.0)',
    '--success-container': 'rgba(0, 230, 118, 0.2)',
}

export const material_colors = {
    '--red-100': 'rgba(255, 138, 128, 1.0)',
    '--red-200': 'rgba(255, 82, 82, 1.0)',
    '--red-300': 'rgba(255, 23, 68, 1.0)',
    '--red-400': 'rgba(213, 0, 0, 1.0)',
    '--pink-100': 'rgba(255, 128, 171, 1.0)',
    '--pink-200': 'rgba(255, 64, 129, 1.0)',
    '--pink-300': 'rgba(245, 0, 87, 1.0)',
    '--pink-400': 'rgba(197, 17, 98, 1.0)',
    '--purple-100': 'rgba(234, 128, 252, 1.0)',
    '--purple-200': 'rgba(224, 64, 251, 1.0)',
    '--purple-300': 'rgba(213, 0, 249, 1.0)',
    '--purple-400': 'rgba(170, 0, 255, 1.0)',
    '--deep-purple-100': 'rgba(179, 136, 255, 1.0)',
    '--deep-purple-200': 'rgba(124, 77, 255, 1.0)',
    '--deep-purple-300': 'rgba(101, 31, 255, 1.0)',
    '--deep-purple-400': 'rgba(98, 0, 234, 1.0)',
    '--blue-100': 'rgba(130, 177, 255, 1.0)',
    '--blue-200': 'rgba(68, 138, 255, 1.0)',
    '--blue-300': 'rgba(41, 121, 255, 1.0)',
    '--blue-400': 'rgba(41, 98, 255, 1.0)',
    '--cyan-100': 'rgba(132, 255, 255, 1.0)',
    '--cyan-200': 'rgba(24, 255, 255, 1.0)',
    '--cyan-300': 'rgba(0, 229, 255, 1.0)',
    '--cyan-400': 'rgba(0, 184, 212, 1.0)',
    '--teal-100': 'rgba(167, 255, 235, 1.0)',
    '--teal-200': 'rgba(100, 255, 218, 1.0)',
    '--teal-300': 'rgba(29, 233, 182, 1.0)',
    '--teal-400': 'rgba(0, 191, 165, 1.0)',
    '--green-100': 'rgba(185, 246, 202, 1.0)',
    '--green-200': 'rgba(105, 240, 174, 1.0)',
    '--green-300': 'rgba(0, 230, 118, 1.0)',
    '--green-400': 'rgba(0, 200, 83, 1.0)',
    '--amber-100': 'rgba(255, 229, 127, 1.0)',
    '--amber-200': 'rgba(255, 215, 64, 1.0)',
    '--amber-300': 'rgba(255, 196, 0, 1.0)',
    '--amber-400': 'rgba(255, 171, 0, 1.0)',
    '--orange-100': 'rgba(255, 158, 128, 1.0)',
    '--orange-200': 'rgba(255, 110, 64, 1.0)',
    '--orange-300': 'rgba(255, 61, 0, 1.0)',
    '--orange-400': 'rgba(221, 44, 0, 1.0)',
}

export const on_material_colors = {
    '--red-100-10': 'rgba(255, 138, 128, 0.1)',
    '--red-100-20': 'rgba(255, 138, 128, 0.2)',
    '--red-200-20': 'rgba(255, 82, 82, 0.2)',
    '--red-300-20': 'rgba(255, 23, 68, 0.2)',
    '--red-400-20': 'rgba(213, 0, 0, 0.2)',
    '--pink-100-20': 'rgba(255, 128, 171, 0.2)',
    '--pink-200-20': 'rgba(255, 64, 129, 0.2)',
    '--pink-300-20': 'rgba(245, 0, 87, 0.2)',
    '--pink-400-20': 'rgba(197, 17, 98, 0.2)',
    '--purple-100-20': 'rgba(234, 128, 252, 0.2)',
    '--purple-200-20': 'rgba(224, 64, 251, 0.2)',
    '--purple-300-20': 'rgba(213, 0, 249, 0.2)',
    '--purple-400-20': 'rgba(170, 0, 255, 0.2)',
    '--deep-purple-100-20': 'rgba(179, 136, 255, 0.2)',
    '--deep-purple-200-20': 'rgba(124, 77, 255, 0.2)',
    '--deep-purple-300-20': 'rgba(101, 31, 255, 0.2)',
    '--deep-purple-400-20': 'rgba(98, 0, 234, 0.2)',
    '--blue-100-20': 'rgba(130, 177, 255, 0.2)',
    '--blue-200-20': 'rgba(68, 138, 255, 0.2)',
    '--blue-300-20': 'rgba(41, 121, 255, 0.2)',
    '--blue-400-20': 'rgba(41, 98, 255, 0.2)',
    '--cyan-100-20': 'rgba(132, 255, 255, 0.2)',
    '--cyan-200-20': 'rgba(24, 255, 255, 0.2)',
    '--cyan-300-20': 'rgba(0, 229, 255, 0.2)',
    '--cyan-400-20': 'rgba(0, 184, 212, 0.2)',
    '--teal-100-20': 'rgba(167, 255, 235, 0.2)',
    '--teal-200-20': 'rgba(100, 255, 218, 0.2)',
    '--teal-300-20': 'rgba(29, 233, 182, 0.2)',
    '--teal-400-20': 'rgba(0, 191, 165, 0.2)',
    '--green-100-20': 'rgba(185, 246, 202, 0.2)',
    '--green-200-20': 'rgba(105, 240, 174, 0.2)',
    '--green-300-20': 'rgba(0, 230, 118, 0.2)',
    '--green-400-20': 'rgba(0, 200, 83, 0.2)',
    '--amber-100-20': 'rgba(255, 229, 127, 0.2)',
    '--amber-200-20': 'rgba(255, 215, 64, 0.2)',
    '--amber-300-20': 'rgba(255, 196, 0, 0.2)',
    '--amber-400-20': 'rgba(255, 171, 0, 0.2)',
    '--orange-100-20': 'rgba(255, 158, 128, 0.2)',
    '--orange-200-20': 'rgba(255, 110, 64, 0.2)',
    '--orange-300-20': 'rgba(255, 61, 0, 0.2)',
    '--orange-400-20': 'rgba(221, 44, 0, 0.2)',
}