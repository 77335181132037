import React from 'react';
import { motion, AnimatePresence } from "framer-motion";

export const Fade = props => {
    const {
        collapsed=false,
        duration=0.3
    } = props;
    
    return (
        <AnimatePresence initial={false}>
            {!collapsed && 
            <motion.div 
                layout 
                initial="collapsed" animate="open" exit="collapsed"
                transition={{ duration }}
                variants={{ open: { opacity: 1}, collapsed: { opacity: 0 } }}
                style={{overflow: 'hidden', height: '100%'}}
                >
                {props.children}
            </motion.div>}
        </AnimatePresence>
    )
}