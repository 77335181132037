import React from 'react';
import styled from 'styled-components';

import { SortableList } from './SortableList';
import { ListTile } from '../base'
import { Text, Collapsible, TextField, Button } from '../base'

const Container = styled.div`

`

export const ComboDND = props => {
    const {
        onCreate,
        onDragEnd,
        options,
        create_placeholder='Create...'
    } = props;
    const ref = React.useRef()
    const [error, setError] = React.useState(null)
    const [loading, setLoading] = React.useState(false)

    React.useEffect(() => {
        error && setTimeout(() => {
            setError(null)
        }, 1000)
    }, [error])

    const onCreateHandler = () => {
        const title = ref.current && ref.current.value
        
        if(title && title.length > 0) {
            setLoading(true)
            setTimeout(() => {
                onCreate(title, (success, err_msg) => {
                    setLoading(false)
                    !success && setError(err_msg || 'Something went wrong')
                    if(success) {
                        ref.current.value = null
                    }
                })
            }, 300)
        } else {
            setError('Required')
        }
    }

    const onDragEndHandler = ({target, over, items}, callback) => {
        const ids = items[0].map(x => x['id'])
        onDragEnd?.(ids)
    }

    const renderItem = (values) => {
        return (
            <ListTile
                {...values}
                trailing={'Drag'}
                trailing_color='var(--on-surface-variant)'
                style={{backgroundColor: 'transparent', cursor: 'move'}}
                />
        )
    }

    return (
        <Container>
            <TextSection>
                <div style={{display: 'flex', flexDirection: 'column'}}>
                    <TextField 
                        placeholder={create_placeholder}
                        onSubmit={onCreateHandler}
                        ref={ref}
                        style={{paddingLeft: 'var(--spacing-small)'}}
                        />
                    <Collapsible collapsed={!error}>
                        <Text type='error'>{`*${error}`}</Text>
                    </Collapsible>
                </div>
                <Button 
                    icon='Plus'
                    loading={loading} 
                    onClick={onCreateHandler} 
                    style={{backgroundColor: 'var(--surface-container)'}}
                    />
            </TextSection>
            <div style={{padding: 'var(--spacing-smallest)'}}>
                {options.length > 0 ?
                <SortableList
                    data={[options]}
                    renderItem={renderItem}
                    renderDragPlaceholder={(values) => {
                        return <DndPlaceholder>{renderItem(values)}</DndPlaceholder>
                    }}
                    onDragEnd={onDragEndHandler}
                    />
                : <Text type='placeholder' style={{padding: 'var(--spacing-small)'}}>No active sections</Text>}
            </div>
        </Container>
    )
}

const DndPlaceholder = styled.div`
    border: 1px solid var(--outline);
    border-radius: var(--border-radius);
    opacity: 0.5;
`

const TextSection = styled.div`
    display: grid;
    grid-template-columns: 1fr auto;
    gap: var(--spacing-smallest);
    width: auto;
    border-bottom: 1px solid var(--outline-variant);
    padding: var(--spacing-smallest);
    align-items: center;
`