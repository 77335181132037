export const priorities = {
    4: { label: 'Normal', color_key: '--priority-1' },
    3: { label: 'Low', color_key: '--priority-2' },
    2: { label: 'Medium', color_key: '--priority-3' },
    1: { label: 'High', color_key: '--priority-4' }
}

export const PRIORITY_OPTIONS = [
    { label: 'Normal', color_key: '--priority-1', value: 4 },
    { label: 'Low', color_key: '--priority-2', value: 3 },
    { label: 'Medium', color_key: '--priority-3', value: 2 },
    { label: 'High', color_key: '--priority-4', value: 1 }
]