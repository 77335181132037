export let SHORTCUTS = {
    ContentModule: {
        title: 'Access side modules',
        shortcuts: ['`','/','shift+n','shift+c','shift+d'],
        actions: {
            '`': 'Side bar',
            '/': 'Search',
            'shift+n': 'New item',
            'shift+c': 'Calendar',
            'shift+d': 'Day',
            
            'backquote': 'Toggle side bar',
        }
    },
    ItemModule: {
        title: 'When an item is selected',
        shortcuts: ['ArrowDown','ArrowUp','enter','shift+enter','shift+delete','e+i','e+l','e+p','e+s','e+r','e+t','e+n','e+n','c+s','o'],
        actions: {
            'e+i': 'Edit item title',
            'e+p': 'Edit priority',
            'e+l': 'Edit list/section',
            'e+s': 'Edit schedule',
            'e+r': 'Edit reminders',
            'e+t': 'Edit tags',
            'e+n': 'Edit notes',
            'o': 'Open options',
            'c+s': 'Create sub-item',
            'ArrowDown': 'Next property',
            'ArrowUp': 'Previous property',
            'down': 'Next property',
            'up': 'Previous property',
            // 'enter': 'Select property',
            // 'shift+enter': 'Unselect property'
            'shift+enter': 'Select property',
            'shift+delete': 'Unselect property'
        },
    },
    DayModule: {
        title: 'When the day module is opened',
        shortcuts: ['t+1','t+2','t+3','ArrowRight','ArrowLeft','ArrowDown','ArrowUp','enter'],
        actions: {
            'ArrowRight': 'Next day',
            'ArrowLeft': 'Previous day',
            'ArrowDown': 'Next item',
            'ArrowUp': 'Previous item',
            'enter': 'Select item',
            't+1': 'Switch to scheduled tab',
            't+2': 'Switch to completed tab',
            't+3': 'Switch to created tab',
            'down': 'Next item',
            'up': 'Previous item',
            'left': 'Previous day',
            'right': 'Next day'
        }   
    },
    SearchModule: {
        title: 'When the search module is opened',
        shortcuts: ['ArrowDown','ArrowUp','enter'],
        actions: {
            'ArrowDown': 'Next item',
            'ArrowUp': 'Previous item',
            'enter': 'Select item',
            'down': 'Next item',
            'up': 'Previous item',
        }
    },
    CalendarModule: {
        title: 'When the calendar module is opened',
        shortcuts: ['d','w','m','ArrowLeft','ArrowRight'],
        actions: {
            'd': 'Day view',
            'w': 'Week view',
            'm': 'Month view',
            'left': 'Move view left',
            'right': 'Move view right'
        }
    },
    PrioritySelector: {
        title: 'When editing item priority',
        shortcuts: ['p+1','p+2','p+3','p+4'],
        actions: {
            'p+1': 'Normal',
            'p+2': 'Low',
            'p+3': 'Medium',
            'p+4': 'High'
        }
    },
    ScheduleSelector: {
        title: 'When editing schedule',
        shortcuts: ['shift+delete','shift+enter','e+1','e+2','a'],
        actions: {
            'shift+delete': 'Clear schedule',
            'shift+enter': 'Update schedule',
            'e+1': 'Edit start',
            'e+2': 'Edit end',
            'a': 'Toggle all day'
        }
    },
    ReminderSelector: {
        title: "When selecting reminders",
        shortcuts: ['ArrowUp','ArrowDown','enter','shift+enter','c+a','c+1','c+2','c+3'],
        actions: {
            'ArrowDown': 'Previous selection',
            'ArrowUp': 'Next selection',
            'up': 'Previous selection',
            'down': 'Next selection',
            'shift+enter': 'Select reminder',
            'enter': 'Submit',
            'c+a': 'Add custom reminder',
            'c+1': 'Minute reminder',
            'c+2': 'Hour reminder',
            'c+3': 'Day reminder'
        }
    },
    TagSelector: {
        title: 'When selecting tags',
        shortcuts: ['ArrowUp','ArrowDown','enter','shift+delete','shift+enter'],
        actions: {
            'ArrowDown': 'Previous selection',
            'ArrowUp': 'Next selection',
            'shift+enter': 'Select tag',
            'shift+delete': 'Clear',
            'enter': 'Submit'
        }
    },
    SectionSelector: {
        title: "When selecting an item's list/section",
        shortcuts: ['ArrowUp','ArrowDown','enter','shift+enter'],
        actions: {
            'ArrowDown': 'Previous selection',
            'ArrowUp': 'Next selection',
            'shift+enter': 'Submit',
            'enter': 'Select tag'
        }
    },
}

Object.keys(SHORTCUTS).forEach(key => {
    const { actions } = SHORTCUTS[key]
    let shortcut_keys = {}
    for (const [key, value] of Object.entries(actions)) {
        if(!['ArrowUp','ArrowDown','ArrowRight','ArrowLeft'].includes(key)) {
            shortcut_keys[value] = key
        }
    }
    SHORTCUTS[key]['shortcut_keys'] = shortcut_keys
})