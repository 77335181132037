import React from 'react'
import styled from 'styled-components'

import { Text, Spinner } from 'components'
import { useParams } from 'react-router-dom'

export const RedirectScreen = props => {
    const params = Object.fromEntries(new URLSearchParams(window.location.search))
    const { type, success, reason } = params
    let title = 'Redirecting'
    let subtitle
    switch(type) {
        case 'integration':
            title = success === 'true' ? 'Link completed' 
                    : success === 'false' && reason === 'cancelled' ? 'Link cancelled'
                    : 'Link failed'
            subtitle = 'You may close this window'
            break
    }

    return (
        <Container>
            <Column>
                <Text type='bold'>{title}</Text>
                {subtitle && <Text type='subtitle'>{subtitle}</Text>}
            </Column>
            <Spinner color='var(--primary)'/>
        </Container>
    )
}

const Container = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    height: 100vh;
    gap: var(--spacing-small);
`

const Column = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
`