import React from 'react';
import styled from 'styled-components';

import { ListTile, Button, Text, Collapsible, Icon, Pressable } from '../base'

import { formatDuration } from "date-fns";
import _ from 'lodash'
import { useHotkeys } from 'react-hotkeys-hook';
import { SHORTCUTS } from 'constants'

const CustomReminderSelector = React.forwardRef((props, forwardedRef) => {
    const {
        onAdd,
        excluded=[],
        initial_frequency='mins'
    } = props;
    const [value, setValue] = React.useState(5) // in minutes
    const [frequency, setFrequency] = React.useState(initial_frequency)
    const [error, setError] = React.useState(null)
    // const ref = React.useRef()
    const ref = forwardedRef || React.createRef()
    useHotkeys(['c+a'], (event, handler) => {
        const key = [...Object.keys(_.pickBy(handler, x => x === true)), ...handler.keys].join('+')
        switch(key){
            case 'c+a':
                onAddHandler()
                break
        }
    }, { enableOnFormTags: ['INPUT'], preventDefault: true });

    React.useEffect(() => {
        ref.current.value = 5
    }, [])

    React.useEffect(() => {
        ref.current.focus()
        setFrequency(initial_frequency)
    }, [initial_frequency])

    React.useEffect(() => {
        setTimeout(() => {
            error && setError(null)
        }, 1000)
    }, [error])

    const onAddHandler = () => {
        const multiplier = frequency === 'hours' ? 60
                            : frequency === 'days' ? 1440
                            : 1
        const new_value = value * multiplier
        if(excluded.includes(parseInt(new_value))) {
            setError('Added already')
        } else {
            onAdd(new_value)
        }
    }

    const renderFreq = () => {
        const options = ['mins','hours','days']
        let output = []
        options.forEach((option, index) => {
            const is_selected = frequency === option
            output.push(
                <Pressable 
                    onClick={() => setFrequency(option)}
                    tooltip={{ title: `Switch to ${option}`, shortcut: `c+${index+1}` }}
                    >
                    <Text style={{color: is_selected ? 'var(--on-surface)' : 'var(--on-surface-variant)', transition: 'color 0.3s ease'}}>{option}</Text>
                </Pressable>
            )

            if(index !== 2) {
                output.push(<Icon icon='SlashForward' color='var(--on-surface-variant)' size={0.6}/>)
            }
        })

        return <div style={{display: 'flex', alignItems: 'center'}}>{output}</div>
    }

    return (
        <CustomWrapper>
            <CustomContainer>
                <div style={{display: 'flex', gap: 'var(--spacing-smallest)', alignItems: 'center'}}>
                    <Input
                        ref={ref}
                        type='number'
                        autoFocus={false}
                        onChange={(e) => setValue(e.target.value)}
                        />
                    {renderFreq()}
                    <Text> before</Text>
                </div>
                <Button
                    icon='Plus'
                    onClick={onAddHandler}
                    style={{backgroundColor: 'var(--surface-container)'}}
                    tooltip={{title: 'Add custom', shortcut: 'c+a'}}
                    />
            </CustomContainer>

            <Collapsible collapsed={!error}>
                <Text type='error'>{`*${error}`}</Text>
            </Collapsible>
        </CustomWrapper>
    )
})

const getLabel = minutes => {
    return minutes === 0 ? 'At start of event' 
            : formatDuration({
                days: Math.round(minutes/1440),
                hours: Math.round(Math.round(minutes%1440)/60), 
                minutes: Math.round(Math.round(minutes%1440)%60)
            }, { format: ['days','hours','minutes'] })
            + ' before'
}

const { shortcuts, actions: shortcut_actions } = SHORTCUTS['ReminderSelector']
const defaults = [0, 10, 60, 1440, 1440 * 7] 
export const ReminderSelector = props => {
    const {
        initial=[],
        onSelect,
    } = props;
    const [selected, setSelected] = React.useState(initial.map(x => parseInt(x)))
    const [loading, setLoading] = React.useState(false)
    const [error, setError] = React.useState(null)
    const ref = React.useRef()
    const [custom_frequency, setCustomFrequency] = React.useState('mins')
    const [highlight, setHighlight] = React.useState(null)
    useHotkeys(shortcuts, (event, handler) => {
        const key = [...Object.keys(_.pickBy(handler, x => x === true)), ...handler.keys].join('+')
        const options = [...selected, ...defaults.filter(x => !selected.includes(x))]
        const action = shortcut_actions[key]
        switch(action){
            case 'Next selection':
                options.length > 0 &&
                setHighlight(highlight === null ? 0 
                            : highlight === options.length - 1 ? 0
                            : highlight + 1)
                break
            case 'Previous selection':
                options.length > 0 &&
                setHighlight(highlight === null ? options.length - 1
                            : highlight === 0 ? options.length - 1
                            : highlight - 1)
                break
            case 'Select reminder':
                highlight !== null && onSelectHandler(options[highlight])
                break
            case 'Submit':
                onSubmitHandler()
                break
            case 'Minute reminder':
                setCustomFrequency('mins')
                ref.current?.focus()
                break
            case 'Hour reminder':
                setCustomFrequency('hours')
                ref.current?.focus()
                break
            case 'Day reminder':
                setCustomFrequency('days')
                ref.current?.focus()
                break
        }
    }, { enableOnFormTags: ['INPUT'], preventDefault: true });

    React.useEffect(() => {
        error && setTimeout(() => {
            setError(null)
        }, 1000)
    }, [error])

    const onSelectHandler = (minutes) => {
        let updated_selection = [...selected]
        if(updated_selection.includes(minutes)) {
            updated_selection = updated_selection.filter(x => x !== minutes)
        } else {
            updated_selection.push(minutes)
        }

        updated_selection = updated_selection.sort((a,b) => a - b)
        setSelected(updated_selection)
    }

    const onSubmitHandler = () => {
        setLoading(true)
        setTimeout(() => {
            onSelect?.(selected, (success, err_msg) => {
                setLoading(false)
                err_msg && setError(err_msg)
                success && props.close?.()
            })
        }, 300)
    }

    const renderContent = () => {
        let output = []
        const options = [...selected, ...defaults.filter(x => !selected.includes(x))]
        options.forEach((minutes, index) => {
            const is_highlighted = highlight === index
            const is_selected = selected.includes(minutes)
            output.push(
                <ListTile
                    label={getLabel(minutes)}
                    onClick={() => onSelectHandler(minutes)}
                    trailing={is_selected && 'Check'}
                    style={{backgroundColor: is_highlighted ? 'var(--surface)' : 'var(--surface-container)'}}
                    />
            )
        })
        return output
    }

    const content = renderContent()

    return (
        <Container>
            <CustomReminderSelector ref={ref} onAdd={onSelectHandler} excluded={selected} initial_frequency={custom_frequency}/>
            <Section>{content}</Section>
            
            <Button
                label='Select'
                style={{backgroundColor: 'var(--primary)', margin: 'var(--spacing)', marginTop: 0, marginBottom: error ? 'var(--spacing-small)' : 'var(--spacing)',  width: 'auto'}}
                labelStyles={{color: 'var(--on-primary)'}}
                onClick={onSubmitHandler}
                loading={loading}
                tooltip={{title: 'Select', shortcut: 'shift+enter'}}
                />
            <Collapsible collapsed={!error}>
                <Text type='error' style={{margin: 'var(--spacing)', marginTop: 0}}>{`*${error}`}</Text>
            </Collapsible>
        </Container>
    )
}

const Container = styled.div`
    border-radius: var(--border-radius);
    border: 1px solid var(--outline-variant);
    background-color: var(--surface-container);
    /* width: 18rem; */
`

const CustomWrapper = styled.div`
    border-bottom: 1px solid var(--outline-variant);
    padding: var(--spacing-small);
    padding-left: var(--spacing);
    display: flex;
    flex-direction: column;
`

const CustomContainer = styled.div`
    display: grid;
    grid-template-columns: auto 1fr;
    /* gap: var(--spacing); */
    width: auto;
    align-items: center;
    justify-content: space-between;
`

const Section = styled.div`
    padding: var(--spacing-small) var(--spacing);
    max-height: 10rem;
    overflow-y: auto;
`

const Input = styled.input`
    background-color: transparent;
    border: none;
    width: 100%;
    resize: none;
    overflow: none;
    outline: none;
    color: var(--on-surface);
    width: 2rem;
    border-bottom: 1px solid var(--outline);
    text-align: center;
    
`