import React from 'react';
import styled from 'styled-components';

import { ListTile } from '../base'

import _ from 'lodash'
import { useHotkeys } from 'react-hotkeys-hook';

const Container = styled.div`
    overflow-y: auto;
    border-radius: var(--border-radius);
    border: 1px solid var(--outline-variant);
    background-color: var(--surface-container);
    min-width: 15rem;
    padding: var(--spacing-smallest);
    max-height: 20rem;
`

export const DropdownSelector = props => {
    const {
        options,
        onSelect,
        initial,
        multi=false,
        required=true,
        closeOnSelect=false
    } = props;
    const [selected, setSelected] = React.useState(initial !== null ? initial : (multi ? [] : null))
    const [highlight, setHighlight] = React.useState(null)
    useHotkeys(['ArrowUp','ArrowDown','enter'], (event, handler) => {
        const key = [...Object.keys(_.pickBy(handler, x => x === true)), ...handler.keys].join('+')
        switch(key){
            case 'down':
                options.length > 0 &&
                setHighlight(highlight === null ? 0 
                            : highlight === options.length - 1 ? 0
                            : highlight + 1)
                break
            case 'up':
                options.length > 0 &&
                setHighlight(highlight === null ? options.length - 1
                            : highlight === 0 ? options.length - 1
                            : highlight - 1)
                break
            case 'enter':
                highlight !== null && onSelectHandler(options[highlight]['value'])
                break
        }
    });

    const onSelectHandler = (option) => {
        let new_selection = required ? option : option === selected ? null : option
        if(multi) {
            new_selection = !selected ? [option] : selected?.includes(option) ? [...selected].filter(x => x !== option) : [...selected, option]
        }
        setSelected(new_selection)
        setTimeout(() => {
            onSelect?.(new_selection)
            closeOnSelect && props.close?.()
        }, 100)
    }

    const renderContent = () => {
        let output = []
        options.forEach((option, index) => {
            if(!option) { return }
            const { label, subtitle, leading, value } = option
            const is_selected = !multi ? selected === value : selected?.includes(value)
            output.push(
                <ListTile
                    label={label} subtitle={subtitle}
                    leading={leading}
                    onClick={() => onSelectHandler(value)}
                    trailing={is_selected && 'Check'}
                    style={{backgroundColor: index === highlight ? 'var(--surface)' : 'var(--surface-container)'}}
                    />
            )
        })

        return output
    }

    const content = renderContent()

    return (
        <Container style={props.style}>
            {content}
        </Container>
    )
}