import React from 'react';
import styled from 'styled-components';
import { AppContext } from 'screens';

import { Text } from 'components'

const Container = styled.div`

`

export const PlaceholderModule = props => {
    const {

    } = props;
    const { renderHeader } = React.useContext(AppContext)

    const header = renderHeader?.(<Text type='placeholder'>No list selected</Text>)
    return (
        <Container>
            {header}
        </Container>
    )
}
