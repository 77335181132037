import _ from 'lodash'
import { formatDuration, intervalToDuration, eachDayOfInterval, areIntervalsOverlapping, isWithinInterval } from 'date-fns'
import moment from 'moment-timezone'
import { priorities } from 'constants'
import { computeReminder, getCron, getDates, parseRepeat } from './schedule'
import GcalIcon from 'resources/icons/google_calendar_icon.png'

export const getListItemCount = (list_id, data) => {
	let output = {
		incomplete: 0,
		completed: 0
	}
	const list = data.lists[list_id]
	if(!list) { return output }
	let incomplete_count = list['items'].filter(item_id => data.items[item_id] && !data.items[item_id]['is_completed'] && !data.items[item_id]['is_archived']).length
	list['sections'] && list['sections'].forEach(section_id => {
		const section = data.sections[section_id]
		if(!section['is_archived']) {
			incomplete_count += section['items'].filter(item_id => !data.items[item_id]?.['is_completed'] && !data.items[item_id]?.['is_archived']).length
		}
	})
	output['incomplete'] = incomplete_count || 0

	const completed_count = Object.keys(_.pickBy(data.items, item => item && item['list_id'] === list_id && item['is_completed'] && !item['is_archived'] && item['parents'].length === 0)).length
	output['completed'] = completed_count || 0
	return output
}

export const buildView = (view, data, user, flatten=false, placeholder=false) => {
	if(!view || !data) { return }
	const { sort=[], group, target='items', hidden=[] } = view
	const filter = _.pickBy(view, (value,key) => {
		const is_valid = ['search','is_completed','is_archived','priorities','tags','schedule','start','end','lists','integration_id'].includes(key)
		return is_valid && value !== null
	})

	// 1. Filter (tags, priorities, projects, is_archived, is_completed, start, end, text)
	let filtered = {...data[target]}
	if(target === 'items') {
		filtered = _.pickBy(filtered, x => !x['parents'] || x['parents'].length === 0)
	}
	Object.keys(filter).forEach(key => {
		switch(key) {
			case 'search':
				if(filter[key] && filter[key] !== ''){
					filtered = _.pickBy(filtered, x => x['title'].toLowerCase().includes(filter['search'].toLowerCase()))
				}
				break
			case 'is_completed':
				filtered = _.pickBy(filtered, x => !!x['is_completed'] === !!filter[key])
				break
			case 'is_archived':
				filtered = _.pickBy(filtered, x => !!x['is_archived'] === !!filter[key])
				break
			case 'priorities':
				if(filter[key] && filter[key].length > 0){
					const priority_filter = filter[key].map(x => `${x}`)
					filtered = _.pickBy(filtered, x => priority_filter.includes(`${x['priority']}`))
				}
				break
			case 'tags':
				if(filter[key] && filter[key].length > 0){
					filtered = _.pickBy(filtered, x => _.intersection(x['tags'], filter[key]).length > 0)
				}
				break
			case 'schedule':
				if(filter[key]) {
					const { start, end } = getDateFilterRange(view)
					if(!start && !end) { break }
					filtered = _.pickBy(filtered, item => {
						if(view['target_time']) {
							if(start && end) {
								return isWithinInterval(moment(item[view['target_time']]).toDate(), { start: start.toDate(), end: end.toDate() })
							} else if (start) {
								return moment(item[view['target_time']]).isAfter(start)
							} else if (end) {
								return moment(item[view['target_time']]).isBefore(end)
							}
						} else if(filter['is_completed'] || filter['is_archived']) { // [TODO] change this to rely on schedule_target
							const target_key = filter['is_completed'] ? 'complete_time' : 'archive_time'
							if(start && end) {
								return isWithinInterval(moment(item[target_key]).toDate(), { start: start.toDate(), end: end.toDate() })
							} else if (start) {
								return moment(item[target_key]).isAfter(start)
							} else if (end) {
								return moment(item[target_key]).isBefore(end)
							}
						} else {
							if(item['start'] && item['end']) {
								if(start && end) {
									return areIntervalsOverlapping(
										{ start: start.toDate(), end: end.toDate() },
										{ start: moment(item['start']).toDate(), end: moment(item['end']).toDate() }
									)
								} else if (start) {
									return moment(item['start']).isAfter(start) || moment(item['end']).isAfter(start)
								} else if (end) {
									return moment(item['start']).isBefore(end) || moment(item['end']).isBefore(end)
								}
							} else if (item['start'] || item['end']) {
								const target = moment(item['start'] || item['end'])
								if(start && end) {
									return target.isAfter(start) && target.isBefore(end)
								} else if (start) {
									return target.isAfter(start)
								} else if (end) {
									return target.isBefore(end)
								}
							} else {
								return false
							}
						}
						
					})
				}

				break
			case 'lists':
				if(filter[key] && filter[key].length > 0){
					filtered = _.pickBy(filtered, x => filter[key].includes(x['list_id']))
				}
				break
			case 'start':
				break
			case 'end':
				break
			case 'integration_id':
				const calendars = _.pickBy(data.calendars, calendar => calendar['integration_id'] === view['integration_id'])
				const calendar_ids = Object.keys(calendars)
				filtered = _.pickBy(filtered, x => calendar_ids.includes(x['calendar_id']))
				break
		}
	})

	// 2. Group
	let output = []
	if(group) {
		let default_list_id = filter.lists && filter.lists.length > 0 ? filter.lists.slice(-1)[0] : user ? user['inbox_id'] : null
		if(!filter['search']){
			filtered = _.pickBy(filtered, item => !item['parents'] || item['parents'].length === 0)
		}
		switch(true) {
			case group === 'sections':
				let section_list_ids = filter.lists && filter.lists.length > 0 ? filter.lists : !user ? [] : [user['inbox_id'], ...user['lists']].filter(list_id => data.lists[list_id] && !data.lists[list_id]['is_archived'])

				section_list_ids.forEach(list_id => {
					let unassigned_section_output = []

					const section_ids = data.lists[list_id]?.['sections'] || []
					// Unassigned section
					let unassigned_items = _.pickBy(filtered, item => !item['section_id'] && item['list_id'] === list_id)
					unassigned_items = sort ? _.sortBy(unassigned_items, [sort]) : data.lists[list_id]['items'].map(item_id => filtered[item_id]).filter(x => x)

					unassigned_section_output.push({ id: list_id, type: 'list', defaults: { list_id }, n: unassigned_items ? unassigned_items.length : 0 }) // [Web only]
					if(!hidden.includes(`${list_id}`)) {
						unassigned_items && unassigned_section_output.push(...unassigned_items.map(item => ({ id: item['item_id'], type: 'item' })))
						unassigned_items.length === 0 && placeholder && unassigned_section_output.push({ id: list_id, type: 'placeholder' }) // [Web only]
						if(view['type'] === 'list' && unassigned_items.length === 0 && !placeholder) { 
							unassigned_section_output.push({ id: list_id, type: 'placeholder' })
						}
					}
					output.push(unassigned_section_output)

					// Assigned section
					section_ids.forEach(section_id => {
						let section_output = []
						const section = data.sections[section_id]
						if(section && !section['is_archived']) {
							let section_items = _.pickBy(filtered, item => item['section_id'] === section_id)
							section_items = sort ? _.sortBy(section_items, [sort]) : section['items'].map(item_id => filtered[item_id]).filter(x => x)

							section_output.push({ id: section_id, type: 'section', defaults: { list_id, section_id }, n: section_items ? section_items.length : 0 })
							if(!hidden.includes(section_id)) {
								section_items && section_output.push(...section_items.map(item => ({ id: item['item_id'], type: 'item' })))
								section_items.length === 0 && placeholder && section_output.push({ id: section_id, type: 'placeholder' }) // [Web only]
							}

							output.push(section_output)
						}
					})
				})
				break
			case group === 'lists':
				let list_ids = filter.lists && filter.lists.length > 0 ? filter.lists : !user ? [] : [user['inbox_id'], ...user['lists']].filter(list_id => data.lists[list_id] && !data.lists[list_id]['is_archived'])
				list_ids.forEach(list_id => {
					let list_output = [] // Not available in web, need 1 array per list for kanban
					let list_items = _.pickBy(filtered, item => item['list_id'] === list_id)
					list_items = sort ? _.sortBy(list_items, [sort]) : _.sortBy(list_items, ['create_time'])

					list_output.push({ id: list_id, type: 'list', defaults: { list_id }, n: list_items ? list_items.length : 0 })
					!hidden.includes(list_id) && list_items && list_output.push(...list_items.map(item => ({ id: item['item_id'], type: 'item' })))
					output.push(list_output)
				})
				break
			case group === 'tags':
				const tag_ids = filter.tags && filter.tags.length > 0 ? filter.tags : user ? user['tags'] : []

				// Untagged items
				let untagged_output = []
				let untagged_items = _.pickBy(filtered, item => !item['tags'] || item['tags'].length === 0)
				untagged_items = sort ? _.sortBy(untagged_items, [sort]) : _.sortBy(untagged_items, ['create_time']).reverse() // [Web only] web need reverse

				untagged_output.push({ id: `untagged`, type: 'tag', defaults: { tags: [], list_id: default_list_id }, n: untagged_items ? untagged_items.length : 0 })
				!hidden.includes('untagged') && untagged_items && untagged_output.push(...untagged_items.map(item => ({ id: item['item_id'], type: 'item' })))
				output.push(untagged_output)

				// Tagged items
				tag_ids && tag_ids.forEach(tag_id => {
					let tag_output = []
					let tag_items = _.pickBy(filtered, item => item['tags'] && item['tags'].includes(tag_id))
					tag_items = sort ? _.sortBy(tag_items, [sort]) : _.sortBy(tag_items, ['create_time'])

					tag_output.push({ id: tag_id, type: 'tag', defaults: { tags: [tag_id], list_id: default_list_id }, n: tag_items ? tag_items.length : 0 })
					!hidden.includes(tag_id) && tag_items && tag_output.push(...tag_items.map(item => ({ id: item['item_id'], type: 'item' })))
					output.push(tag_output)
				})
				break
			case group === 'priority':
				let priorities = filter.priorities && filter.priorities.length > 0 ? filter.priorities : Object.values([4,1,2,3])
				priorities = priorities.includes(4) ? [4, ...priorities.filter(x => x !== 4)] : priorities
				priorities.forEach(priority => {
					let priority_output = []
					let priority_items = _.pickBy(filtered, item => `${item['priority']}` === `${priority}`)
					priority_items = sort ? _.sortBy(priority_items, [sort]) : _.sortBy(priority_items, ['create_time']).reverse() // [Web only] web need reverse

					priority_output.push({ id: priority, type: 'priority', defaults: { priority, list_id: default_list_id }, n: priority_items ? priority_items.length : 0 })
					!hidden.includes(priority) && priority_items && priority_output.push(...priority_items.map(item => ({ id: item['item_id'], type: 'item' })))
					output.push(priority_output)
				})
				break
			case group === 'schedule':
				const item_dates = _.uniq(Object.values(filtered).map(item => item['start'] ? moment(item['start']).format('YYYY-MM-DD') : item['end'] ? moment(item['end']).format('YYYY-MM-DD') : null)).filter(x => x).sort() || []
				const date_range = eachDayOfInterval({
					start: item_dates.length > 0 && moment(item_dates[0]).isBefore(moment(), 'day') ? moment(item_dates[0]).toDate() : moment().toDate(),
					end: item_dates.length > 1 && moment(item_dates.slice(-1)[0]).isAfter(moment().add(7,'day'), 'day') ? moment(item_dates.slice(-1)[0]).toDate() : moment().add(7,'day').toDate()
				}).map(item_date => moment(item_date).format('YYYY-MM-DD'))

				// Unscheduled items
				let unscheduled_output = []
				let unscheduled_items = _.pickBy(filtered, item => !item['start'])
				unscheduled_items = sort ? _.sortBy(unscheduled_items, [sort]) : _.sortBy(unscheduled_items, ['create_time']).reverse() // [Web only] web need reverse

				unscheduled_output.push({ id: `unscheduled`, type: 'date', defaults: { list_id: default_list_id }, n: unscheduled_items ? unscheduled_items.length : 0 })
				!hidden.includes('unscheduled') && unscheduled_items && unscheduled_output.push(...unscheduled_items.map(item => ({ id: item['item_id'], type: 'item' })))
				output.push(unscheduled_output)

				// Scheduled items
				date_range.forEach((item_date, index) => {
					let date_output = []
					let date_items = _.pickBy(filtered, item => {
						return item['start'] ? moment(item['start']).format('YYYY-MM-DD') === item_date
							: !item['start'] && item['end'] ? moment(item['end']).format('YYYY-MM-DD') === item_date
							: false
					})
					date_items = sort ? _.sortBy(date_items, [sort]) : _.sortBy(date_items, ['create_time'])

					if(index < 7 || date_items && date_items.length > 0){
						const target_date = moment(item_date).set({ hour: moment().hour() !== 23 ? moment().hour() + 1 : 8, minute: 0 })
						date_output.push({ 
							id: item_date,
							type: 'date', 
							n: date_items ? date_items.length : 0, 
							defaults: { 
								start: target_date,
								end: moment(target_date).add(user.preferences['default_schedule_duration'], 'minute'),
								is_all_day: true,
								list_id: default_list_id 
							} })
						!hidden.includes(item_date) && date_items && date_output.push(...date_items.map(item => ({ id: item['item_id'], type: 'item' })))
						output.push(date_output)
					}
				})
				break
			case ['complete_date','archive_date','create_date'].includes(group):
				const key = group.replace('date','time')
				const dates = _.uniq(_.flatten(Object.values(filtered).map(item => item[key] && moment(item[key]).format('YYYY-MM-DD')))).filter(x => x).reverse() // Updated to reverse to have the earliest first
				dates.forEach(date => {
					let date_output = []
					const date_items = _.pickBy(filtered, item => item[key] && moment(item[key]).format('YYYY-MM-DD') === date)
					const sorted_date_items = sort ? _.sortBy(date_items, [sort]) : Object.values(date_items)

					date_output.push({ id: date, type: 'date', n: sorted_date_items ? sorted_date_items.length : 0 })
					!hidden.includes(date) && sorted_date_items && date_output.push(...sorted_date_items.map(item => ({ id: item[`${target.slice(0,-1)}_id`], type: `${target.slice(0,-1)}` })))
					output.push(date_output)
				})
				break
			case group === 'calendars':
				const calendars = _.pickBy(data.calendars, calendar => calendar['integration_id'] === view['integration_id'])
				for (const [calendar_id, calendar] of Object.entries(calendars)) {
					let calendar_output = [{ id: calendar_id, type: 'calendar' }]
					const calendar_items = _.pickBy(filtered, item => item['calendar_id'] === calendar_id)
					const sorted_calendar_items = sort ? _.sortBy(calendar_items, [sort]) : Object.values(calendar_items)
					!hidden.includes(calendar_id) && sorted_calendar_items?.length > 0 && calendar_output.push(...sorted_calendar_items.map(item => ({ id: item['item_id'], type: 'item' })))

					output.push(calendar_output)
				  }
				break
		}
	} else {
		let sorted = sort === 'priority' ? _.sortBy(filtered, [sort]).reverse() : _.sortBy(filtered, [sort])
		output = [sorted.map(i => ({ id: i[`${target.slice(0,-1)}_id`], type: target.slice(0,-1) }))]
	}

	return flatten ? [_.flatten(output)] : output
}

const getDateFilterRange = (view) => {
	const { schedule, start, end } = view

	let output = {}
	switch(schedule){
		case 'custom':
			output['start'] = start && moment(start).startOf('day')
			output['end'] = end && moment(end).endOf('day')
			break
		case 'today':
			output['start'] = moment().startOf('day')
			output['end'] = moment().endOf('day')
			break
		case 'this_week':
			output['start'] = moment().startOf('week')
			output['end'] = moment().endOf('week')
			break
		case 'next_7_days':
			output['start'] = moment().startOf('day')
			output['end'] = moment().add(7, 'day').endOf('day')
			break
		case 'upcoming':
			output['start'] = moment().startOf('day')
			output['end'] = null
			break
		case 'past':
			output['start'] = null
			output['end'] = moment().endOf('day')
			break
	}

	return output
}

export const formatScheduleDistance = (start, end, simplify=true) => {
	if(!start && !end) { return }
	const format_units = !simplify ? ['years', 'months', 'weeks', 'days', 'hours', 'minutes'] : moment(start).isSame(moment(end),'day') ? ['hours', 'minutes'] : ['years', 'months', 'weeks', 'days']
	let schedule = formatDuration(intervalToDuration({ start: moment(start).toDate(), end: moment(end).toDate() }), { format: format_units })
	const units = {
		years: 'y',
		months: 'm',
		weeks: 'wk',
		days: 'd',
		hours: 'h',
		minutes: 'm',
		seconds: 's'
	}
	Object.keys(units).forEach(unit => {
		if(schedule.includes(unit)) {
			schedule = schedule.replace(` ${unit}`, units[unit])
		} if (schedule.includes(unit.slice(0,-1))) {
			schedule = schedule.replace(` ${unit.slice(0,-1)}`, units[unit])
		} 
	})
	
	return !simplify ? schedule : schedule.split(' ').slice(0,2).join(' ')
}

export const formatSchedule = (schedule, user) => {
	const start = schedule.start && moment(schedule.start)
	const end = schedule.end && moment(schedule.end)
	const { is_all_day } = schedule 
	const is_same_day = start && end && start.isSame(end, 'day')
	const { include_day, date_format:date_format_raw, time_format } = user.preferences
	const date_format = moment().isSame(start,'year') ? date_format_raw : `${date_format_raw} YYYY`

	if(!start || !end || !user) { return }
	let start_format
	let end_format
	switch(true) {
		case is_all_day && is_same_day:
			return start.format(`${include_day ? 'ddd, ' : ''}${date_format}`)
		case is_all_day && !is_same_day:
			start_format = `${include_day ? 'ddd, ' : ''}${date_format}`
			end_format = start_format
			return `${start.format(start_format)} - ${end.format(end_format)}`
		case !is_all_day && is_same_day:
			start_format = `${include_day ? 'ddd, ' : ''}${date_format} ${time_format}`
			end_format = `${time_format}`
			return `${start.format(start_format)} - ${end.format(end_format)}`
		case !is_all_day && !is_same_day:
			start_format = `${include_day ? 'ddd, ' : ''}${date_format} ${time_format}`
			end_format = start_format
			return `${start.format(start_format)} - ${end.format(end_format)}`
	}
}

export const getItemLabels = (item_id, user, data, labels=['list_section','created','sub_items'], values) => {
	let output = {}
	if(!data) { return [] }

	// const item = values || data.items[item_id]
	const item = {...data.items[item_id], ...values}
	const list = data.lists[item['list_id']]
	labels && labels.forEach((target,index) => {
		switch(target){
			case 'list_section':
				const section = data.sections[item ? item['section_id'] : values['section_id']]
				output[target] = {
					key: 'list_section',
					label: !list ? 'List not selected' : !section && !list ? 'Section not selected' : !section && list ? list['title'] : `${list['title']} > ${section['title']}`,
				}
				break
			case 'create_time':
				if(!item) { break }
				output[target] = {
					label: `Created ${moment(item['create_time']).format('D MMM YYYY')}`,
					icon: 'ClockOutline',
					disable: true
				}
				break
			case 'sub_items':
				if(!item) { break }
				const children = _.pickBy(data.items, child => child['parents'] && child['parents'].slice(-1)[0] === item_id)
				const n = children && Object.keys(children).length
				const completed = Object.keys(_.pickBy(children, child => child['is_completed'])).length
				if(n) {
					output[target] = {
						label: `${completed}/${n}`,
						icon: 'CheckAll'
					}
				}
				break
			case 'tags':
				if(!item) { break }
				const tag_ids = item['tags'] || []
				let tag_labels = []
				tag_ids.forEach((tag_id, index) => {
					const tag = data.tags[tag_id]
					tag && tag_labels.push({
						label: tag['title'],
						color: tag['color'],
						icon: 'Pound',
						key: 'tags'
					})
				})
				output[target] = tag_labels
				break
			case 'complete_time':
				if(!item) { break }
				if(item['is_completed']) {
					output[target] = {
						icon: 'CheckboxMarkedOutline',
						label: `${moment(item['complete_time'] || item['update_time'] || item['create_time']).format('Do MMM YYYY h:mma')}`,
					}
				}
				break
			case 'archive_time':
				if(!item) { break }
				if(item['is_archived']) {
					output[target] = {
						icon: 'ArchiveOutline',
						label: `${moment(item['archive_time'] || item['update_time'] || item['create_time']).format('Do MMM YYYY h:mma')}`,
					}
				}
				break
			case 'schedule':
				if(!item) { break }
				if(item['start'] || item['end']) {
					let schedule_label;
					// if(!_.isEmpty(item['repeat'])) {
					// 	const duration = moment(item.end).diff(moment(item.start))
					// 	const { nextDate } = computeReminder(item)
					// 	schedule_label = formatSchedule({ start: nextDate, end: moment(nextDate).add(duration, 'millisecond'), is_all_day: item.is_all_day }, user)
					// } else {
					// 	schedule_label = formatSchedule({ start: item.start, end: item.end, is_all_day: item.is_all_day }, user)
					// }
					schedule_label = formatSchedule({ start: item.start, end: item.end, is_all_day: item.is_all_day }, user)
					
					if(!schedule_label) { break }
					output[target] = {
						icon: 'ClockOutline',
						label: schedule_label,
						key: 'schedule'
					}
				}
				break
			case 'repeat':
				if(!item) { break}
				if(item['repeat']) {
					output[target] = {
						icon: 'RepeatVariant',
						label: getRepeatTitle(item['repeat']),
						key: 'repeat'
					}
				}
				break
			case 'duration': 
				if(!item) { break }
				if(item['start'] && item['end'] && !item['is_all_day']) {
					output[target] = {
						icon: 'TimerSand',
						label: formatScheduleDistance(item['start'], item['end'])
					}
				}
				break
			case 'list':
				if(!item) { break }
				if(list) {
					output[target] = {
						label: list['title'],
						color: list['color']
					}
				}
				break
			case 'is_archived':
				output[target] = {
					label: 'Archived'
				}
				break
			case 'priority_selector':
				if(!item) { break }
				const { label, color_key } = priorities[item['priority']]
				const color = `var(${color_key})`
				output[target] = {
					icon: 'FlagOutline',
					label,
					color,
					key: 'priority'
				}
				break
			case 'schedule_selector':
				if(!item) { break }
				const has_schedule = item['start'] || item['end']
				if(!has_schedule) {
					output[target] = {
						icon: 'ClockOutline',
						label: 'Not scheduled',
						key: 'schedule'
					}
				}
				break
			case 'tag_selector':
				if(!item) { break }
				const has_tags = item['tags'] && item['tags'].length > 0
				if(!has_tags) {
					output[target] = {
						icon: 'Pound',
						label: 'No tags',
						key: 'tags'
					}
				}
				break
			case 'calendar':
				if(!item) { return }
				if(item['calendar_id']) {
					const calendar = data.calendars[item['calendar_id']]
					output[target] = {
						icon: 'CalendarBlank',
						label: calendar['title'],
						key: 'calendar',
						color: calendar['color']
					}
				}
		}
	})

	let sorted = []
	labels.forEach(key => {
		if(key === 'tags') {
			output[key] && sorted.push(...output[key])
		} else {
			output[key] && sorted.push(output[key])
		}
	})
	return sorted
}

export const formatReminder = (minutes, suffix=' before') => {
	return minutes === 0 ? 'At start of event' 
            : formatDuration({
                days: Math.round(minutes/1440),
                hours: Math.round(Math.round(minutes%1440)/60), 
                minutes: Math.round(Math.round(minutes%1440)%60)
            }, { format: ['days','hours','minutes'] })
            + suffix
}

export const getDailyMetrics = (items) => {
	const initial = performance.now()
	const debug = false
	let output = {} // 'YYYY-MM-DD': { completed: x, scheduled: y, created: z }
	let filtered = Object.values(_.pickBy(items, item => (!item['parents'] || item['parents'].length === 0) && !item['is_archived'] && _.isEmpty(item['repeat'])))

	// [TODO] Add repeated items in filtered
	const repeat_items = Object.values(_.pickBy(items, item => (!item['parents'] || item['parents'].length === 0) && !item['is_archived'] && !_.isEmpty(item['repeat'])))
	let n = 0 
	repeat_items.forEach(item => {
		// 1. Calculate all repeat within range
		const { dates } = parseRepeat({...item['repeat'], start: item['start'], end: moment().add(2,'month').utc().format()})
		const duration = moment(item.end).diff(moment(item.start))
		dates.forEach(start => {
			const end = moment(start).add(duration, 'millisecond').utc().format()
			filtered.push({...item, start, end})
		})
		n += dates.length
	})
	debug && console.log(`Repeat ${((performance.now() - initial)/1000).toFixed(2)}s ${repeat_items.length} items ${n}`);

	const keys = ['complete_time','create_time','start']
	keys.forEach(key => {
		// 1. Group items
		let grouped = _.groupBy(Object.values(filtered), item => {
			let is_valid = true
			switch(key){
				case 'complete_time':
					is_valid = item['is_completed'] && item['complete_time']
					break
				case 'create_time':
					if(!item['create_time']) {
						is_valid = false
					} else if (!_.isEmpty(item['repeat']) && !moment(item['start']).isSame(items[item['item_id']]['start'],'day')) { // Need this to prevent repeat items from showing duplicated records in DayModule
						is_valid = false
					}
					break
				case 'start':
					const { start, end } = item
					if(!start) {
						is_valid = false
					} else if (start && !moment(start).isSame(moment(end),'day')) {
						is_valid = false
					} else if (!_.isEmpty(item['repeat'])) {
						const exdate = item['repeat']['exdate'].map(x => moment(x).utc().format())
						const is_excluded = exdate.includes(moment(item['start']).utc().format())
						is_valid = !is_excluded
					}
					break
			}
			return !is_valid ? 'invalid' : moment(item[key]).format('YYYY-MM-DD')
		})
		delete grouped['invalid']
		let counts = _.mapValues(grouped, x => x.length)

		// 2. Fill in missing dates (get the min and max -> fill everything)
		let min_date = _.min(Object.keys(grouped))
		let max_date = _.max(Object.keys(grouped))
		if(key === 'start') {
			const scheduled_items = _.filter(filtered, item => item['start'])
			min_date = _.min(Object.values(scheduled_items).map(item => moment(item['start']).format('YYYY-MM-DD')))
			max_date = _.max(Object.values(scheduled_items).map(item => moment(item['end']).format('YYYY-MM-DD')))
		}
		const dates = eachDayOfInterval({start: min_date, end: max_date}).map(date => moment(date).format('YYYY-MM-DD'))
		const missing_dates = _.difference(dates, Object.keys(grouped))
		counts = {...counts, ..._.mapValues(_.keyBy(missing_dates, date => date), x => 0)}
		grouped = {...grouped, ..._.mapValues(_.keyBy(missing_dates, date => date), x => [])}

		// 3. Handle multi day events
		if(key === 'start') {
			const multiday_items = _.pickBy(filtered, item => item['start'] && !moment(item['start']).isSame(moment(item['end']), 'day'))
			Object.values(multiday_items).forEach(item => {
				const item_dates = eachDayOfInterval({ start: item['start'], end: item['end'] }).map(date => moment(date).format('YYYY-MM-DD'))
				item_dates.forEach(date => {
					counts[date] += 1
					grouped[date] = [...grouped[date], item]
				})
			})
		}

		// 4. Return item_id instead of item
		grouped = _.mapValues(grouped, day_items => day_items.map(item => item['item_id']))

		output[key] = { items: grouped, counts }
		debug && console.log(`${key} ${((performance.now() - initial)/1000).toFixed(2)}s`);
	})
	debug && console.log(`Metrics ${((performance.now() - initial)/1000).toFixed(2)}s`);
	return output
}

export const getRepeatTitle = values => {
	const { freq, interval } = values;
	const freq_labels = {
		DAILY: 'day',
		WEEKLY: 'week',
		MONTHLY: 'month',
		YEARLY: 'year'
	}
	if(interval > 1) {
		return `Every ${interval} ${freq_labels[freq]}s`
	} else {
		return `Every ${freq_labels[freq]}`
	}
}

export const popupCenter = ({url, title, w, h}) => {
    // Fixes dual-screen position                             Most browsers      Firefox
	const dualScreenLeft = 0;
    // const dualScreenLeft = window.screenLeft !==  undefined ? window.screenLeft : window.screenX;
    const dualScreenTop = window.screenTop !==  undefined   ? window.screenTop  : window.screenY;

    const width = window.innerWidth ? window.innerWidth : document.documentElement.clientWidth ? document.documentElement.clientWidth : window.width;
    const height = window.innerHeight ? window.innerHeight : document.documentElement.clientHeight ? document.documentElement.clientHeight : window.height;

    const systemZoom = width / window.screen.availWidth;
    const left = (width - w) / 2 / systemZoom + dualScreenLeft
    const top = (height - h) / 2 / systemZoom + dualScreenTop
    const newWindow = window.open(url, title, 
        `
        scrollbars=yes,
        width=${w / systemZoom}, 
        height=${h / systemZoom}, 
        top=${top}, 
        left=${left}
        `
    )

    if (window.focus) newWindow.focus();
}

export const getFilterSubtitle = (view) => {
	let output = []

	Object.values(['priorities','schedule','tags']).forEach(key => {
		if(key === 'schedule' && view[key]) {
			output.push(key)
		} else if (['priorities','tags'].includes(key) && view[key]?.length > 0) {
			output.push(key)
		}
	})

	if(output.length > 0) {
		output = output.join(', ')
		return output.charAt(0).toUpperCase() + output.slice(1)
	} else {
		return 'None selected'
	}
}