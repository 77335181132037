import React from 'react';
import styled from 'styled-components';

import { Button, Text, Collapsible, TextArea } from '../base'

const Input = styled.input`
    background-color: transparent;
    border: none;
    border-radius: 5px;
    width: 100%;
    resize: none;
    overflow: none;
    outline: none;
    color: var(--on-surface);
    /* min-width: 15rem; */
    
`

const Container = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    border: ${props => `1px solid ${props.border ? 'var(--outline-variant)' : 'transparent'}`};
    border-radius: var(--border-radius);
    background-color: var(--surface-container);
    gap: var(--spacing-small);
    width: 100%;
    transition: border-color 0.2s ease; 
    &:hover {
        border: ${props => `1px solid ${props.border ? 'var(--outline-variant)' : 'transparent'}`};
    };
    &:focus-within {
        border: ${props => `1px solid ${props.border ? 'var(--outline-variant)' : 'transparent'}`};
    }
`

const Column = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
    padding: var(--spacing-small);
`

export const TextInput = React.forwardRef((props, fowardedRef) => {
    const {
        initial,
        placeholder,
        onChange,
        onSubmit,
        onClose,
        onFocus,
        censor=false,
        auto_focus=true,
        border=false,
        disabled,
        clearOnSubmit=true,
        disableSpinner=false,
        submit_icon='ChevronRight',
        trailing,
        multiline=false
    } = props;
    const ref = fowardedRef || React.createRef()
    const [loading, setLoading] = React.useState(false)
    const [error, setError] = React.useState(null)

    React.useEffect(() => {
        auto_focus && ref.current && ref.current.focus()
        if(initial && ref.current) {
            ref.current.value = initial
        }
    }, [])

    React.useEffect(() => {
        error && setTimeout(() => {
            setError(null)
        }, 1000)
    }, [error])

    const onSubmitHandler = () => {
        !disableSpinner && setLoading(true)
        onSubmit?.(ref.current.value, (success, error_msg) => {
            if(success) {
                // onClose?.()
                setLoading(false)
                props.setContext?.(null)
                if(clearOnSubmit && ref.current) { ref.current.value = '' }
            } else {
                setError(error_msg)
                setLoading(false)
            }
        })
    }

    const onKeyDown = (event) => {
        const { keyCode } = event;
        error && setError(false)
        switch(keyCode) {
            case 13: // enter
                onSubmitHandler()
                break
            case 27: // esc
                onClose?.()
                props.setContext?.(null)
                break
        }
    }

    return (
        <Container border={border} style={props.style}>
            <Column>
                {multiline ?
                <TextArea
                    ref={ref}
                    initial={initial} 
                    placeholder={placeholder}
                    onSubmit={onSubmit}
                    onClose={onClose}
                    auto_focus={auto_focus}
                    />
                : <Input 
                    ref={ref}
                    spellcheck="false"
                    type={censor ? 'password' : 'text'} 
                    placeholder={placeholder}  
                    autofocus={auto_focus}
                    onKeyDown={onKeyDown} 
                    onFocus={onFocus} 
                    disabled={loading || disabled}
                    onChange={(e) => onChange?.(e.target.value)}
                    />}
                <Collapsible collapsed={!error}>
                    <Text type='error'>{`*${error}`}</Text>
                </Collapsible>
            </Column>
            {trailing ||
            <Button
                icon={submit_icon} icon_color='var(--on-surface-variant)'
                onClick={onSubmitHandler}
                loading={loading}
                style={{backgroundColor: 'transparent)'}}
                />}
        </Container>
    )
})