import React from 'react';
import { useSelector } from 'react-redux';
import styled from 'styled-components';

import { ListTile, Text } from '../base';
import { TagOptions } from '../options'
import { useNavigate } from 'react-router-dom';
import { AppContext } from 'screens';
import { isMobile } from 'react-device-detect';

const Container = styled.div`

`

export const TagBlock = props => {
    const {
        tag_id,
        labels=[],
        mode='static', // static or active
        trailing,
        style
    } = props;
    const { setModule, setNavigation } = React.useContext(AppContext)
    const navigate = useNavigate()
    const data = useSelector(state => state.db.data)
    const tag = data.tags[tag_id]
    if(!tag) { return }

    const { title, color='var(--on-surface-variant)' } = tag
    const incomplete_count = !data.items ? 0 : Object.values(data.items).filter(item => item['tags'] && item['tags'].includes(tag_id) && !item['is_completed'] && !item['is_archived']).length
    return (
        <ListTile key={tag_id}
            label={title}
            leading='Pound'
            leading_color={color}
            leading_size={0.6}
            onClick={() => {
                navigate(`/tag/${tag_id}`)
                setModule(null)
                isMobile && setNavigation(false)
            }}
            style={style}
            trailing={<Text type='subtitle'>{incomplete_count}</Text>}
            popover={<TagOptions tag_id={tag_id} options={['edit','color','delete']}/>}
            anchorOrigin={{
                vertical: 'top',
                horizontal: 'right',
            }}
            transformOrigin={{
                vertical: 'top',
                horizontal: 'left',
            }}
            />
    )
}