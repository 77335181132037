import React from 'react';
import styled from "styled-components";
import { useNavigate, useParams } from 'react-router-dom'
import { useDispatch, useSelector } from "react-redux";

import { Text, Spinner, Button } from 'components'

import { getAuth } from "@firebase/auth";
import { api } from 'services'
import * as actions from 'store/actions'
import { store, persistor } from 'store'
import * as actionTypes from 'store/actions/actionTypes';
import { useNotifications } from 'hooks'; 
import { onUserLoad, getLocalLastAction } from 'utils';
import _ from 'lodash'
import moment from 'moment-timezone'

const Container = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100vh;
    flex-direction: column;
    gap: 0.5rem;
`

const Wrapper = styled.div`
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
    width: 20rem;
    justify-content: center;
    align-items: center;
    text-align: center;
    margin-bottom: var(--spacing-small);
`

const AlertContainer = styled.div`
    -webkit-animation: vibrate-1 0.3s linear 2 both;
    animation: vibrate-1 0.3s linear 2 both;
`

export const LoadingScreen = props => {
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const url_params = useParams()
    const { type: origin_type, id: origin_id } = url_params
    const { setupNoti } = useNotifications()
    const user = useSelector(state => state.db.user)
    const data = useSelector(state => state.db.data)
    const [showRetry, setRetry] = React.useState(false)
    const auth = getAuth()

    React.useEffect(() => {
        const subscriber = auth.onAuthStateChanged(onAuth);
        return subscriber
    }, [])

    const onAuth = (values) => {
        showRetry && setRetry(false)
        if(!values) { navigate('/login') }
        const onboarding_data = values && {
            uid: values['uid'],
            email: values['email'] || values.providerData[0]['email'],
            displayName: values['displayName'],
            providerId: values.providerData[0]['providerId']
        }
        process.env.REACT_APP_ENV === 'test' && console.log('[onAuthStateChanged]', onboarding_data)
        values && api.getUser({uid: values['uid']})
        .then(res => {
            const { token, last_action } = res.data
            let user = {...res.data['user'], token}
            if(!res.data) {
                navigate('/onboarding', { state: onboarding_data })
            } else if(!_.isEmpty(data)) {
                const local_last_action = getLocalLastAction()
                
                if(moment(last_action).isAfter(moment(local_last_action))) {
                    process.env.REACT_APP_ENV === 'test' && console.log('need to download')
                    dispatch(actions.loadData(user, 'sync', {date: local_last_action}, success => {
                        if(success) {
                            redirect(user)
                            user && onUserLoad(user)
                        } else {
                            setRetry(true)
                        }
                    }))
                } else {
                    process.env.REACT_APP_ENV === 'test' && console.log('no need to download', { last_action, local_last_action })
                    store.dispatch({ type: actionTypes.UPDATE_USER, user })
                    user && onUserLoad(user)
                    redirect(user)
                }
            }  else {
                process.env.REACT_APP_ENV === 'test' && console.log('no local state')
                dispatch(actions.loadData(user,'initial',null,success => {
                    if(success) {
                        redirect(user)
                        user && onUserLoad(user)
                    } else {
                        setRetry(true)
                    }
                }))
            }
        })
        .catch(err => {
            process.env.REACT_APP_ENV === 'test' && console.log(err)
            setRetry(true)
            // if(values.uid) {
            //     navigate('/onboarding', { state: onboarding_data })
            // } else {
            //     dispatch(actions.signOut(() => navigate('/login')))
            // }
        })
    }

    const redirect = (user) => {
        if(!user) { navigate('/login') }
        if(origin_type) {
            const ext = [origin_type, origin_id, url_params['*']].filter(x => x && x !== '').join('/')
            navigate(`/${ext}`)
        } else {
            navigate('/list')
        }        
    }

    const onRetry = () => {
        setRetry(false)
        if(auth?.currentUser) {
            onAuth(auth.currentUser)
        } else {
            setTimeout(() => {
                setRetry(true)
            }, 1000)
        }
    }

    if(showRetry) {
        return (
            <Container>
                <Wrapper>
                    <AlertContainer>
                        <Text type='title'>Something went wrong</Text>
                    </AlertContainer>
                    <Text type='placeholder'>Please make sure you have a working internet connection before trying again</Text>
                </Wrapper>
                <Button
                    label='Retry'
                    icon='Refresh'
                    style={{backgroundColor: 'var(--primary)'}}
                    onClick={onRetry}
                    />
                <Button
                    label='Back to login'
                    labelStyles={{color: 'var(--on-surface-variant)'}}
                    onClick={() => navigate('/login')}
                    />
            </Container>
        )
    } else {
        return (
            <Container>            
                <Spinner color='var(--primary)'/>
                <Text>Loading</Text>
            </Container>
        )
    }
    
}