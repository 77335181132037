import moment from 'moment-timezone'
import { formatDuration } from 'date-fns'

export const settings_options = [
    { 
        key: 'account',
        icon: 'AccountOutline', 
        title: 'Account', 
        labels: ['Name','Email','Upgrades','Theme'],
        options: [
            {
                type: 'ListTile',
                label: 'Name', 
                subtitle: user => user && user['displayName'] || 'Not set', 
                selector: 'NameModule',
                target: 'displayName'
            },
            { 
                type: 'ListTile',
                label: 'Email', 
                subtitle: user => user && user['email'],
                disabled: true,
            },
            {
                type: 'ListTile',
                label: 'Account type',
                target: 'is_pro',
                labels: user => {
                    if(user['is_pro']) {
                        return [{ label: 'Dayscaled Pro' }, { label: `Expires/renews on ${moment(user['pro_expiry']).format('D-MMM YYYY')}` }]
                    } else {
                        return [{ label: 'Free' }, { label: user['pro_expiry'] ? `Expired on ${moment(user['pro_expiry']).format('D-MMM YYYY')}` : 'Ugrade today!' }]
                    }
                },
                selector: null
            },
            {
                type: 'Switch',
                leading: 'palette-outline',
                label: 'Theme',
                subtitle: user => user && user.preferences['is_dark'] ? 'Dark' : 'Light',
                target: 'is_dark'
            },
            {
                type: 'ListTile',
                label: 'Delete account',
                subtitle: 'Action cannot be undone. All your data will be removed permanently, we will not be able to recover any information for you.',
                leading: 'alert-outline',
                target: 'delete_account'
            }
        ]
    },
    { 
        key: 'schedules',
        icon: 'TimerOutline', 
        title: 'Schedules', 
        labels: ['Default duration','Date/Time format'],
        options: [
            {
                type: 'ListTile',
                label: 'Default duration',
                subtitle: user => user && user.preferences['default_schedule_duration'] === 60 ? '1hr' : `${user.preferences['default_schedule_duration']}mins`,
                selector: 'OptionSelector',
                target: 'default_schedule_duration',
                extinfo: {
                    title: 'Default duration',
                    subtitle: 'When setting start/end times',
                    options: [
                        { title: '15mins', subtitle: 'Short', key: 15 },
                        { title: '30mins', subtitle: 'Medium', key: 30 },
                        { title: '1hr', subtitle: 'Long', key: 60 },
                        { title: '2hr', subtitle: 'Abit longer', key: 120 }
                    ]
                }
            },
            {
                type: 'Switch',
                label: 'Include day in labels',
                target: 'include_day',
                subtitle: (user) => {
                    const format = user && `${user.preferences['include_day'] ? 'ddd, ' : ''}${user.preferences['date_format']}`
                    return moment().format(format)
                }
            },
            {
                type: 'ListTile',
                label: 'Date format',
                subtitle: user => user && moment().format(user.preferences['date_format']),
                target: 'date_format',
                selector: 'OptionSelector',
                extinfo: {
                    title: 'Date format',
                    subtitle: 'For schedule dates',
                    options: [
                        { title: moment().format('D MMM'), subtitle: 'D MMM', key: 'D MMM' },
                        { title: moment().format('MMM D'), subtitle: 'MMM D', key: 'MMM D' },
                        { title: moment().format('DD/MM'), subtitle: 'DD/MM', key: 'DD/MM' },
                        { title: moment().format('MM/DD'), subtitle: 'MM/DD', key: 'MM/DD' },
                    ]
                }
            },
            {
                type: 'ListTile',
                label: 'Time format',
                subtitle: user => user && user.preferences['time_format'] === 'h:mma' ? '12hr (h:mm am/pm)': '24hr (HH:mm)',
                target: 'time_format',
                selector: 'OptionSelector',
                extinfo: {
                    title: 'Time format',
                    subtitle: 'For schedule times',
                    options: [
                        { title: '12hrs', subtitle: moment().format('h:mma'), key: 'h:mma' },
                        { title: '24hrs', subtitle: moment().format('HH:mm'), key: 'HH:mm' } 
                    ]
                }
            }
        ]
    },
    {
        key: 'notifications',
        icon: 'Alarm',
        title: 'Notifications',
        labels: ['Reminders'],
        options: [
            {
                type: 'Switch',
                label: 'Reminders',
                subtitle: 'Before scheduled item starts',
                target: 'enable_reminders'
            },
            {
                type: 'ListTile',
                label: 'Default reminder duration',
                target: 'default_reminder_duration',
                subtitle: user => {
                    const reminder = user.preferences['default_reminder_duration'] || 0
                    const duration = formatDuration({
                        days: Math.round(reminder/1440),
                        hours: Math.round(Math.round(reminder%1440)/60), 
                        minutes: Math.round(Math.round(reminder%1440)%60)
                    }, { format: ['days','hours','minutes'] })

                    return reminder === 0 ? 'At start of event' : `${duration} before event`
                },
                extinfo: {
                    options: [
                        { title: 'At start of event',  key: 0 },
                        { title: '10 minutes before', key: 10 },
                        { title: '1 hour before', key: 60 },
                        { title: '1 day before', key: 1440 },
                        { title: '1 week before',  key: 120 }
                    ]
                }
            }
        ]
    },
    { 
        key: 'calendars',
        icon: 'CalendarOutline', 
        title: 'Calendar', 
        labels: ['Defaults', 'Completed items'],
        options: [
            {
                type: 'Switch',
                label: 'Hide completed',
                subtitle: user => {
                    return user.preferences['calendar_hide_completed'] ? 'Completed items will be hidden' : 'Completed items will be shown'
                },
                target: 'calendar_hide_completed'
            },
            {
                type: 'ListTile',
                label: 'Default side calendar view',
                target: 'default_side_calendar_view',
                subtitle: user => {
                    switch(user.preferences['default_side_calendar_view']) {
                        case 'month':
                            return 'Month'
                        case 'week':
                            return 'Week'
                        case 'day':
                            return 'Day'
                        default:
                            return 'Day'
                    }
                },
                extinfo: {
                    default_option: 'day',
                    options: [
                        { title: 'Day',  key: 'day' },
                        { title: 'Month', key: 'month' },
                    ]
                }
            },
            {
                type: 'ListTile',
                label: 'Default full calendar view',
                target: 'default_full_calendar_view',
                subtitle: user => {
                    switch(user.preferences['default_full_calendar_view']) {
                        case 'month':
                            return 'Month'
                        case 'week':
                            return 'Week'
                        case 'day':
                            return 'Day'
                        default:
                            return 'Week'
                    }
                },
                extinfo: {
                    default_option: 'week',
                    options: [
                        { title: 'Day',  key: 'day' },
                        { title: 'Week',  key: 'week' },
                        { title: 'Month', key: 'month' },
                    ]
                }
            }
        ]
    },
    { 
        key: 'about',
        icon: 'InformationOutline', 
        title: 'Help & Support', 
        onClick: () => window.open('https://forms.gle/4ZsfVgSq81XVwaLF6', '_blank'),
        labels: ['Bugs','Feedback','Enquiries'] 
    },
    { 
        key: 'mobile',
        icon: 'Cellphone', 
        title: 'Get iOS app', 
        onClick: () => window.open('https://apps.apple.com/pe/app/dayscaled/id6472298215', '_blank'),
        labels: ['For the road'] 
    },
    { 
        key: 'logout',
        icon: 'LogoutVariant', 
        title: 'Logout', 
        labels: ['End current session'],
    },
]