import React from 'react';
import styled from 'styled-components';

import { Button, Text, TextInput, ListTile, Collapsible, TextField, Icon } from 'components'

import _ from 'lodash'
import { useHotkeys } from 'react-hotkeys-hook';

const Container = styled.div`
    border-radius: var(--border-radius);
    border: 1px solid var(--outline-variant);
    background-color: var(--surface-container);
    width: 18rem;
`

const TextSection = styled.div`
    display: grid;
    grid-template-columns: auto 1fr;
    padding: var(--spacing-small);
    gap: var(--spacing-small);
    width: auto;
`

const Section = styled.div`
    border-top: 1px solid var(--outline-variant);
    padding: var(--spacing-smallest) var(--spacing-small);
    max-height: 30rem;
    overflow-y: auto;
`

export const ComboboxSelector = props => {
    const {
        options=[],
        initial=[],
        onSelect,
        onSubmit,
        onCreate,
        disableSpinnner=false,
        renderPlaceholder,
        renderItem,
        placeholder='None available',
        searchPlaceholder='Search or create...',
        searchStrategy,
        disableSort=false,
        multi=true
    } = props;
    const ref = React.useRef()
    const [available, setAvailable] = React.useState(options)
    const [selected, setSelected] = React.useState(initial)
    const [loading, setLoading] = React.useState(false)
    const [error, setError] = React.useState(null)
    const [search, setSearch] = React.useState(null)
    const [highlight, setHighlight] = React.useState(null)
    useHotkeys(['ArrowUp','ArrowDown','enter','shift+delete','shift+enter'], (event, handler) => {
        const key = [...Object.keys(_.pickBy(handler, x => x === true)), ...handler.keys].join('+')
        switch(key){
            case 'down':
                available.length > 0 &&
                setHighlight(highlight === null ? 0 
                            : highlight === available.length - 1 ? 0
                            : highlight + 1)
                break
            case 'up':
                available.length > 0 &&
                setHighlight(highlight === null ? available.length - 1
                            : highlight === 0 ? available.length - 1
                            : highlight - 1)
                break
            case 'enter':
                onSubmitHandler()
                break
            case 'shift+delete':
                const output = multi ? [] : null
                setSelected(output)
                onSelect?.(output)
                onSubmitHandler(output)
                break
            case 'shift+enter':
                const sorted = disableSort ? available : sortOptions(available)
                highlight !== null && onSelectHandler(sorted[highlight]['value'])
                break
        }
    }, { preventDefault: true, enableOnFormTags: ['INPUT'] });

    React.useEffect(() => {
        error && setTimeout(() => {
            setError(null)
        }, 1000)
    }, [error])

    const onSearch = (value) => {
        setSearch(value)
        if(!value || value === '') {
            setAvailable(options)
        } else {
            const filtered = searchStrategy ? searchStrategy(options, value) : options.filter(option => option['label'].toLowerCase().includes(value.toLowerCase()))
            setAvailable(filtered)
            // setAvailable([renderPlaceholder ? renderPlaceholder(search) : <Text type='placeholder'>{placeholder}</Text>, ...filtered])
        }   
    }

    const onSelectHandler = (option) => {
        let updated_selection
        if(option === 'create') {
            onCreate?.(search, (success, new_option) => {
                const { value } = new_option
                success && setSearch(null)
                new_option && setSelected(multi ? [...selected, value] : value)
                new_option && setAvailable([...options, new_option])
                if(ref.current) {
                    ref.current.value = ''
                    ref.current.focus()
                }
            })
        } else {
            if(multi) {
                updated_selection = [...selected]
                if(updated_selection.includes(option)) {
                    updated_selection = updated_selection.filter(x => x !== option)
                } else {
                    updated_selection.push(option)
                }
            } else {
                updated_selection = option
            }
            setSelected(updated_selection)
            onSelect?.(updated_selection)
        }   
    }

    const onSubmitHandler = (values) => {
        const selection = values || selected
        if(disableSpinnner) {
            onSubmit?.(selection)
            props.close?.()
        } else {
            setLoading(true)
            onSubmit?.(selection, (success, err_msg) => {
                setLoading(false)
                err_msg && setError(err_msg)
                props.close?.()
            })
        }
    }

    const sortOptions = options => {
        // const sorted = _.sortBy(options, ['label']) // Removed, else TagSelector will not sort based on user.tags
        const selected_options = options.filter(x => multi ? selected.includes(x['value']) : selected === x['value'])
        const unselected_options = options.filter(x => multi ? !selected.includes(x['value']) : selected === x['value'])
        const x = [...selected_options, ...unselected_options]
        return x
    }

    const renderContent = () => {
        let output = [];
        const sorted = disableSort ? available : sortOptions(available)
        sorted.forEach((option, index) => {
            const { value, label, leading } = option
            const is_selected = multi ? selected.includes(value) : selected === value
            const is_highlighted = index === highlight
            if(renderItem) {
                output.push(renderItem?.({...option, is_selected, is_highlighted, onSelect: () => onSelectHandler(value)}))
            } else {
                output.push(
                    <ListTile
                        leading={leading}
                        label={label}
                        trailing={is_selected ? 'Check' : null}
                        onClick={() => onSelectHandler(value)}
                        style={{backgroundColor: is_highlighted ? 'var(--surface)' : 'var(--surface-container)'}}
                        textStyle={{color: value === 'create' ? 'var(--on-surface-variant)' : 'var(--on-surface)'}}
                        />
                )
            }
        })

        return output
    }

    const onRenderPlaceholder = () => {
        if(!search) { return }
        if(renderPlaceholder) {
            return renderPlaceholder?.(search, (success, new_option) => {
                const { value } = new_option
                success && setSearch(null)
                new_option && setSelected(multi ? [...selected, value] : value)
                new_option && setAvailable([...options, new_option])
                if(ref.current) {
                    ref.current.value = ''
                    ref.current.focus()
                }
            })
        } else if (!renderPlaceholder && available.length === 0) {
            return <Text type='placeholder'>{placeholder}</Text>
        }

    }

    const content = renderContent()

    return (
        <Container>
            <TextSection>
                <Icon icon='Magnify' color='var(--on-surface-variant)'/>
                <TextField 
                    placeholder={searchPlaceholder}
                    onChange={onSearch}
                    ref={ref}
                    />
            </TextSection>

            <Section>
                {onRenderPlaceholder()}
                {content}
            </Section>

            <Button
                label='Select'
                style={{backgroundColor: 'var(--primary)', margin: 'var(--spacing-small)', marginTop: 0, width: 'auto'}}
                labelStyles={{color: 'var(--on-primary)'}}
                onClick={onSubmitHandler}
                tooltip={{title: 'Select', shortcut: 'shift+enter'}}
                loading={loading}
                />
            <Collapsible collapsed={!error}>
                <Text type='error' style={{margin: 'var(--spacing-small)', marginTop: 0}}>{`*${error}`}</Text>
            </Collapsible>
        </Container>
    )
}