import React from 'react';
import styled from 'styled-components';

import { Button, Text } from '../base'

const Input = styled.input`
    background-color: transparent;
    border: none;
    border-radius: 5px;
    width: 100%;
    resize: none;
    overflow: hidden;
    text-overflow: ellipsis;
    outline: none;
    color: var(--on-surface);
    /* min-width: 15rem; */
    cursor: inherit;
`

const Area = styled.textarea`
    background-color: transparent;
    border: none;
    border-radius: 5px;
    width: 100%;
    resize: none;
    overflow: none;
    outline: none;
    color: var(--on-surface);
    min-width: 15rem;
    cursor: inherit;
`

const Container = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    border: ${props => `1px solid ${props.border ? 'var(--outline-variant)' : 'transparent'}`};
    border-radius: var(--border-radius);
    background-color: var(--surface-container);
    gap: var(--spacing-small);
    padding: 0 0 0 var(--spacing-small);
    width: 100%;
    transition: border-color 0.2s ease; 
    &:hover {
        border: 1px solid var(--outline-variant);
    };
    &:focus-within {
        border: ${props => `1px solid ${props.border ? 'var(--outline-variant)' : 'var(--outline)'}`};
    }
`

const Column = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
`

export const TextField = React.forwardRef((props, forwardedRef) => {
    const {
        initial,
        placeholder,
        onChange,
        onSubmit,
        onClose,
        onFocus,
        onBlur,
        censor=false,
        auto_focus=true,
        disabled,
        clearOnSubmit=true,
        multiline=false
    } = props;
    const ref = forwardedRef || React.createRef()

    React.useEffect(() => {
        auto_focus && ref.current && ref.current.focus()
        if(initial && ref.current) {
            ref.current.value = initial
        }
    }, [])

    const onSubmitHandler = (callback) => {
        onSubmit?.(ref.current.value, callback)
    }

    const onKeyDown = (event) => {
        const { keyCode } = event;
        switch(keyCode) {
            case 13: // enter
                onSubmitHandler((success, error_msg) => {
                    if(success) {
                        onClose?.()
                    } else {
                    }
                })
                break
            case 27: // esc
                onClose?.()
                break
        }
    }

    if(multiline) {
        return (
            <Area 
                ref={ref}
                type={censor ? 'password' : 'text'} 
                placeholder={placeholder}  
                autofocus={auto_focus}
                onKeyDown={onKeyDown} 
                onFocus={onFocus} 
                onBlur={onBlur}
                disabled={disabled}
                onChange={(e) => onChange?.(e.target.value)}
                style={props.style}
                onClick={props.onClick}
                />
        )
    } else {
        return (
            <Input 
                ref={ref}
                type={censor ? 'password' : 'text'} 
                placeholder={placeholder}  
                autofocus={auto_focus}
                onKeyDown={onKeyDown} 
                onFocus={onFocus} 
                onBlur={onBlur}
                disabled={disabled}
                onChange={(e) => onChange?.(e.target.value)}
                style={props.style}
                onClick={props.onClick}
                />
        )
    }
    
})