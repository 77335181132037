import React from 'react';
import styled from 'styled-components';

import { Text, Button, TextInput, Collapsible, Pressable } from 'components'
import {useSortable} from '@dnd-kit/sortable';
import {CSS} from '@dnd-kit/utilities';

const Column = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
`

const Subheader = styled.div`
    display: flex;
    /* padding-top: 20rem; */
    padding-top: var(--spacing-small); // need to use padding instead of margin, else the dnd placement will be off
    border: 0px solid red;
    margin-bottom: var(--spacing-small);
    align-items: flex-start;
`

const SubheaderSection = styled.div`
    display: grid;
    grid-template-columns: 1fr auto auto;
    align-items: center;
    border-bottom: 1px solid var(--outline-variant);
    /* margin-bottom: var(--spacing-small); */
`

const Wrapper = styled.div`
    border-radius: var(--border-radius);
    height: 100%;
    width: 100%;
    /* display: flex; */
    /* align-items: center; */
    &:hover {
        background-color: ${props => !props.disabled && 'var(--surface-container)'};
    }
`

export const SubheaderBlock = props => {
    const {
        id,
        type,
        disableDND=false,
        disableHide=false,
        disableCreate=false,
        hidden=false,
        setHidden,
        title,
        titleInput,
        showCreate,
        setCreate,
        options,
        onCreate,
        defaults={},
        animateDND=true
    } = props;
    const { attributes, listeners, setNodeRef, transform, transition, setActivatorNodeRef, isOver } = useSortable({id, data: { id, type }});
    const style = {
        transform: CSS.Translate.toString(transform),
        transition: animateDND ? transition : null,
    };

    const content = 
    <Subheader>
        <Button 
            icon={hidden ? 'ChevronRight' : 'ChevronDown'} 
            onClick={setHidden} 
            icon_color={disableHide ? 'transparent' : 'var(--on-surface-variant)'}
            style={{backgroundColor: 'transparent'}}
            />
        <Column>
            <SubheaderSection>
                <Wrapper disabled={!titleInput}>
                    <Pressable 
                        popover={titleInput} 
                        disabled={!titleInput}
                        style={{height: '100%', width: '100%', display: 'flex', alignItems: 'center'}}>
                        <Text style={{marginLeft: 'var(--spacing-small)'}}>{title}</Text>
                    </Pressable>
                </Wrapper>
                
                <Button 
                    icon={showCreate ? 'Close' : 'Plus'}
                    icon_color='var(--on-surface-variant)'
                    onClick={!disableCreate && setCreate}
                    style={{opacity: disableCreate ? 0 : onCreate ? 1 : 0, cursor: disableCreate ? 'auto' : !onCreate && 'auto'}}
                    />
                {options &&
                <Button 
                    icon='DotsHorizontal' 
                    icon_color={'var(--on-surface-variant)'} 
                    context_menu={options}
                    anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
                    transformOrigin={{ vertical: 'top', horizontal: 'right' }}
                    />}
            </SubheaderSection>
            
            <Collapsible collapsed={!showCreate}>
                <TextInput 
                    placeholder='Create item...' submit_icon='Plus'
                    border 
                    onSubmit={(title, callback) => onCreate({title, callback, defaults})}
                    onClose={setCreate}
                    style={{marginTop: 'var(--spacing-small)'}}
                    />
            </Collapsible>
        </Column>
    </Subheader>

    if(disableDND) {
        return content
    } else {
        return (
            <div ref={setNodeRef} style={style} {...attributes}>
                {content}
            </div>
        )
    }
}