import React from 'react';
import styled from 'styled-components';

import { Button, Collapsible, Icon, Pressable, Text, Fade } from '../base'
import { DateSelector } from './DateSelector'; 
import { TimeSelector } from './TimeSelector';

import moment from 'moment-timezone'
import { useSelector } from 'react-redux';
import { useHotkeys } from 'react-hotkeys-hook';
import { SHORTCUTS } from 'constants'
import _ from 'lodash'

const getClosestSchedule = (duration=30) => {
    let closest_time = moment().endOf('hour').add(1,'second')
    if(closest_time.date() !== moment().date()){
        closest_time = moment().startOf('hour')
    }
    const default_schedule = {
        start: closest_time,
        end: moment(closest_time).add(duration,'minute'),
        is_all_day: false
    }
    return default_schedule
}

const { shortcuts, actions: shortcut_actions } = SHORTCUTS['ScheduleSelector']
export const ScheduleSelector = props => {
    const {
        initial={},
        onSelect,
        onCancel
    } = props;
    const user = useSelector(state => state.db.user)
    const { time_format, date_format, default_schedule_duration } = user['preferences']

    const [values, setValues] = React.useState(() => {
        const defaults = getClosestSchedule(default_schedule_duration)
        return {
            start: initial.start || defaults.start,
            end: initial.end || defaults.end,
            is_all_day: initial.is_all_day || defaults.is_all_day
        }
    })
    const [focus, setFocus] = React.useState('start')
    const [loading, setLoading] = React.useState(false)
    const [error, setError] = React.useState(null)
    
    const { start, end, is_all_day } = values
    useHotkeys(shortcuts, (event, handler) => {
        const key = [...Object.keys(_.pickBy(handler, x => x === true)), ...handler.keys].join('+')
        const action = shortcut_actions[key]
        switch(action){
            case 'Clear schedule':
                onClear()
                break
            case 'Update schedule':
                onSubmit()
                break
            case 'Edit start':
                setFocus('start')
                break
            case 'Edit end':
                setFocus('end')
                break
            case 'Toggle all day':
                setValues({...values, is_all_day: !values['is_all_day']})
                break
        }
    }, { enableOnFormTags: ['INPUT'], preventDefault: true });

    const onAllDay = () => {
        const new_start = !is_all_day ? moment(start).startOf('day').utc().format() : moment(start).set({ hour: moment().hour() }).endOf('hour').add(1,'minute').utc().format()
        const new_end = !is_all_day ? moment(end).endOf('day').utc().format() : moment(new_start).add('minute',user.preferences['default_schedule_duration']).utc().format()
        setValues({
            start: new_start,
            end: new_end,
            is_all_day: !is_all_day
        })
    }

    const onFocus = target => {
        setFocus(target === focus ? null : target)
        const has_schedule = start || end
        !has_schedule && setValues(getClosestSchedule(default_schedule_duration))
    }

    const onClear = () => {
        props.onClose?.()
        props.close?.()
        onSelect?.({ start: null, end: null, is_all_day: false })
    }

    const onSubmit = () => {
        setLoading(true)
        setTimeout(() => {
            onSelect?.(
                { 
                    start: moment(values['start']).utc().format(), 
                    end: moment(values['end']).utc().format(), 
                    is_all_day: values['is_all_day'] 
                }, 
                (success, err_msg) => {
                setLoading(false)
                err_msg && setError(err_msg)
                success && props.close?.()
                success && props.onClose?.()
            })
        }, 100)
    }

    const onSelectHandler = (new_date) => {
        let updated_schedule = {...values, [focus]: new_date}
        const { start, end } = updated_schedule
        if(moment(start).isAfter(moment(end))) {
            updated_schedule['end'] = moment(start).add(default_schedule_duration, 'minutes')
        }
        setValues(updated_schedule)
    }

    const getSchedule = target => {
        const value = values[target]
        const date_label = !value ? 'None selected' : moment(value).format(`ddd, ${date_format}`)
        const time_label = value && !is_all_day && moment(value).format(time_format)
        const borderBottom = focus === target ? '1px solid var(--outline)' : '1px solid transparent'
        return (
            <Pressable 
                onClick={() => onFocus(target)} 
                style={{ borderBottom, transition: 'border 0.3s ease', padding: 'var(--spacing-small)' }}
                tooltip={{title: `Edit ${target}`, shortcut: `e+${target === 'start' ? 1 : 2}`}}
                >
                <Text>{date_label}</Text>
                <Collapsible collapsed={is_all_day} style={{width: 'min-content'}}>  {/* Need the min-content to prevent the wonky width animation */}
                    <Text type='subtitle' style={{width: '5rem'}}>{time_label}</Text>
                </Collapsible>
            </Pressable>
        )
    }

    return (
        <Container>
            <ScheduleContainer>
                <ScheduleSection>
                    {getSchedule('start')}
                    <Icon icon='ArrowRight' color='var(--on-surface-variant)'/>
                    {getSchedule('end')}
                </ScheduleSection>
                <Button label='All day' 
                    style={{ backgroundColor: 'var(--surface)' }} 
                    labelStyles={{color: is_all_day ? 'var(--on-surface)' : 'var(--on-surface-variant)'}} 
                    onClick={onAllDay}
                    tooltip={{title: 'Toggle', shortcut: 'a'}}
                    />
            </ScheduleContainer>

            <Collapsible collapsed={!focus}>
                <SelectorContainer>
                    <DateSelector 
                        initial={values[focus]}
                        onSelect={onSelectHandler}
                        />
                    <Divider/>
                    <Fade collapsed={is_all_day} style={{height: '100%', border: '1px solid red'}}>
                        <TimeSelector
                            key={`${focus}_time`}
                            initial={values[focus]}
                            onSelect={onSelectHandler}
                            />
                    </Fade>
                </SelectorContainer>
            </Collapsible>

            <Collapsible collapsed={!values.start}>
                <ButtonSection>
                    <Button 
                        label='Clear' 
                        style={{backgroundColor: 'var(--surface-container)'}} 
                        onClick={onClear} disabled={loading}
                        tooltip={{title: 'Remove schedule', shortcut: 'del'}}
                        />
                    <Button 
                        label='Select' 
                        style={{backgroundColor: 'var(--primary)'}} 
                        labelStyles={{color: 'var(--on-primary)'}}
                        onClick={onSubmit} loading={loading}
                        tooltip={{title: 'Select schedule', shortcut: 'shift+enter'}}
                        />
                </ButtonSection>
            </Collapsible>
        </Container>
    )
}

const Container = styled.div`
    border-radius: var(--border-radius);
    border: 1px solid var(--outline-variant);
    background-color: var(--surface-container);
    min-width: 30rem;
`

const ScheduleContainer = styled.div`
    background-color: var(--surface);
    border-radius: var(--border-radius);
    padding: var(--spacing);
    margin: var(--spacing-small);
    display: flex;
    justify-content: space-between;
    align-items: 'center'
`

const ScheduleSection = styled.div`
    display: grid;
    gap: var(--spacing);
    grid-template-columns: auto auto auto;
    align-items: center;
`

const SelectorContainer = styled.div`
    display: grid;
    grid-template-columns: 1fr auto 10rem;
    align-items: center;
    gap: var(--spacing-small);
    padding: var(--spacing-small);
    min-height: 10rem;
`

const ButtonSection = styled.div`
    display: flex;
    gap: var(--spacing-small);
    justify-content: flex-end;
    align-items: center;
    padding: var(--spacing-small);
    border-top: 1px solid var(--outline-variant);
`

const Divider = styled.div`
    border-left: 1px solid var(--outline-variant);
    height: 100%;
`