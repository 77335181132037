import React from 'react'
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import { useSelector } from 'react-redux';
import { 
	AuthScreen,
	AppScreen,
	LoadingScreen,
	OnboardingScreen,
	ErrorScreen,
	RedirectScreen,
 } from 'screens';
import { 
	light_theme, 
    dark_theme, 
    material_colors, 
    on_material_colors, 
    default_colors, 
    accent_colors,
	spacing,
	typography,
} from 'constants'
import _ from 'lodash'

const routes = [
	{ path: "/error", element: <ErrorScreen/> },
	{ path: "/login", element: <AuthScreen/>, errorElement: <ErrorScreen/>  },
	{ path: "/loading/:type?/:id?/*", element: <LoadingScreen/>, errorElement: <ErrorScreen/>  },
	{ path: "/onboarding", element: <OnboardingScreen/>, errorElement: <ErrorScreen/>  },
	{ path: "/redirect/*", element: <RedirectScreen/>, errorElement: <ErrorScreen/>  },
	{ path: "/:type?/:id?/*", element: <AppScreen/>, errorElement: <ErrorScreen/>  },
]
export const App = props => {
	const user = useSelector(state => state.db.user)	
	const router = createBrowserRouter(routes);

    React.useEffect(() => {
        const target_theme = user && !user.preferences['is_dark'] ? 'light' : 'dark'
		const values = [target_theme === 'dark' ? dark_theme : light_theme, material_colors, on_material_colors, default_colors, accent_colors, spacing, typography]
		values.forEach(colors => {
			for (const [key, value] of Object.entries(colors)) {
				document.documentElement.style.setProperty(key,value);
			}
		})
    },[user?.preferences.is_dark])
	
  	return <RouterProvider router={router} fallbackElement={ErrorScreen} errorElement={ErrorScreen}/>
}