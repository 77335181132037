export const START_DOWNLOAD = 'START_DOWNLOAD'
export const LOAD_DATA = 'LOAD_DATA'
export const LOAD_PROMOS = 'LOAD_PROMOS'
export const UPDATE_LOAD_STATUS = 'UPDATE_LOAD_STATUS'
export const UPDATE_STATUS = 'UPDATE_STATUS'
export const UPDATE_ID = 'UPDATE_ID'

export const SET_MODAL = 'SET_MODAL'
export const SET_SEC_MODAL = 'SET_SEC_MODAL'
export const SET_OVERLAY = 'SET_OVERLAY'
export const SET_MODULE = 'SET_MODULE'
export const SET_THEME = 'SET_THEME'

export const SET_PREFERENCES = 'SET_PREFERENCES'
export const SET_FAVOURITES = 'SET_FAVOURITES'

export const UPDATE_DATA = 'UPDATE_DATA'
export const UPDATE_USER = 'UPDATE_USER'
export const UPDATE_THEME = 'UPDATE_THEME'

export const NEW_INTEGRATION = 'NEW_INTEGRATION'
export const SET_SOCKET = 'SET_SOCKET'