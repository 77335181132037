import React from 'react';
import styled from 'styled-components';

const Container = styled.div`
    border: 1px solid yellow;
    height: 100px;
`

export const CalendarEventContainerWrapper = props => {
    const {

    } = props;
    return (
        <Container >
            {props.children}
        </Container>
    )
}