import React from 'react';
import styled from 'styled-components';

import { Text } from 'components'

import { useDroppable } from '@dnd-kit/core';
import { CalendarContext } from './Calendar'; 
import moment from 'moment-timezone';

const Container = styled.div`
    /* flex: 1 0; */
    height: 24px;
    background-color: ${props => props.isSelected ? 'var(--calendar-selection)' : 'var(--surface)'};
    /* border-top: ${props => props.isStart && '1px solid var(--primary)'};
    border-bottom: ${props => props.isEnd && '1px solid var(--primary)'}; */
`

const Placeholder = styled.div`
    position: absolute;
    background-color: var(--calendar-selection);
    /* border: 1px solid var(--outline); */
    /* border-radius: var(--border-radius); */
    z-index: 2;
    width: 99%;
    height: ${props => `${props.n*24}px`};
    transform: translateY(-0.8rem); // to account for time gutter label
    padding: var(--spacing-small);
`

export const CalendarTimeSlotWrapper = props => {
    const {
        value
    } = props;
    const id = moment(value).format('YYYY-MM-DD HH:mm')
    const { onDragOver, selected=[], step, isDraggingOver } = React.useContext(CalendarContext)
    const { setNodeRef, isOver, rect } = useDroppable({ id, data: { id, value } })
    const isOverAdj = isOver && rect && rect.current && selected.length > 0

    React.useEffect(() => {
        if(isOver) {
            onDragOver(value)
        }
    }, [isOver])

    const start = isOver && moment(selected[0]).format('h:mmA')
    const end = isOver && moment(selected[selected.length - 1]).add(step, 'minutes').format('h:mmA')
    return (
        <Container 
            ref={setNodeRef} 
            isSelected={!isDraggingOver && selected.includes(id)} 
            >
            {props.children}
            {isOverAdj && 
            <Placeholder n={selected.length}>
                <Text style={{fontSize: '0.8rem'}}>{`${start} - ${end}`}</Text>
            </Placeholder>}
        </Container>
    )
}