import React from 'react';
import styled from 'styled-components';
import { useSelector, useDispatch } from 'react-redux';

import { ComboDND } from './ComboDND';

import * as actions from 'store/actions'

const Container = styled.div`
    width: 18rem;
    border: 1px solid var(--outline-variant);
    border-radius: var(--border-radius);
    background-color: var(--surface-container);
    /* padding: var(--spacing-smallest); */
    max-height: 30vh;
`

export const SectionDND = props => {
    const {
        list_id,
        onDragEnd
    } = props;
    const dispatch = useDispatch()
    const user = useSelector(state => state.db.user)
    const data = useSelector(state => state.db.data)
    const list = data.lists[list_id]

    const onCreate = (title, callback) => {
        dispatch(actions.createSection({ title, list_id }, (success, err_msg) => {
            callback?.(success,err_msg)
        }))
    }

    const active_sections = list?.['sections'].filter(section_id => {
        const section = data.sections[section_id]
        return section && !section['is_archived']
    })
    const options = active_sections && active_sections.map(section_id =>  {
        const section = data.sections[section_id]
        return { 
            id: section_id,
            type: 'section', 
            label: section['title'] 
        }
    })
    return (
        <Container>
            <ComboDND
                options={options}
                onDragEnd={onDragEnd}
                onCreate={onCreate}
                create_placeholder='Create new section'
                />
        </Container>
    )
}