import _ from 'lodash'
import moment from 'moment-timezone'

import { getCron, getDates, parseRepeat } from './schedule'
import { getShadeColor } from './colors'
import { priorities } from 'constants'
import { store } from 'store'

export const getEvents = (date=moment()) => {
    const data = store.getState().db.data
    const user = store.getState().db.user
    let output = []
    let filtered = _.filter(Object.values(data.items), item => {
        if(user.preferences['calendar_hide_completed'] && item['is_completed']) { 
            return false 
        } else {
            return !item['is_archived'] && (item['start'] || item['end'])
        }
    })

    filtered.forEach(item => {
        let style = {
            backgroundColor: 'var(--surface-container)', 
            border: '1px solid var(--outline-variant)',
            borderLeft: '5px solid var(--outline)',
            color: document.documentElement.style.getPropertyValue(`${priorities[item['priority']]['color_key']}`)
        }
        if(item['calendar_id'])  {
            const calendar = data.calendars[item['calendar_id']]
            style['color'] = item['priority'] === 4 && calendar ? calendar['color'] : style['color']
        } 
        style['backgroundColor'] = user.preferences['is_dark'] ? getShadeColor(style['color'],1,1,0.7) : getShadeColor(style['color'],0.8,1,1)
        style['borderLeft'] = `5px solid ${style['color']}`
        style['opacity'] = item['is_completed'] ? 0.6 : 1

        if(!_.isEmpty(item['repeat'])) {
            // 1. Calculate all repeat within range
            const { count, until, exdate:exdate_raw=[] } = item['repeat']
            const exdate = exdate_raw.map(x => moment(x).utc().format()) // Need this because the item scheudle keep having the additional milliseconds in the format
            const { next_date, dates } = parseRepeat({...item['repeat'], start: moment(item['start']).utc().format(), end: moment().add(2,'month').utc().format()})

            // 2. Add repeat events
            const duration = moment(item.end).diff(moment(item.start))
            Object.values(dates).forEach(date => {
                output.push({
                    start: moment(date).toDate(),
                    end: moment(date).add(duration, 'millisecond').toDate(),
                    allDay: item['is_all_day'],
                    title: item['title'],
                    resource: { item_id: item['item_id'], style }
                })
            })
        } else {
            output.push({
                start: moment(item['start']).toDate(),
                end: item['is_all_day'] ? moment(item['end']).endOf('day').toDate() : moment(item['end']).toDate(), // Need to adjust to end of day for all day items, else it will display 1 day short in the calendar
                allDay: item['is_all_day'],
                title: item['title'],
                resource: { item_id: item['item_id'], style }
            })
        }
    })
    return output
}