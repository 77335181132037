import CircularProgress, { circularProgressClasses } from '@mui/material/CircularProgress';

export const Spinner = props => {
    const {
        color='var(--on-surface)',
        size=20,
        margin
    } = props;
    return (
        <CircularProgress
            size={size}
            sx={{
                [`&.${circularProgressClasses.colorPrimary}`]: { color },
                [`&.${circularProgressClasses.root}`]: { margin }
            }}
            />
    )
}