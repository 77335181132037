import styled from "styled-components";
import _ from 'lodash'

const styles = {
    normal: {
        color: 'var(--on-surface)',
        fontSize: '1rem',
        textOverflow: 'ellipsis',
    },
    subtitle: {
        color: 'var(--on-surface-variant)',
        fontSize: '0.8rem',
    },
    title: {
        fontSize: '1.5rem',
        fontWeight: 'bold',
        lineHeight: '1.5rem',
        overflow: 'visible'
    },
    subheader: {
        color: 'var(--on-surface-variant)',
        fontSize: '0.8rem',
        fontWeight: 'bold'
    },
    placeholder: {
        color: 'var(--on-surface-variant)',
    },
    error: {
        color: 'var(--error)',
    },
    success: {
        color: 'var(--success)',
    },
    strikethrough: {
        color: 'var(--on-surface-variant)',
        textDecorationLine: 'line-through', 
        textDecorationStyle: 'solid',
        transition: 'color 0.3s ease'
    },
    bold: {
        color: 'var(--on-surface)',
        fontWeight: 'bold'
    },
    banner: {
        fontWeight: 'bold',
        fontSize: '4rem',
        lineHeight: '4rem',
        textAlign: 'center'
    },
    code: {
        fontFamily: 'Courier New',
        color: 'var(--on-surface-variant)',
        padding: '0.1rem 0.5rem',
        borderRadius: 'var(--border-radius)',
        backgroundColor: 'var(--surface-container)',
        fontSize: '1rem',
        textAlign: 'center'
    }
}

const Container = styled.div`
    overflow: hidden;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: ${props => props.numberOfLines}; /* Number of lines to show */
`

export const Text = props => {
    const {
        type='normal',
        numberOfLines=3
    } = props;

    return <Container numberOfLines={numberOfLines} style={{...styles['normal'], ...styles[type], ...props.style}} {..._.omit(props, ['style'])}>{props.children}</Container>
}