import _ from 'lodash'
import * as actionTypes from '../actions/actionTypes';
import moment from 'moment-timezone'
import { EJSON, ObjectId } from 'bson';
import { googleLogin, appleLogin, initialLoad, loadByDates, loadByPages, commit } from 'utils'
import { api } from 'services'
import { getAuth } from "@firebase/auth";
import { USER_ITEM_LIMITS, USER_REMINDER_LIMITS } from 'constants'
import { eachDayOfInterval } from 'date-fns'
import { persistor } from '../store'

const debug = false
const auth = getAuth()
const REACT_APP_TRIGGER_REDUX_ERROR = process.env.REACT_APP_TRIGGER_REDUX_ERROR

// Mobile: Theme update, Web: None
export const loadData = (user, method='initial', extinfo, callback) => {
    return (dispatch, getState) => {
        if(user && user['token']) {
            const { token } = user
            const db_user = getState().db.user
            const db_data = getState().db.data 
            const loaded_dates = getState().db.loaded_dates
            switch(method) {
                case 'initial':
                    // return callback?.(true)
                    return initialLoad(user)
                    .then(res => {
                        const data = _.pick(res, ['views','lists','sections','items','tags','integrations','calendars'])
                        for (const [key, value] of Object.entries(data)) {
                            debug && console.log(`${key}: ${Object.keys(value).length}`);
                        }
                        if(res) {
                            dispatch({ 
                                type: actionTypes.LOAD_DATA, 
                                user: { ...user, token }, 
                                data,
                            })
                            callback?.(true, data)
                        } else {
                            callback?.(false)
                        }
                    })
                    .catch(err => {
                        debug && console.log({err})
                        callback?.(false)
                    })
                case 'by_dates':
                    const { start, end } = extinfo
                    let to_load = eachDayOfInterval({start, end})
                    to_load = to_load.filter(date => !loaded_dates?.includes(moment(date).format('YYYY-MM-DD'))).map(date => moment(date).format('YYYY-MM-DD'))
                    if(to_load.length === 0) { return callback?.(true, db_data) }
                    return loadByDates(user, start, end)
                    .then(loaded_items => {
                        const updated_data = {...db_data, items: {...db_data.items, ...loaded_items}}
                        dispatch({
                            type: actionTypes.LOAD_DATA, 
                            user,
                            data: updated_data,
                            loaded_dates: [...loaded_dates, ...to_load]
                        })
                        callback?.(true, updated_data)
                    })
                    .catch(err => {
                        callback?.(false)
                    })
                case 'by_pages':
                    const query = extinfo
                    if(!query) { return callback?.(false) }
                    return loadByPages(user, query)
                    .then(res => {
                        const { loaded, end_reached, page } = res
                        const item_dates = Object.keys(_.groupBy(loaded, item => moment(item['complete_time']).format('YYYY-MM-DD')))
                        const start = _.min(item_dates)
                        const end = _.min(loaded_dates) || moment().utc().format()
                        const newly_loaded_dates = eachDayOfInterval({start, end}).map(date => moment(date).format('YYYY-MM-DD'))
                        dispatch({
                            type: actionTypes.LOAD_DATA, 
                            user,
                            data: {...db_data, items: {...db_data.items, ...loaded}},
                            loaded_dates: query ? loaded_dates : [...loaded_dates, ...newly_loaded_dates]
                        })
                        callback?.(true, end_reached, page)
                    })
                case 'sync':
                    const { date } = extinfo
                    return initialLoad(user, {
                        created_by: user['user_id'],
                        or: [
                            { createdAt: { gte: date } },
                            { updatedAt: { gte: date } },
                        ]
                    })
                    .then(downloaded_data => {
                        let updated_data = {...db_data}
                        Object.values(['views','lists','sections','items','tags','integrations','calendars']).forEach(key => {
                            updated_data[key] = {...db_data[key], ...downloaded_data[key]}
                        })
                        if(downloaded_data) {
                            dispatch({ type: actionTypes.UPDATE_DATA, data: updated_data })
                            dispatch({ type: actionTypes.UPDATE_USER, user }) // cannot put in LoadingScreen, else the redirection won't work if there is an additional dispatch
                            callback?.(true, updated_data)
                        } else {
                            callback?.(false)
                        }
                    })
                case 'invalid':
                    callback?.(false) 
                    break
                default:
                    callback?.(false)
            }
        } else {
            return callback?.(false) 
        }

    }
}
///////////////////
// USER HANDLERS //
///////////////////
export const signOut = (callback) => {
    return (dispatch, getState) => {
        const socket = getState().db.socket
        return Promise.all([
            persistor.purge(),
            auth.signOut(),
            socket?.disconnect()
        ])
        .then(res => {
            callback && callback(true)
            dispatch({ type: actionTypes.UPDATE_DATA, data: null })  
            dispatch({ type: actionTypes.UPDATE_USER, user: null })
            dispatch({ type: actionTypes.SET_SOCKET, socket: null })
        })
        .catch(err => {
            console.log(err)
        })
    }
    
}

export const updateUser = (values, callback, commit_earlier=true) => {
    return (dispatch, getState) => {
        const data = getState().db.data
        const user = getState().db.user
        const updatedUser = {...user, ...values, update_time: moment()}
        if(REACT_APP_TRIGGER_REDUX_ERROR === 'true') { return callback?.(false) }

        // Commit
        const data_updates = null
        const user_updates = _.pick(updatedUser, ['displayName','preferences','pn_tokens','is_pro','pro_expiry','lists','tags','user_uuid'])
        return commit({
            data, user, dispatch, commit_earlier,
            data_updates, user_updates, 
            callback: success => callback?.(success), 
            promises: [
                user['uid'] && api.updateUser(user['user_id'], user_updates, user['token'])
            ]
        })
    }
}

export const deleteUser = (user, callback) => {
    return dispatch => {
        const loginHandler = user.providerId === 'google.com' ? googleLogin : appleLogin
        loginHandler()
        .then(res => {
            if(!res) {
                callback?.(false)
            } else if (res.user && res.user.uid !== user.uid) {
                callback?.(false)
            } else if(res.user && res.user.uid === user.uid) {
                const firebase_user = auth.currentUser
                const created_by = user['user_id']
                return Promise.all([
                    api.deleteViews({ created_by }, user['token']),
                    api.deleteLists({ created_by }, user['token']),
                    api.deleteSections({ created_by }, user['token']),
                    api.deleteItems({ created_by }, user['token']),
                    api.deleteTags({ created_by }, user['token']),
                    api.deleteUser(user['user_id'], user['token']),
                    api.deleteReminders({ user_id: user['user_id'] }, user['token']),
                    firebase_user.delete()
                ])
                .then(res => {
                    callback?.(true)
                    dispatch({ type: actionTypes.UPDATE_DATA, data: null })  
                    dispatch({ type: actionTypes.UPDATE_USER, user: null })
                })
                .catch(err => {
                    callback?.(false)
                })
            } else {
                callback?.(false)
            }
        })
    }
}

// Mobile: Need support onboard of local user cause of app store requirement to allow usage without initial sign up, Mobile: Error if no uid
export const onboardUser = (values, callback) => {
    return dispatch => {
        if(!values) { return callback?.(false) }
        let token
        const { displayName, email, providerId, pn_tokens=[] } = values
        const uid = values.uid
        const user_id = EJSON.deserialize(new ObjectId())
        const inbox_id = EJSON.deserialize(new ObjectId())
        const view_id = EJSON.deserialize(new ObjectId())
        let user = {
            _id: user_id,
            uid,
            displayName,
            email,
            providerId,
            user_id,
            inbox_id,
            create_time: moment(),
            lists: [],
            views: [view_id],
            tags: [],
            item_count: 0,
            reminder_count: 0,
            item_limit: USER_ITEM_LIMITS.FREE,
            reminder_limit: USER_REMINDER_LIMITS.FREE,
            is_pro: false,
            pn_tokens,
            preferences: {
                is_dark: true,
                enable_reminders: true,
                default_schedule_duration: 60,
                default_reminder_duration: 0,
                include_day: true,
                date_format: 'D MMM',
                time_format: 'h:mma',
                enable_pn: true,
                default_side_calendar_view: 'day',
                default_full_calendar_view: 'week',
                ...values.preferences
            }
        }
        const inbox = {
            _id: inbox_id,
            list_id: inbox_id,
            title: 'Inbox',
            create_time: moment(),
            created_by: user_id,
            view_id,
            sections: [],
            items: []
        }
        const view = {
            _id: view_id,
            title: 'Inbox view',
            view_id,
            list_id: inbox_id,
            group: 'sections',
            lists: [inbox_id],
            sort: null,
            display: ['sub_items','schedule','repeat','tags'],
            target: 'items',
            create_time: moment(),
            created_by: user_id,
            hidden: [],
            type: 'list'
        }
        
        if(uid) {
            return api.createUser(user)
            .then(res => {
                token = res.data['token']
                debug && console.log({token})
                return Promise.all([
                    api.createLists([inbox], token),
                    api.createViews([view], token)
                ])
                .then(createRes => {
                    dispatch({type: actionTypes.UPDATE_DATA, token, data: {
                        lists: { [inbox_id]: inbox }, 
                        views: { [view_id]: view },
                        sections: {},
                        items: {},
                        tags: {},
                        reminders: {},
                    }})
                    dispatch({type: actionTypes.UPDATE_USER, user: {...user, token}})
                    callback?.(true, view_id)
                })
                .catch(err => {
                    callback?.(false)
                })
            })
            .catch(err => {
                callback?.(false)
            })
        } else {
            callback?.(false)
        }
    }
}

///////////////////
// VIEW HANDLERS //
///////////////////
export const updateView = (values, callback, commit_earlier=true) => {
    return (dispatch, getState) => {
        const data = getState().db.data
        const user = getState().db.user
        if(process.env.REACT_APP_TRIGGER_REDUX_ERROR === 'true') { return callback?.(false) }

        // 1. Basic validation
        if(!user || !values || !data) { return callback?.(false) }

        // 2. View validation
        if(values && !values['view_id']) { return callback?.(false) }

        // 3. Update values
        const updatedValues = {...values, update_time: moment()}
        const updatedViews = {...data.views, [values['view_id']]: updatedValues}

        // 4. Commit
        const data_updates = { views: updatedViews }
        const user_updates = null
        return commit({
            data, user, dispatch, commit_earlier,
            data_updates, user_updates, 
            callback: success => callback?.(success), 
            promises: [
                user['uid'] && api.updateViews([{ 
                    filter: { view_id: values['view_id'], created_by: user['user_id'] }, 
                    update: _.pick(updatedValues,['title','notes','group','priorities','schedule','tags','lists','start','end','sort','display','target','type','hidden','update_time']) 
                }], user['token'])
            ]
        })
    }
}

///////////////////
// LIST HANDLERS //
///////////////////
// Mobile: Callback provides view, Web: Callback provides list_id
export const createList = (values, callback, commit_earlier=false) => {
    return (dispatch, getState) => {
        const data = getState().db.data
        const user = getState().db.user
        if(process.env.REACT_APP_TRIGGER_REDUX_ERROR === 'true') { return callback?.(false) }

        // 1. Basic validation
        if(!user || !values || !data) { return callback?.(false) }
        
        // 2. List validation
        if(!values?.['title']) { return callback?.(false) }

        // 3. Update values
        const list_id = EJSON.deserialize(new ObjectId())
        const view_id = EJSON.deserialize(new ObjectId())
        const updatedValues = {
            ...values, 
            _id: list_id, 
            view_id, 
            list_id, 
            create_time: moment(), 
            created_by: user['user_id'], 
            items: [],
            sections: []
        }
        const view = {
            _id: view_id,
            title: `${updatedValues['title']}'s view`,
            view_id,
            list_id,
            group: 'sections',
            lists: [list_id],
            sort: null,
            display: ['sub_items','schedule','repeat','tags'],
            target: 'items',
            create_time: moment(),
            created_by: user['user_id'],
            type: 'list'
        }

        // 4. Commit
        const data_updates = { 
            lists: {...data.lists, [list_id]: updatedValues},
            views: {...data.views, [view_id]: view}
        }
        const user_updates = { lists: [list_id, ...user.lists] }
        return commit({
            data, user, dispatch, commit_earlier,
            data_updates, user_updates, 
            callback: success => callback?.(success, list_id), // Web need list_id to redirect, mobile need view instead
            promises: [
                user['uid'] && api.createLists([{...updatedValues}], user['token']),
                user['uid'] && api.createViews([view], user['token']),
                user['uid'] && api.updateUser(user['user_id'], user_updates, user['token'])
            ]
        })
    }
}

export const deleteList = (list_id, callback, commit_earlier=true) => {
    return (dispatch, getState) => {
        const data = getState().db.data
        const user = getState().db.user
        if(REACT_APP_TRIGGER_REDUX_ERROR === 'true') { return callback?.(false) }
        if(!list_id) { return callback?.(false) }

        // 1. Update data
        let updatedData = {...data}
        const view_id = updatedData.lists[list_id]['view_id']
        delete updatedData.lists[list_id]
        delete updatedData.views[view_id]

        // 2. Update user
        let updatedUser = {...user}
        let updatedLists = updatedUser['lists']
        updatedLists.splice(updatedLists.indexOf(list_id),1)
        updatedUser['lists'] = updatedLists
        

        // 3. Commit data
        const data_updates = _.pick(updatedData, ['lists','views'])
        const user_updates = { lists: updatedLists }
        commit({
            data, user, dispatch, commit_earlier,
            data_updates, user_updates, 
            callback: success => callback?.(success), 
            promises: [
                user['uid'] && api.deleteLists({ list_id, created_by: user['user_id'] }, user['token']),
                user['uid'] && api.deleteViews({ ids: [view_id], created_by: user['user_id'] }, user['token']),
                user['uid'] && api.updateUser(user['user_id'], user_updates, user['token'])
            ]
        })
    }
}

export const updateList = (values, callback, commit_earlier=true) => {
    return (dispatch, getState) => {
        const data = getState().db.data
        const user = getState().db.user
        if(REACT_APP_TRIGGER_REDUX_ERROR === 'true') { return callback?.(false) }

        let updatedValues = { ...values, update_time: moment() }
        const updatedLists = {...data.lists, [values['list_id']]: updatedValues}

        // Commit
        const data_updates = { lists: updatedLists }
        const user_updates = null
        commit({
            data, user, dispatch, commit_earlier,
            data_updates, user_updates, 
            callback: success => callback?.(success), 
            promises: [
                user['uid'] && api.updateLists([{ 
                    filter: { list_id: values['list_id'], created_by: user['user_id'] }, 
                    update: _.pick(updatedValues, ['title','sections','color','update_time','is_archived','items','archive_time'])
                }], user['token'])
            ]
        })
    }
}

//////////////////////
// SECTION HANDLERS //
//////////////////////
export const createSection = (values, callback, commit_earlier=true) => {
    return (dispatch, getState) => {
        const data = getState().db.data
        const user = getState().db.user
        if(process.env.REACT_APP_TRIGGER_REDUX_ERROR === 'true') { return callback?.(false) }

        // 1. Basic validation
        if(!user || !values || !data || !values['list_id'] || !values['title']) { return callback?.(false) }

        // 2. Section validation
        if(values && !values['list_id'] || !values['title']) { return callback?.(false) }

        // 3. Update values
        const section_id = EJSON.deserialize(new ObjectId())
        const updatedValues = {...values, _id: section_id, section_id, items: [], created_by: user['user_id'], create_time: moment()}

        // 4. Update list
        let updatedList = {...data.lists[values['list_id']]}
        updatedList['sections'] = [...updatedList['sections'], section_id]
        const updatedLists = {...data.lists, [values['list_id']]: updatedList}
        const updatedSections = {...data.sections, [section_id]: updatedValues}

        // 5. Commit
        const data_updates = { lists: updatedLists, sections: updatedSections}
        const user_updates = null
        commit({
            data, user, dispatch, commit_earlier,
            data_updates, user_updates, 
            callback: success => callback?.(success), 
            promises: [
                user['uid'] && api.createSections([updatedValues], user['token']),
                user['uid'] && api.updateLists([{ filter: { list_id: updatedValues['list_id'], created_by: user['user_id'] }, update: { sections: updatedList['sections'] } }], user['token']),
            ]
        })
    }
}

export const deleteSection = (section_id, callback, commit_earlier=true) => {
    return (dispatch, getState) => {
        const data = getState().db.data
        const user = getState().db.user
        if(REACT_APP_TRIGGER_REDUX_ERROR === 'true') { return callback?.(false) }
        if(!section_id, !data, !user) { return callback?.(false) }
        const section = data.sections[section_id]

        // 1. Delete section
        let updatedSections = {...data.sections}
        delete updatedSections[section_id]

        // 2. Delete items
        const updatedItems = _.omitBy({...data.items}, item => {
            return item['section_id'] === section_id
        })

        // 3. Update list
        let updatedList = {...data.lists[section['list_id']]}
        updatedList['sections'] = updatedList['sections'].filter(id => id !== section_id)
        const updatedLists = {...data.lists, [section['list_id']]: updatedList}

        // 4. Commit
        const data_updates = { items: updatedItems, sections: updatedSections, lists: updatedLists }
        const user_updates = null
        commit({
            data, user, dispatch, commit_earlier,
            data_updates, user_updates, 
            callback: success => callback?.(success), 
            promises: [
                user['uid'] && api.deleteItems({ section_id, created_by: user['user_id'] }, user['token']),
                user['uid'] && api.deleteSections({ section_id, created_by: user['user_id'] }, user['token']),
                user['uid'] && api.updateLists([{ filter: { list_id: section['list_id'], created_by: user['user_id'] }, update: { sections: updatedList['sections'] } }], user['token']),
            ]
        })
    }
}

export const updateSection = (values, callback, commit_earlier=true) => {
    return (dispatch, getState) => {
        const data = getState().db.data
        const user = getState().db.user
        const sections = data.sections
        if(REACT_APP_TRIGGER_REDUX_ERROR === 'true') { return callback?.(false) }
        if(!values || !data || !user) { return callback?.(false) }

        // 1. Section validation
        if(!values['section_id'] || !values['title']) { return callback?.(false) }

        // 2. Update values
        const updatedValues = { ...values, update_time: moment() }
        const updatedSections = {...sections, [values['section_id']]: updatedValues}

        // 3. Commit data
        const data_updates = { sections: updatedSections }
        const user_updates = null
        commit({
            data, user, dispatch, commit_earlier,
            data_updates, user_updates, 
            callback: success => callback?.(success), 
            promises: [
                user['uid'] && api.updateSections([{ 
                    filter: { section_id: values['section_id'], created_by: user['user_id'] }, 
                    update: _.pick(updatedValues, ['title','items','list_id','is_archived','update_time','archive_time']) 
                }], user['token']),
            ]
        })
    }
}

///////////////////
// ITEM HANDLERS //
///////////////////
export const createItem = (values, callback, commit_earlier=true) => {
    return (dispatch, getState) => {
        const data = getState().db.data
        const user = getState().db.user
        if(process.env.REACT_APP_TRIGGER_REDUX_ERROR === 'true') { return callback?.(false) }

        // 1. Basic validation
        if(!user || !values || !data) { return callback?.(false)}
        
        // 2. Item validation
        if(!values['title'] || values['title'] === '' || (!values['list_id'] && !values['calendar_id'])) { return callback?.(false) }

        // 3. User validation
        if(!user['is_pro']) {
            const item_limit = user['uid'] ? (user['item_limit'] || USER_ITEM_LIMITS.FREE) : USER_ITEM_LIMITS.LOCAL
            const hit_limit = (user['item_count'] || 0) >= item_limit
            debug && console.log('[createItem] Limit check', { item_limit, item_count: user['item_count'], hit_limit })
            if(hit_limit) {
                return callback?.(false, 'HIT_LIMIT')
            }
        }

        // 4. Update values
        const item_id = EJSON.deserialize(new ObjectId())
        const updatedValues = {_id: item_id, item_id, priority: 4, items: [], parents: [], tags: [], create_time: moment(), created_by: user['user_id'], ...values}
        let updatedItems = {...data.items, [item_id]: updatedValues}

        // 5. Update parent (section / item)
        let updatedSections  = {...data.sections}
        let updatedLists = {...data.lists}
        let updatedParentItem
        let updatedSection
        let updatedList
        let updateParentItem = false
        let updateSection = false
        let updateList = false
        if(values['parents'] && values['parents'].length > 0) {
            updateParentItem = true
            const parent_id = values['parents'].slice(-1)[0]
            let parent = {...data.items[parent_id]}
            parent['items'] = parent['items'] ? [item_id, ...parent['items']] : [item_id]
            updatedItems[parent_id] = parent
            updatedParentItem = parent
        } else if (values['section_id']) {
            updateSection = true
            updatedSection = {...data.sections[values['section_id']]}
            updatedSection['items'] = [item_id, ...updatedSection['items']]
            updatedSections[values['section_id']] = updatedSection
        } else if (!values['section_id']) {
            updateList = true
            updatedList = {...data.lists[values['list_id']]}
            updatedList['items'] = [item_id, ...updatedList['items']]
            updatedLists[values['list_id']] = updatedList
        }

        // 6. Create reminders (if any)
        const has_reminders = values['reminders']?.length > 0
        let reminders = []
        if(has_reminders) {
            values['reminders'].forEach(reminder => {
                reminders.push({
                    start: values['is_all_day'] ? moment(values['start']).startOf('day').utc().format() : moment(values['start']).utc().format(),
                    interval: reminder,
                })
            })
        }

        // 7. Commit
        const data_updates = { sections: updatedSections, items: updatedItems, lists: updatedLists }
        const user_updates = { item_count: user['item_count'] + 1 }
        commit({
            data, user, dispatch, commit_earlier,
            data_updates, user_updates, 
            callback: success => callback?.(success, item_id), 
            promises: [
                user['uid'] && api.createItems([updatedValues], user['token']),
                user['uid'] && updateParentItem && api.updateItems([{ filter: { item_id: updatedParentItem['item_id'], created_by: user['user_id'] }, update: _.pick(updatedParentItem, ['items','update_time']) }], user['token']),
                user['uid'] && updateSection && api.updateSections([{ filter: { section_id: updatedSection['section_id'], created_by: user['user_id'] }, update: { items: updatedSection['items'] } }], user['token']),
                user['uid'] && updateList && api.updateLists([{ filter: { list_id: updatedList['list_id'], created_by: user['user_id'] }, update: { items: updatedList['items'] } }], user['token']),
                user['uid'] && api.updateUser(user['user_id'], user_updates, user['token']),
                user['uid'] && reminders.length > 0 && api.createReminders({item_id, user_id: user['user_id'], reminders}, user['token']),
            ]
        })
    }
}

export const deleteItem = (item_id, callback, commit_earlier=true) => {
    return (dispatch, getState) => {
        const data = getState().db.data
        const user = getState().db.user
        if(REACT_APP_TRIGGER_REDUX_ERROR === 'true') { return callback?.(false) }
        if(!item_id || !data || !user || !user?.['uid']) { return callback?.(false) }

        let updated_data = {...data}
        const item = data.items[item_id]
        let item_deletes
        let list_updates
        let section_updates
        let item_updates
        let user_updates
        let reminder_deletes

        // 2. Update parent
        const parent_type = item['parents']?.length > 0 ? 'item'
                            : item['section_id'] ? 'section'
                            : item['calendar_id'] ? 'calendar'
                            : 'list'
        if(parent_type !== 'calendar') {
            const parent_id = parent_type === 'item' ? item['parents'].slice(-1)[0] : item[`${parent_type}_id`]
            const parent = data[`${parent_type}s`][parent_id]
            const parent_item_ids = parent['items'].filter(x => x !== item_id)
            updated_data[`${parent_type}s`][parent_id] = {...parent, items: parent_item_ids}
            switch(parent_type) {
                case 'list':
                    list_updates = [{
                        filter: { list_id: parent_id, created_by: user['user_id'] },
                        update: { items: parent_item_ids }
                    }]
                    break
                case 'section':
                    section_updates = [{
                        filter: { section_id: parent_id, created_by: user['user_id'] },
                        update: { items: parent_item_ids }
                    }]
                    break
                case 'item':
                    item_updates = [{
                        filter: { item_id: parent_id, created_by: user['user_id'] },
                        update: { items: parent_item_ids }
                    }]
                    break
            }
        }

        // 2. Delete item, children and branches
        const child_ids = Object.keys(_.pickBy(updated_data.items, x => x['parents'] && x['parents'].includes(item_id))) || []
        updated_data.items = _.pickBy(
                                updated_data.items, 
                                x => {
                                    if(item['event_id']) {
                                        return x['item_id'] !== item_id 
                                            && x['repeat_id'] !== item_id 
                                            && !x['parents'].includes(item_id)
                                            && x['event_id'] !== item['event_id']
                                            && x['repeat_id'] !== item['event_id']
                                    } else {
                                        return x['item_id'] !== item_id  && !x['parents'].includes(item_id)
                                    }
                                }
                            )
        item_deletes = {
            created_by: user['user_id'],
            or: [
                { item_id },
                { repeat_id: item['event_id'] || item_id },
                { parents: { in: [item_id] } }
            ]
        }

        // 4. Remove reminders
        const has_reminders = item.reminders && item.reminders?.length > 0
        if(has_reminders) {
            reminder_deletes = {
                item_id,
                user_id: user['user_id']
            }
        }

        // 5. Update user
        const new_item_count = user['item_count'] - 1 - child_ids.length > 0 ? user['item_count'] - 1 - child_ids.length : user['item_count']
        user_updates = { item_count: new_item_count }

        // 6. Commit
        const data_updates = updated_data
        commit({
            data, user, dispatch, commit_earlier,
            data_updates, user_updates, 
            callback: success => callback?.(success, item_id), 
            promises: [
                user['uid'] && section_updates && api.updateSections(section_updates, user['token']),
                user['uid'] && list_updates && api.updateLists(list_updates, user['token']),
                user['uid'] && item_updates && api.updateItems(item_updates, user['token']),
                user['uid'] && api.deleteItems(item_deletes, user['token']),
                user['uid'] && api.updateUser(user['user_id'], user_updates, user['token']),
                user['uid'] && reminder_deletes && api.deleteReminders(reminder_deletes, user['token'])
            ]
        })
    }
}

export const updateItem = (item_id, edits, callback, commit_earlier=true) => {
    return (dispatch, getState) => {
        // 1. Initial setup
        const data = getState().db.data
        const user = getState().db.user
        if(REACT_APP_TRIGGER_REDUX_ERROR === 'true') { return callback?.(false) }
        const update_time = moment().utc().format()
        const item = data.items[item_id]
        let updated_item = {...data.items[item_id], ...edits, update_time}
        let updated_data = {...data}
        updated_data.items[item_id] = updated_item
        let updates = ['items']

        // 2. Updates
        const valid_item_keys = ['_id','item_id','created_by','is_completed','is_archived','priority','notes','title','items','parents','tags','start','end','is_all_day','section_id','list_id','update_time','complete_time','archive_time','reminders','repeat','link','event_id','calendar_id','repeat_id']
        const keys = ['update_time', ...Object.keys(edits)]
        let item_update_keys = [...keys].filter(x => valid_item_keys.includes(x))
        let item_updates = [];
        let reminder_updates = [];
        let reminder_deletes;
        let section_updates = [];
        let list_updates = [];
        let item_creates = []

        const parent_type = item['section_id'] ? 'section' : 'list'
        const parent_id = item[`${parent_type}_id`]
        let parent = data[`${parent_type}s`][parent_id]
        let parent_update_query
        const is_branch_out = keys.includes('repeat') && Object.keys(edits).length > 1 && ((edits?.repeat?.exdate?.length > item?.repeat?.exdate?.length ) || (edits?.repeat?.exdate?.length > 0 && !item?.repeat?.exdate))
        let branch_item
        if(is_branch_out) {
            const branch_id = EJSON.deserialize(new ObjectId())
            branch_item = {...item, ..._.pick(edits, valid_item_keys), _id: branch_id, item_id: branch_id, repeat_id: item_id, create_time: update_time, repeat: null, event_id: null}
            if(edits['is_completed']) {
                branch_item['complete_time'] = update_time
            }
            branch_item = _.pick(branch_item, valid_item_keys)

            // 1. Duplicate children (if any)
            let dup_child_ids = []
            if(edits['children']) {
                edits['children'].forEach(child => {
                    const child_id = EJSON.deserialize(new ObjectId())
                    dup_child_ids.push(child_id)
                    let dup_child = { ...child, _id: child_id, item_id: child_id, create_time: update_time, parents: [branch_id], repeat_id: item_id }
                    dup_child = _.pick(dup_child, valid_item_keys)
                    item_creates.push(dup_child)
                    data.items[child_id] = dup_child
                })
                branch_item['items'] = dup_child_ids
            } else if(item['items']?.length > 0) {
                item['items'].forEach(child_id => {
                    const dup_child_id = EJSON.deserialize(new ObjectId())
                    dup_child_ids.push(dup_child_id)
                    let dup_child = { ...data.items[child_id], _id: dup_child_id, item_id: dup_child_id, create_time: update_time, parents: [branch_id], repeat_id: item_id }
                    dup_child = _.pick(dup_child, valid_item_keys)
                    item_creates.push(dup_child)
                    data.items[dup_child_id] = dup_child
                })
                branch_item['items'] = dup_child_ids
            }

            // 2. Uncheck children (if any) *no need anymore; cause edit of sub-items will alr prompt EditRepeatModal*
            // if(edits['is_completed'] && item.items?.length > 0) {
            //     item['items'].forEach(child_id => {
            //         const child = data.items[child_id]
            //         if(child['is_completed']) {
            //             data.items[child_id] = {...child, is_completed: false, complete_time: false}
            //             item_updates.push({
            //                 filter: { item_id: child_id, created_by: user['user_id'] },
            //                 update: { is_completed: false, complete_time: false } 
            //             })
            //         }
            //     })
            // }

            // 3. Create branch item
            item_creates.push(branch_item)
            updated_data.items[branch_id] = branch_item

            // 4. Remove edits for main item, except repeat.exdate
            item_update_keys = ['update_time','repeat']
            updated_item = {...item, ..._.pick(updated_item, ['update_time','repeat'])}
            updated_data.items[item_id] = updated_item

            // 5. Update section/list
            if(!edits['is_completed'] && !edits['is_archived'] && parent) {
                const position = parent['items'].indexOf(item_id) + 1
                let updated_parent = {...parent} 
                let updated_parent_items = updated_parent['items']
                updated_parent_items.splice(position,0,branch_id)
                updated_parent['items'] = updated_parent_items
                updated_data[`${parent_type}s`][parent_id] = updated_parent
                const parent_update_query = {
                    filter: { [`${parent_type}_id`]: parent_id, created_by: user['user_id'] },
                    update: { items: updated_parent_items, update_time }
                } 
                parent_type === 'section' ? section_updates.push(parent_update_query) : list_updates.push(parent_update_query)
            }
        } else {
            keys.forEach(key => {
                switch(key) {
                    case 'start':
                        // 1. Update reminders
                        const reminder_action = !edits['start'] ? 'remove'
                                                : edits['start'] && !item['start'] ? 'add'
                                                : 'update'
                        updated_item['reminders'] = reminder_action === 'remove' ? []
                                                    : reminder_action === 'add' && user['is_pro'] ? [user.preferences['default_reminder_duration'] || 0]
                                                    : reminder_action === 'add' && !user['is_pro'] ? []
                                                    : item['reminders']
                        
                        // 2. Construct reminder updates
                        switch(reminder_action) {
                            case 'remove':
                                item_update_keys.push('reminders')
                                reminder_deletes = {
                                    item_id,
                                    user_id: user['user_id']
                                }
                                break
                            case 'add':
                                if(user['is_pro']) {
                                    item_update_keys.push('reminders')
                                    updated_item['reminders'].forEach(reminder => {
                                        reminder_updates.push({
                                            start: updated_item['is_all_day'] ? moment(updated_item['start']).startOf('day').utc().format() : moment(updated_item['start']).utc().format(),
                                            interval: reminder,
                                        })
                                    })
                                }
                                
                                break
                            case 'update':
                                updated_item['reminders']?.length > 0 && item_update_keys.push('reminders')
                                updated_item['reminders']?.forEach(reminder => {
                                    reminder_updates.push({
                                        start: updated_item['is_all_day'] ? moment(updated_item['start']).startOf('day').utc().format() : moment(updated_item['start']).utc().format(),
                                        interval: reminder,
                                    })
                                })
                                break
                        }
                        break
                    case 'reminders':
                        const action = edits['reminders'].length > 0 ? 'update' : 'remove'
                        switch(action) {
                            case 'update':
                                item_update_keys.push('reminders')
                                updated_item['reminders'].forEach(reminder => {
                                    reminder_updates.push({
                                        start: updated_item['is_all_day'] ? moment(updated_item['start']).startOf('day').utc().format() : moment(updated_item['start']).utc().format(),
                                        interval: reminder,
                                    })
                                })
                                break
                            case 'remove':
                                item_update_keys.push('reminders')
                                reminder_deletes = {
                                    item_id,
                                    user_id: user['user_id']
                                }
                                break
                        }
                        
                        break
                    case 'section_id':
                        // 1. Skip update if section is updated
                        if(keys.includes('list_id')) { break }
                        break
                    case 'list_id':
                        // 1. Update children
                        const children = _.pickBy(data.items, item => (item?.['parents']?.includes(item_id)))
                        if(Object.keys(children).length > 0) {
                            Object.values(children).forEach(child => {
                                updated_data.items[child['item_id']] = {
                                    ...child,
                                    section_id: edits['section_id'],
                                    list_id: edits['list_id'],
                                    update_time
                                }
                            })
                        }
    
                        // 2. Update current parent
                        const is_same_section = item['section_id'] === edits['section_id'] && item['list_id'] === edits['list_id']
                        if(!is_same_section) {
                            const current_parent_type = item['section_id'] ? 'section' : 'list'
                            const current_parent_id = item[`${current_parent_type}_id`]
                            let current_parent = {...data[`${current_parent_type}s`][current_parent_id]}
                            current_parent['items'] = current_parent['items'].filter(x => x !== item_id)
                            updated_data[`${current_parent_type}s`][current_parent_id] = current_parent
                            const current_parent_update_query = {
                                filter: { [`${current_parent_type}_id`]: current_parent_id, created_by: user['user_id'] },
                                update: { items: current_parent['items'], update_time }
                            } 
                            current_parent_type === 'section' ? section_updates.push(current_parent_update_query) : list_updates.push(current_parent_update_query)
                        }
    
                        // 3. Update new parent
                        const new_parent_type = edits['section_id'] ? 'section' : 'list'
                        const new_parent_id = edits[`${new_parent_type}_id`]
                        let new_parent = {...data[`${new_parent_type}s`]}[new_parent_id]
                        new_parent['items'] = edits['items'] || [...new_parent['items'], item_id]
                        // if(edits['items']) { item_update_keys.splice(item_update_keys.indexOf('items'), 1) }
                        updated_data[`${new_parent_type}s`][new_parent_id] = new_parent
                        const new_parent_update_query = {
                            filter: { [`${new_parent_type}_id`]: new_parent_id, created_by: user['user_id'] },
                            update: { items: new_parent['items'], update_time }
                        } 
                        new_parent_type === 'section' ? section_updates.push(new_parent_update_query) : list_updates.push(new_parent_update_query)
                        break
                    case 'repeat':
                        break
                    case 'is_completed':
                        const complete_time = edits['is_completed'] ? update_time : null
                        updated_item['complete_time'] = complete_time
                        item_update_keys.push('complete_time')
    
                        // 1. Update children
                        const has_children = item['items']?.length > 0
                        if(has_children) {
                            item['items'].forEach(child_id => {
                                const child = {
                                    ...updated_data['items'][child_id],
                                    is_completed: edits['is_completed'],
                                    complete_time
                                }
                                updated_data['items'][child_id] = child
                            })
    
                            item_updates.push({
                                filter: { parents: [item_id], created_by: user['user_id'] },
                                update: { is_completed: edits['is_completed'], complete_time }
                            })
                        }
    
                        // 2. Update parents
                        if(parent) {
                            parent['items'] = edits['is_completed'] ? parent['items'].filter(x => x !== item_id) : [...parent['items'], item_id]
                            parent['update_time'] = update_time
                            parent_update_query = {
                                filter: { [`${parent_type}_id`]: parent_id, created_by: user['user_id'] },
                                update: { items: parent['items'], update_time }
                            }
                            parent_type === 'section' ? section_updates.push(parent_update_query) : list_updates.push(parent_update_query)
                        }
                        
                        break
                    case 'is_archived':
                        const archive_time = edits['is_archived'] ? update_time : null
                        updated_item['archive_time'] = archive_time
                        item_update_keys.push('archive_time')
    
                        // 1. Update parents
                        parent['items'] = edits['is_archived'] ? parent['items'].filter(x => x !== item_id) : [...parent['items'], item_id]
                        parent_update_query = {
                            filter: { [`${parent_type}_id`]: parent_id, created_by: user['user_id'] },
                            update: { items: parent['items'], update_time }
                        }
                        parent_type === 'section' ? section_updates.push(parent_update_query) : list_updates.push(parent_update_query)
                        break
                    case 'items':
                        // Need this to prevent item edit after dnd
                        // If not will cause entire section to disappear; dnd > mark as complete > items in section will disappear
                        if(edits['section_id'] || edits['list_id']) {
                            updated_item['items'] = item['items']
                            item_update_keys = item_update_keys.filter(x => x !== 'items')
                        } 
                        break
                    default:
                        break
                }
            })
        }
        
        // x. Commit
        const data_updates = _.pick(updated_data, updates)
        const user_updates = null
        commit({
            data, user, dispatch, commit_earlier,
            data_updates, user_updates, 
            callback: success => callback?.(success, null, branch_item || updated_item), 
            promises: [
                user['uid'] && api.updateItems([{
                    filter: { item_id, created_by: user['user_id'] }, 
                    update: _.pick(updated_item, item_update_keys)
                }, ...item_updates].filter(x => x), user['token']),
                user['uid'] && reminder_updates.length > 0 && api.createReminders({ item_id, user_id: user['user_id'], reminders: reminder_updates }, user['token']),
                user['uid'] && reminder_deletes && api.deleteReminders(reminder_deletes, user['token']),
                user['uid'] && section_updates.length > 0 && api.updateSections(section_updates, user['token']),
                user['uid'] && list_updates.length > 0 && api.updateLists(list_updates, user['token']),
                user['uid'] && item_creates.length > 0 && api.createItems(item_creates, user['token'])
            ]
        })
    }
}

//////////////////
// TAG HANDLERS //
//////////////////
export const createTag = (values, callback, commit_earlier=true) => {
    return (dispatch, getState) => {
        const data = getState().db.data
        const user = getState().db.user
        if(REACT_APP_TRIGGER_REDUX_ERROR === 'true') { return callback?.(false) }
        if(!values || !data || !user) { return callback?.(false)}

        // 1. Update values
        const tag_id = EJSON.deserialize(new ObjectId())
        const updatedValues = {
            ...values,
            _id: tag_id,
            tag_id,
            create_time: moment(),
            created_by: user['user_id']
        }
        const updatedTags = {...data.tags, [tag_id]: updatedValues}

        // 2. Update user
        const updatedUser = {...user, tags: user.tags ? [tag_id, ...user.tags] : [tag_id]}

        // 3. Commit
        const data_updates = { tags: updatedTags }
        const user_updates = { tags: updatedUser['tags'] }
        commit({
            data, user, dispatch, commit_earlier,
            data_updates, user_updates, 
            callback: success => callback?.(success, tag_id), 
            promises: [
                user['uid'] && api.createTags([updatedValues], user['token']),
                user['uid'] && api.updateUser(user['user_id'], user_updates, user['token'])
            ]
        })
    }
}

export const deleteTag = (tag_id, callback, commit_earlier=true) => {
    return (dispatch, getState) => {
        const data = getState().db.data
        const user = getState().db.user
        if(REACT_APP_TRIGGER_REDUX_ERROR === 'true') { return callback?.(false) }
        if(!tag_id || !data || !user) { return callback?.(false)}

        // 1. Update tags
        let updatedTags = {...data.tags}
        delete updatedTags[tag_id]

        // 2. Update items
        let updatedItems = {...data.items}
        const tagged_items = _.pickBy(data.items, item => item['tags'] && item['tags'].includes(tag_id))
        Object.values(tagged_items).forEach(tagged_item => {
            updatedItems[tagged_item['item_id']] = {
                ...tagged_item,
                tags: tagged_item['tags'].filter(item_tag_id => item_tag_id !== tag_id),
                update_time: moment()
            }
        })
        
        // 3. Update user
        const updatedUser = {
            ...user,
            tags: user['tags'].filter(user_tag_id => user_tag_id !== tag_id),
        }

        // 4. Update views
        let updatedViews = {...data.views}
        const affected_views = _.pickBy(updatedViews, view => {
            return view.filter && view.filter['tags'] && view.filter['tags'].includes(tag_id)
        })
        if(Object.keys(affected_views).length > 0) {
            Object.keys(affected_views).forEach(view_id => {
                let updated_view = {...updatedViews[view_id]}
                updated_view.filter['tags'] = updated_view.filter['tags'].filter(x => x !== tag_id)
                updatedViews[view_id] = updated_view
            })
        }

        // 5. Commit
        const data_updates = { tags: updatedTags, items: updatedItems, views: updatedViews }
        const user_updates = { tags: updatedUser['tags'] }
        commit({
            data, user, dispatch, commit_earlier,
            data_updates, user_updates, 
            callback: success => callback?.(success, tag_id), 
            promises: [
                user['uid'] && api.deleteTags({ tag_id, created_by: user['user_id'] }, user['token']),
                user['uid'] && api.updateUser(user['user_id'], _.pick(updatedUser, ['tags']), user['token']),
                user['uid'] && api.updateItems([{ filter: { tags: [tag_id], created_by: user['user_id'] }, update: { tags: { pull: tag_id } } }], user['token']),
                user['uid'] && api.updateViews([{ filter: { tags: [tag_id], created_by: user['user_id'] }, update: { tags: { pull: tag_id } } }], user['token']),
            ]
        })
    }
}

export const updateTag = (values, callback, commit_earlier=true) => {
    return (dispatch, getState) => {
        const data = getState().db.data
        const user = getState().db.user
        if(REACT_APP_TRIGGER_REDUX_ERROR === 'true') { return callback?.(false) }
        if(!values || !data || (values && Object.keys(values).includes(['tag_id', 'title', 'color']))) { return callback?.(false) }

        // 1. Update tag
        const updatedValues = {...values, update_time: moment()}
        const updatedTags = {...data.tags, [values['tag_id']]: updatedValues}

        // 2. Commit
        const data_updates = { tags: updatedTags }
        const user_updates = null
        commit({
            data, user, dispatch, commit_earlier,
            data_updates, user_updates, 
            callback: success => callback?.(success), 
            promises: [
                user['uid'] && api.updateTags([{ 
                    filter: { tag_id: values['tag_id'], created_by: user['user_id'] }, 
                    update: _.pick(updatedValues,['update_time','title','color','notes']) 
                }], user['token']),
            ]
        })
    }
}