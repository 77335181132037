import React from 'react';
import styled from 'styled-components';
import {useSortable} from '@dnd-kit/sortable';
import {CSS} from '@dnd-kit/utilities';

const Container = styled.div`

`

const DragHandle = styled.div`
    border: 1px solid red;
    position: absolute;
    transform: translateX(-100%);
`

export const Sortable = props => {
    const {
        id,
        type,
        disabled=false,
    } = props;
    const { attributes, listeners, setNodeRef, transform, transition, setActivatorNodeRef, isOver } = useSortable({id, data: { id, type }, disabled});
    const style = {
        transform: CSS.Translate.toString(transform),
        transition: isOver ? transition : null, // fixes the weird jumping effect after drop
    };

    if(disabled) {
        return (
            <Container>
                {props.children}
            </Container>
        )
    } else {
        return (
            <Container ref={setNodeRef} style={style} {...attributes} {...listeners}>
                {props.children}
            </Container>
        )
    }
    
}