import React from 'react';
import styled from 'styled-components';
import { useSelector, useDispatch } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import { motion, AnimatePresence } from "framer-motion";

import { AppContext } from 'screens';
import { ListTile, Text, Button, SortableList, TextInput, ListBlock, TagBlock, Collapsible, CalendarBlock, Modal, LinkCalendarModal } from 'components'

import { getListItemCount, randomTagColor } from 'utils'
import * as actions from 'store/actions'
import { useToast } from 'hooks';
import { isMobile } from 'react-device-detect';
import _ from 'lodash'
import { IntegrationBlock } from 'components';

const Container = styled.div`
    height: 100vh;
    display: flex;
`

const Wrapper = styled.div`
    width: ${props => props.width};
    height: 100vh;
    padding: var(--spacing-large) var(--spacing);
    background-color: var(--surface-container);
    border-right: 1px solid var(--outline-variant);
    z-index: -1;
`

const Row = styled.div`
    display: flex;
    align-items: center;
    gap: var(--spacing-smallest);
    padding: var(--spacing-small);
    margin-top: var(--spacing);
`

const DndOverlay = styled.div`
    border: 1px solid var(--outline);
    border-radius: var(--border-radius);
`

const DndPlaceholder = styled.div`
    border: 1px solid var(--outline);
    border-radius: var(--border-radius);
    opacity: 0.5;
`

export const NavigationModule = props => {
    const {
        width='20rem'
    } = props;
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const { showToast } = useToast()
    const data = useSelector(state => state.db.data)
    const user = useSelector(state => state.db.user)
    const { showNavigation: open, setModule, setNavigation } = React.useContext(AppContext)
    const { type: screen_type, id: screen_id } = useParams()
    const [focus, setFocus] = React.useState(null)
    const [modal, setModal] = React.useState(null)
    if(!user) { return }

    const onDragEnd = ({target, over, items}, callback) => {
        if(!target || !over) { return }
        if(target['type'] !== over['type']) { return }

        let ids;
        items.forEach(section_items => {
            const section_ids = section_items.map(x => x['id'])
            if(section_ids.includes(target['id'])) {
                ids = section_ids.slice(1,)
            }
        })
        
        let updates = {}
        switch(target['type']) {
            case 'list':
                updates['lists'] = ids
                break
            case 'tag':
                updates['tags'] = ids
                break
        }
        dispatch(actions.updateUser(updates, success => {
            callback(success)
            !success && showToast({ title: `${target['type'] === 'list' ? 'Lists' : 'Tags'} not updated`, subtitle: 'Please try again', type: 'error' })
        })) 
    }

    const getDefaults = () => {
        let output = []
        const defaults = [
            // { value: 'WorkspaceModule', label: 'Workspace', icon: 'bulletin-board', onPress: () => navigation.push('WorkspaceModule', { view_id: user['workspace_id'], view: user && data.views[user['workspace_id']] })} ,
            // { value: 'TestModule', label: 'Test', icon: 'calendar-outline', onPress: () => navigation.push('TestModule') },
            // { value: 'onboarding', label: 'Onboarding', icon: 'calendar-outline' },
            // { value: 'upgrade', label: 'Upgrade', icon: 'one-up' },
            !isMobile && { value: 'calendar', label: 'Calendar', icon: 'CalendarOutline', path: '/calendar/', is_selected: screen_type === 'calendar' },
            { value: 'inbox', label: 'Inbox', icon: 'InboxOutline', path: `/list/${user['inbox_id']}`, is_selected: screen_type === 'list' && screen_id === user?.['inbox_id'] },
            { value: 'past', label: 'Past items', icon: 'ArchiveOutline', path: '/past/', is_selected: screen_type === 'past' }
        ]

        defaults.forEach((values,index) => {
            const { value, label, icon, path, is_selected } = values
            output.push(
                <ListTile 
                    label={label} 
                    leading={icon} 
                    leading_color='var(--on-surface-variant)'
                    style={{backgroundColor: is_selected ? 'var(--surface)' : 'var(--surface-container)'}}
                    onClick={() => {
                        navigate(path)
                        setModule(null)
                        isMobile && setNavigation(false)
                    }}
                    />
            )
        })

        return (
            <div style={{display: 'flex', flexDirection: 'column'}}>
                {output}
            </div>
        )
    }

    const renderItem = (values) => {
        const { id, type, extinfo={} } = values
        switch(type){
            case 'subheader':
                let input_placeholder;
                let onSubmit;
                const is_focused = id === focus
                let onSelect = () => setFocus(is_focused ? null : id)
                let subheader
                switch(id) {
                    case 'lists':
                        subheader = 'Lists'
                        input_placeholder = 'Create a new list...'
                        onSubmit = (title, callback) => {
                            dispatch(actions.createList({ title }, (success, new_list_id) => {
                                callback?.(success)
                                success && navigate(`/list/${new_list_id}`)
                                !success && showToast({ title: 'List not created', subtitle: 'Please try again', type: 'error' })
                                
                            }, true))
                        }
                        break
                    case 'tags':
                        subheader = 'Tags'
                        input_placeholder = 'Create a new tag...'
                        onSubmit = (title, callback) => {
                            dispatch(actions.createTag({ title, color: randomTagColor() }, (success, new_tag_id) => {
                                callback?.(success)
                                success && navigate(`/tag/${new_tag_id}`)
                                !success && showToast({ title: 'Tag not created', subtitle: 'Please try again', type: 'error' })
                            }, true))
                        }
                        break
                    case 'calendars':
                        subheader = 'Calendars'
                        onSelect = () => setModal(<LinkCalendarModal close={() => setModal(null)}/>)
                        break
                }
                return (
                    <div>
                        <Row> 
                            <Text type='subheader'>{subheader}</Text>
                            <Button 
                                icon={is_focused ? 'Close' : 'Plus'} 
                                icon_color='var(--on-surface-variant)' 
                                style={{padding: 0, backgroundColor: 'var(--surface-container)'}}
                                onClick={onSelect}
                                />
                        </Row>
                        <Collapsible collapsed={!is_focused}>
                            <TextInput 
                                placeholder={input_placeholder} 
                                submit_icon='Plus'
                                border={true}
                                auto_focus={true}
                                multiline={false} 
                                clearOnSubmit={true}
                                onSubmit={onSubmit}
                                onClose={() => setFocus(null)}
                                style={{marginBottom: 'var(--spacing-smallest)', backgroundColor: 'var(--surface)'}}
                                />
                        </Collapsible>
                    </div>
                )
            case 'list':
                return <ListBlock list_id={id} style={{backgroundColor: screen_id === id ? 'var(--surface)' : 'var(--surface-container)'}}/>
            case 'tag':
                return <TagBlock tag_id={id} style={{backgroundColor: screen_id === id ? 'var(--surface)' : 'var(--surface-container)'}}/>
            case 'integration':
                return <IntegrationBlock integration_id={id} style={{backgroundColor: screen_id === id ? 'var(--surface)' : 'var(--surface-container)'}} options={['calendars','unlink']}/>
            case 'placeholder':
                let placeholder = id === 'calendar_placeholder' ? 'None connected' : 'None created'
                return <Text type='placeholder' style={{marginLeft: 'var(--spacing-small)'}}>{placeholder}</Text>
            default:
                const { title, color, is_selected, trailing, onClick } = extinfo
                return (
                    <ListTile
                        label={title}
                        leading='SquareRounded'
                        leading_color={color || 'var(--on-surface-variant)'}
                        leading_size={0.6}
                        onClick={onClick}
                        style={{backgroundColor: is_selected ? 'var(--surface)' : 'var(--surface-container)'}}
                        trailing={<Text type='subtitle'>{trailing}</Text>}
                        />
                )
        }
    }

    const getContent = () => {
        // 1. Default modules
        const default_section = getDefaults()

        // 2. Lists and tags
        let output = []

        // 2a. Lists
        const list_ids = user['lists'].filter(list_id => {
            const list = data.lists[list_id]
            if(!list) {
                return false
            } else {
                return !list['is_archived'] && list['list_id'] !== user['inbox_id']
            }
        })
        const list_data = list_ids.map(list_id => {
            return {
                id: list_id,
                type: 'list'
            }
        })
        let list_blocks = [{ id: 'lists', type: 'subheader', disabled: true }, ...list_data]
        if(list_blocks.length === 1) {
            list_blocks.push({ id: 'list_placeholder', type: 'placeholder' })
        }
        output.push(list_blocks)

        // 2b. Calendars
        const calendar_data = user['integrations']?.map(integration_id => { return { id: integration_id, type: 'integration' } }) || []
        let calendar_blocks = [
            {id: 'calendars', type: 'subheader', disabled: true},
            ...calendar_data,
        ]
        if(calendar_data.length === 0) {
            calendar_blocks.push({ id: 'calendar_placeholder', type: 'placeholder' })
        }
        output.push(calendar_blocks)

        // 2c. Tags
        const tag_data = user['tags'].map(tag_id => {
            return {
                id: tag_id,
                type: 'tag'
            }
        })
        let tag_blocks = [{ id: 'tags', type: 'subheader', disabled: true }, ...tag_data]
        if(tag_blocks.length === 1) {
            tag_blocks.push({ id: 'tag_placeholder', type: 'placeholder' })
        }
        output.push(tag_blocks)

        return (
            <div style={{display: 'grid', gridTemplateRows: 'auto 1fr', border: '0px solid blue', height: '100%'}}>
                {default_section}
                <SortableList
                    data={output}
                    renderItem={renderItem}
                    renderDragOverlay={(values) => {
                        if(!values) { return }
                        return <DndOverlay>{renderItem(values)}</DndOverlay>
                    }}
                    renderDragPlaceholder={(values) => {
                        return <DndPlaceholder>{renderItem(values)}</DndPlaceholder>
                    }}
                    onDragEnd={onDragEnd}
                    />
            </div>
        )
    }

    const content = getContent()
    return (
        <Container>
            <AnimatePresence initial={false}>
                {open && 
                <motion.div key='navigation'
                    initial="collapsed"
                    animate="open"
                    exit="collapsed"
                    transition={{ duration: 0.3, ease: [0.04, 0.62, 0.23, 0.98] }}
                    variants={{ open: { x: 0, width }, collapsed: { x: `-${width}`, width: 0 } }}
                    >
                    <Wrapper width={width}>{content}</Wrapper>   
                </motion.div>}
            </AnimatePresence>
            <Modal open={!!modal} onClose={() => setModal(null)} zIndex={1300}>{modal || <div/>}</Modal> 
            {/* Need the zIndex to ensure the IntegrationOptions don't get covered */}
        </Container>
    )
}
