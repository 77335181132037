import React from 'react';
import styled from 'styled-components';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import { 
    Text, 
    Button, 
    ViewOptions, 
    ItemBlock, 
    SubheaderBlock, 
    Pressable, 
    TextInput, 
    Icon, 
    ContextMenu, 
    ListBlock, 
    ListTile, 
    ListOptions, 
    SectionOptions ,
    Labels,
    Fade
} from 'components'
import { Virtuoso } from 'react-virtuoso'

import * as actions from 'store/actions'
import { AppContext } from 'screens';
import { buildView, getLoadQuery } from 'utils'
import _ from 'lodash'
import moment from 'moment-timezone'

const COLUMN_WIDTH = '25rem'

const base = {
    completed: {
        is_completed: true,
        sort: 'complete_time',
        display: ['list','complete_time','schedule','tags'],
        target: 'items',
        type: 'list',
        // group: 'complete_date'
    },
    archived: {
        is_archived: true,
        sort: 'archive_time',
        display: ['list','archive_time','schedule','tags'],
        target: 'items',
        type: 'list',
        // group: 'archive_date'
    }
}

export const PastModule = props => {
    const {
        
    } = props;
    const navigate = useNavigate()
    const dispatch = useDispatch()
    const { renderHeader } = React.useContext(AppContext)
    const data = useSelector(state => state.db.data)
    const user = useSelector(state => state.db.user)
    const loaded_dates = useSelector(state => state.db.loaded_dates)
    const [loading, setLoading] = React.useState(false)
    const [end_reached, setEndReached] = React.useState(false)
    const [page, setPage] = React.useState(null)
    const [view, setView] = React.useState(base['completed'])
    const [focus, setFocus] = React.useState('completed') // completed, archived
    const sub_focus = view['target']

    const onView = (edits) => {
        const updated_view = {...view, ...edits}
        setEndReached(false)
        setView(updated_view)
    }

    const onFocus = (new_focus) => {
        setFocus(new_focus)
        onView({
            ...base[new_focus],
            filter: view['filter']
        })
    }

    const onLoad = () => {
        setLoading(true)
        const query = getLoadQuery(view ,user, loaded_dates, page)
        dispatch(actions.loadData(user, 'by_pages', query, (success, end_reached, loaded_page) => {
            loaded_page && setPage(loaded_page)
            end_reached && setEndReached(true)
            setLoading(false)
        }))
    }

    const renderItem = (values) => {
        const { type, id, defaults={} } = values
        const { hidden=[] } = view
        switch(true) {
            case type === 'item':
                return <ItemBlock item_id={id} labels={view['display']} disableDND={true} disableHandle={true}/>
            case type === 'section':
                const section = data.sections[id]
                const section_list = data.lists[section['list_id']]
                const section_labels = [
                    { label: `Archived ${moment(section['archive_time']).format(`${user.preferences['date_format']} YYYY`)}` },
                    section_list && { label: section_list['title'], color: section_list['color'] },
                    { label: `${section_list.items.length || 0} incomplete items` }
                ]
                return (
                    <ListTile
                        label={section['title']}
                        popover={<SectionOptions section_id={id}/>}
                        subtitle={
                            <Labels labels={section_labels}/>
                        }
                        />
                )
            case type === 'list':
                const list = data.lists[id]
                const list_labels = [
                    { label: `Archived ${moment(list['archive_time']).format(`${user.preferences['date_format']} YYYY`)}` },
                    { label: `${list['items'].length || 0} incomplete items` }
                ]
                return (
                    <ListTile
                        label={list['title']}
                        popover={<ListOptions list_id={id} options={['delete','archive']}/>}
                        subtitle={<Labels labels={list_labels}/>}
                        />
                )
            case type === 'placeholder':
                return 
            case type === 'load_more':
                if(end_reached) {
                    return <div/>
                } else {
                    return (
                        <Button
                            label='Load more'
                            loading={loading}
                            onClick={onLoad}
                            labelStyles={{color: 'var(--on-surface-variant)'}}
                            style={{backgroundColor: 'var(--surface-container)', width: 'auto', border: '1px solid var(--outline-variant)', margin: 'var(--spacing-small) 0'}}
                            />
                    )
                }
            default:
                return <div>{id}</div>
        }
       
    }

    const getHeader = () => {
        let content;

        content = 
        <Header>
            <FocusContainer>
                {['completed','divider','archived'].map(option => {
                    if(option === 'divider') {
                        return <Text>/</Text>
                    } else {
                        return (
                            <Pressable onClick={() => onFocus(option)}>
                                <Text type='title' style={{color: focus !== option ? 'var(--on-surface-variant)' : 'var(--on-surface)'}}>{option === 'completed' ? 'Completed' : 'Archived'}</Text>
                            </Pressable>
                        )
                    }
                    
                })}
                {focus === 'archived' && 
                <Button 
                    key={sub_focus}
                    label={sub_focus.charAt(0).toUpperCase() + sub_focus.slice(1)}
                    icon='ChevronDown'
                    style={{backgroundColor: 'var(--surface-container)', padding: 'var(--spacing-smallest)', border: '1px solid var(--outline-variant)', paddingRight: 'var(--spacing-small)'}}
                    context_menu={
                        <ContextMenu
                            options={['items','sections','lists']}
                            data={{
                                items: {
                                    label: 'Items',
                                    icon: 'FileDocumentOutline',
                                    trailing: sub_focus === 'items' && 'Check',
                                    onClick: () => onView({target: 'items'})
                                },
                                sections: {
                                    label: 'Sections',
                                    icon: 'FormatListGroup',
                                    trailing: sub_focus === 'sections' && 'Check',
                                    onClick: () => onView({target: 'sections'})
                                },
                                lists: {
                                    label: 'Lists',
                                    icon: 'ViewListOutline',
                                    trailing: sub_focus === 'lists' && 'Check',
                                    onClick: () => onView({target: 'lists'})
                                }
                            }}
                            />
                    }
                    anchorReference='anchorEl'
                    anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'right',
                    }}
                    transformOrigin={{
                        vertical: 'top',
                        horizontal: 'right',
                    }}
                    />}
            </FocusContainer>
            <Fade collapsed={!(focus === 'completed' || (focus === 'archived' && sub_focus === 'items'))}>
                <Button
                    key={`${focus}_options`}
                    icon='DotsHorizontal'
                    icon_color='var(--on-surface-variant)'
                    anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
                    transformOrigin={{ vertical: 'top', horizontal: 'right' }}
                    context_menu={
                        <ViewOptions view={view} 
                            setView={onView} 
                            options={['sort','display','filter']} 
                            sort_options={focus === 'completed' ? ['complete_time','priority','start','create_time','update_time'] : ['archive_time','priority','start','create_time','update_time']}
                            filter_options={['priorities','tags','lists']}
                            />
                        }
                    />
            </Fade>
        </Header>

        return renderHeader?.(content)
    }

    const getContent = () => {
        let output = []

        // 1. Search
        output.push(
            <TextInput 
                placeholder='Search' submit_icon='Magnify'
                auto_focus={true}
                border={false} clearOnSubmit={false} closeOnSubmit={false} disableSpinner={true}
                style={{marginBottom: 'var(--spacing-small)'}}
                // onChange={search => {
                //     setView({...view, search})
                // }}
                onSubmit={search => onView({search})}
                />
        )

        // 2. Content
        const blocks = buildView(view, data, user, view['type'] === 'list', view['type'] === 'kanban')
        const virtuoso_data = !end_reached ? [...blocks[0], {type: 'load_more'}] :blocks[0]
        if(virtuoso_data.length === 0) {
            output.push(
                <Text type='placeholder' style={{margin: 'var(--spacing) var(--spacing-small)'}}>
                    {`No ${focus} ${focus === 'completed' ? 'items' : sub_focus}`}
                </Text>
            )
        } else {
            output.push(
                <Virtuoso
                    data={virtuoso_data}
                    itemContent={(i) => renderItem(virtuoso_data[i])}
                    computeItemKey={(i) => `${virtuoso_data[i]['id']}_${virtuoso_data[i]['type']}`}
                    totalCount={virtuoso_data.length}
                    style={{
                        width: view['type'] === 'list' ? 'auto' : COLUMN_WIDTH, 
                        height: 'calc(100vh - 10rem)', 
                        overflowX: 'hidden'
                    }} // - 5rem for the header -1rem for scrollbar
                    />
            )
        }

        return output
    }

    const header = getHeader()
    const content = getContent()
    return (
        <Container>
            {header}
            <Body>{content}</Body>
        </Container>
    )
}

const Container = styled.div`

`

const Body = styled.div`
    padding: 0 var(--spacing) var(--spacing) var(--spacing);
    /* display: grid; */
    /* grid-template-columns: auto 1fr; */
`

const Header = styled.div`
    display: grid;
    grid-template-columns: 1fr auto;
    gap: var(--spacing);
    align-items: center;
`

const FocusContainer = styled.div`
    display: flex;
    gap: var(--spacing-small);
    align-items: center;
`

const Row = styled.div`
    display: grid;
    grid-template-columns: auto 1fr;
`