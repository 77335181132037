import { setNoti } from './notifications'
import { getPagination } from './load'
import socketIOClient from "socket.io-client";
import moment from 'moment-timezone'
import { store } from 'store'
import * as actionTypes from 'store/actions/actionTypes';
import _ from 'lodash'

const debug = false;
export const setupSocket = user => {
    const socket = socketIOClient.connect(process.env.REACT_APP_SOCKET_ENDPOINT, {
        query: { user_id: user['user_id'], start: moment().unix() },
        // transports: ['websocket'] // Need to update BE app.yaml (session_affinity: true and env: flex) and change firewall rules also https://stackoverflow.com/questions/37401250/how-do-i-deploy-socket-io-to-google-app-engine
    })

    socket.on('connected', (connection_id) => {
        debug && console.log('socket connected', connection_id)
        const transport = socket.io.engine.transport.name; // in most cases, "polling"
        process.env.REACT_APP_ENV === 'test' && console.log({transport})
        socket.io.engine.on("upgrade", () => {
            const upgradedTransport = socket.io.engine.transport.name; // in most cases, "websocket"
            process.env.REACT_APP_ENV === 'test' && console.log({upgradedTransport})
        });
        store.dispatch({ type: actionTypes.SET_SOCKET, socket })
    })

    socket.on('disconnect', (reason, details) => {
        console.log('socket disconnected', reason, details)
    })

    process.env.REACT_APP_ENV === 'test' && socket.onAny((eventName, ...args) => {
        console.log({eventName, args})
    });

    socket.on('pn', onPN)
    socket.on('integration', onIntegration)
    socket.on('sync', onSync)
    socket.on('items', onItems)

    window.addEventListener('beforeunload', () => {
        debug && console.log('[Disconnect socket]')
        socket.disconnect()
    });
}

const onPN = (values) => {
    process.env.REACT_APP_ENV === 'test' && console.log('[onPN]', values)
    const { title='This is a notification', body } = values
    setNoti({title, subtitle: body, data: _.pick(values, ['item_id','user_id','pn_tokens'])})
} 

const onIntegration = values => {
    const { integrations, integration } = values
    const data = store.getState().db.data
    const user = store.getState().db.user
    if(integrations) {
        const updated_integrations = {...data['integrations'], [integration['integration_id']]: integration}
        store.dispatch({type: actionTypes.UPDATE_DATA, data: { integrations: updated_integrations }})
        store.dispatch({type: actionTypes.UPDATE_USER, user: { ...user, integrations }})
        store.dispatch({type: actionTypes.NEW_INTEGRATION, new_integration_id: integration['integration_id']})
    } else {
        store.dispatch({type: actionTypes.NEW_INTEGRATION, new_integration_id: integration['integration_id']})
    }
}

const onSync = values => {
    const data = store.getState().db.data
    let updated_data = {...data}
    for (const [type, object] of Object.entries(values)) {
        updated_data[`${type}s`][object[`${type}_id`]] = object
        process.env.REACT_APP_ENV === 'test' && console.log('[onSync]',{type, object})
    }
    store.dispatch({type: actionTypes.UPDATE_DATA, data: updated_data})
}

const _onItems = async(body=[]) => {
    console.log('[onItems]', body)
    body.forEach(values => {
        const { action, filter, event_ids=[] } = values
        const user = store.getState().db.user
        const data = store.getState().db.data
        switch(action) {
            case 'create':
                if(!filter) { return }
                return getPagination({target: 'items', query: filter, token: user['token']})
                .then(res => {
                    const new_items = _.keyBy(res, '_id')
                    store.dispatch({type: actionTypes.UPDATE_DATA, data: { items: {...data.items, ...new_items} }})
                })
            case 'update':
                break
            case 'delete':
                if(event_ids.length === 0) { return }
                let updated_items = _.pickBy(data.items, item => !event_ids.includes(item['event_id']))
                store.dispatch({type: actionTypes.UPDATE_DATA, data: { items: updated_items }})
                break
        }
    })
}

const onItems = async(body={}) => {
    process.env.REACT_APP_ENV === 'test' && console.log('[onItems]', body)
    const user = store.getState().db.user
    const data = store.getState().db.data
    let updated_items = {...data.items}
    if(body['create']) {
        const { filter } = body['create']
        if(filter) {
            await getPagination({target: 'items', query: filter, token: user['token']})
            .then(res => {
                const new_items = _.keyBy(res, '_id')
                updated_items = {...updated_items, ...new_items}
            })
        }
    }

    if(body['delete']) {
        const { event_ids } = body['delete']
        updated_items = _.pickBy(updated_items, item => !event_ids.includes(item['event_id']))
    }

    store.dispatch({ type: actionTypes.UPDATE_DATA, data: { items: updated_items } })
}