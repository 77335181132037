import React from 'react';
import styled from 'styled-components';

import { Calendar, CalendarHeader, EditRepeatModal, Modal } from 'components'
import { AppContext } from 'screens';

import moment from 'moment-timezone'
import _ from 'lodash'
import * as actions from 'store/actions'
import { useDispatch, useSelector } from 'react-redux';
import { useToast } from 'hooks';
import { useHotkeys } from 'react-hotkeys-hook';
import { SHORTCUTS } from 'constants'
import { getEvents } from 'utils'
import { eachDayOfInterval } from 'date-fns';

const { shortcuts, actions: shortcut_actions } = SHORTCUTS['CalendarModule']
const Container = styled.div`
    height: 100%;
    display: grid;
    grid-template-rows: auto 1fr;
`

export const FullCalendarModule = props => {
    const {
        views=['day','week','month']
    } = props;
    const dispatch = useDispatch()
    const { showToast } = useToast()
    const data = useSelector(state => state.db.data)
    const user = useSelector(state => state.db.user)
    const loaded_dates = useSelector(state => state.db.loaded_dates)
    const [date, setDate] = React.useState(moment())
    const [view, setView] = React.useState(user.preferences['default_full_calendar_view'] || 'week')
    const [loading, setLoading] = React.useState(false)
    const [modal, setModal] = React.useState(null)
    const { renderHeader, setModule } = React.useContext(AppContext)
    useHotkeys(shortcuts, (event, handler) => {
        const key = [...Object.keys(_.pickBy(handler, x => x === true)), ...handler.keys].join('+')
        const action = shortcut_actions[key]
        switch(action){
            case 'Day view':
                views.includes('day') && setView('day')
                break
            case 'Week view':
                views.includes('week') && setView('week')
                break
            case 'Month view':
                views.includes('month') && setView('month')
                break
            case 'Move view left':
                setDate(moment(date).subtract(1,view))
                break
            case 'Move view right':
                setDate(moment(date).add(1,view))
                break
        }
    });

    React.useEffect(() => {
        let need_download = false;
        const start = moment(date).startOf('month').utc().format()
        const end = moment(date).endOf('month').utc().format()
        switch(view) {
            case 'day':
                need_download = !loaded_dates.includes(moment(date).format('YYYY-MM-DD'))
                break
            case 'week':
                const week_dates = eachDayOfInterval({
                    start: moment(date).startOf('week'),
                    end: moment(date).endOf('week')
                }).map(x => moment(x).format('YYYY-MM-DD'))
                need_download = _.difference(week_dates, loaded_dates).length > 0
                break
            case 'month':
                const month_dates = eachDayOfInterval({
                    start: moment(date).startOf('month'),
                    end: moment(date).endOf('month')
                }).map(x => moment(x).format('YYYY-MM-DD'))
                need_download = _.difference(month_dates, loaded_dates).length > 0
                break
        }
        if(need_download) {
            !loading && setLoading(true)
            dispatch(actions.loadData(user, 'by_dates', { start, end }, success => {
                setLoading(false)
            }))
        }
    }, [date]) 

    const onSelectSlot = (values) => {
        if(!values) {
            // Opens ItemModule on load
            setModule({target: 'ItemModule', extinfo: { defaults: { start: null, end: null, is_all_day: false }, type: 'module_create' }})
        } else {    
            const {start, end, allDay, top, left} = values
            setModule({target: 'ItemModule', extinfo: { defaults: { start, end, is_all_day: allDay, list_id: user['inbox_id'] }, type: 'module_create' }})
        }
    }

    const onSelectEvent = event => {
        setModule({target: 'ItemModule', extinfo: { item_id: event.resource.item_id, type: 'module', defaults:_.pick(event,['start','end']) }})
    }

    const onReschedule = ({ event, start, end, allDay }) => {
        const { item_id } = event.resource
        const new_schedule = {
            start,
            end,
            is_all_day: allDay
        }
        const item = data.items[item_id]
        const trigger_modal = item['repeat']
        if(trigger_modal) {
            setModal(
                <EditRepeatModal
                    onConfirm={(selection, modal_callback) => {
                        let updated_edits = {...new_schedule}
                        const difference = moment(start).set({ 
                            date: moment(item['start']).date(), 
                            month: moment(item['start']).month(), 
                            year: moment(item['start']).year() 
                        }).diff(item['start'], 'millisecond')

                        if(selection === 'current_only') {
                            const current_date = moment(start).set({
                                hour: moment(item['start']).hour(), 
                                minute: moment(item['start']).minute(), 
                            }).utc().format()
                            updated_edits['repeat'] = {
                                ...item['repeat'],
                                exdate: item['repeat']['exdate'] ? [...item['repeat']['exdate'], current_date]: [current_date]
                            }
                        } else if (selection === 'current_and_future') {
                            const duration = moment(end).diff(moment(start))
                            const new_start = moment(item['start']).add(difference, 'millisecond').utc().format()
                            const new_end = moment(new_start).add(duration,'millisecond').utc().format()
                            updated_edits['start'] = new_start // Need this to ensure the right schedule is duplicated for the branch item
                            updated_edits['end'] = new_end
                            let new_exdate = item.repeat['exdate'].map(date => moment(date).add(difference,'millisecond').utc().format())
                            updated_edits['repeat'] = {...item.repeat, exdate: new_exdate}
                        }
                        dispatch(actions.updateItem(item_id, updated_edits, (success, err_msg) => {
                            modal_callback?.(success, err_msg)
                            setModal(null)
                            !success && showToast({ title: 'Item not updated', subtitle: 'Please try again', type: 'error' })
                        }))
                    }}
                    onCancel={() => {
                        setModal(null)
                    }}
                    />
            )
        } else {
            dispatch(actions.updateItem(item_id, new_schedule, (success,err_msg) => {
                !success && showToast({ title: 'Schedule not updated', subtitle: 'Please try again', type: 'error' })
            }))
        }
    }

    const onDrillDown = (new_date) => {
        setDate(new_date)
        setView('day')
    }

    const events = getEvents()
    const header = renderHeader(
        <CalendarHeader 
            date={date}
            view={view}
            onSelectView={(new_view) => setView(new_view)}
            onSelectDate={setDate}
            views={['month','week','day']}
            />
    )
    return (
        <Container>
            <div style={{borderBottom: view === 'day' && '1px solid var(--outline-variant)'}}>
                {header}
            </div>
            <Calendar
                date={date}
                view={view}
                events={events}
                onSelectSlot={onSelectSlot}
                onSelectEvent={onSelectEvent} // Redirect to item module
                onReschedule={onReschedule} // Update item
                onDrillDown={onDrillDown} // Update view and date
                />
            <Modal open={!!modal} onClose={() => setModal(null)}>{modal || <div/>}</Modal>
        </Container>
    )
}