import { Modal as CustomModal } from '@mui/material';
import styled from 'styled-components'

const Container = styled.div`
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    outline: 0; // Need this to prevent the blue outline when autoFocus on the modal
`

export const Modal = props => {
    const {
        zIndex=1500
    } = props
    return (
        <CustomModal {...props} sx={{ zIndex }} disableAutoFocus={true}>
            <Container>{props.children}</Container>
        </CustomModal>
    )
}