import React from 'react';

import { Icon } from '../base'
import { DropdownSelector } from './DropdownSelector'

import { PRIORITY_OPTIONS } from 'constants'
import { useHotkeys } from 'react-hotkeys-hook';
import { SHORTCUTS } from 'constants'
import _ from 'lodash'

const { shortcuts, actions: shortcut_actions } = SHORTCUTS['PrioritySelector']
export const PrioritySelector = props => {
    const {
        initial,
        onSelect
    } = props;
    useHotkeys(shortcuts, (event,handler) => {
        const key = [...Object.keys(_.pickBy(handler, x => x === true)), ...handler.keys].join('+')
        const action = shortcut_actions[key]
        switch(action){
            case 'Normal':
                onSelectHandler(4)
                break
            case 'Low':
                onSelectHandler(3)
                break
            case 'Medium':
                onSelectHandler(2)
                break
            case 'High':
                onSelectHandler(1)
                break
        }
    });

    const options = PRIORITY_OPTIONS.map(option => {
        const { label, color_key, value } = option
        return {
            label,
            value,
            leading: <Icon icon='FlagOutline' size={0.8} color={`var(${color_key})`}/>,
        }
    })

    const onSelectHandler = (values,callback) => {
        onSelect?.(values,callback)
        props.close?.()
    }

    return (
        <DropdownSelector
            options={options}
            onSelect={onSelectHandler}
            initial={initial}
            />
    )
}