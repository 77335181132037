import React from 'react';
import styled from 'styled-components';
import { useSelector } from 'react-redux';

import { ComboboxSelector } from './ComboboxSelector';
import { ListTile, Icon } from '../base'

import _ from 'lodash'

const Container = styled.div`

`

const SectionOption = styled.div`
    display: grid;
    grid-template-columns: auto 1fr;
`

export const SectionSelector = props => {
    const {
        initial,
        onSelect
    } = props;
    const data = useSelector(state => state.db.data)
    const user = useSelector(state => state.db.user)
    const sections = data.sections

    const getOptions = () => {
        let output = []
        const list_ids = [user['inbox_id'], ...user['lists']]
        list_ids.forEach(list_id => {
            const list = data.lists[list_id]
            if(list && !list['is_archived']) {
                output.push({ id: list_id, type: 'list', value: list_id })
                const section_ids = list['sections']
                section_ids.forEach(section_id => {
                    const section = data.sections[section_id]
                    if(section && !section['is_archived']) {
                        output.push({ id: section_id, type: 'section', value: section_id })
                    }
                })
            }
        })

        return output
    }

    const onSubmitHandler = (new_value, callback) => {
        const type = [user['inbox_id'], ...user['lists']].includes(new_value) ? 'list' : 'section'
        let output = {}
        switch(type) {
            case 'list':
                output = { list_id: new_value, section_id: null }
                break
            case 'section':
                const target_list_id = data.sections[new_value]['list_id']
                output = { list_id: target_list_id, section_id: new_value }
                break
        }
        if(!new_value) {
            callback?.(false, 'Please select a section')
        } else {
            onSelect?.(output, callback)
            props.close?.()
        }
    }

    const renderItem = (option) => {
        const { id, type, is_selected, onSelect, is_highlighted } = option
        switch(type) {
            case 'list':
                const list = data.lists[id]
                return (
                    <ListTile
                        leading='SquareRounded'
                        leading_color={list['color'] || 'var(--on-surface-variant)'}
                        leading_size={0.6}
                        label={list['title']}
                        trailing={is_selected && 'Check'}
                        onClick={onSelect}
                        style={{backgroundColor: is_highlighted ? 'var(--surface)' : 'var(--surface-container)'}}
                        />
                )
            case 'section':
                const section = data.sections[id]
                return (
                    <SectionOption>
                        <Icon icon='SquareRounded' color='transparent' size={0.6}/>
                        <ListTile
                            label={section['title']}
                            trailing={is_selected && 'Check'}
                            onClick={onSelect}
                            style={{backgroundColor: is_highlighted ? 'var(--surface)' : 'var(--surface-container)', marginLeft: 'var(--spacing-small)'}}
                            />
                    </SectionOption>
                )
                break
        }
    }

    const onSearch = (options, value) => {
        if(value && value !== '') {
            let filtered = []
            options.forEach(option => {
                if(option) {
                    const { id, type } = option
                    switch(type){
                        case 'list':
                            const list = data.lists[id]
                            list && list['title'].toLowerCase().includes(value.toLowerCase()) && filtered.push(option)
                            break
                        case 'section':
                            const section = data.sections[id]
                            if(section['title'].toLowerCase().includes(value.toLowerCase())) {
                                const section_list_id = section['list_id']
                                const filtered_ids = filtered.map(x => x && x['id'])
                                const list_option = _.find(options, x => x['id'] === section_list_id)
                                !filtered_ids.includes(section_list_id) && filtered.push(list_option)
                                filtered.push(option)
                            }
                            break
                    }
                }
            })
            return filtered
        } else {
            return options
        }
    }

    const options = getOptions()
    return (
        <ComboboxSelector
            options={options}
            initial={initial}
            onSubmit={onSubmitHandler}
            renderItem={renderItem}
            disableSort={true}
            searchStrategy={onSearch}
            multi={false}
            searchPlaceholder='Search...'
            />
    )
}