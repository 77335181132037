import React from 'react';
import styled from 'styled-components';

import { Text } from 'components'

import { useDroppable } from '@dnd-kit/core';
import { CalendarContext } from './Calendar'; 
import moment from 'moment-timezone';

const Container = styled.div`
    flex: 1 0;
    background-color: ${props => props.isSelected ? 'var(--calendar-selection)' : 'var(--surface)'};
    transition: background-color 0.3s ease;
    /* border-right: ${props => `${props.view !== 'day' && !props.is_saturday ? '1px' : 0} solid var(--outline-variant)`}; */
    box-shadow: 0 0 0 1px var(--outline-variant); // prevents misalignment issues (https://stackoverflow.com/questions/2748637/how-to-give-border-to-any-element-using-css-without-adding-border-width-to-the-w)
`

const Border = styled.div`
    border-right: ${props => `${props.view !== 'day' && !props.is_saturday ? '1px' : 0} solid var(--outline-variant)`};
    position: absolute;
    height: 100%;
    right: 0;
`


export const CalendarDateCellWrapper = props => {
    const {
        value
    } = props;
    const id = moment(value).format('YYYY-MM-DD HH:mm')
    const is_saturday = moment(value).day() === 6
    const { view, onDragOver, selected=[], step } = React.useContext(CalendarContext)
    const { setNodeRef, isOver, rect } = useDroppable({ id, data: { id, value } })
    React.useEffect(() => {
        if(isOver) {
            onDragOver(value)
        }
    }, [isOver])
    
    return (
        <Container 
            ref={setNodeRef} 
            isSelected={isOver || selected.includes(id)} 
            view={view}
            is_saturday={is_saturday}
            >
            {props.children}
        </Container>
    )
}