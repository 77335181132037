import React from 'react';
import styled from 'styled-components';
import { Icon } from './Icon'
import { Text } from './Text'
import { Pressable } from './Pressable';
import Popover, { popoverClasses } from '@mui/material/Popover';

const Container = styled.div`
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    row-gap: var(--spacing-smallest);
    margin-top: ${props => props.n > 0 ? 'var(--spacing-smallest)' : 0};
`

const Wrapper = styled.div`
    display: flex;
    flex-direction: row;
    gap: var(--spacing-smallest);
    align-items: center;
`

export const Labels = props => {
    const { 
        // labels=[],
        renderContext,
        anchorOrigin={ vertical: 'bottom', horizontal: 'left' },
        transformOrigin={ vertical: 'top', horizontal: 'left' },
        selectable=false
    } = props;
    const labels = props.labels.filter(x => x)
    const [context_menu, setContext] = React.useState(null)
    const [anchorEl, setAnchorEl] = React.useState(null);

    const onRenderContext = (e, values) => {
        const new_context = renderContext?.(values, () => setAnchorEl(null))
        if(new_context) {
            setContext(new_context)
            setAnchorEl(e.currentTarget)
        }
    }

    const getLabels = () => {
        let output = []
        labels.forEach((value, index) => {
            const { label, icon, color='var(--on-surface-variant)', disable, fontSize='0.8rem' } = value
            const content = <Wrapper>
                                {!icon ? null 
                                : icon && typeof icon === 'string' ? <Icon icon={icon} color={color} size={0.7}/>
                                : icon}
                                <Text type='subtitle' style={{color: color || '#707070', fontSize}}>{label}</Text>
                            </Wrapper>
            if(disable || !selectable) {
                output.push(content)
            } else {
                output.push(<Pressable onClick={(e) => onRenderContext(e,value)}>{content}</Pressable>)
            }

            if(labels.length > 0 && index !== labels.length - 1) {
                output.push( <Icon icon={'CircleSmall'} color={'var(--on-surface-variant)'} size={0.7}/>)
            }
        })
        return output
    }

    return (
        <Container n={labels.length} style={props.style}>
            {getLabels()}
            <Popover
                open={anchorEl}
                anchorEl={anchorEl}
                onClose={() => setAnchorEl(null)}
                anchorOrigin={anchorOrigin}
                transformOrigin={transformOrigin}
                slotProps={{
                    paper: {
                        sx: {
                            [`&.${popoverClasses.paper}`]: { backgroundColor: 'transparent', marginBlock: 'var(--spacing-small)' }
                        }
                    }
                }}
                >
                {context_menu}
            </Popover>
        </Container>
    )
}