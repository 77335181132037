import React from 'react';
import styled from 'styled-components';
import { useNavigate } from 'react-router-dom'
import { useSelector } from 'react-redux'

import { Pressable, Text, Icon } from '../base'
import { AppContext } from 'screens';
import { motion, AnimatePresence } from "framer-motion";

import { USER_ITEM_LIMITS } from 'constants'
import { buildView } from 'utils'
import moment from 'moment-timezone'

const Container = styled.div`
    display: flex;
    gap: var(--spacing-small);
    /* border: 1px solid red; */
`

const Wrapper = styled.div`
    position: absolute;
    bottom: 0;
    /* left: 0; */
    /* transform: translateX(-3rem); // For the side module */
    margin: var(--spacing);
    display: flex;
    gap: var(--spacing-small);
`

const Row = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    gap: var(--spacing-smallest);
    background-color: var(--surface-container);
    border-radius: var(--border-radius);
    padding: var(--spacing-small);
    cursor: pointer;
    border: 1px solid transparent;
    transition: border 0.3s ease;
    &:hover {
        border: 1px solid var(--outline-variant);
    }
`

export const Footer = props => {
    const {

    } = props;
    const { module={}, setModule } = React.useContext(AppContext) 
    const navigate = useNavigate()
    const user = useSelector(state => state.db.user)
    const data = useSelector(state => state.db.data)

    const { item_count, reminder_count } = user
    const item_limit = !user['uid'] ? USER_ITEM_LIMITS.LOCAL
                    : user['is_pro'] ? '∞'
                    : (user['item_limit'] || USER_ITEM_LIMITS.FREE)
    
    const onSelectDay = () => {
        if(!module) {
            setModule({ target: 'DayModule' })
        }
        if(module && module.target !== 'DayModule') {
            setModule(null)
            setTimeout(() => {
                setModule({ target: 'DayModule' })
            }, 300)
        } else if (module && module.target === 'DayModule') {
            setModule(null)
        }
    }

    const getDayLabel = () => {
        const view = {
            schedule: 'custom',
            start: moment().startOf('day'), 
            end: moment().endOf('day'), 
            display: ['sub_items','list','schedule','complete_time','tags'],
            sort: 'start'
        }
        const blocks = buildView(view, data, user, true, false)
        const item_ids = blocks[0].map(x => x['id'])
        const incomplete_count = item_ids.filter(item_id => data.items[item_id]?.['is_completed']).length || 0
        const item_count = item_ids.length || 0
        return item_count === 0 ? 'No schedule' : `${incomplete_count}/${item_count}`
    }
    const day_label = getDayLabel()
    return (
        <Wrapper>
            <AnimatePresence>
                <motion.div
                    layout 
                    initial="collapsed" animate="open" exit="collapsed"
                    transition={{ duration: 0.6 }}
                    variants={{ open: { opacity: 1, y: 0 }, collapsed: { opacity: 0, y: 'var(--spacing-small)' } }}
                    >
                    <Container>
                        <Row onClick={onSelectDay}>
                            <Icon icon='FormatListChecks' color='var(--on-surface-variant)' size={0.8}/>
                            <Text type='subtitle'>{day_label}</Text>
                        </Row>

                        {!user['is_pro'] && 
                        <Row onClick={() => navigate('/upgrade')}>
                            <Icon icon='InformationOutline' color='var(--on-surface-variant)' size={0.8}/>
                            <Text type='subtitle'>{`Created ${item_count}/${item_limit} items`}</Text>
                        </Row>}
                    </Container>
                </motion.div>
            </AnimatePresence>
        </Wrapper>
        
    )
}