import React from 'react';
import styled from 'styled-components';
import { useSelector, useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom'
import { useParams } from 'react-router-dom';

import { 
    Text, 
    Icon, 
    Button, 
    Check, 
    Pressable,
    Labels, 
    TextField,
    ItemOptions,
    TagSelector, 
    PrioritySelector, 
    ScheduleSelector,
    ReminderSelector,
    ItemBlock,
    Collapsible,
    Chip,
    ConfirmSelector,
    SectionSelector,
    TextInput,
    SortableList,
    TextArea,
    Tooltip,
    RepeatSelector,
    EditRepeatModal,
    Modal,
    SubitemBlock
} from 'components'

import * as actions from 'store/actions'
import _ from 'lodash'
import { priorities } from 'constants'
import { formatSchedule, formatReminder, getItemLabels, getRepeatTitle, parseRepeat, setNoti } from 'utils'
import moment from 'moment-timezone'
import { Fade } from 'components';
import { useToast } from 'hooks';
import { AppContext } from 'screens';
import { useHotkeys } from 'react-hotkeys-hook';
import { SHORTCUTS } from 'constants'
import GcalIcon from 'resources/icons/google_calendar_icon.png'

const { shortcuts, actions: shortcut_actions, shortcut_keys } = SHORTCUTS['ItemModule']
export const ItemModule = props => {
    const {
        item_id,
        type='module_create', // module, modal
        defaults={},
    } = props;
    const dispatch = useDispatch()
    const { type: screen_type, id: screen_id } = useParams()
    const { module={}, setModule } = React.useContext(AppContext)
    const { showToast } = useToast()
    const navigate = useNavigate()
    const user = useSelector(state => state.db.user)
    const data = useSelector(state => state.db.data)
    const item = data.items[item_id]
    const [values, setValues] = React.useState({priority: 4, reminders: [], ...item, ...defaults})
    const [loading, setLoading] = React.useState(false)
    const [error, setError] = React.useState(null)
    const [focus, setFocus] = React.useState(null)
    const [highlight, setHighlight] = React.useState(null)
    const [modal, setModal] = React.useState(null)
    const notes_ref = React.createRef()
    const sub_ref = React.createRef()
    const properties = values['event_id'] ? ['calendar','priority','schedule','reminders','repeat','tags','notes'] 
                    : values['start'] ? ['section','priority','schedule','reminders','repeat','tags','notes'] 
                    : ['section','priority','schedule','tags','notes']
    const ref = useHotkeys(shortcuts, (event,handler) => {
        const key = [...Object.keys(_.pickBy(handler, x => x === true)), ...handler.keys].join('+')
        const action = shortcut_actions[key]
        if(focus === 'title' || focus === 'notes') { return }
        switch(action){
            case 'Create sub-item':
                setFocus(focus === 'sub' ? null : 'sub')
                break
            case 'Edit notes':
                const to_focus = focus === 'notes' ? null : 'notes'
                if(['module_create','modal_create'].includes(type)){
                    to_focus && notes_ref.current?.focus()
                } 
                setFocus(focus === 'notes' ? null : 'notes')
                break
            case 'Edit item title':
                setFocus('title')
                break
                break
            case 'Next property':
                highlight !== null && event.preventDefault()
                properties.length > 0 && focus !== 'priority' && 
                setHighlight(highlight === null ? 0 
                            : highlight === properties.length - 1 ? 0
                            : highlight + 1)
                break
            case 'Previous property':
                highlight !== null && event.preventDefault()
                properties.length > 0 && focus !== 'priority' && 
                setHighlight(highlight === null ? properties.length - 1
                            : highlight === 0 ? properties.length - 1
                            : highlight - 1)
                break
            case 'Select property':
                highlight !== null && event.preventDefault()
                setFocus(properties[highlight])
                break
            case 'Unselect property':
                setHighlight(null)
                break
        }
    }, { preventDefault: false, enableOnContentEditable: true });

    React.useEffect(() => {
        process.env.REACT_APP_ENV === 'test' && console.log(item)
    }, [])

    React.useEffect(() => {
        if(Object.keys(defaults).length > 0) {
            const current_values = _.pick(values, Object.keys(defaults))
            setValues({...values, ...defaults})
        }
        // ref.current?.focus()
    }, [defaults])

    React.useEffect(() => {
        error && setTimeout(() => {
            setError(null)
        }, 1000)
    }, [error])

    React.useEffect(() => {
        focus === 'sub' && sub_ref.current?.focus()
    }, [focus])

    React.useEffect(() => {
        if(sub_ref.current) {
            sub_ref.current.textContent = null
        }
    }, [item?.items?.length])

    const onEdit = (edits, callback) => {
        let updated_edits = {...edits}

        // 1. Update local state
        if(!item_id) {
            if(edits['start']) {
                updated_edits['reminders'] = user['is_pro'] ? [user.preferences['default_reminder_duration']] : []
            } else if (edits['start'] === null) {
                updated_edits['reminders'] = []
            }
        }
        setValues(old_values => {
            return {...old_values, ...updated_edits}
        })

        // 2. Stop spinner for create
        if(!item_id) { 
            callback?.(true)
            return 
        }

        // 3a. Trigger repeat modal
        const trigger_modal = edits['repeat'] === null ? false 
                            : edits['repeat'] ? false
                            : edits['start'] === null ? false
                            : edits['is_completed'] ? false
                            : values['repeat']

        if(trigger_modal) {
            setModal(
                <EditRepeatModal
                    onConfirm={(selection, modal_callback) => {
                        if(selection === 'current_only') {
                            const current_date = moment(values['start']).utc().format()
                            updated_edits['repeat'] = {
                                ...values['repeat'],
                                exdate: values['repeat']['exdate'] ? [...values['repeat']['exdate'], current_date]: [current_date]
                            }
                            updated_edits = {start: values['start'], end: values['end'], ...updated_edits} // To ensure start & end is reflected in the branch item
                        } else if (selection === 'current_and_future' && edits['start']) {
                            const duration = moment(edits.end).diff(moment(edits.start))
                            const difference = moment(edits['start']).diff(values['start'], 'millisecond')
                            const new_start = moment(item['start']).add(difference, 'millisecond').utc().format()
                            const new_end = moment(new_start).add(duration,'millisecond').utc().format()
                            updated_edits['start'] = new_start // Need this to ensure the right schedule is duplicated for the branch item
                            updated_edits['end'] = new_end
                            let new_exdate = item.repeat['exdate'].map(date => moment(date).add(difference,'millisecond').utc().format())
                            updated_edits['repeat'] = {...item.repeat, exdate: new_exdate}
                        }
                        // Need to use values['item_id'] instead of item_id because item_id changes if item branches out
                        dispatch(actions.updateItem(values['item_id'], updated_edits, (success, err_code, updated_item) => {
                            callback?.(success, err_code)
                            modal_callback?.(success, err_code)
                            setModal(null)
                            if(!success) {
                                let title = 'Item not updated'
                                let subtitle = 'Please try again'
                                showToast({ title, subtitle, type: 'error' })
                                setValues(item)
                            } else {
                                selection === 'current_only' && updated_item && setValues(updated_item)
                            }
                        }))
                    }}
                    onCancel={() => {
                        setValues({...item,...defaults})
                        setModal(null)
                    }}
                    />
            )
        } else {
            const has_repeat = !_.isEmpty(item?.['repeat'])
            let next_start
            if(has_repeat && edits['is_completed']) {
                const current_date = values['start']
                const { next_date } = parseRepeat({
                    ...item['repeat'], 
                    start: values['start'], 
                    current_date, 
                })

                if(next_date) {
                    next_start = next_date
                    const current_date = moment(values['start']).utc().format()
                    const exdate = item['repeat']['exdate'] ? [...item['repeat']['exdate'], current_date] : [current_date]
                    updated_edits['repeat'] = {...item['repeat'], exdate}
                    updated_edits['start'] = values['start'] // Need this to ensure the right schedule is duplicated for the branch item
                    updated_edits['end'] = values['end']
                }
            } 

            if(edits['start'] === null) {
                updated_edits['repeat'] = null
                updated_edits['reminders'] = []
            }
            
            // 3b. Commit changes
            dispatch(actions.updateItem(item_id, updated_edits, (success, err_code, updated_item) => {
                callback?.(success, err_code)
                if(!success) {
                    let title = 'Item not updated'
                    let subtitle = 'Please try again'
                    showToast({ title, subtitle, type: 'error' })
                    setValues({...values, ...defaults})
                } else {
                    if(type === 'modal') {
                        setValues({...values, ...updated_edits})
                        const should_close = edits['is_completed'] || edits['start'] === null
                        should_close && props.onClose?.()
                    } else {
                        setValues(updated_item)
                    }
                }
            }))
        }   
    }

    const onConfirm = (option, callback) => {
        switch(option){
            case 'delete':
                // dispatch(actions.deleteItem(item_id, success => {
                //     callback?.(success)
                //     success && props.onClose?.()
                //     !success && showToast({ title: 'Item not deleted', subtitle: 'Please try again', type: 'error' })
                // }))
                if(!_.isEmpty(item['repeat'])) {
                    setModal(
                        <EditRepeatModal
                            onConfirm={(selection, modal_callback) => {
                                if(selection === 'current_only') {
                                    const current_date = moment(values['start']).utc().format()
                                    const edits = {
                                        repeat: {
                                            ...values['repeat'],
                                            exdate: values['repeat']['exdate'] ? [...values['repeat']['exdate'], current_date]: [current_date]
                                        }
                                    }

                                    dispatch(actions.updateItem(values['item_id'], edits, (success, err_code, updated_item) => {
                                        callback?.(success, err_code)
                                        modal_callback?.(success, err_code)
                                        setModal(null)
                                        if(!success) {
                                            let title = 'Item not delete'
                                            let subtitle = 'Please try again'
                                            showToast({ title, subtitle, type: 'error' })
                                            setValues(item)
                                        } else {
                                            props.onClose?.()
                                        }
                                    }))
                                } else {
                                    dispatch(actions.deleteItem(item_id, success => {
                                        callback?.(success)
                                        success && props.onClose?.()
                                        !success && showToast({ title: 'Item not deleted', subtitle: 'Please try again', type: 'error' })
                                    }))
                                }
                            }}
                            onCancel={callback}
                            />
                    )
                } else {
                    dispatch(actions.deleteItem(item_id, success => {
                        callback?.(success)
                        success && props.onClose?.()
                        !success && showToast({ title: 'Item not deleted', subtitle: 'Please try again', type: 'error' })
                    }))
                }
                break
            case 'archive':
                onEdit({ is_archived: !item['is_archived'] })
                break
        }
    }

    const reset = () => {
        if(ref.current) { ref.current.textContent = null }
        if(notes_ref.current) { notes_ref.current.textContent = null }
        if(sub_ref.current) { sub_ref.current.textContent = null }
        setValues({priority: 4, reminders: [], ...defaults, start: null, end: null})
    }

    const onCreate = (callback) => {
        const is_valid = ref.current.textContent && ref.current.textContent !== ''
        if(!is_valid) {
            setError('Required')
        } else {
            setLoading(true)
            dispatch(actions.createItem({...values, title: ref.current?.textContent, notes: notes_ref.current?.textContent}, (success,err_msg) => {
                success && props.onClose?.()
                if(err_msg === 'HIT_LIMIT') {
                    showToast({title: 'Item limit hit', subtitle: 'Upgrade to remove limits', onClick: () => navigate('/upgrade'), type: 'error'})
                } else if (!success) {
                    showToast({title: 'Item not created', subtitle: 'Please try again or refresh the screen', onClick: () => navigate('/loading'), type: 'error'})
                }
                setLoading(false)
                reset()
            }))
        }
    }

    const onCreateSub = ({callback}) => {
        const title = sub_ref.current?.textContent
        if(!title) {
            callback && callback(false,'Required')
            return
        } else {
            if(!_.isEmpty(values['repeat'])) {
                setModal(
                    <EditRepeatModal
                        onCancel={() => setModal(null)}
                        onConfirm={(selection, modal_callback) => {
                            setModal(null)
                            switch(selection) {
                                case 'current_only':
                                    const current_date = moment(values['start']).utc().format()
                                    let parent_edits = {
                                        ..._.pick(values, ['start','end','is_all_day']),
                                        repeat: {
                                            ...values['repeat'],
                                            exdate: values['repeat']['exdate'] ? [...values['repeat']['exdate'], current_date]: [current_date]
                                        }
                                    }
                                    const new_child = {
                                        title,
                                        parents: [...item['parents'], item_id],
                                        section_id: item['section_id'],
                                        list_id: item['list_id'],
                                        priority: 4
                                    }
                                    const children = [new_child, ...item.items.map(child_id => data.items[child_id])]
                                    parent_edits['children'] = children
                                    dispatch(actions.updateItem(item_id, parent_edits, (success, err_code, updated_item) => {
                                        modal_callback(success)
                                        success && setModal(null)
                                        !success && setError('Something went wrong, please try again')
                                        setModule({target: 'ItemModule', extinfo: { item_id: updated_item['item_id'], type: 'module' }})
                                    }))
                                    break
                                case 'current_and_future':
                                    createSub(title, callback)
                                    break
                            }
                        }}
                        />
                )
            } else {
                createSub(title, callback)
            }
        }
    }

    const createSub = (title, callback) => {
        const new_item = {
            title,
            parents: [...item['parents'], item_id],
            priority: 4,
            ..._.pick(item, ['section_id','list_id','calendar_id','repeat_id']),
            repeat_id: item['repeat_id'] || item['event_id']
        }

        dispatch(actions.createItem(new_item, ((success, error) => {
            callback?.(success)
            if(!success) {
                if(error === 'HIT_LIMIT') {
                    showToast({title: 'Item limit hit', subtitle: 'Upgrade to remove limits', onClick: () => navigate('/upgrade'), type: 'error'})
                } else {
                    showToast({title: 'Something went wrong', subtitle: 'Please try again or refresh the screen', onClick: () => navigate('/loading'), type: 'error'})
                }
            } 
        })))
    }

    const onDragEnd = ({target, over, items}, callback) => {
        const sub_items = items[0].map(x => x['id'])
        onEdit({ items: sub_items }, callback)
    }

    const onExpand = () => {
        if(module && Object.keys(module).length > 0) {
            setModule(null)
            setTimeout(() => {
                setModule({target: 'ItemModule', extinfo: { item_id, type: 'module' }})
            }, 300)
        } else {
            setModule({target: 'ItemModule', extinfo: { item_id, type: 'module' }})
        }   
    }

    const getProperty = (key,index) => {
        let icon;
        let icon_color;
        let content;
        let subtitle;
        let context;
        let tooltip
        let disabled = focus ? focus !== key : false
        let disableHover = false
        let shortcuts
        let onClick
        // const target = item || values
        const target = values || item
        switch(key) {
            case 'section':
                icon = 'SquareRounded'
                shortcuts=[shortcut_keys['Edit list']]
                tooltip = { title: 'List/section', shortcut: shortcut_keys['Edit list/section'], placement: 'left-end', arrow: false }
                const list_id = target['list_id'] || data.sections[target['section_id']]?.['list_id'] || user['inbox_id']
                icon_color = data.lists[list_id]['color']
                const item_section = target['section_id'] && data.sections[target['section_id']]
                const section_title = item_section && item_section['title']
                const list_title = target['list_id'] ? data.lists[target['list_id']]['title']
                                    : item_section ? data.lists[item_section['list_id']]['title']
                                    : null
                content = list_title && section_title ? `${list_title} > ${section_title}`
                            : list_title && !section_title ? `${list_title}`
                            : 'None selected'
                context = <SectionSelector
                            initial={target['section_id'] || target['list_id']}
                            onSelect={(new_target, callback) => {
                                onEdit?.(new_target, callback)
                            }}
                            />
                break
            case 'priority':
                icon = 'FlagOutline'
                icon_color = `var(${priorities[target['priority'] || 4]['color_key']})`
                content = <Text style={{color: icon_color}}>{priorities[target['priority'] || 4]['label']}</Text>
                shortcuts=[shortcut_keys['Edit priority']]
                tooltip = { title: 'Priority', shortcut: shortcut_keys['Edit priority'], placement: 'left-end', arrow: false }
                context = <PrioritySelector
                            initial={target['priority']}
                            onSelect={(new_priority, callback) => {
                                onEdit?.({priority: new_priority}, callback)
                            }}
                            />
                break
            case 'tags':
                shortcuts = [shortcut_keys['Edit tags']]
                tooltip = { title: 'Tags', shortcut: shortcut_keys['Edit tags'], placement: 'left-end', arrow: false }
                icon = 'TagOutline'
                const has_tags = target['tags'] && target['tags'].length > 0
                const tag_blocks = has_tags && target['tags'].map(tag_id => {
                    const tag = data.tags[tag_id]
                    if(tag) {
                        const { title, color } = tag
                        return <Chip label={title} color={color}/>
                    }
                })
                content = !has_tags ? <Text type='placeholder'>No tags</Text> : <TagContainer>{tag_blocks}</TagContainer>
                context = <TagSelector
                            initial={target['tags']} 
                            onSelect={(new_tags, callback) => {
                                onEdit({tags: new_tags}, callback)
                            }}
                            />
                break
            case 'schedule':
                icon = 'CalendarOutline'
                shortcuts=[shortcut_keys['Edit schedule']]
                tooltip = screen_type === 'calendar' ? 'Please select on the calendar' : { title: 'Schedule', shortcut: shortcut_keys['Edit schedule'], placement: 'left-end', arrow: false }
                content = !target['start'] ? <Text type='placeholder'>No schedule</Text> : <Text>{formatSchedule(_.pick(target, ['start','end','is_all_day']), user)}</Text>
                disabled = screen_type === 'calendar' && false
                context = screen_type !== 'calendar' && <ScheduleSelector 
                            initial={_.pick(target, ['start','end','is_all_day'])}
                            onSelect={onEdit}
                            />
                break
            case 'reminders':
                icon = 'BellOutline'
                shortcuts=[shortcut_keys['Edit reminders']]
                tooltip = { title: 'Reminders', shortcut: shortcut_keys['Edit reminders'], placement: 'left-end', arrow: false }
                const has_reminders = target['reminders'] && target['reminders'].length > 0
                content = !has_reminders ? <Text type='placeholder'>No reminders</Text> 
                            : <div>
                                {target['reminders'].map((x, index) => <Text>{`${formatReminder(x)}${index !== target['reminders'].length - 1 ? ',' : ''}`}</Text>)}
                            </div>
                if(user['is_pro']) {
                    context = <ReminderSelector
                            initial={target['reminders']}
                            onSelect={(new_reminders, callback) => {
                                onEdit({reminders: new_reminders}, callback)
                            }}
                            />
                } else {
                    onClick = () => showToast({title: "You've discovered a pro feature!", subtitle: 'Click here to find out more', onClick: () => navigate('/upgrade')})
                }
                break
            case 'repeat':
                icon = 'RepeatVariant'
                shortcuts=[shortcut_keys['Edit repeat']]
                tooltip = { title: 'Repeat', shortcut: shortcut_keys['Edit repeat'], placement: 'left-end', arrow: false }
                content = !target['repeat'] ? <Text type='placeholder'>Does not repeat</Text> : <Text>{getRepeatTitle(target['repeat'])}</Text>
                if(user['is_pro']) {
                    context = <RepeatSelector 
                            initial={target['repeat']} 
                            reference_date={moment(target['start'])}
                            onSelect={(new_repeat, callback) => onEdit({repeat: new_repeat}, callback)}
                            />
                } else {
                    onClick = () => showToast({title: "You've discovered a pro feature!", subtitle: 'Click here to find out more', onClick: () => navigate('/upgrade')})
                }
                
                break
            case 'more':
                icon = 'DotsHorizontal'
                shortcuts=['o']
                context = <ItemOptions item_id={item_id} options={['delete','archive']} defaults={defaults}/>
                break
            case 'notes':
                icon = 'Text'
                shortcuts=[shortcut_keys['Edit notes']]
                tooltip = { title: 'Notes', shortcut: shortcut_keys['Edit notes'], placement: 'left-end', arrow: false }
                // disableHover = true
                switch(true){
                    case ['module','modal'].includes(type):
                        content = <NotesInput key={`${item_id}_notes`}
                                    initial={item['notes']} 
                                    onSubmit={(new_notes, callback) => onEdit({ notes: new_notes }, callback)}
                                    is_focused={focus === 'notes'}
                                    setFocus={setFocus}
                                    />
                        break
                    case ['module_create','modal_create'].includes(type):
                        content = <TextArea
                                    ref={notes_ref}
                                    placeholder='Notes'
                                    auto_focus={false}
                                    />
                        break
                }
                break
            case 'calendar':
                const calendar = data.calendars[item['calendar_id']]
                const integration = data.integrations[calendar['integration_id']]
                icon = <div style={{width: '1.5rem', display: 'flex', alignItems: 'center', justifyContent: 'center'}}><img src={GcalIcon} style={{width: '1.1rem'}}/></div>
                const calendar_title = calendar['title'] !== integration['title'] && `${calendar['title']}`
                content = calendar_title ? `${integration['title']} (${calendar_title})` : integration['title']
                onClick = () => window.open(item['link'], '_blank')
                break
        }
        const is_highlighted = highlight === index
        const is_modal = ['modal','modal_create'].includes(type)
        const focusBackgroundColor = is_modal ? 'var(--surface)' : 'var(--surface-container)'
        const backgroundColor = is_highlighted && is_modal ? 'var(--surface)' 
                                : is_highlighted && !is_modal ? 'var(--surface-container)'
                                : 'transparent'

        return (
            <PropertyBlock 
                open={key === focus}
                icon={icon} 
                icon_color={icon_color} 
                content={content} 
                subtitle={subtitle}
                context={context} 
                tooltip={tooltip}
                disabled={disabled}
                disableHover={disableHover}
                onClickShortcuts={shortcuts}
                onContextMenuShortcuts={shortcuts}
                is_focused={key === focus}
                focusBackgroundColor={focusBackgroundColor}
                backgroundColor={backgroundColor}
                key={`${type}_${item_id}_${target['update_time'] && moment(target['update_time']).format('YYYY-MM-DD HH:mm:ss')}_${key}`} // need the key to ensure context_menu gets updated to re-hydrate callbacks
                setFocus={setFocus}
                property={key}
                onClick={onClick}
                onOpen={() => {
                    setFocus(key)
                    setHighlight(properties.indexOf(key))
                }}
                onClose={() => {
                    setFocus(null)
                    highlight && setHighlight(null)
                }}
                />
        )
            
    }

    const renderItem = (x) => {
        const { id:sub_item_id, type } = x
        return <SubitemBlock 
                key={sub_item_id}
                item_id={sub_item_id} 
                onFocus={(new_focus) => new_focus ? setFocus(sub_item_id) : setFocus(null)} 
                is_focused={focus === sub_item_id} 
                disableDND={true}
                parent={values}
                />
    }

    const getSubItems = () => {
        let sub_items = []
        if(!item_id || !item) { return sub_items }

        // 1. Active items
        const active_item_ids = item['items'].filter(item_id => !data.items[item_id]?.['is_completed']) || []
        sub_items.push(...active_item_ids)

        // 2. Inactive items
        let inactive_items = _.pickBy(data.items, (i, index) => {
            const isChild = i['parents'] && i['parents'].slice(-1)[0] === item_id
            return i['is_completed'] && isChild && !active_item_ids.includes(i['item_id'])
        })
        inactive_items = _.sortBy(inactive_items,['complete_time'])
        sub_items.push(...inactive_items.map(child => child['item_id']))

        // 3. Render
        if(sub_items.length > 0) {
            return (
                <SortableList
                    data={[sub_items.map(item_id => ({ id: item_id, type: 'item' }))]}
                    renderItem={renderItem}
                    renderDragOverlay={(values) => {
                        if(!values) { return }
                        return <DndOverlay>{renderItem(values)}</DndOverlay>
                    }}
                    onDragEnd={onDragEnd}
                    />
            )
        } else {
            return <Text type='placeholder' style={{marginLeft: 'var(--spacing-small)'}}>None created</Text>
        }
    }

    if(!item && !['modal_create','module_create'].includes(type)) {
        return (
            <Container>
                <Text type='placeholder'>Something went wrong</Text>
            </Container>
        )
    } else {
        const labels = item_id && getItemLabels(item_id, user, data, item['event_id'] ? ['create_time'] :  ['create_time','list_section'])
        switch(type) {
            case 'modal':
                return (
                    <ModalContainer>
                        <ModalContent>
                            {values['is_archived'] && <ArchiveHeader item_id={item_id}/>}
                            <Labels labels={labels} selectable={false} style={{marginLeft: 'var(--spacing-small)'}}/>
                            <Header>
                                <Check 
                                    is_selected={values['is_completed']}
                                    color={`var(${priorities[values['priority']]['color_key']})`}
                                    selectedColor={`var(${priorities[values['priority']]['color_key']})`}
                                    onClick={() => onEdit({ is_completed: !values['is_completed'] })}
                                    style={{marginTop: '0.65rem'}} // title is 2.5rem, but check is only 1.2rem
                                    />
                                <TitleInput
                                    initial={values['title']}
                                    onSubmit={(new_title, callback) => onEdit({ title: new_title }, callback)}
                                    is_focused={focus === 'title'}
                                    setFocus={setFocus}
                                    strikethrough={values['is_completed']}
                                    // onClose={reset}
                                    />
                            </Header>
                            <Column>{properties.map((x,index) => getProperty(x,index))}</Column>
                        </ModalContent>
                        
                        <OptionsContainer>
                            <Button 
                                label='Close' labelStyles={{color: 'var(--on-surface-variant)'}}
                                onClick={() => props.onClose?.()} style={{backgroundColor: 'var(--surface-container)'}}
                                />

                            <div style={{display: 'flex', }}>
                                <Button 
                                    tooltip={{title: 'Open'}}
                                    icon='ArrowExpand' icon_color='var(--on-surface-variant)'
                                    style={{backgroundColor: 'var(--surface-container)'}}
                                    onClick={onExpand}
                                    />
                                <Button 
                                    tooltip={{title: 'Archive'}}
                                    icon='ArchiveOutline' icon_color='var(--on-surface-variant)'
                                    style={{backgroundColor: 'var(--surface-container)'}}
                                    context_menu={ <ConfirmSelector onConfirm={(callback) => onConfirm('archive',callback)}/>}
                                    />
                                <Button 
                                    tooltip={{title: 'Delete'}}
                                    icon='TrashCanOutline' icon_color='var(--on-surface-variant)'
                                    style={{backgroundColor: 'var(--surface-container)'}}
                                    context_menu={ <ConfirmSelector onConfirm={(callback) => onConfirm('delete',callback)}/>}
                                    />
                            </div>
                        </OptionsContainer>

                        <Modal open={!!modal} onClose={() => setModal(null)}>{modal || <div/>}</Modal>
                    </ModalContainer>
                )
            case 'module':
                const sub_items = getSubItems()
                const property_blocks = [...properties,'more'].map((x,index) => getProperty(x,index))
                return (
                    <Container>
                        {values['is_archived'] && <ArchiveHeader item_id={item_id}/>}
                        <Labels labels={labels} selectable={false} style={{marginLeft: 'var(--spacing-small)'}}/>
                        <Header>
                            <Check 
                                is_selected={values['is_completed']}
                                color={`var(${priorities[values['priority']]['color_key']})`}
                                selectedColor={`var(${priorities[values['priority']]['color_key']})`}
                                onClick={() => onEdit({is_completed: !values['is_completed']})}
                                style={{marginTop: '0.65rem'}} // title is 2.5rem, but check is only 1.2rem
                                />
                            <TitleInput
                                initial={values['title']}
                                onSubmit={(new_title, callback) => onEdit({ title: new_title }, callback)}
                                is_focused={focus === 'title'}
                                setFocus={setFocus}
                                strikethrough={values['is_completed']}
                                // onClose={reset}
                                />
                        </Header>
                        
                        {/* <Divider/> */}
                        <Column>{property_blocks}</Column>
                        <Divider/>

                        <div style={{
                            display: 'flex',
                            alignItems: 'center', 
                            paddingLeft: 'var(--spacing-small)', 
                            marginTop: 'var(--spacing)',
                            marginBottom: 'var(--spacing-small)'
                            }}>
                            <Text type='subheader'>Sub-items</Text>
                            <Button 
                                icon={focus === 'sub' ? 'Close' : 'Plus'}
                                icon_color='var(--on-surface-variant)'
                                style={{padding: 0, backgroundColor: 'var(--surface)'}}
                                onClick={() => {
                                    const new_focus = focus !== 'sub' ? 'sub' : null
                                    setFocus(new_focus)
                                }}
                                />
                        </div>
                        <Collapsible collapsed={focus !== 'sub'}>
                            <TextInput ref={sub_ref} 
                                multiline={true} clearOnSubmit={true}
                                placeholder='Create item...' submit_icon='Plus'
                                border auto_focus={true}
                                onSubmit={(title, callback) => onCreateSub({title, callback})}
                                onClose={() => setFocus(null)}
                                style={{marginBottom: 'var(--spacing-smallest)'}}
                                />
                        </Collapsible>
                        {sub_items}

                        <Modal open={!!modal} onClose={() => setModal(null)}>{modal || <div/>}</Modal>
                    </Container>
                )
            case 'modal_create':
                return (
                    <ModalContainer>
                        <ModalContent>
                             <TextArea
                                ref={ref} tabIndex={0}
                                placeholder={'Create item...'}
                                auto_focus={true}
                                onSubmit={onCreate}
                                style={{fontSize: '1.5rem',fontWeight: 'bold', padding: 'var(--spacing-small) 0'}} 
                                />
                            <Collapsible collapsed={!error}>
                                <Text type='error'>{`*${error}`}</Text>
                            </Collapsible>
                            <Column style={{marginTop: 'var(--spacing-small)'}}>{properties.map((x,index) => getProperty(x,index))}</Column>
                        </ModalContent>
                        
                        <OptionsContainer>
                            <Button 
                                label='Cancel' labelStyles={{color: 'var(--on-surface-variant)'}}
                                onClick={() => props.onClose?.()} style={{backgroundColor: 'var(--surface-container)'}}
                                />
                            <Button 
                                label='Create' 
                                style={{backgroundColor: 'var(--primary)'}}
                                labelStyles={{color: 'var(--on-primary)'}}
                                onClick={onCreate}
                                loading={loading}
                                />
                        </OptionsContainer>
                    </ModalContainer>
                )
            case 'module_create':
                return (
                    <Container>
                        <TextArea
                            ref={ref} tabIndex={0}
                            placeholder={'Create item...'}
                            auto_focus={true}
                            onSubmit={onCreate}
                            style={{fontSize: '1.5rem',fontWeight: 'bold', padding: 'var(--spacing-small) 0'}} 
                            />
                        <Collapsible collapsed={!error}>
                            <Text type='error'>{`*${error}`}</Text>
                        </Collapsible>
                        <Column style={{marginTop: 'var(--spacing-small)'}}>{properties.map((x,index) => getProperty(x,index))}</Column>
                        <Button 
                            label='Create' 
                            style={{backgroundColor: 'var(--primary)', width: '100%', marginTop: 'var(--spacing)'}}
                            labelStyles={{color: 'var(--on-primary)'}}
                            onClick={onCreate}
                            loading={loading}
                            />
                    </Container>
                )
        }
    }
    
}

const ArchiveHeader = props => {
    const {
        item_id
    } = props;
    const dispatch = useDispatch()
    const { showToast } = useToast()
    const data = useSelector(state => state.db.data)
    const user = useSelector(state => state.db.user)
    const item = data.items[item_id]

    const onArchive = (callback) => {
        dispatch(actions.updateItem(item_id, { is_archived: !item['is_archived'] }, success => {
            callback?.(success)
            success && props.onClose?.()
            !success && showToast({ title: `Item not ${item['is_archived'] ? 'unarchived' : 'archived'}`, subtitle: 'Please try again', type: 'error' })
        }))
    }

    return (
        <ArchiveContainer>
             <Button 
                label='This item is archived' 
                icon='InformationOutline'
                context_menu={<ConfirmSelector onConfirm={onArchive} title='Unarchive?' subtitle='Item will be unarchived'/>} 
                style={{width: '100%', backgroundColor: 'var(--surface-container)'}}
                labelStyles={{color: 'var(--on-surface-variant)'}}
                anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
                transformOrigin={{ vertical: 'top', horizontal: 'center' }}
                />
        </ArchiveContainer>
    )
}

const PropertyBlock = props => {
    const {
        icon,
        icon_color='var(--on-surface-variant)',
        content,
        subtitle,
        context,
        placeholder,
        tooltip,
        disabled,
        disableHover=false,
        is_focused=false,
        focusBackgroundColor='var(--surface-container)',
        backgroundColor='transparent',
        onClickShortcuts=[],
        onContextMenuShortcuts=[],
        setFocus,
        property,
        open,
        onOpen,
        onClose,
        onClick
    } = props;

    const output = <PropertyContainer 
                        disabled={disabled} 
                        disableHover={disableHover} 
                        is_focused={is_focused} 
                        focusBackgroundColor={focusBackgroundColor}
                        backgroundColor={backgroundColor}
                        onClick={onClick}
                        >
                        {!icon ? null
                        : icon && typeof icon === 'string' ?
                         <Icon
                            icon={icon} size={0.9}
                            color={icon_color}
                            />
                        : icon}
                        <PropertyContent>
                            {content || <Text type='placeholder'>{placeholder}</Text>}
                            {subtitle && <Text type='subtitle'>{subtitle}</Text>}
                        </PropertyContent>
                    </PropertyContainer>

    // return context ? <Pressable context_menu={context} anchorReference='anchorEl' tooltip={tooltip} shortcuts={shortcuts}>{output}</Pressable> : output
    if(context) {
        return (
            <Pressable
                open={open}
                popover={context}
                tooltip={tooltip}
                onClickShortcuts={onClickShortcuts}
                onContextMenuShortcuts={onContextMenuShortcuts}
                disabled={disabled}
                onOpen={onOpen}
                onClose={onClose}
                // onOpen={() => setFocus(property)}
                // onClose={() => setFocus(null)}
                >
                {output}
            </Pressable>
        )
    } else if (tooltip) {
        return <Tooltip {...tooltip}>{output}</Tooltip>
    } else {
        return output
    }
}

const TitleInput = props => {
    const {
        initial,
        onSubmit,
        is_focused,
        setFocus,
        strikethrough=false,
        placeholder='Title...',
        auto_focus=false,
        onClose
    } = props;
    const [error, setError] = React.useState(null)
    const [loading, setLoading] = React.useState(false)
    const ref = React.useRef()

    React.useEffect(() => {
        error && setTimeout(() => {
            setError(false)
        }, 1000)
    }, [error])

    React.useEffect(() => {
        is_focused ? ref.current?.focus() : ref.current?.blur()
    }, [is_focused])

    const onSubmitHandler = () => {
        const value = ref.current?.textContent
        if(!value || value.length === 0) {
            setError('Required')
        } else {
            setLoading(true)
            setTimeout(() => {
                onSubmit?.(value, (success, err_msg) => {
                    setLoading(false)
                    success && onFocus(false, false)
                    !success && err_msg && setError(err_msg)
                })
            }, 100)
        }
    }

    const onCloseHandler = () => {
        onFocus(false)
        onClose?.()
        ref.current.textContent = initial
    }

    const onFocus = (new_focus, reset=true) => {
        setFocus?.(new_focus ? 'title' : null)
        if(reset) {
            ref.current.value = initial || ''
        }
    }

    let style = {
        fontWeight: 'bold', fontSize: '1.5rem', lineHeight: '1.5rem',
    }
    if(strikethrough) {
        style = {
            ...style,
            color: 'var(--on-surface-variant)',
            textDecorationLine: 'line-through', 
            textDecorationStyle: 'solid', 
            transition: 'color 0.3s ease',
        }
    }
    return (
        <TitleContainer isEditing={is_focused}>
            <div style={{display: 'flex', flexDirection: 'column'}}>
                <TextArea
                    ref={ref}
                    placeholder={placeholder}
                    initial={initial}
                    auto_focus={auto_focus}
                    onFocus={() => onFocus(true)}
                    onClose={onCloseHandler}
                    onSubmit={onSubmitHandler}
                    style={style}
                    />
                

                <Collapsible collapsed={!error}>
                    <Text type='error' style={{margin: '0 0 var(--spacing-small) var(--spacing-small)'}}>{`*${error || 'Something went wrong'}`}</Text>
                </Collapsible>
            </div>

           <Fade collapsed={!is_focused}>
                <div style={{display: 'flex', alignItems: 'center', gap: 'var(--spacing-small)', paddingRight: 'var(--spacing-small)'}}>
                    <Button
                        icon='Close' icon_color='var(--on-surface-variant)'
                        onClick={onCloseHandler}
                        style={{backgroundColor: 'transparent', padding: 0}}
                        />
                    <Button
                        icon='Check' icon_color='var(--on-surface)'
                        onClick={onSubmitHandler}
                        style={{backgroundColor: 'transparent', padding: 0}}
                        loading={loading}
                        />
                </div>
           </Fade>
        </TitleContainer>
    )
    
}

const NotesInput = props => {
    const {
        initial,
        onSubmit,
        is_focused,
        setFocus,
        backgroundColor='var(--surface-container)'
    } = props;
    const [error, setError] = React.useState(null)
    const [loading, setLoading] = React.useState(false)
    const ref = React.createRef()
    React.useEffect(() => {
        error && setTimeout(() => {
            setError(false)
        }, 1000)
    }, [error])

    // React.useEffect(() => {
    //     is_focused && ref.current?.focus()
    // }, [is_focused])

    const onSubmitHandler = () => {
        const value = ref.current && ref.current.textContent
        setLoading(true)
        setTimeout(() => {
            onSubmit?.(value, (success, err_msg) => {
                setLoading(false)
                success && onFocus(false, false)
                !success && err_msg && setError(err_msg)
                setFocus?.(false)
                ref.current?.blur()
            })
        }, 100)
        
    }

    const onFocus = (new_focus, reset=true) => {
        setFocus(new_focus ? 'notes' : null)
        !new_focus && ref.current?.blur()
        if(reset) {
            ref.current.textContent = initial || ''
        }
    }
    return (
        <NotesContainer isEditing={is_focused} backgroundColor={backgroundColor}>
            <TextArea
                ref={ref}
                placeholder='Add notes'
                initial={initial}
                auto_focus={false}
                multiline={true}
                onSubmit={onSubmitHandler}
                onChange={e => error && setError(null)}
                onFocus={() => onFocus(true)}
                onClose={() => onFocus(false)}
                />

            <Collapsible collapsed={!error}>
                <Text type='error' style={{margin: '0 0 var(--spacing-small) var(--spacing-small)'}}>{`*${error || 'Something went wrong'}`}</Text>
            </Collapsible>

            <Collapsible collapsed={!is_focused}>
                <div style={{display: 'flex', justifyContent: 'flex-end', alignItems: 'center', gap: 'var(--spacing-small)', marginTop: 'var(--spacing-small)'}}>
                    <Button
                        label='Cancel'
                        onClick={() => onFocus(false)}
                        style={{backgroundColor: 'var(--surface-container)'}}
                        />
                    <Button
                        label='Edit'
                        onClick={onSubmitHandler}
                        style={{backgroundColor: 'var(--primary)'}}
                        loading={loading}
                        />
                </div>
            </Collapsible>
        </NotesContainer>
    )
    
}

const Container = styled.div`
    padding: var(--spacing);
    display: grid;
    grid-template-rows: auto auto auto auto auto auto auto 1fr;
    height: 100vh;
`

const Header = styled.div`
    display: grid;
    grid-template-columns: auto 1fr;
    gap: var(--spacing);
    border-radius: var(--border-radius);
    padding: 0 var(--spacing-small);
    /* padding-bottom: var(--spacing-smallest); */
    margin-block: var(--spacing-smallest);
`

const ModalContainer = styled.div`
    background-color: var(--surface-container);
    border: 1px solid var(--outline-variant);
    border-radius: var(--border-radius);
    width: 30rem;
`

const ModalContent = styled.div`
    padding: var(--spacing);
`

const PropertyContainer = styled.div`
    transition: background-color 0.3s ease;
    display: grid;
    grid-template-columns: 1.5rem 1fr;
    gap: var(--spacing);
    cursor: ${props => props.disabled ? 'cursor' : 'pointer'};
    pointer-events: ${props => props.disabled ? 'none' : 'auto'};
    padding: var(--spacing-small);
    border-radius: var(--border-radius);
    background-color: ${props => props.is_focused ? props.focusBackgroundColor : props.backgroundColor || 'transparent'};
    align-items: center;
    &:hover {
        background-color: ${props => (props.disabled || props.disableHover) ? props.backgroundColor || 'transparent' : props.focusBackgroundColor};
    }
`

const PropertyContent = styled.div`
    height: 100%;
    display: flex;
    /* align-items: center; */
    border-radius: var(--border-radius);
    flex-direction: column;
`

const Column = styled.div`
    display: flex;
    flex-direction: column;
    margin-top: var(--spacing-smallest);
    /* gap: var(--spacing-smallest); */
`

const Divider = styled.div`
    /* margin-top: var(--spacing); */
    margin: var(--spacing-small) 0;
    border-bottom: 1px solid var(--outline-variant);
    width: 100%;
`

const TitleContainer = styled.div`
    cursor: ${props => props.isEditing ? 'auto' : 'pointer'};
    border-bottom: ${props => props.isEditing ? '1px solid var(--primary)' : '1px solid transparent'};
    transition: border-bottom 0.3s ease;
    display: grid;
    grid-template-columns: 1fr auto;
    align-items: center;
    min-height: 2.5rem;
    padding: var(--spacing-small) 0; // ensures alignment with check
    &:hover {
        border-bottom: ${props => props.isEditing ? '1px solid var(--primary)' : '1px solid var(--outline-variant)'};
    }
`

const NotesContainer = styled.div`
    width: 100%;
    border-radius: var(--border-radius);
    transition: background-color 0.3s ease, padding 0.3s ease, border 0.3s ease; 
    cursor: ${props => props.isEditing ? 'auto' : 'pointer'};
`

const TagContainer = styled.div`
    display: flex;
    gap: var(--spacing-small);
    flex-wrap: wrap;
`

const OptionsContainer = styled.div`
    border-top: 1px solid var(--outline-variant);
    padding: var(--spacing-small);
    /* display: flex;
    justify-content: flex-end; */
    align-items: center;
    display: grid;
    grid-template-columns: 1fr auto;
`

const ArchiveContainer = styled.div`
    background-color: var(--surface-container);
    border-radius: var(--border-radius);
    display: flex;
    align-items: center;
    margin-bottom: var(--spacing-small);
`

const DndOverlay = styled.div`
    border: 1px solid var(--outline);
    border-radius: var(--border-radius);
`

const DndPlaceholder = styled.div`
    border: 1px solid var(--outline);
    border-radius: var(--border-radius);
    opacity: 0.5;
`

const Input = styled.div`
    user-select: text; white-space: pre-wrap; word-break: break-word;
    background-color: transparent;
    border: none;
    border-radius: 5px;
    width: 100%;
    resize: none;
    overflow: hidden;
    text-overflow: ellipsis;
    outline: none;
    color: var(--on-surface);
    font-weight: bold;
    font-size: 1.5rem;
    /* min-width: 15rem; */
    cursor: inherit;
`