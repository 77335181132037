import React from 'react';
import styled from "styled-components";
import { useLocation, useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux'

import { Text, Button, Collapsible, BackgroundBeams, ListTile, Check } from 'components'
import { motion, AnimatePresence } from "framer-motion";

import { light_theme, dark_theme } from 'constants'
import { useNotifications, useToast } from 'hooks'
import * as actions from 'store/actions'

const Container = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100vh;
    overflow: hidden auto;
`

const Body = styled.div`
    display: grid;
    grid-template-rows: 1fr auto;
    width: 25rem;
    border-radius: var(--border-radius);
    padding: var(--spacing);
`

const ContentSection = styled.div`
    display: flex;
    flex-direction: column;
    gap: var(--spacing);
`

const Footer = styled.div`
    display: grid;
    grid-template-columns: ${props => props.show_back ? '1fr auto auto' : '1fr auto'};
    gap: var(--spacing-small);
    align-items: center;
    margin-top: var(--spacing);
`

const ProgressBlock = styled.div`
    border-radius: 50%;
    height: 0.5rem;
    width: 0.5rem;
    margin-right: var(--spacing-smallest);
    cursor: pointer;
    pointer-events: ${props => props.disabled ? 'none' : 'auto'};
    background-color: var(--on-surface);
    opacity: ${props => props.disabled ? 0.5 : 1};
`

const Row = styled.div`
    display: flex;
    align-items: center;
    gap: var(--spacing-small);
`

const Column = styled.div`
    display: flex;
    flex-direction: column;
    gap: var(--spacing-small);
`

const TextInput = styled.input`
    background-color: transparent;
    border: none;
    width: 100%;
    resize: none;
    overflow: hidden;
    text-overflow: ellipsis;
    outline: none;
    color: var(--on-surface);
    cursor: inherit;
    border-bottom: 2px solid var(--outline-variant);
    font-size: 1.5rem;
    transition: border-bottom 0.6s ease;
    padding: var(--spacing-small);
    padding-top: 0;
    &:focus-within {
        border-bottom: 2px solid var(--blue-300);
    }
`

const targets = ['displayName', 'is_dark', 'enable_reminders']
export const OnboardingScreen = props => {
    const dispatch = useDispatch()
    const location = useLocation();
    const { state } = location
    const { getPermissions } = useNotifications()
    const { showToast } = useToast()
    const navigate = useNavigate()
    const [values, setValues] = React.useState({...state, preferences: { is_dark: true, enable_reminders: true }})
    const [target, setTarget] = React.useState('displayName') // displayName, is_dark, enable_reminders
    const [loading, setLoading] = React.useState(false)
    const [error, setError] = React.useState(null)
    const text_ref = React.createRef()

    React.useEffect(() => {
        !state && navigate('/loading')
    }, [])

    React.useEffect(() => {
        error && setTimeout(() => {
            setError(null)
        },1000)
    }, [error])

    React.useEffect(() => {
        if(target === 'displayName' && text_ref.current) {
            text_ref.current.value = values['displayName']
        } else {
            
        }

        target === 'enable_reminders' && checkNotiPermissions()
    },[target])

    const checkNotiPermissions = () => {
        if (!("Notification" in window)) {
            setError(`Your browser doesn't seem to support notifications`)
        } else {
            Notification.requestPermission(result => {
                if(result === 'denied') {
                    setError(`Browser permission denied, please update your browser's settings`)
                }
            });
        }
    }

    const onReminder = async() => {
        const enable_reminders = !values.preferences['enable_reminders']
        setValues({...values, preferences: {...values.preferences, enable_reminders}})
        enable_reminders && checkNotiPermissions()
    }

    const onBack = () => {
        const current_index = targets.indexOf(target)
        setTarget(targets[current_index - 1])
    }

    const onNext = () => {
        // 1. Validation for displayName
        if(target === 'displayName') {
            const new_displayName = text_ref.current.value
            if(new_displayName && new_displayName !== '') {
                setValues({...values, displayName: new_displayName})
            } else {
                return setError('Required')
            }
        }

        if(target !== 'enable_reminders') {
            const current_index = targets.indexOf(target)
            setTarget(targets[current_index + 1])
        } else {
            setLoading(true)
            dispatch(actions.onboardUser(values, success => {
                if(success) {
                    navigate('/list')
                } else {
                    setLoading(false)
                    setError('Something went wrong, please try again')
                }
            }))
        }
    }

    const getContent = () => {
        let title;
        let subtitle;
        let content;

        switch(target){
            case 'displayName':
                title = 'How can I address you?'
                subtitle = `I'll never forget your name`
                content = 
                <div>
                    <TextInput
                        ref={text_ref}
                        placeholder='Your name'
                        initial={values['displayName']}
                        />
                    <Collapsible collapsed={!error}>
                        <Text type='error'>{`*${error}`}</Text>
                    </Collapsible>
                </div>
                break
            case 'is_dark':
                title = 'Selected your preferred theme'
                subtitle = 'I really recommend the dark theme'
                content = 
                <Column>
                    {['dark','light'].map(key => {
                        const theme = key === 'dark' ? dark_theme : light_theme
                        const is_selected = key === 'dark' ? values.preferences['is_dark'] : !values.preferences['is_dark']
                        return (
                            <ListTile
                                label={key === 'dark' ? 'Dark' : 'Light'}
                                subtitle={'Subtitles will look something like this'}
                                style={{backgroundColor: theme['--surface-container'], border: `1px solid ${theme['--outline-variant']}`}}
                                textStyle={{color: theme['--on-surface']}}
                                trailing={is_selected && 'Check'}
                                trailing_color={theme['--on-surface-variant']}
                                onClick={() => setValues({...values, preferences: {...values.preferences, is_dark: key === 'dark'}})}
                                />
                        )
                    })}
                </Column>
                break
            case 'enable_reminders':
                title = 'Enable reminders?'
                subtitle = `We'll remind you when your item is starting`
                content = 
                <div>
                    <Row>
                        <Check is_selected={values.preferences['enable_reminders']} onClick={onReminder}/>
                        <Text>{values.preferences['enable_reminders'] ? 'Enabled' : 'Disabled'}</Text>
                    </Row>
                    <Collapsible collapsed={!error}>
                        <Text type='error'>{`*${error}`}</Text>
                    </Collapsible>
                </div>
                break
        }

        return (
            <AnimatePresence key={target}>
                <motion.div
                    initial="collapsed" animate="open" exit="collapsed"
                    transition={{ duration: 0.6 }}
                    variants={{ open: { opacity: 1}, collapsed: { opacity: 0 } }}
                    >
                    <ContentSection>
                        <div>
                            <Text type='title'>{title}</Text>
                            <Text style={{color: 'var(--on-surface-variant)'}}>{subtitle}</Text>
                        </div>
                        {content}
                    </ContentSection>
                </motion.div>
            </AnimatePresence>
        )
    }

    const getFooter = () => {
        let output = []

        // 1. Progress
        let progress_blocks = targets.map(key => {
            const current_index = targets.indexOf(target)
            const key_index = targets.indexOf(key)
            return <ProgressBlock is_selected={key === target} onClick={() => setTarget(key)} disabled={loading || key_index > current_index}/>
        })
        output.push(<Row>{progress_blocks}</Row>)

        // 2. Cancel
        target !== 'displayName' && output.push(
            <Button
                label='Back'
                onClick={onBack}
                disabled={loading}
                />
        )

        // 3. Next
        output.push(
            <Button
                label='Next'
                trailing='ArrowRight'
                loading={loading}
                style={{backgroundColor: 'var(--primary)'}}
                onClick={onNext}
                />
        )


        return <Footer show_back={target !== 'displayName'}>{output}</Footer>
        
    }

    const content = getContent()
    const footer = getFooter()
    return (
        <Container>
            <Body>
                {content}
                {footer}
            </Body>
            <BackgroundBeams/>
        </Container>
    )
}