import React from 'react';
import styled from 'styled-components';

import { ListTile, TextInput, Text, ConfirmSelector, Collapsible, Modal } from 'components'
import { useDispatch, useSelector } from 'react-redux';
import Popover, { popoverClasses } from '@mui/material/Popover';
import Popper from '@mui/material/Popper';
import Fade from '@mui/material/Fade';

const Container = styled.div`
    width: 18rem;
    border: 1px solid var(--outline-variant);
    border-radius: var(--border-radius);
    background-color: var(--surface-container);
`

const Divider = styled.div`
    border-bottom: 1px solid var(--outline-variant);
`

const Placeholder = styled.div`
    /* border: 1px solid var(--outline); */
    /* width: 10rem;
    height: 10rem; */
    /* border-radius: var(--border-radius); */
`


export const ContextMenu = props => {
    const {
        options=[],
        data={},
        zIndex=1500
    } = props;
    const [anchorEl, setAnchorEl] = React.useState(null);
    const [focus, setFocus] = React.useState(null)
    const [inline, setInline] = React.useState(null)
    const [modal, setModal] = React.useState(null)

    const onHover = (e, option) => {
        if(option && option !== focus && data[option]['popper']) {
            setFocus(option)
            setAnchorEl(e && e.currentTarget)
            setInline(null)
        } else if (data[option] && !data[option]['popper'] && focus) {
            setFocus(null)
            setAnchorEl(null)
        }
    }

    const onSelect = (e, option) => {
        if(!data[option]) { return }
        const { inline_selector, onClick, trigger='hover', modal:option_modal } = data[option]
        if(inline_selector) {
            setInline(option === inline ? null : option)
        } else if (onClick) {
            onClick()
        } else if (trigger === 'click') {
            setFocus(focus !== option ? option : null)
            setAnchorEl(focus !== option ? e?.currentTarget : null)
        } else if (option_modal) {
            setModal(React.cloneElement(option_modal, { close: () => setModal(null) }))
        }
    }

    const getContent = () => {
        let output = []
        options.forEach(option => {
            if(!data[option]) { return }
            const { label, subtitle, icon, divider, trailing, content, trigger='hover', onConfirm, renderSelector, confirm_title, confirm_subtitle } = data[option]
            divider && output.push(<Divider/>)

            content && output.push(
                <div 
                    onMouseEnter={(e) => trigger === 'hover' && onHover(e, option)} 
                    onMouseLeave={() => onHover()}
                    >
                    {content}
                </div>
            )
            !content && label && output.push(
                <div 
                    onMouseOver={(e) => trigger === 'hover' && onHover(e, option)} 
                    onMouseLeave={() => onHover()}
                    >
                    <ListTile 
                        label={label} subtitle={subtitle} leading={icon} trailing={trailing}
                        style={{padding: 'var(--spacing-small)', backgroundColor: 'var(--surface-container)', margin: 'var(--spacing-smallest)'}}
                        onClick={(e) => {
                            onSelect(e, option)
                            // getSelector(option)
                        }}
                        />
                    <Collapsible collapsed={inline !== option}>
                        {renderSelector ? renderSelector() 
                        : onConfirm ? <ConfirmSelector 
                                        onCancel={() => setInline(null)} 
                                        onConfirm={(callback) => onConfirm?.(option, callback)} 
                                        title={confirm_title} 
                                        subtitle={confirm_subtitle} 
                                        style={{margin: 'var(--spacing-smallest)', marginTop: 0}}/>
                        : null}
                    </Collapsible>
                </div>
            )
        })

        return output
    }

    const content = getContent()
    const popper_w_handler = focus && React.cloneElement(data[focus]['popper'], { close: () => setFocus(null) })
    return (
        <Container data={data} style={props.style}>
            {content}
            <Popper
                open={anchorEl}
                anchorEl={anchorEl}
                placement='right-start'
                onClose={() => {
                    setAnchorEl(null)
                    setFocus(null)
                }}
                style={{zIndex}} // need this to prevent interactions from closing the entire ItemOptions, default zIndex is 1300
                transition
                >
                {({ TransitionProps }) => (
                <Fade {...TransitionProps} timeout={350} key={focus}>
                    <Placeholder>{popper_w_handler}</Placeholder>
                </Fade>
                )}
               
            </Popper>
            <Modal open={!!modal} onClose={() => setModal(null)} zIndex={1300}>{modal || <div/>}</Modal> 
        </Container>
    )
}