import React from 'react';
import styled from 'styled-components';
import { useDispatch, useSelector } from 'react-redux';

import { Text, Button, ListTile, Collapsible, Dropdown, Labels, ConfirmSelector, Icon, DropdownSelector, Check, TextInput } from 'components'
import { useNavigate } from 'react-router-dom';

import * as actions from 'store/actions'
import { USER_ITEM_LIMITS, USER_REMINDER_LIMITS, settings_options, PRIORITY_OPTIONS } from 'constants'
import { useNotifications } from 'hooks'; 
import { api } from 'services'
import { useToast } from 'hooks';
import { AppContext } from 'screens';

const Container = styled.div`

`

export const SettingsModule = props => {
    const {

    } = props;
    const navigate = useNavigate()
    const dispatch = useDispatch()
    const { showToast } = useToast()
    const { setupNoti, getPermissions } = useNotifications()
    const [focus, setFocus] = React.useState(null)
    const user = useSelector(state => state.db.user)
    const { module, setModule } = React.useContext(AppContext)
    process.env.REACT_APP_ENV === 'test' && console.log(user)
    const onEdit = (edits, callback) => {
        let updated_user = {...user, preferences: { ...user.preferences, ...edits }}
        edits['enable_reminders'] && getPermissions()
        dispatch(actions.updateUser(updated_user, success => {
            callback?.(success)
            !success && showToast({ title: 'Something went wrong', subtitle: 'Please try again or reload the page', type: 'error' })
        }))
    }

    const getHeader = () => {
        let labels = []
        let title
        if(user && user['uid']) {
            const { item_count, reminder_count } = user
            const item_limit = !user['uid'] ? USER_ITEM_LIMITS.LOCAL
                            : user['is_pro'] ? '∞'
                            : (user['item_limit'] || USER_ITEM_LIMITS.FREE)
            const reminder_limit = user['is_pro'] ? '∞'
                                    : user['reminder_limit'] || USER_REMINDER_LIMITS.FREE
            title = user['displayName'] || user['email']
            labels = [
                { label: user['is_pro'] ? `Created ${item_count} items` : `Created ${item_count}/${item_limit} items` },
                { label: user['is_pro'] ? `${reminder_count} reminders` : `${reminder_count}/${reminder_limit} reminders` }
            ]
        }

        const onHeader = () => {
            setModule(null)
            setTimeout(() => {
                navigate(`/upgrade`)
            }, 300)
        }

        return (
            <Header onClick={onHeader}>
                <Text>{title}</Text>
                <Labels labels={labels} selectable={false}/>
                {user && user['uid'] && !user['is_pro'] &&
                <UpgradeSection >
                    <Icon icon='AlertOutline' color={'var(--primary)'} size={0.6}/>
                    <Text style={{color: 'var(--primary)', fontSize: '0.9rem'}}>Get unlimited items, reminders and more.</Text>
                </UpgradeSection>}
            </Header>
        )
    }

    const getContent = () => {
        let output = []

        settings_options.forEach(option => {
            // 1. Main tab
            const { key, icon, title, labels, options, disabled, onClick } = option
            const onClickHandler = () => setFocus(focus === key ? null : key)
            output.push(
                <ListTile
                    label={title}
                    leading={icon}
                    subtitle={<Labels labels={labels.map(label => ({ label }))} selectable={false}/>}
                    onClick={onClick || onClickHandler}
                    style={{gap: 'var(--spacing)', backgroundColor: 'transparent'}}
                    disabled={!onClickHandler && !onClick && !options || disabled}
                    />
            )

            // 2. Tab content
            output.push(
                <Collapsible collapsed={focus !== key}>
                    {getTab(key)}
                </Collapsible>
            )
        })

        return output
    }

    const getTab = () => {
        switch(true){
            case focus === 'logout':
                return (
                    <ConfirmSelector
                        subtitle='Your current session will end'
                        onConfirm={() => dispatch(actions.signOut(success => {
                            success && navigate('/login')
                            !success && showToast({ title: 'Something went wrong', subtitle: 'Please try again or reload the page', type: 'error' })
                        }))}
                        onCancel={() => setFocus(null)}
                        />
                )
            case focus === 'about':
                return (
                    <div>
                        <div>hii</div>
                    </div>
                )
            default:
                let output = []
                const options = settings_options.filter(x => x['key'] === focus)[0]?.['options'] || []
                options.forEach(option => {
                    const { type, label, extinfo={}, target } = option
                    const { options:sub_options=[], default_option } = extinfo 
                    let subtitle = option['subtitle'] && typeof option['subtitle'] === 'string' ? option['subtitle'] : option['subtitle']?.(user)
                    const labels = option['labels'] && option['labels']?.(user)
                    if(labels && labels.length > 0) {
                        subtitle = <Labels labels={labels}/>
                    }
                    let context_menu
                    let trailing
                    let onClick
                    switch(true){
                        case target === 'displayName':
                            context_menu = <TextInput 
                                            placeholder='Your name...' submit_icon='SquareEditOutline'
                                            initial={user['displayName']}
                                            border 
                                            onSubmit={(new_name, callback) => {
                                                if(new_name && new_name !== '') {
                                                    dispatch(actions.updateUser({displayName: new_name}, success => {
                                                        callback?.(success)
                                                        !success && showToast({ title: 'Something went wrong', subtitle: 'Please try again or reload the page', type: 'error' })
                                                    }))
                                                } else {
                                                    callback(false, 'Required')
                                                }
                                            }}
                                            />
                            break
                        case target === 'delete_account':
                            context_menu = <ConfirmSelector 
                                                title='Are you sure?'
                                                subtitle={`We will not be able to recover anything, please log into ${user.email} to authorize the request`}
                                                style={{width: '20rem'}}
                                                onConfirm={(callback) => {
                                                    dispatch(actions.deleteUser(user, success => {
                                                        callback?.(success)
                                                        success && navigate('/login')
                                                        !success && showToast({ title: 'Something went wrong', subtitle: 'Please try again or reload the page', type: 'error' })
                                                    }))
                                                }}/>
                            break
                        case target === 'logout':
                            break
                        case target === 'is_pro':
                            onClick = () => navigate('/upgrade')
                            break
                        case type === 'Switch':
                            trailing = <Check is_selected={user.preferences[target]} onClick={() => onEdit({ [target]: !user.preferences[target] })}/>
                            onClick = () => onEdit({ [target]: !user.preferences[target] })
                            break
                        case sub_options.length > 0:
                            const sub_options_adj = sub_options.map(option => {
                                return {
                                    label: option['title'],
                                    subtitle: option['subtitle'],
                                    value: option['key']
                                }
                            })
                            context_menu = <DropdownSelector 
                                            options={sub_options_adj} 
                                            onSelect={(new_value) => onEdit({[target]: new_value})} 
                                            initial={user.preferences[target] !== null ? user.preferences[target] : default_option}
                                            />
                            trailing='ChevronDown'
                            break
                        
                    }
                    output.push(
                        <ListTile
                            label={label}
                            subtitle={subtitle}
                            leading={'Minus'} leading_color='transparent' 
                            trailing={trailing}
                            onClick={onClick}
                            popover={context_menu}
                            style={{gap: 'var(--spacing)', backgroundColor: 'transparent'}}
                            anchorReference={'anchorEl'}
                            anchorOrigin={{vertical: 'bottom',horizontal: 'right'}}
                            transformOrigin={{vertical: 'top',horizontal: 'right'}}
                            disabled={!onClick && !context_menu}
                            />
                    )
                })
                return <TabSection>{output}</TabSection>
        }        
    }

    const header = getHeader()
    const content = getContent()
    return (
        <Container>
            {header}
            <Body>{content}</Body>
        </Container>
    )
}


const Header = styled.div`
    background-color: var(--surface-container);
    border-radius: var(--border-radius);
    
    padding: var(--spacing);
    margin: var(--spacing);
    margin-right: 0;
    border: 1px solid transparent;
    cursor: pointer;
    transition: border 0.3s ease;
    &:hover {
        border: 1px solid var(--outline);
    }
`

const UpgradeSection = styled.div`
    display: grid;
    grid-template-columns: auto 1fr auto;
    gap: var(--spacing-smallest);
    margin-top: var(--spacing-smallest);
    align-items: center;
`

const Body = styled.div`
    padding-left: var(--spacing);
    display: flex;
    flex-direction: column;
    gap: var(--spacing-small);
`

const TabSection = styled.div`
    display: flex;
    flex-direction: column;
    gap: var(--spacing-small);
    border-bottom: 1px solid var(--outline-variant);
    padding-bottom: var(--spacing);
`