import React from 'react';
import styled from 'styled-components';
import { useDispatch, useSelector } from 'react-redux';

import { ListTile, TextInput, Pressable, Icon, Text, Labels } from 'components'
import { ColorSelector, GroupSelector, SortSelector, FilterSelector, DisplaySelector } from '../selectors'
import { ContextMenu } from './ContextMenu'
import { SectionDND } from '../dnd'

import * as actions from 'store/actions'
import { useNavigate, useParams } from 'react-router-dom';
import moment from 'moment-timezone'
import _ from 'lodash'
import { VIEW_TYPES, sort_options, group_options } from 'constants'
import { useToast } from 'hooks';
import { getFilterSubtitle } from 'utils'

const Container = styled.div`
    width: 15rem;
    border: 1px solid var(--outline-variant);
    border-radius: var(--border-radius);
    background-color: var(--surface-container);
    padding: var(--spacing-smallest);
`

const Row = styled.div`
    display: grid;
    grid-template-columns: 1fr 1fr;
    align-items: center;
`

export const ListOptions = props => {
    const {
        list_id,
        options=['direction','edit','sections','group','sort','display','filter','delete','archive'], // group, direction, add_section, delete, archive, sections, filter, sort, display
    } = props;
    const { type: screen_type, id: screen_id } = useParams()
    const { showToast } = useToast()
    const navigate = useNavigate()
    const dispatch = useDispatch()
    const data = useSelector(state => state.db.data)
    const user = useSelector(state => state.db.user)

    const list = data.lists[list_id]

    if(!list) { return }
    const view = data.views[list['view_id']]

    const onEdit = (edits, callback) => {
        let updated_list = {...list, ...edits}
        dispatch(actions.updateList(updated_list, (success, err_msg) => {
            callback?.(success, err_msg)
            !success && showToast({ title: 'List not updated', subtitle: 'Please try again', type: 'error' })
        }))
    }

    const onConfirm = (option, callback) => {
        const is_same_screen = screen_type === 'list' && screen_id === list_id
        switch(option){
            case 'delete':
                dispatch(actions.deleteList(list_id, success => {
                    callback?.(success)
                    success && props.onClose?.()
                    success && is_same_screen && navigate('/')
                    !success && showToast({ title: 'List not deleted', subtitle: 'Please try again', type: 'error' })
                }))
                break
            case 'archive':
                const updated_list = {
                    ...list, 
                    is_archived: !list['is_archived'],
                    archive_time: !list['is_archived'] ? moment() : null
                }
                dispatch(actions.updateList(updated_list, (success, err_msg) => {
                    callback?.(success)
                    success && props.onClose?.()
                    success && is_same_screen && navigate('/')
                    !success && showToast({ title: `List not ${list['is_archived'] ? 'unarchived' : 'archived'}`, subtitle: 'Please try again', type: 'error' })
                }))
                break
        }
    }

    const onEditView = (edits, callback) => {
        let updated_view = {...view, ...edits}
        if(updated_view['group'] === 'none') {
            updated_view['group'] = null
        }
        dispatch(actions.updateView(updated_view, (success,err_msg) => {
            props.setView?.(updated_view)
        }))
    }

    const onDragEnd = (section_ids) => {
        onEdit({sections: section_ids})
    }

    return (
        <ContextMenu key={`${list_id}_Options`}
            options={list_id === user['inbox_id'] ? options.filter(x => !['delete','archive'].includes(x)) : options}
            data={{
                edit: {
                    label: 'Edit',
                    icon: 'SquareEditOutline',
                    trigger: 'click',
                    popper: <TextInput
                                placeholder='Edit list...' 
                                border 
                                initial={list['title']}
                                clearOnSubmit={false}
                                onSubmit={(title, callback) => {
                                    const updated_list = {...list, title}
                                    if(title && title !== '') {
                                        dispatch(actions.updateList(updated_list, success => {
                                            callback?.(success)
                                            success && props.onClose?.()
                                            !success && showToast({ title: 'List not updated', subtitle: 'Please try again', type: 'error' })
                                        }))
                                    } else {
                                        callback(false, 'Cannot be empty')
                                    }
                                }}
                                />,
                },
                color: {
                    label: 'Change color',
                    icon: 'Pound',
                    trailing:'ChevronRight',
                    popper: <ColorSelector 
                                initial={list['color'] || 'var(--on-surface-variant)'} // var(--on-surface-variant) is the default in NavigationModule
                                onSubmit={(new_color, callback) => {
                                    onEdit({ color: new_color }, callback)
                                }}
                                />
                },
                direction: {
                    content: <Row style={{backgroundColor: 'var(--surface)', borderRadius: 'var(--border-radius)', padding: 'var(--spacing-small)', margin: 'var(--spacing-small)'}}>
                                {['list','kanban'].map(view_type => {
                                    const { label, icon } = VIEW_TYPES[view_type]
                                    const is_selected = view_type === view['type']
                                    const color = !is_selected ? 'var(--on-surface-variant)' : 'var(--on-surface)'
                                    return (
                                        <Pressable 
                                            onClick={() => onEditView({ type: view_type })} 
                                            style={{
                                                backgroundColor: is_selected ? 'var(--surface-container)' : 'var(--surface)',
                                                padding: 'var(--spacing-small)', 
                                                borderRadius: 'var(--border-radius)',
                                                border: is_selected && '1px solid var(--outline-variant)',
                                            }}>
                                            <div style={{display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center'}}>
                                                <Icon icon={icon} color={color}/>
                                                <Text style={{color}}>{label}</Text>
                                            </div>
                                        </Pressable>
                                    )
                                })}
                            </Row>
                },
                sections: {
                    label: 'Sections',
                    icon: 'FormatListGroup',
                    trailing:'ChevronRight',
                    popper: <SectionDND list_id={list_id} onDragEnd={onDragEnd}/>
                },
                group: {
                    label: 'Group',
                    icon: 'GridLarge',
                    trailing:'ChevronRight',
                    divider: true,
                    subtitle: group_options[view['group']]?.['label'],
                    popper: <GroupSelector 
                                onSelect={(new_group) => onEditView({ group: new_group })} 
                                selected={view['group']} 
                                options={['sections','tags','priority','schedule','create_date']}
                                />
                },
                sort: {
                    label: 'Sort',
                    icon: 'Sort',
                    trailing:'ChevronRight',
                    subtitle: sort_options[view['sort'] || 'default']['label'],
                    popper: <SortSelector 
                                selected={view['sort'] || 'default'} 
                                onSelect={(new_sort, callback) => onEditView({ sort: new_sort }, callback)}
                                />,
                },
                display: {
                    label: 'Labels',
                    icon: 'LabelMultipleOutline',
                    trailing:'ChevronRight',
                    subtitle: `${view['display']?.length > 0 ? view['display'].length : 'None'} selected`,
                    popper: <DisplaySelector 
                                onSelect={(new_display) => onEditView({ display: new_display })} 
                                selected={view['display']}
                                />,
                },
                filter: {
                    label: 'Filter',
                    icon: 'FilterOutline',
                    trailing:'ChevronRight',
                    subtitle: getFilterSubtitle(view),
                    popper: <FilterSelector 
                                options={['priorities','tags','schedule']} 
                                onSelect={(new_filters) => onEditView(new_filters)}
                                selected={_.pick(view, ['priorities','schedule','tags','start','end'])}
                                />,
                },
                delete: {
                    label: 'Delete',
                    icon: 'TrashCanOutline',
                    inline_selector: true,
                    divider: true,
                    onConfirm
                },
                archive: {
                    label: list['is_archived'] ? 'Unarchive' : 'Archive',
                    confirm_subtitle: `Section will be ${list['is_archived'] ? 'unarchived' : 'archived'}`,
                    icon: 'ArchiveOutline',
                    inline_selector: true,
                    onConfirm
                },
            }}
            />
    )
}