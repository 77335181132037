import React from 'react';
import styled from 'styled-components';
import { useSelector } from 'react-redux'

import { Text } from './Text'
import { Icon } from './Icon'
import { Pressable } from './Pressable';
import Popover, { popoverClasses } from '@mui/material/Popover';

import { colorTheme, rgbaToHex } from 'utils'

const Container = styled.div`
    background-color: var(--surface);
    display: grid;
    grid-template-columns: ${props => `${props.hasLeading ? 'auto ' : ''}1fr${props.hasTrailing ? ' auto' : ''}`};
    align-items: center;
    gap: var(--spacing-small);
    padding: var(--spacing-small);
    border-radius: var(--border-radius);
    /* cursor: pointer; */
    &:hover {
        filter: brightness(${props => props.disabled ? 1 : props.isDark ? 1.5 : 0.95});
        /* transition: filter 0.3s ease;  */
    }
`

export const ListTile = props => {
    const {
        label,
        labelStyles,
        subtitle,
        disabled,
        leading,
        leading_color='var(--on-surface-variant)',
        leading_size=0.8,
        trailing,
        trailing_color='var(--on-surface-variant)',
        trailing_size=0.8,
        onClick,
        popover,
        type='default',
        style={},
        textStyle={},
        anchorOrigin,
        transformOrigin,
        anchorReference='anchorEl'
    } = props;
    const user = useSelector(state => state.db.user)
    const [is_dark, setDark] = React.useState(true);

    React.useEffect(() => {
        // To ensure hover brightness adjusts properly
        let isDark = false
        let target = style?.backgroundColor || '--surface'
        if(target.includes('var')) {
            target = target.replace('var(','').replace(')','')
        }
        if(target.includes('--')) {
            target = document.documentElement.style.getPropertyValue(target)
        }
        if(target.includes('rgb')) {
            target = rgbaToHex(target)
        }
        isDark = colorTheme(target) === 'dark'
        setDark(isDark)
    }, [user && user.preferences?.['is_dark']])

    return (
        <Pressable 
            onClick={onClick} 
            popover={popover} 
            anchorOrigin={anchorOrigin} 
            transformOrigin={transformOrigin} 
            anchorReference={anchorReference}
            disabled={disabled}
            >
            <Container isDark={is_dark} style={style} hasLeading={!!leading} hasTrailing={!!trailing} disabled={disabled}>
                {leading && typeof leading === 'string' ? <Icon icon={leading} color={leading_color} size={leading_size}/> : leading}
                <div>
                    <Text style={textStyle}>{label}</Text>
                    {subtitle && typeof subtitle === 'string' ? <Text type='subtitle'>{subtitle}</Text> : subtitle}
                </div>
                {trailing && typeof trailing === 'string' ? <Icon icon={trailing} color={trailing_color} size={trailing_size}/> : trailing}
            </Container>
            
        </Pressable>
        
    )
}