import { createStore, combineReducers, applyMiddleware, compose } from 'redux';
import { persistStore, persistReducer } from 'redux-persist';
import { encryptTransform } from 'redux-persist-transform-encrypt';
import {thunk} from 'redux-thunk';
import { createFilter } from 'redux-persist-transform-filter';

import localforage from 'localforage';
import storage from 'redux-persist/lib/storage'; // Choose your storage engine
import hardSet from 'redux-persist/lib/stateReconciler/hardSet'
import dbReducer from './reducers/db';

localforage.config({
    name: 'main',
    storeName: 'db',
});

const persistConfig = {
    key: 'root',
    storage,
    // storage: localforage,
    stateReconciler: hardSet,
    whitelist: ['db'], // Specify the reducers you want to persist
    transforms: [
        createFilter(
          'db',
          ['data','loaded_dates']
        ),
        encryptTransform({
          secretKey: process.env.REACT_APP_CLIENT_SECRET,
          onError: function (error) {
            // Handle the error.
            console.log(error)
          },
        }),
        // filterUserTransform
    ],
};

const rootReducer = combineReducers({
    db: dbReducer,
})
const persistedReducer = persistReducer(persistConfig, rootReducer)


const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
export const store = createStore(persistedReducer, composeEnhancers(applyMiddleware(thunk)));
export const persistor = persistStore(store)