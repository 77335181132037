import React from 'react';
import styled from 'styled-components';
import { useSelector, useDispatch } from 'react-redux';

import { ComboboxSelector } from './ComboboxSelector';
import { ListTile, Spinner, Icon } from '../base'

import { randomTagColor } from 'utils'
import * as actions from 'store/actions'
import { useToast } from 'hooks';

const Container = styled.div`

`

export const TagSelector = props => {
    const {
        initial=[],
        onSelect
    } = props;
    const dispatch = useDispatch()
    const { showToast } = useToast()
    const data = useSelector(state => state.db.data)
    const user = useSelector(state => state.db.user)
    const [loading, setLoading] = React.useState(false)
    const tags = data.tags
    const tag_options = user['tags'].map(tag_id => {
        const tag = data.tags[tag_id]
        return {
            value: tag['tag_id'],
            label: tag['title'],
            leading: <Icon icon='SquareRounded' size={0.6} color={tag['color']}/>,
            is_selected: initial.includes(tag['tag_id'])
        }
    })

    const onSubmitHandler = (new_tags, callback) => {
        onSelect?.(new_tags)
        callback?.(true)
        props.close?.()
    }

    const onCreate = (title, callback) => {
        const new_tag = {
            title,
            color: randomTagColor()
        }
        setLoading(true)
        setTimeout(() => {
            dispatch(actions.createTag(new_tag, (success, tag_id, new_tag) => {
                setLoading(false)
                if(success) {
                    callback?.(success, { 
                        value: tag_id, 
                        label: new_tag['title'], 
                        leading: <Icon icon='SquareRounded' size={0.6} color={new_tag['color']}/>,
                        is_selected: true 
                    })
                } else {
                    !success && showToast({ title: 'Tag not created', subtitle: 'Please try again', type: 'error' })
                }
            }))
        },300)
    }

    const onSearch = (options, value) => {
        if(value && value !== '') {
            const filtered = options.filter(option => option['label'].toLowerCase().includes(value.toLowerCase()))
            return [{ value: 'create', label: `Create "${value}"` },...filtered]
        } else {
            return options
        }
    }

    return (
        <ComboboxSelector
            options={tag_options}
            initial={initial}
            onSubmit={onSubmitHandler}
            searchStrategy={onSearch}
            onCreate={(value, callback) => onCreate(value, callback)}
            // renderPlaceholder={(value, callback) => {
            //     return (
            //         <ListTile 
            //             label={`Create "${value}"`} 
            //             onClick={() => onCreate(value, callback)} 
            //             style={{backgroundColor: 'var(--surface-container)'}} 
            //             textStyle={{color: 'var(--on-surface-variant)'}}
            //             trailing={loading && <Spinner size='1rem' color='var(--on-surface-variant)'/>}
            //             />
            //     )
            // }}
            />
    )
}