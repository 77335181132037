import React from 'react';
import { motion, AnimatePresence } from "framer-motion";

export const Collapsible = props => {
    const {
        collapsed=false,
        duration=0.3,
        style
    } = props;
    
    return (
        <AnimatePresence initial={false}>
            {!collapsed && 
            <motion.div 
                layout 
                initial="collapsed" animate="open" exit="collapsed"
                transition={{ duration }}
                variants={{ open: { height: 'auto', }, collapsed: { height: '0' } }}
                style={{overflow: 'hidden', width: 'auto', ...style }}
                >
                {props.children}
            </motion.div>}
        </AnimatePresence>
    )
}