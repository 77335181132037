export const STRIPE_PRICES = {
    monthly: process.env.REACT_APP_STRIPE_MONTHLY_PRICE,
    yearly: process.env.REACT_APP_STRIPE_YEARLY_PRICE
}
export const FREQUENCY_OPTIONS = [
    { value: 'monthly', label: 'Monthly', color: 'var(--on-surface-variant)', backgroundColor: 'var(--outline-variant)' },
    { value: 'yearly', label: 'Yearly (-25%)', color: 'var(--green-200)', backgroundColor: 'var(--green-200-20)' }
]

export const PLAN_OPTIONS = {
    free: { 
        title: 'Free', 
        subtitle: 'The start of your productivity journey', 
        price: 0, 
        features_title: 'The Free plan includes:',
        features: [
            { label: 'Up to 100 items' },
            { label: 'Calendar' },
            { label: 'Drag and drop time blocking' },
            { label: 'Daily overview' },
            { label: 'Tags' }
        ]
    },
    pro_monthly: { 
        title: 'Pro', 
        subtitle: 'Unlock a new level of personal productivity',
        price: 4,
        features_title: 'Everything in Free, plus:',
        features: [
            { label: 'Unlimited items' },
            { label: 'Reminders', subtitle: 'Available on iOS also' },
            { label: 'Recurring items' },
            { label: 'Google Calendar sync' },
        ],
    },
    pro_yearly: { 
        title: 'Pro', 
        subtitle: 
        'Unlock a new level of personal productivity', 
        price: 3,
        features_title: 'Everything in Free, plus:',
        features: [
            { label: 'Unlimited items' },
            { label: 'Reminders', subtitle: 'Available on iOS also' },
            { label: 'Recurring items' },
            { label: 'Google Calendar sync' },
            { label: 'Actions', subtitle: 'Coming soon' },
            { label: 'Time analytics', subtitle: 'Coming soon' }
        ],
    },
}