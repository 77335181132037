import React from 'react';
import styled from 'styled-components';

const Input = styled.div`
    user-select: text; 
    white-space: pre-wrap; 
    word-break: break-word;
    outline: none;
    cursor: auto;
`

export const TextArea = React.forwardRef((props, forwardedRef) => {
    const {
        initial,
        placeholder,
        onSubmit,
        onClose,
        onFocus,
        onBlur,
        auto_focus=true,
        disabled,
    } = props;
    const ref = forwardedRef || React.createRef()
    React.useEffect(() => {
        if(auto_focus) {
            ref.current?.focus()
            const el = ref.current
            if (typeof window.getSelection != "undefined" &&
                typeof document.createRange != "undefined") {
                var range = document.createRange();
                range.selectNodeContents(el);
                range.collapse(false); // Collapse the range to the end
                var sel = window.getSelection();
                sel.removeAllRanges();
                sel.addRange(range);
            } else if (typeof document.body.createTextRange != "undefined") {
                var textRange = document.body.createTextRange();
                textRange.moveToElementText(el);
                textRange.collapse(false); // Collapse the range to the end
                textRange.select();
            }
        }
    },[])

    const onKeyDown = (event) => {
        const { keyCode, shiftKey } = event;
        switch(keyCode) {
            case 13: // enter
                if(!shiftKey) {
                    event.preventDefault()
                    onSubmit?.(ref.current?.textContent)
                }
                break
            case 27: // esc
                onClose?.()
                ref.current?.blur()
                break
        }
    }

    return (
        <Input
            ref={ref}
            spellcheck="false"
            contentEditable
            role='textbox'
            data-lexical-editor="true"
            onKeyDown={onKeyDown}
            onBlur={onBlur}
            onFocus={onFocus}
            disabled={disabled}
            autofocus={auto_focus}
            placeholder={placeholder}
            dangerouslySetInnerHTML={{ __html: initial }}
            {...props}
            />
    )
})