import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';

import { Text, Icon, Button, Check, Pressable, Labels, TextField, Collapsible, TextArea } from '../base'
import { ItemOptions } from '../options'
import { TagSelector, PrioritySelector, ScheduleSelector, SectionSelector } from '../selectors'
import { useSortable } from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';

import { getItemLabels,parseRepeat, setNoti } from 'utils'
import { priorities } from 'constants'

import { AppContext } from 'screens';
import * as actions from 'store/actions'
import _ from 'lodash'
import { useToast } from 'hooks'
import moment from 'moment-timezone'

const Container = styled.div`
    display: grid;
    grid-template-columns: ${props => !props.disableHandle ? 'auto 1fr' : '1fr'};
    align-items: center;
`

const ContentWrapper = styled.div`
    background-color: ${props => props.isDragging ? 'var(--surface-container)' : 'transparent'};
    border: ${props => props.isDragging ? '1px solid var(--outline-variant)' : '1px solid transparent'};
    border-radius: var(--border-radius);
`

const ContentSection = styled.div`
    opacity: ${props => props.isDragging ? 0 : 1};
    display: grid;
    grid-template-columns: auto 1fr;
    gap: var(--spacing);
    border-radius: var(--border-radius);
    padding: ${props => props.isEditing ? 'var(--spacing)' : 'var(--spacing-small)'};
    cursor: ${props => props.disabled ? 'auto' : 'pointer'};
    background-color: ${props => props.isEditing ? 'var(--surface-container)' : 'transparent'};
    border: ${props => props.isEditing ? '1px solid var(--outline)' : '1px solid transparent'};
    transition: background-color 0.3s ease; 
    &:hover {
        background-color: ${props => props.isEditing || props.disableHover ? null : 'var(--surface-container)'};
    }
`

const DragHandle = styled.div`
    opacity: 0;
    padding: 0 var(--spacing-smallest);
    margin-top: var(--spacing-small);
    align-self: flex-start;
    transition: opacity 0.3s ease; 
    cursor: ${props => props.disableDND ? 'auto' : 'move'};
    ${Container}:hover & {
        opacity: ${props => props.disableDND ? 0 : 1};
    }
`

const Divider = styled.div`
    width: 100%;
    border: 1px solid var(--primary);
    border-radius: var(--border-radius);
`

const _ItemBlock = props => {
    const {
        item_id,
        labels=['sub_items','tags','schedule','repeat'],
        mode='active',
        animateDND=true,
        isEditing=false,
        disableDND=false,
        disableHandle=false,
        defaults={}
    } = props;
    const dispatch = useDispatch()
    const { showToast } = useToast()
    const user = useSelector(state => state.db.user)
    const data = useSelector(state => state.db.data)
    const [edited_item, setEdits] = React.useState({})
    const [loading, setLoading] = React.useState(false)
    const { module={}, setModule } = React.useContext(AppContext)
    const { attributes, listeners, setNodeRef, transform, transition, setActivatorNodeRef, isDragging } = useSortable({
        id: props.id || item_id, // need props.id to prevent duplicate ItemBlocks when DayModule is open with WorkspaceModule
        data: { id: item_id, type: 'item' }, disabled: disableDND
    });
    const [error, setError] = React.useState(null)
    const ref = React.createRef()

    React.useEffect(() => {
        error && setTimeout(() => {
            setError(null)
        }, 1000)
    }, [error])

    const item = data.items[item_id]
    if(!item) { return }

    const onSelect = () => {
        if(module) {
            const { target, extinfo={} } = module
            if(target === 'ItemModule' && extinfo['item_id'] === item_id) {
                setModule(null)
            } else {
                if(module['target'] === 'ItemModule') {    
                    setModule({target: 'ItemModule', extinfo: { item_id, type: 'module', defaults }})
                } else {
                    setModule(null)
                    setTimeout(() => {
                        setModule({target: 'ItemModule', extinfo: { item_id, type: 'module', defaults }})
                    }, 300)
                }
            }
        } else {
            setModule({target: 'ItemModule', extinfo: { item_id, type: 'module', defaults }})
        }
    }

    const onSubmit = () => {
        const title = ref.current?.textContent
        const values = {...edited_item, title}
        if(!values) {
            props.setEdit?.(null)
        } else if (!values['title'] || values['title'] === '') {
            setLoading(false)
            setError('Required')
        } else {
            setLoading(true)
            dispatch(actions.updateItem(item_id, values, success => {
                if(success) {
                    setLoading(false)
                    props.setEdit?.(null)
                } else {
                    setLoading(false)
                    setError('Something went wrong, please try again')
                }
            }))
        }
    }

    const onCancel = () => {
        props.setEdit?.(false)
        setLoading(false)
        setEdits(null)
    }

    const onCheck = () => {
        let updates = { is_completed: !item['is_completed'] }
        const is_repeat = !_.isEmpty(item['repeat'])
        const start = defaults['start'] || item['start']
        let next_start
        if(is_repeat) {
            const { next_date } = parseRepeat({...item['repeat'], start, current_date: start})
            if(next_date) {
                next_start = next_date
                const exdate = item['repeat']['exdate'] ? [...item['repeat']['exdate'], start] : [start]
                updates['repeat'] = {...item['repeat'], exdate}
                updates['start'] = defaults['start'] || item['start'] // Need this to ensure the right schedule is duplicated for the branch item
                updates['end'] = defaults['end'] || item['end']
            }
        } 
        dispatch(actions.updateItem(item_id, updates, (success,err_msg,branch_item) => {
            !success && showToast({ title: 'Status not updated', subtitle: 'Please try again', type: 'error' })
            const is_module_opened = module?.['target'] === 'ItemModule' && module?.extinfo?.['item_id'] === item_id
            if(is_module_opened && is_repeat) {
                if(next_start) {
                    const duration = moment(item.end).diff(moment(item.start))
                    const next_end = moment(next_start).add(duration, 'millisecond').utc().format()
                    setModule({target: 'ItemModule', extinfo: { item_id, type: 'module', defaults: { start: next_start, end: next_end } }})
                } else {
                    // setModule(null)
                }
            }
        }))
    }

    const item_labels = getItemLabels(
        item_id, 
        user, 
        data, 
        labels, 
        mode === 'preview' ? item : !_.isEmpty(edited_item) ? {...item, ...edited_item, ...defaults} : defaults
    )
    
    const style = {
        transform: CSS.Translate.toString(transform),
        transition: animateDND ? transition : null,
    };
    const has_schedule = item['start'] || item['end']
    let item_options =['edit','shift','priority','schedule','repeat','reminders','tags','delete','archive']
    if(!has_schedule) {
        item_options = item_options.filter(x => x !== 'reminders' && x !== 'repeat')
    }

    switch(mode) {
        case 'active':
            const item_content = <Container disableHandle={disableHandle}>
                                    {!disableHandle &&
                                    <DragHandle ref={setActivatorNodeRef} {...listeners} disableDND={disableDND}>
                                        <Icon icon='Drag' color='var(--on-surface-variant)' style={{opacity: disableDND ? 0 : isEditing ? 0 : 1}}/>
                                    </DragHandle>}
                                    
                                    <ContentWrapper isDragging={isDragging}>
                                        <ContentSection isDragging={isDragging} isEditing={isEditing}>
                                            <Check 
                                                is_selected={item['is_completed']}
                                                color={`var(${priorities[item['priority']]['color_key']})`}
                                                selectedColor={`var(${priorities[item['priority']]['color_key']})`}
                                                onClick={onCheck}
                                                />
                                            {isEditing ? 
                                            <div>
                                                <TextArea
                                                    ref={ref}
                                                    initial={item['title']} 
                                                    style={{lineHeight: '1.2rem'}} 
                                                    onSubmit={onSubmit}
                                                    onClose={onCancel}
                                                    auto_focus={true}
                                                    />
                                                <Collapsible collapsed={!error}>
                                                    <Text type='error'>{`*${error}`}</Text>
                                                </Collapsible>
                                                <Labels labels={item_labels} style={{backgroundColor: 'var(--surface-container)'}} selectable={true}/>
                                                <div style={{display: 'flex', flexDirection: 'row', gap: 'var(--spacing-small)', marginTop: 'var(--spacing-small)'}}>
                                                    <Button 
                                                        label='Edit' icon='Check' 
                                                        style={{ backgroundColor: 'var(--primary)' }} 
                                                        labelStyles={{color: 'var(--on-primary)'}} 
                                                        icon_color={'var(--on-primary)'}
                                                        onClick={onSubmit} 
                                                        loading={loading}
                                                        />
                                                    <Button label='Cancel' onClick={onCancel} style={{backgroundColor: 'var(--surface-container)'}}/>
                                                </div>
                                            </div>
                                            : <Pressable 
                                                onClick={onSelect} 
                                                anchorReference='anchorPosition'
                                                popover={
                                                    <ItemOptions 
                                                        item_id={item_id} 
                                                        setEdit={props.setEdit}
                                                        options={item_options}
                                                        defaults={defaults}
                                                        />
                                                }
                                                >
                                                <Text style={{lineHeight: '1.2rem',whiteSpace:'pre-wrap', wordBreak:'break-word'}} type={item['is_completed'] && 'strikethrough'}>{item['title']}</Text>
                                                <Labels labels={item_labels}/>
                                                {labels.includes('notes') && item['notes'] && <Labels labels={[{ label: item['notes'], icon: 'Text' }]}/>}
                                            </Pressable>}
                                            
                                        </ContentSection>
                                    </ContentWrapper>
                                    
                                </Container>
            if(disableDND) {
                return item_content
            } else {
                return (
                    <div ref={setNodeRef} style={style} {...attributes}>
                        {item_content}
                    </div>
                )
            }
        case 'static':
            return (
                <div>
                    <ContentSection isDragging={false} isEditing={isEditing}>
                        <Check 
                            is_selected={item['is_completed']}
                            color={`var(${priorities[item['priority']]['color_key']})`}
                            selectedColor={`var(${priorities[item['priority']]['color_key']})`}
                            onClick={onCheck}
                            style={{marginTop: '0.4rem'}}
                            />
                        {isEditing ? 
                        <div>
                            <TextArea
                                ref={ref}
                                initial={item['title']} 
                                style={{lineHeight: '1.2rem'}} 
                                onSubmit={onSubmit}
                                onClose={onCancel}
                                auto_focus={true}
                                />
                            <div style={{display: 'flex', flexDirection: 'row', gap: 'var(--spacing-small)', marginTop: 'var(--spacing-small)'}}>
                                <Button 
                                    label='Edit' icon='Check' 
                                    style={{ backgroundColor: 'var(--primary)' }} 
                                    labelStyles={{color: 'var(--on-primary)'}} 
                                    icon_color={'var(--on-primary)'}
                                    onClick={onSubmit} 
                                    loading={loading}
                                    />
                                <Button 
                                    label='Cancel' 
                                    onClick={onCancel} 
                                    style={{backgroundColor: 'var(--surface-container)'}}
                                    />
                            </div>
                        </div>
                        : <Pressable 
                            onClick={() => props.setEdit(item_id)} 
                            anchorReference='anchorPosition'
                            popover={
                                <ItemOptions 
                                    item_id={item_id} 
                                    setEdit={props.setEdit}
                                    options={item_options}
                                    defaults={defaults}
                                    />
                            }
                            >
                            <Text type={item['is_completed'] && 'strikethrough'} style={{fontSize: '1.5rem',fontWeight: 'bold'}}>{item['title']}</Text>
                        </Pressable>}
                        
                    </ContentSection>

                    <Labels labels={item_labels} style={{marginLeft: 'var(--spacing-small)', marginTop: 0}}/>
                </div>

            )
        case 'sub-item':
            return (
                    <ContentWrapper isDragging={isDragging}>
                        <ContentSection isDragging={isDragging} isEditing={isEditing}>
                            <Check 
                                is_selected={item['is_completed']}
                                color={`var(${priorities[item['priority']]['color_key']})`}
                                selectedColor={`var(${priorities[item['priority']]['color_key']})`}
                                onClick={onCheck}
                                />
                            {isEditing ? 
                            <div>
                                <TextArea
                                    ref={ref}
                                    initial={item['title']} 
                                    style={{lineHeight: '1.2rem'}} 
                                    onSubmit={onSubmit}
                                    onClose={onCancel}
                                    auto_focus={true}
                                    />
                                <Labels labels={item_labels} style={{backgroundColor: 'var(--surface-container)'}}/>
                                <div style={{display: 'flex', flexDirection: 'row', gap: 'var(--spacing-small)', marginTop: 'var(--spacing-small)'}}>
                                    <Button 
                                        label='Edit' icon='Check' 
                                        style={{ backgroundColor: 'var(--primary)' }} 
                                        labelStyles={{ color: 'var(--on-primary)' }} 
                                        icon_color={'var(--on-primary)'}
                                        onClick={onSubmit} 
                                        loading={loading}
                                        />
                                    <Button label='Cancel' onClick={onCancel} style={{backgroundColor: 'var(--surface-container)'}}/>
                                </div>
                            </div>
                            : <Pressable 
                                onClick={() => props.setEdit?.(item_id)} 
                                anchorReference='anchorPosition'
                                popover={
                                    <ItemOptions 
                                        item_id={item_id} 
                                        setEdit={props.setEdit}
                                        options={item_options}
                                        />
                                }
                                >
                                <Text style={{lineHeight: '1.2rem'}} type={item['is_completed'] && 'strikethrough'}>{item['title']}</Text>
                                <Labels labels={item_labels}/>
                            </Pressable>}
                        </ContentSection>
                    </ContentWrapper>
            )
    }
}

export const ItemBlock = React.memo(_ItemBlock)
