import React from 'react';
import styled from 'styled-components';
import { useDispatch, useSelector } from 'react-redux';
import {createPortal} from 'react-dom';
import { useNavigate, useParams } from 'react-router-dom';

import { Text, Button, ItemBlock, SubheaderBlock, SectionOptions, TextInput, Collapsible, Pressable, Icon, Sortable, ListOptions, TagOptions, IntegrationOptions } from 'components'
import { Virtuoso } from 'react-virtuoso'
import { DndContext, DragOverlay, defaultDropAnimation, useDndMonitor } from '@dnd-kit/core';
import { SortableContext, verticalListSortingStrategy, horizontalListSortingStrategy } from '@dnd-kit/sortable';
import { AppContext } from 'screens';

import * as actions from 'store/actions'
import { buildView, parseRepeat } from 'utils'
import _ from 'lodash'
import { priorities } from 'constants'
import moment from 'moment-timezone'
import { useToast } from 'hooks';

const COLUMN_WIDTH = '25rem'

export const WorkspaceModule = props => {
    const {
        view: default_view,
        defaults={}
    } = props;
    const navigate = useNavigate()
    const dispatch = useDispatch()
    const { showToast } = useToast()
    const { renderHeader } = React.useContext(AppContext)
    const { type: screen_type, id: screen_id } = useParams()
    
    const data = useSelector(state => state.db.data)
    const user = useSelector(state => state.db.user)
    const [create_focus, setFocus] = React.useState(null)
    const [active_id, setActive] = React.useState(null)
    const [view_data, setViewData] = React.useState(null)
    const [initial_data, setInitial] = React.useState(null)
    const [edit_item, setEdit] = React.useState(null)
    const [view, setView]  = React.useState(default_view)
    const default_input_ref = React.createRef()
    const animateDND = true;

    React.useEffect(() => {
        setView({...default_view})
    }, [screen_id])

    const onToggleSection = (key) => {
        const { hidden=[] } = view
        let updated_hidden = [...hidden]
        updated_hidden = updated_hidden.includes(key) ? updated_hidden.filter(x => x !== key) : [...updated_hidden, key]
        const updated_view = {...view, hidden: updated_hidden}
        setView(updated_view)
        view && view['view_id'] && dispatch(actions.updateView(updated_view, success => {
            if(!success) {
                showToast({title: 'Something went wrong', subtitle: 'Please try again or refresh the screen', onClick: () => navigate('/loading'), type: 'error'})
            }
        }))
    }

    const onCreateItem = ({title, callback, defaults}) => {
        const updated_item = {
            ...defaults,
            title,
        }
        if(!title || title === '') {
            callback(false, 'Required')
        } else {
            dispatch(actions.createItem(updated_item, (success, error) => {
                callback?.(success)
                if(error === 'HIT_LIMIT') {
                    showToast({title: 'Task limit hit', subtitle: 'Upgrade to remove limits', onClick: () => navigate('/upgrade'), type: 'error'})
                } else if (!success) {
                    showToast({title: 'Task not created', subtitle: 'Please try again', onClick: () => navigate('/loading'), type: 'error'})
                }
            }))
        }
    }

    const onUpdateSection = (section_id, title, callback) => {
        const section = data.sections[section_id]
        let updated_section = {...section, title}
        dispatch(actions.updateSection(updated_section, success => {
            callback?.(success)
            !success && showToast({ title: 'Section not updated', subtitle: 'Please try again', type: 'error' })
        }))
    }
    
    const renderItem = (values) => {
        const { type, id, defaults={} } = values
        const { hidden=[] } = view
        let disableCreate = view['group'] === 'create_date' || view['integration_id']

        switch(true) {
            case type === 'item':
                const item = data.items[id]
                if(!item) { return }
                let item_defaults;
                if(!_.isEmpty(item['repeat'])) {
                    const { next_date } = parseRepeat({...item['repeat'], start: item['start']})
                    if(next_date) {
                        const duration = moment(item.end).diff(moment(item.start))
                        item_defaults = { start: next_date, end: moment(next_date).add(duration, 'millisecond').utc().format() }
                    }
                }
                return <ItemBlock item_id={id} animateDND={animateDND} setEdit={setEdit} isEditing={edit_item === id} disableDND={view['group'] !== 'sections' || !!edit_item} labels={view['display']} defaults={item_defaults}/>
            case type === 'list' && view['type'] === 'list':
                return (
                    <Row style={{gap: 0, alignItems: 'center', marginBottom: 'var(--spacing-small)'}}>
                        <Button 
                            icon={hidden.includes(id) ? 'ChevronRight' : 'ChevronDown'} 
                            onClick={() => onToggleSection(id)}
                            icon_color={'var(--on-surface-variant)'}
                            style={{backgroundColor: 'transparent'}}
                            />
                        <TextInput 
                            ref={default_input_ref}
                            placeholder='Create item...' submit_icon='Plus'
                            auto_focus={false}
                            border={false} clearOnSubmit={true} closeOnSubmit={false}
                            onSubmit={(title, callback) => onCreateItem({title, callback, defaults: { list_id: view['lists'].slice(-1)[0] || user['inbox_id'] }})}
                            onClose={() => default_input_ref.current?.blur()}
                            onFocus={() => setFocus(null)}
                            style={{border: '0px solid transparent'}}
                            />
                    </Row>
                )
            case ['untagged','unscheduled',4].includes(id):
                if(disableCreate) {
                    return (
                        <SubheaderBlock
                            id={id} type={type}
                            disableHide={view['type'] === 'kanban'}
                            disableDND={view['type'] === 'kanban'}
                            disableCreate={true}
                            hidden={hidden.includes(id)}
                            setHidden={() => onToggleSection(id)}
                            title={id === 'untagged' ? 'Untagged' : id === 'unscheduled' ? 'Unscheduled' : 'None'}
                            setCreate={() => setFocus(create_focus === id ? null : id)}
                            onCreate={onCreateItem}
                            animateDND={animateDND}
                            />
                    )
                } else {
                    return (
                        <Row style={{gap: 0, alignItems: 'center', marginBottom: 'var(--spacing-small)'}}>
                            <Button 
                                icon={hidden.includes(id) ? 'ChevronRight' : 'ChevronDown'} 
                                onClick={() => onToggleSection(id)}
                                icon_color={'var(--on-surface-variant)'}
                                style={{backgroundColor: 'transparent'}}
                                />
                            <TextInput 
                                ref={default_input_ref}
                                placeholder='Create item...' submit_icon='Plus'
                                auto_focus={false}
                                border={false} clearOnSubmit={true} closeOnSubmit={false}
                                onSubmit={(title, callback) => onCreateItem({title, callback, defaults: { list_id: view['lists'].slice(-1)[0] || user['inbox_id'] }})}
                                onClose={() => default_input_ref.current?.blur()}
                                onFocus={() => setFocus(null)}
                                style={{border: '0px solid transparent'}}
                                />
                        </Row>
                    )
                }
                
            case type === 'placeholder':
                return (
                    // need to set key, else dnd won't work when switch view type
                    <Sortable key={`${id}_${view['type']}`} id={id} type={type} disabled={view['type'] !== 'kanban' && true}>
                        <Placeholder height={view['type'] === 'kanban' && '100%'}>
                            <Icon icon='Drag' color='transparent' style={{padding: 'var(--spacing-small)'}}/>
                            <Text type='placeholder' style={{marginTop: 'var(--spacing-small)'}}>No available items</Text>
                        </Placeholder>
                    </Sortable>
                )
            case ['list','section','priority','tag','date','calendar'].includes(type):
                let title;
                let options;
                let title_input
                let section_defaults = {..._.pick(view, ['tags', 'start', 'end']), ...defaults}
                switch(type) {
                    case 'list':
                        const list = data.lists[id]
                        title = view['type'] === 'kanban' && view['group'] === 'sections' ? 'Unassigned' : list['title']
                        break
                    case 'section':
                        const section = data.sections[id]
                        title = section['title']
                        // title = section['id']
                        options = <SectionOptions section_id={id}/>
                        title_input = <TextInput 
                                        placeholder='Edit section...' initial={title} 
                                        border={true} clearOnSubmit={false} closeOnSubmit={true}
                                        onSubmit={(title,callback) => onUpdateSection(id, title, callback)}
                                        />
                        break
                    case 'priority': 
                        title = priorities[id]['label']
                        break
                    case 'tag':
                        const tag = data.tags[id]
                        title = id === 'untagged' ? 'Untagged' : tag && tag['title']
                        break
                    case 'date':
                        title = id === 'unscheduled' ? 'Unscheduled' : moment(id).format(`ddd, ${user.preferences['date_format']}`)
                        break
                    case 'calendar':
                        const calendar = data.calendars[id]
                        title = calendar['title']
                        disableCreate = true
                        break
                }

                return (
                    <SubheaderBlock
                        id={id} type={type}
                        disableHide={view['type'] === 'kanban'}
                        disableDND={view['type'] === 'kanban'}
                        disableCreate={disableCreate}
                        hidden={hidden.includes(id)}
                        setHidden={() => onToggleSection(id)}
                        titleInput={title_input}
                        title={title}
                        showCreate={create_focus === id}
                        setCreate={() => setFocus(create_focus === id ? null : id)}
                        options={options}
                        onCreate={onCreateItem}
                        defaults={section_defaults}
                        animateDND={animateDND}
                        />
                )
            default:
                return <div>{id}</div>
        }
       
    }

    const onDragEnd = ({active, over}) => {
        if(!active || !over) { return }
        const { id, type, sortable } = over.data.current
        const is_workspace_dnd = ['tag','item','section','placeholder'].includes(type)

        if(!is_workspace_dnd) { 
            setViewData(null)
            setInitial(null)
            return
        }

        let new_destination
        let target_index
        let items
        let updated_view_data
        let commit = true
        switch(view['type']) {
            case 'kanban':
                // 1. Update view
                const parent_id = sortable.items[0]
                updated_view_data = []
                view_data.forEach(column => {
                    const column_ids = column.map(x => x['id'])
                    const is_origin = column_ids.includes(active_id)
                    const is_destination = column_ids.includes(parent_id)
                    let updated_column = [...column]
                    if(is_origin) {
                        updated_column = updated_column.filter(x => x['id'] !== active_id)
                    } 

                    if(is_destination) {
                        updated_column.splice(sortable.index, 0, { id: active_id, type: 'item' })
                    }
                    updated_view_data.push(updated_column)
                })
                setViewData(updated_view_data)

                // 2. Get output
                target_index = sortable.index - 1
                const parent_type = Object.keys(data.sections).includes(parent_id) ? 'section' : 'list'
                const parent = parent_type === 'section' ? data.sections[parent_id] : data.lists[parent_id]
                items = sortable.items.slice(1, sortable.items.length)
                const item = data.items[active_id]
                if(parent['section_id'] === item['section_id']) {
                    items = items.filter(x => x !== active_id)
                    items.splice(target_index, 0, active_id)
                }
                new_destination = {
                    list_id: parent['list_id'],
                    section_id: parent['section_id'],
                    index: target_index,
                    items
                }
                break
            case 'list':
                // 1. Update view [to make rendering faster]
                const from = sortable.items.indexOf(active_id)
                const to = sortable.index
                updated_view_data = [...view_data[0]]
                updated_view_data = updated_view_data.filter(x => x['id'] !== active_id)
                updated_view_data.splice(to,0,{ id: active_id, type: 'item' })
                setViewData([updated_view_data])

                // 2. Get ids
                let ids = sortable.items
                ids = ids.filter(x => x !== active_id)
                ids.splice(to, 0, active_id)

                // 3. Get section indexes
                const list_id = sortable.items[0] // first one should be the create item placeholder which has the list_id
                const list = data.lists[list_id]
                target_index = sortable.index
                const sections = list['sections'].map(section_id => {
                    const section_index = ids.indexOf(section_id)
                    return {
                        index: section_index,
                        section_id,
                        value: section_index - to
                    }
                })
                const filtered = sections.filter(x => x['value'] < 0 && x['index'] > 0)

                // 4. Get output
                if(filtered.length === 0) {
                    if(view['hidden'].includes(list['list_id'])) {
                        commit = false
                    }
                    new_destination = {
                        list_id: list['list_id'],
                        index: to - 1,
                        items: ids.filter(id => {
                            const item = data['items'][id]
                            if(!item) { return false }
                            return !item['section_id'] || id === active_id
                        })
                    }
                } else {
                    const target_section_id = filtered.slice(-1)[0]['section_id']
                    if(view['hidden'].includes(target_section_id)) {
                        commit = false
                    }
                    new_destination = {
                        list_id: list['list_id'],
                        section_id: target_section_id,
                        index: to - ids.indexOf(target_section_id) - 1,
                        items: ids.filter(id => {
                            const item = data['items'][id]
                            if(!item) { return false }
                            return item['section_id'] === target_section_id || id === active_id
                        })
                    }
                }
                break
        }
        if(!new_destination['section_id']) {
            new_destination['section_id'] = null
        }

        commit && dispatch(actions.updateItem(active_id, new_destination, success => {
            setActive(null)
            setInitial(updated_view_data)
            if(!success) {
                setViewData(initial_data)
                showToast({title: 'Something went wrong', subtitle: 'Please try again or refresh the screen', onClick: () => navigate('/loading'), type: 'error'})
            }
        }))
        setViewData(null)
        setInitial(null)
    }

    const onDragStart = ({active}) => {
        setActive(active.id)
        const blocks = buildView({...view, is_completed: false, is_archived: false}, data, user, view['type'] === 'list', view['type'] === 'kanban')
        setViewData(blocks)
        setInitial(blocks)
    }

    const onDragOver = (values) => {
        const {active, over} = values
        if(!active || !over) { return }
        const { id: active_id, type: active_type } = active.data.current
        const { id: over_id, type: over_type } = over.data.current
        const is_workspace_dnd = ['tag','item','section','placeholder'].includes(over_type)
        if(!is_workspace_dnd) { return }
        const target_column = _.find(view_data, column => column.map(x => x['id']).includes(over_id))
        const need_shift = target_column && !target_column.map(x => x['id']).includes(active_id)

        if(need_shift) {
            const updated_view = adjustView({view_data: initial_data, over: { over_id, over_type }}) // Need to use initial_data to add back placeholders that got removed
            setViewData(updated_view)
        }
    }

    const onEditTitle = (title, callback) => {
        let updated_values = {...data[`${screen_type}s`][screen_id], title}
        const is_valid = title && title !== ''
        if(is_valid) {
            switch(screen_type) {
                case 'list':
                    dispatch(actions.updateList(updated_values, success => {
                        callback?.(success)
                        !success && showToast({ title: 'List not updated', subtitle: 'Please try again', type: 'error' })
                        success && props.onClose?.()
                    }))
                    break
                case 'tag':
                    dispatch(actions.updateTag(updated_values, success => {
                        callback?.(success)
                        !success && showToast({ title: 'Tag not updated', subtitle: 'Please try again', type: 'error' })
                        success && props.onClose?.()
                    }))
                    break
            }
        } else {
            callback(false, 'Cannot be empty')
        }
    }

    const adjustView = ({view_data, over}) => {
        const target_column = _.find(view_data, column => column.map(x => x['id']).includes(over['over_id']))
        const need_shift = !target_column.map(x => x['id']).includes(active_id)
        if(need_shift) {
            let output = []
            view_data.forEach(column => {
                const column_ids = column.map(x => x['id'])
                const has_active = column_ids.includes(active_id)
                const has_over = column_ids.includes(over['over_id'])
                if(has_active) {
                    let origin_column
                    // 1a. Remove from original section
                    origin_column = column.filter(x => x['id'] !== active_id)
                    
                    // 1b. Replace with placeholder if its the only item
                    if(origin_column.length === 1) {
                        const origin_header = origin_column[0]
                        origin_column.push({ id: origin_header['id'], type: 'placeholder' })
                    }

                    output.push(origin_column)

                } else if (has_over) {
                    // 2. Add into new section
                    const over_index = column_ids.indexOf(over['over_id'])
                    let updated_column = [...column]
                    if(over['over_type'] === 'placeholder') {
                        updated_column = [updated_column[0], { id: active_id, type: 'item' }]
                    } else {
                        updated_column.splice(over_index, 0, { id: active_id, type: 'item' })
                    }
                    output.push(updated_column)
                } else {
                    // 3. Do nothing
                    output.push(column)
                }
            })
            return output
        } else {
            return view_data
        }
    }

    const getContent = () => {
        const is_valid = ['list','tag','google_calendar'].includes(screen_type) && view && Object.keys(view).length > 0
        if(!is_valid) { return }
        
        let output = [];
        const blocks = view_data || buildView({
            ...view, 
            is_completed: false, 
            is_archived: false, 
            hidden: view['type'] === 'list' ? view['hidden'] : [] // unhide for kanban since there is no toggle
        }, data, user, view['type'] === 'list', view['type'] === 'kanban')
        blocks && blocks.forEach(virtuoso_data => {
            const list = <Virtuoso
                            data={virtuoso_data}
                            itemContent={(i) => renderItem(virtuoso_data[i])}
                            computeItemKey={(i) => {
                                const { id, type } = virtuoso_data[i]
                                return `${id}_${type}`
                            }}
                            totalCount={virtuoso_data.length}
                            style={{border: '0px solid red', width: view['type'] === 'list' ? 'auto' : COLUMN_WIDTH, height: 'calc(100vh - 6rem)', overflowX: 'hidden'}} // - 5rem for the header -1rem for scrollbar
                            // defaultItemHeight={1}
                            />
            if(virtuoso_data.length === 0) {
                output.push(<Text type='placeholder'>No available items</Text>)
            } else if(view['group'] === 'sections') {
                output.push(<SortableContext items={virtuoso_data} strategy={verticalListSortingStrategy}>{list}</SortableContext>)
            } else {
                output.push(list)
            }
        })

        
        if(view['group'] === 'sections') {
            return (
                <div onDragEnd={onDragEnd} onDragStart={onDragStart} onDragOver={onDragOver}>
                    <Wrapper vertical={view['type'] === 'list'}>{output}</Wrapper>
                    {createPortal(
                        <DragOverlay dropAnimation={animateDND ? defaultDropAnimation : null}>
                            {active_id && <ItemBlock item_id={active_id} animate={animateDND} labels={view['display']}/>}
                        </DragOverlay>,
                        document.body
                    )}
                </div>
            )
        } else {
            return output
        }
    }

    const getHeader = () => {
        let is_valid = false
        let content
        let title = !screen_id ? `No ${screen_type} selected` : `No view selected`
        let options
        let title_context
        switch(screen_type) {
            case 'list':
                const list = data.lists[screen_id]
                if(list) {
                    is_valid = true
                    title = list['title']
                    options = <ListOptions list_id={screen_id} setView={setView}/>
                    title_context = <TextInput onSubmit={onEditTitle} placeholder='Edit list...' border initial={list['title']} clearOnSubmit={false}/>
                    
                } else {
                    is_valid = false
                    title = !screen_id ? 'No list selected' : 'Invalid list selected'
                }
                break
            case 'tag':
                const tag = data.tags[screen_id]
                if(tag) {
                    is_valid = true
                    title = tag['title']
                    options = <TagOptions tag_id={screen_id} view={view} setView={setView} options={['direction','edit','color','group','sort','display','filter','delete']}/>
                    title_context = <TextInput onSubmit={onEditTitle} placeholder='Edit tag...' border initial={tag['title']} clearOnSubmit={false}/>
                } else {
                    is_valid = false
                    title = !screen_id ? 'No tag selected' : 'Invalid tag selected'
                }
                break
            case 'google_calendar':
                const gcal = data.integrations[screen_id]
                if(gcal) {
                    is_valid = true
                    title = gcal?.['title']
                    options = <IntegrationOptions integration_id={screen_id} setView={setView}/>
                } else {
                    is_valid = false
                }
                break
        }
        content = <Header>
                    <Pressable key={screen_id} popover={title_context} >
                        <Text type={is_valid ? 'title' : 'placeholder'}>{title}</Text>
                    </Pressable>
                    
                    {is_valid &&
                    <Button
                        key={`${screen_id}_options`}
                        icon='DotsHorizontal'
                        icon_color='var(--on-surface-variant)'
                        context_menu={options}
                        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
                        transformOrigin={{ vertical: 'top', horizontal: 'right' }}
                        />}
                </Header>
        return renderHeader(content)
    }
    
    const header = getHeader()
    const content = getContent()
    useDndMonitor({ onDragStart, onDragOver, onDragEnd })
    return (
        <Container>
            {header}
            <Body>{content}</Body>
        </Container>
    )
}

const Container = styled.div`
    /* border: 1px solid yellow; */
`

const Body = styled.div`
    padding: 0 var(--spacing) var(--spacing) var(--spacing);
`

const Header = styled.div`
    display: grid;
    grid-template-columns: 1fr auto;
    gap: var(--spacing);
    align-items: center;
`

const Row = styled.div`
    display: grid;
    grid-template-columns: auto 1fr;
`

const Wrapper = styled.div`
    display: grid;
    grid-template-columns: repeat(auto-fill, ${props => props.vertical ? '1fr' : COLUMN_WIDTH});
    grid-auto-flow: column;
    overflow-x: ${props => props.vertical ? 'hidden' : 'auto'};
    overflow-y:${props => props.vertical ? 'auto' : 'hidden'};
    border: 0px solid blue;
`

const Placeholder = styled.div`
    border: 0px solid red;
    height: ${props => props.height};
    display: grid;
    grid-template-columns: auto 1fr;
    /* gap: var(--spacing); */
`