import * as actionTypes from '../actions/actionTypes';
import _ from 'lodash'
import { EJSON, ObjectId } from 'bson';
import { api } from 'services'
import moment from 'moment-timezone'

export const watchCalendars = (integration_id, calendars, callback) => {
    return (dispatch, getState) => {
        const data = getState().db.data
        const user = getState().db.user
        const integration = data.integrations[integration_id]
        const client_ids = calendars.map(calendar => calendar['client_id'])
        const current_client_ids = Object.values(_.pickBy(data.calendars, calendar => calendar['integration_id'] === integration_id)).map(calendar => calendar['client_id'])
        let to_watch_calendars = calendars.filter(calendar => _.difference(client_ids, current_client_ids).includes(calendar['client_id']))
        let to_unwatch_calendars = _.difference(current_client_ids, client_ids).map(client_id => _.find(data.calendars, calendar => calendar['client_id'] === client_id))
        let updated_calendars = {...data.calendars}
        let updated_items = {...data.items}

        // 1. Create and watch new calendars
        let calendar_creates = []
        to_watch_calendars.forEach(calendar => {
            const calendar_id = EJSON.deserialize(new ObjectId())
            const new_calendar = {
                created_by: user['user_id'],
                integration_id,
                color: calendar['color'],
                title: calendar['title'],
                client_id: calendar['client_id'],
                calendar_id,
                _id: calendar_id,
                source: 'google_calendar'
            }
            calendar_creates.push(new_calendar)
            updated_calendars[calendar_id] = new_calendar
        })

        // 2. Delete removed calendars
        let calendar_deletes = [] 
        to_unwatch_calendars.forEach(calendar => {
            calendar_deletes.push(calendar['calendar_id'])
            delete updated_calendars[calendar['calendar_id']]
        })

        // 3. Commit
        // if(process.env.REACT_APP_DISABLE_COMMIT === 'true') { 
        //     dispatch({ type: actionTypes.UPDATE_DATA, data: { calendars: updated_calendars, items: updated_items } })
        //     callback?.(true)
        //     return 
        // }
        return Promise.all([
            calendar_creates.length > 0 && api.createCalendars(calendar_creates, user['token']),
            calendar_deletes.length > 0 && api.deleteCalendars({ids: calendar_deletes, created_by: user['user_id']}, user['token'])
        ])
        .then(res => {
            // 3a. Watch new calendars
            const to_watch_calendar_ids = calendar_creates.map(calendar => calendar['calendar_id'])
            const calendar_watches = to_watch_calendar_ids.length > 0 && { user_id: user['user_id'], integration_id, calendars: to_watch_calendar_ids }

            // 3b. Unwatch removed calendars
            const to_unwatch_calendar_ids = calendar_deletes
            const calendar_unwatches = to_unwatch_calendar_ids.length > 0 && { user_id: user['user_id'], integration_id, calendars: to_unwatch_calendar_ids } 

            // 3c. Delete items from removed calendars
            let item_deletes
            if(to_unwatch_calendar_ids.length > 0) {
                item_deletes = { created_by: user['user_id'], calendar_id: { in: to_unwatch_calendar_ids } }
                updated_items = _.pickBy(updated_items, item => !item['calendar_id'] || !to_unwatch_calendar_ids.includes(item['calendar_id'])) // Will cause items to disappear when syncing new calendar if update even when to_unwatch_calendar_ids is empty
            }
            // console.log({calendar_creates, calendar_deletes, calendar_watches, calendar_unwatches, item_deletes, to_unwatch_calendar_ids})            

            // 3d. Delete reminders of removed items
            const reminder_deletes = to_unwatch_calendar_ids.length > 0 && { user_id: user['user_id'], calendar_id: { in: to_unwatch_calendar_ids } }

            return Promise.all([
                calendar_watches && api.watchGcalCalendars(calendar_watches, user['token']),
                calendar_unwatches && api.unwatchGcalCalendars(calendar_unwatches, user['token']),
                item_deletes && api.deleteItems(item_deletes, user['token']),
                reminder_deletes && api.deleteReminders(reminder_deletes, user['token']),
            ])
            .then(watch_res => {
                // console.log('watch completed', updated_calendars)
                dispatch({ type: actionTypes.UPDATE_DATA, data: { calendars: updated_calendars, items: updated_items } })
                callback?.(true)
            })
            .catch(watch_err => {
                // console.log('watch failed', watch_err)
                callback?.(false)
            })
        })
        .catch(err => {
            console.log(err)
        })
        
        
    }
}

export const unlinkGcal = (integration_id, callback) => {
    return (dispatch, getState) => {
        const data = getState().db.data
        const user = getState().db.user
        const calendars = _.pickBy(data.calendars, calendar => calendar['integration_id'] === integration_id)
        const calendar_ids = Object.keys(calendars)
        let updated_items = {...data.items}
        let updated_calendars = {...data.calendars}
        let updated_integrations = {...data.integrations}
        const integration = data.integrations[integration_id]

        // 1. Unwatch calendars
        const calendar_unwatches = { user_id: user['user_id'], integration_id, calendars: calendar_ids } 

        // 2. Delete items
        const item_deletes = { created_by: user['user_id'], calendar_id: { in: calendar_ids } }
        updated_items = _.pickBy(updated_items, item => !calendar_ids.includes(item['calendar_id']))

        // 3. Delete reminders [TODO]
        const reminder_deletes = { user_id: user['user_id'], calendar_id: { in: calendar_ids } }

        // 4. Delete views
        const view_deletes = { ids: [integration['view_id']], created_by: user['user_id'] }

        // 5. Delete calendars
        const calendar_deletes = { ids: calendar_ids, created_by: user['user_id'] }
        updated_calendars = _.pickBy(updated_calendars, calendar => !calendar_ids.includes(calendar['calendar_id']))

        // 6. Delete integration
        const integration_deletes = { integration_id, created_by: user['user_id'] }
        delete updated_integrations[integration_id]        

        // 7. Update user
        const updated_user = {
            ...user, 
            integrations: user['integrations'].filter(x => x !== integration_id),
            last_action: moment().utc().format()
        }

        // 7. Commit
        // console.log({ calendar_unwatches, item_deletes, calendar_deletes, integration_deletes, calendars, view_deletes })
        // if(process.env.REACT_APP_DISABLE_COMMIT === 'true') { 
        //     dispatch({ type: actionTypes.UPDATE_DATA, data: { items: updated_items, calendars: updated_calendars, integrations: updated_integrations } })
        //     dispatch({ type: actionTypes.UPDATE_USER, user: updated_user })
        //     callback?.(true)
        //     return 
        // }

        return Promise.all([
            api.unwatchGcalCalendars(calendar_unwatches, user['token']),
            api.deleteItems(item_deletes, user['token']),
            api.deleteReminders(reminder_deletes, user['token']),
            api.deleteCalendars(calendar_deletes, user['token']),
            api.deleteIntegrations(integration_deletes, user['token']),
            api.deleteViews(view_deletes, user['token']),
            api.updateUser(user['user_id'], _.pick(updated_user, ['integrations','last_action']), user['token'])
        ])
        .then(res => {
            dispatch({ type: actionTypes.UPDATE_DATA, data: { items: updated_items, calendars: updated_calendars, integrations: updated_integrations } })
            dispatch({ type: actionTypes.UPDATE_USER, user: updated_user })
            callback?.(true)
        })
        .catch(err => {
            callback?.(false)
        })
    }
}