import React from 'react'
import styled from 'styled-components'

import { Text, Button, ListTile } from 'components'
import { Radio } from '@mui/material';

const Container = styled.div`
    border-radius: var(--border-radius);
    background-color: var(--surface-container);
    padding: var(--spacing);
    min-width: 15rem;
    display: flex;
    flex-direction: column;
    width: 150%;
`

const RadioSection = styled.div`
    margin-top: var(--spacing-small);
    margin-bottom: var(--spacing);
`

const ButtonSection = styled.div`
    display: flex;
    gap: var(--spacing-small);
    justify-content: flex-end;
    align-items: center;
`

const options = [
    { label: 'This and following items', key: 'current_and_future' },
    { label: 'This item only', key: 'current_only' },
]

export const EditRepeatModal = props => {
    const {
        onCancel,
        onConfirm
    } = props;
    const [selected, setSelected] = React.useState('current_and_future')
    const [loading, setLoading] = React.useState(false)
    const onConfirmHandler = () => {
        setLoading(true)
        onConfirm?.(selected, success => {
            setLoading(false)
        })
    }

    return (
        <Container>
            <Text type='bold' style={{marginLeft: 'var(--spacing-smallest)'}}>Edit repeating item</Text>

            <RadioSection>
                {options.map(values => {
                    const { label, key } = values;
                    const is_selected = key === selected
                    return (
                        <ListTile
                            leading={is_selected ? 'RadioboxMarked' : 'RadioboxBlank'}
                            label={label}
                            onClick={() => setSelected(key)}
                            style={{backgroundColor: 'var(--surface-container)'}}
                            />
                    )
                })}
            </RadioSection>

            <ButtonSection>
                <Button label='Cancel' onClick={onCancel} style={{backgroundColor: 'transparent'}} labelStyles={{color: 'var(--on-surface-variant)'}}/>
                <Button label='Confirm' style={{backgroundColor: 'var(--primary)'}} onClick={onConfirmHandler} loading={loading}/>
            </ButtonSection>
        </Container>
    )
}