export const VIEW_TYPES = {
    list: {
        label: 'List',
        icon: 'ViewListOutline'
    },
    kanban: {
        label: 'Kanban',
        icon: 'ViewColumnOutline'
    }
}
export const view_options = {
    // filter: ['priorities','tags','lists','schedule'],
    filter: ['priorities','tags','lists'], // remove schedule, cause need to handle filter by start/end, create_time, complete_time and archive_time
    group: ['none','sections','lists', 'tags','calendars','priority', 'schedule', 'create_date', 'complete_date', 'archive_date'],
    sort: ['default','priority','start','create_time','update_time','complete_time','archive_time'],
    display: ['list','tags','schedule','repeat','sub_items','create_time','complete_time','archive_time','notes']
}

export const filter_options = {
    priorities: { label: 'Priority', icon: 'FlagOutline', placeholder: 'No priorities set' },
    schedule: { label: 'Schedule', icon: 'CalendarOutline', placeholder: 'No schedule set' },
    tags: { label: 'Tags', icon: 'TagOutline', placeholder: 'No tags set' },
    lists: { label: 'Lists', icon: 'FormatListChecks', placeholder: 'No lists set' },
    status: { label: 'Status', icon: 'CheckAll', placeholder: 'None set' },
}

export const group_options = {
    sections: { label: 'Sections', icon: 'FormatListGroup', subtitle: 'The default option' },
    lists: { label: 'Lists', icon: 'FormatListChecks', subtitle: 'Overview between different projects' },
    tags: { label: 'Tags', icon: 'TagMultipleOutline', subtitle: 'Search by sub defined categories' },
    priority: { label: 'Priority', icon: 'FlagOutline', subtitle: 'Zoom into important items' },
    schedule: { label: 'Schedule', icon: 'CalendarOutline', subtitle: 'By item start/end date' },
    create_date: { label: 'Create date', icon: 'CalendarTextOutline', subtitle: 'Identify old items or newly created ones' },
    complete_date: { label: 'Complete date', icon: 'CalendarCheckOutline', subtitle: 'Review past completed items' },
    archive_date: { label: 'Archive date', icon: 'CalendarImport', subtitle: 'Revisit older archived items' },
    none: { label: 'None', icon: 'Cancel', subtitle: 'Just list everything' },
    calendars: { label: 'Calendars', icon: 'CalendarBlankMultiple', subtitle: 'Differentiate by event origin' }
}

export const sort_options = {
    default: { label: 'None', subtitle: 'Manually sorted' },
    priority: { label: 'Priority', subtitle: 'Highest first' },
    start: { label: 'Schedule', subtitle: 'Latest first' },
    create_time: { label: 'Create time', subtitle: 'Last created first' },
    update_time: { label: 'Update time', subtitle: 'Last updated first' },
    complete_time: { label: 'Complete time', subtitle: 'Last completed first' },
    archive_time: { label: 'Archive time', subtitle: 'Last archived first' },
}

export const display_options = {
    list: { label: 'List', subtitle: 'Item origin', key: 'list' },
    tags: { label: 'Tags', subtitle: 'Selected item tags', key: 'tags' },
    schedule: { label: 'Schedule', subtitle: 'Start & end times', key: 'schedule' },
    repeat: { label: 'Repeat', subtitle: 'Frequency & duration', key: 'repeat' },
    sub_items: { label: 'Sub-item count', subtitle: 'Number of sub-items', key: 'sub_items' },
    create_time: { label: 'Create time', subtitle: 'To identify old & new items', key: 'create_time' },
    complete_time: { label: 'Completed time', subtitle: 'When the item was completed', key: 'complete_item' },
    archive_time: { label: 'Archive time', subtitle: 'When the item was archvied', key: 'archive_time' },
    notes: { label: 'Description', subtitle: 'For quick context, up to 2 lines', key: 'notes' },
    calendar: { label: 'Calendar', subtitle: 'Event origin', key: 'calendar' },
}

export const schedule_options = {
    today: { 
        title: 'Today', subtitle: 'Agenda for today', icon: 'BookClockOutline',
        label: 'Today', key: 'today',
        filter: () => {

        }
    },
    this_week: {
        title: 'This week', subtitle: 'Monday to Sunday', icon: 'CalendarWeek',
        label: 'This week', key: 'this_week',
        filter: () => {

        }
    },
    next_7_days: {
        title: 'Next 7 days', subtitle: 'From today onwards', icon: 'CalendarRange',
        label: 'Next 7 days', key: 'next_7_days',
        filter: () => {

        }
    },
    upcoming: {
        title: 'All upcoming items', icon: 'CalendarClock', subtitle: 'All future items scheduled',
        label: 'All upcoming items', key: 'upcoming',
        filter: () => {

        }
    },
    past: {
        title: 'Past items', icon: 'History', subtitle: 'Before end of today',
        label: 'Past items', key: 'past',
        filter: () => {

        }
    },
    // custom: {
    //     title: 'Custom range', icon: 'calendar-edit', subtitle: 'Not set',
    //     label: 'Custom range', key: 'custom',
    //     filter: () => {

    //     }
    // }
}