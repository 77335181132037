import React from 'react'
import socketIOClient from "socket.io-client";
import moment from 'moment-timezone'
import { useToast } from './useToast';
import { setNoti } from 'utils'

const debug = false
export const useNotifications = () => {
    const { showToast } = useToast()
    // const data = useSelector(state => state.db.data) // Cannot use this cause it will always be null in setNoti
    const [granted, setGranted] = React.useState(false)

    const setupNoti = async (user) => {
        if(!user) { return }
        if(!user.preferences['enable_reminders']) { return }
        const permission = await getPermissions()
        if(permission !== 'granted') { return }
    }

    const getPermissions = async() => {
        if (!("Notification" in window)) {
            debug && console.log("Browser does not support desktop notification");
            return 'unsupported'
        } else {
            return await Notification.requestPermission(result => {
                switch(result) {
                    case 'granted':
                        setGranted(true)
                        break
                    case 'denied':
                        showToast({title: 'Reminders enabled but browser does not allow notifications', subtitle: 'Please update browser permissions for reminders to work'})
                        break
                }
                return result
            });
        }
    }

    return { granted, getPermissions, setupNoti }
}