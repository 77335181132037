import React from 'react'
import styled from 'styled-components'

import { Text, Pressable, Check, Button, EditRepeatModal, TextArea, ConfirmSelector, Collapsible, Modal } from 'components';

import { useSelector, useDispatch } from 'react-redux';
import { useSortable } from '@dnd-kit/sortable';
import { priorities } from 'constants'
import _ from 'lodash'
import * as actions from 'store/actions'
import moment from 'moment-timezone'
import { AppContext } from 'screens';

export const SubitemBlock = props => {
    const {
        item_id,
        disableDND=false,
        onFocus,
        is_focused,
        parent={}
    } = props;
    const dispatch = useDispatch()
    const { module={}, setModule } = React.useContext(AppContext)
    const data = useSelector(state => state.db.data)
    const [loading, setLoading] = React.useState(false)
    const [error, setError] = React.useState(null)
    const [modal, setModal] = React.useState(null)
    const ref = React.createRef()
    const { attributes, listeners, setNodeRef, transform, transition, setActivatorNodeRef, isDragging } = useSortable({
        id: props.id || item_id, // need props.id to prevent duplicate ItemBlocks when DayModule is open with WorkspaceModule
        data: { id: item_id, type: 'item' }, disabled: disableDND
    });

    const item = data.items[item_id]
    const has_repeat = parent && !_.isEmpty(parent['repeat'])
    if(!item) { return }
    const { is_completed, priority } = item
    
    const onCheck = () => {
        onEdit({is_completed: !is_completed})
    }

    const onSubmit = () => {
        const edits = { title: ref.current?.textContent }
        onEdit(edits)
    }

    const reset = () => {
        setModal(null)
        onFocus(false)
        setLoading(false)
    }

    const onEdit = (edits) => {
        setLoading(true)
        if(has_repeat) {
            setModal(
                <EditRepeatModal
                    onConfirm={(selection, modal_callback) => {
                        switch(selection) {
                            case 'current_only':
                                const current_date = moment(parent['start']).utc().format()
                                let parent_edits = {
                                    ..._.pick(parent, ['start','end','is_all_day']), // Need this to ensure the right repeat is used for the branch item
                                    repeat: {
                                        ...parent['repeat'],
                                        exdate: parent['repeat']['exdate'] ? [...parent['repeat']['exdate'], current_date]: [current_date]
                                    }
                                }
                                const children = parent.items.map(child_id => {
                                    if(child_id === item_id) {
                                        return {...data.items[child_id], ...edits}
                                    } else {
                                        return data.items[child_id]
                                    }
                                })
                                parent_edits['children'] = children
                                dispatch(actions.updateItem(parent['item_id'], parent_edits, (success, err_code, updated_item) => {
                                    modal_callback(success)
                                    success && reset()
                                    !success && setError('Something went wrong, please try again')
                                    setModule({target: 'ItemModule', extinfo: { item_id: updated_item['item_id'], type: 'module' }})

                                }))
                                break
                            case 'current_and_future':
                                dispatch(actions.updateItem(item_id, edits, (success, err_code) => {
                                    modal_callback(success)
                                    success && reset()
                                    !success && setError('Something went wrong, please try again')
                                }))
                                break
                        }
                    }}
                    onCancel={reset}
                    />
            )
        } else {
            dispatch(actions.updateItem(item_id, edits, (success, err_code) => {
                success && reset()
                !success && setError('Something went wrong, please try again')
            }))
        }
    }
    
    const onDelete = (callback) => {
        if(has_repeat) {
            setModal(
                <EditRepeatModal
                    onConfirm={(selection, modal_callback) => {
                        switch(selection) {
                            case 'current_only':
                                const current_date = moment(parent['start']).utc().format()
                                let parent_edits = {
                                    ..._.pick(parent, ['start','end','is_all_day']), // Need this to ensure the right repeat is used for the branch item
                                    repeat: {
                                        ...parent['repeat'],
                                        exdate: parent['repeat']['exdate'] ? [...parent['repeat']['exdate'], current_date]: [current_date]
                                    }
                                }
                                const children = parent.items.filter(child_id => child_id !== item_id).map(child_id => {
                                    return data.items[child_id]
                                })
                                parent_edits['children'] = children
                                dispatch(actions.updateItem(parent['item_id'], parent_edits, (success, err_code, updated_item) => {
                                    modal_callback(success)
                                    success && reset()
                                    !success && setError('Something went wrong, please try again')
                                    setModule({target: 'ItemModule', extinfo: { item_id: updated_item['item_id'], type: 'module' }})
                                }))
                                break
                            case 'current_and_future':
                                dispatch(actions.deleteItem(item_id, success => {
                                    callback?.(success)
                                    success && reset()
                                }))
                                break
                        }
                    }}
                    onCancel={reset}
                    />
            )
        } else {
            dispatch(actions.deleteItem(item_id, success => {
                callback?.(success)
                success && reset()
            }))
        }
    }

    if(is_focused) {
        return (
            <Container is_focused={is_focused}>
                <Check 
                    is_selected={is_completed}
                    color={`var(${priorities[priority]['color_key']})`}
                    selectedColor={`var(${priorities[priority]['color_key']})`}
                    onClick={onCheck}
                    />
                <TextSection>
                    <TextArea
                        ref={ref}
                        initial={item['title']} 
                        style={{lineHeight: '1.2rem'}} 
                        onSubmit={onSubmit}
                        onClose={() => onFocus(false)}
                        auto_focus={true}
                        />
                    <Collapsible collapsed={!error}>
                        <Text type='error'>{`*${error}`}</Text>
                    </Collapsible>
                </TextSection>
    
                <ButtonSection style={{opacity: 1}}>
                    <Button 
                        icon='Close' 
                        icon_color='var(--on-surface-variant)' 
                        style={{backgroundColor: 'var(--surface-container)'}}
                        onClick={() => onFocus(false)}
                        tooltip={{title: 'Cancel', shortcut: 'esc'}}
                        />
                    <Button 
                        icon='CheckBold' 
                        icon_color='var(--on-primary)' 
                        style={{backgroundColor: 'var(--primary)'}}
                        onClick={onSubmit}
                        loading={loading}
                        tooltip={{title: 'Edit', shortcut: 'enter'}}
                        />
                </ButtonSection>
                <Modal open={!!modal} onClose={reset}>{modal || <div/>}</Modal>
            </Container>
        )
    } else {
        return (
            <Container>
                <Check 
                    is_selected={is_completed}
                    color={`var(${priorities[priority]['color_key']})`}
                    selectedColor={`var(${priorities[priority]['color_key']})`}
                    onClick={onCheck}
                    />
                <Pressable onClick={() => onFocus?.(true)}>
                    <Text type={item['is_completed'] && 'strikethrough'}>{item['title']}</Text>
                </Pressable>

                <ButtonSection>
                    <Button 
                        icon='TrashCanOutline' 
                        onClick={onDelete} // Will have issue if use ConfirmSelector, the parent prop doesn't seem to be updated
                        style={{backgroundColor: 'var(--surface-container)'}}
                        icon_color='var(--on-surface-variant)'
                        />
                    <DragHandle ref={setActivatorNodeRef} {...listeners} disableDND={disableDND}>
                        <Button 
                            icon='Drag' 
                            icon_color='var(--on-surface-variant)' 
                            style={{backgroundColor: 'transparent'}}/>
                    </DragHandle>
                </ButtonSection>
                <Modal open={!!modal} onClose={reset}>{modal || <div/>}</Modal>
            </Container>
        )
    }
    
}

const Container = styled.div`
    display: grid;
    grid-template-columns: auto 1fr auto;
    align-items: center;
    justify-content: center;
    padding: ${props => props.is_focused ? 'var(--spacing-small)' : 'var(--spacing-smallest) var(--spacing-small)'};
    border-radius: var(--border-radius);
    gap: var(--spacing);
    background-color: ${props => props.is_focused ? 'var(--surface-container)' : 'transparent'};
    border: ${props => `1px solid ${props.is_focused ? 'var(--outline)' : 'transparent'}`};
    transition: all 0.3s ease; 
    &:hover {
        background-color: var(--surface-container);
    }
`

const TextSection = styled.div`
    display: flex;
    flex-direction: column;
    gap: var(--spacing-smallest)
`

const ButtonSection = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    opacity: 0;
    transition: opacity 0.3s ease; 
    ${Container}:hover & {
        opacity: ${props => props.disableDND ? 0 : 1};
    }
`

const DragHandle = styled.div`
    cursor: move;
    display: flex;
`