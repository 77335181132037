import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';

import { Text, Button, ListTile, Spinner } from 'components'
import GcalIcon from 'resources/icons/google_calendar_icon.png'
import { api } from 'services'
import _ from 'lodash'
import * as actions from 'store/actions'

const Container = styled.div`
    padding: var(--spacing-large);
    border-radius: var(--border-radius);
    width: 30rem;
    height: 40rem;
    display: flex;
    flex-direction: column;
    gap: var(--spacing);
    background-color: var(--surface);
`

const OptionsContainer = styled.div`
    background-color: var(--surface-container);
    border-radius: var(--border-radius);
    padding: var(--spacing-small);
    overflow-y: auto;
    border: 1px solid var(--outline-variant);
    flex-grow: 1;
`

const Row = styled.div`
    display: flex;
    align-items: center;
    gap: var(--spacing);
`

const Column = styled.div`
    display: flex;
    flex-direction: column;
`

const ButtonContainer = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: var(--spacing-small);
`

export const CalendarSelectorModal = props => {
    const { 
        integration_id,
        close
    } = props;
    const dispatch = useDispatch()
    const user = useSelector(state => state.db.user)
    const data = useSelector(state => state.db.data)
    const integration = data.integrations[integration_id] || {}
    const [options, setOptions] = React.useState(null)
    const [selection, setSelection] = React.useState([])
    const [loading, setLoading] = React.useState(false)
    const { type, title } = integration

    
    React.useEffect(() => {
        api.getGcalCalendars({integration_id, user_id: user['user_id']})
        .then(res => {
            const new_options = _.sortBy(res.data, 'etag').reverse().map(calendar => {
                return {
                    color: calendar['backgroundColor'],
                    client_id: calendar['id'],
                    title: calendar['summary'],
                }
            })
            setOptions(new_options)
            // setSelection([res.data.filter(calendar => calendar['primary'])[0]['id']])

            let default_selection = []
            const selected_calendars = Object.values(_.pickBy(data.calendars, calendar => calendar['integration_id'] === integration_id)).map(calendar => calendar['client_id'])
            const primary_calendar_id = res.data.filter(calendar => calendar['primary'])[0]['id']
            default_selection = selected_calendars.length > 0 ? selected_calendars : []
            setSelection(default_selection)
        })
    }, [])

    const onSubmit = () => {
        setLoading(true)
        dispatch(actions.watchCalendars(integration_id, options.filter(option => selection.includes(option['client_id'])), success => {
            setLoading(false)
            close?.()
        }))
    }

    const onCancel = () => {
        close?.()
    }

    const getBlocks = () => {
        let output = []

        options && options.forEach(option => {
            const { title, color, client_id } = option
            const is_selected = selection.includes(client_id)
            output.push(
                <ListTile
                    label={title}
                    trailing={is_selected && 'Check'}
                    trailingColor='var(--on-surface-variant)'
                    leading='SquareRounded'
                    leading_color={color || 'var(--on-surface-variant)'}
                    leading_size={0.6}
                    style={{backgroundColor: 'var(--surface-container)'}}
                    onClick={() => {
                        setSelection(is_selected ? selection.filter(x => x !== client_id) : [...selection, client_id])
                    }}
                    />
            )
        })

        if(output.length === 0 && !options) {
            output.push(
                <Row style={{gap: 'var(--spacing-small)', padding: 'var(--spacing-small)'}}>
                    <Spinner color='var(--on-surface-variant)' size='1rem'/>
                    <Text type='placeholder'>Retrieving calendars</Text>
                </Row>
            )
        }

        return output
    }

    const blocks = getBlocks()

    return (
        <Container>
            <Row style={{padding: 'var(--spacing-small)'}}>
                <img src={GcalIcon} style={{width: '1.5rem'}}/>
                <Column>
                    <Text type='bold'>Select calendars to sync</Text>
                    <Text type='subtitle'>{title}</Text>
                </Column>
            </Row>
            
            <OptionsContainer>
                {blocks}
            </OptionsContainer>

            <ButtonContainer>
                <Button 
                    label='Select' 
                    onClick={onSubmit} 
                    style={{backgroundColor: 'var(--primary)', width: 'auto', justifyContent: 'center'}}
                    loading={loading}
                    />
                <Button 
                    label='Cancel' 
                    onClick={onCancel}  
                    style={{backgroundColor: 'var(--surface)', width: 'auto', justifyContent: 'center'}}
                    labelStyles={{color: 'var(--on-surface-variant)'}}
                    disabled={loading}
                    />
            </ButtonContainer>
        </Container>
    )
}

