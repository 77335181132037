import React from 'react';
import styled from 'styled-components';
import {createPortal} from 'react-dom';

import { DndContext, DragOverlay, defaultDropAnimation, PointerSensor, useSensor, useSensors } from '@dnd-kit/core';
import { SortableContext, verticalListSortingStrategy, horizontalListSortingStrategy } from '@dnd-kit/sortable';
import { Sortable } from './Sortable'

import _ from 'lodash'

const Container = styled.div`
    overflow: ${props => props.horizontal ? 'auto hidden' : 'hidden auto'};
`

export const SortableList = props => {
    const {
        data=[],
        renderItem,
        horizontal=false,
        renderDragOverlay,
        renderDragPlaceholder,
        drag_handle=false,
        onDragEnd
    } = props;
    const [active_id, setActive] = React.useState(null)
    const [view_data, setView] = React.useState(data)
    const [initial_data, setInitial] = React.useState(data)

    React.useEffect(() => {
        setView(data)
        setInitial(data)
    }, [data])

    const onDragEndHandler= ({active, over}) => {
        if(!active || !over) { return setActive(null) }
        const { id: over_id, type: over_type, sortable } = over.data.current
        const { index:to } = sortable

        if(over_id === active_id) { return setActive(null) }

        let output = []
        const flattened = _.flatten(view_data)
        const target_item = _.find(flattened, x => x['id'] === active_id)
        const over_item = _.find(flattened, x => x['id'] === over_id)
        if(over_item['type'] !== target_item['type']) { return setActive(null) }
        view_data.forEach(items => {
            const ids = items.map(x => x['id'])
            const is_origin = ids.includes(active_id)
            const is_destination = ids.includes(over_id)

            let updated_items = [...items]
            if(is_origin) {
                updated_items = updated_items.filter(x => x['id'] !== active_id)
            } 

            if(is_destination) {
                updated_items.splice(to, 0, target_item)
            }
            output.push(updated_items)
        })
        setView(output)
        onDragEnd({items: output, target: target_item, over: over_item}, success => {
            !success && setView(initial_data)
        })
        setActive(null)
    }

    const onDragStart = ({active}) => {
        setActive(active.id)
    }

    const onRenderItem = values => {
        const { id, type, disabled } = values
        const is_active = active_id === id
        return (
            <Sortable id={id} type={type} disabled={disabled} drag_handle={drag_handle}>
                {is_active && renderDragPlaceholder ? renderDragPlaceholder?.(values) : renderItem?.(values)}
            </Sortable>
        )
    }

    const onRenderDragOverlay = () => {
        const flatten_data = _.flatten(view_data)
        const target = _.find(flatten_data, x => x['id'] === active_id)
        return renderDragOverlay?.(target)
    }

    const sections = view_data && view_data.map(column_data => {
        return (
            <SortableContext items={column_data} strategy={verticalListSortingStrategy}>
                {column_data.map(x => onRenderItem(x))}
            </SortableContext>
        )
    })

    return (
        <Container horizontal={horizontal}>
            <DndContext 
                onDragEnd={onDragEndHandler} 
                onDragStart={onDragStart}
                sensors={useSensors(
                    useSensor(PointerSensor, {
                        activationConstraint: {
                          delay: 300,
                          tolerance: 0
                        }
                      })
                )}
                >
                {sections}
                {/* {renderDragOverlay &&
                <DragOverlay dropAnimation={defaultDropAnimation}>
                    {onRenderDragOverlay()}
                </DragOverlay>} */}
                {renderDragOverlay &&
                createPortal(
                    <DragOverlay dropAnimation={defaultDropAnimation}>
                        {onRenderDragOverlay()}
                    </DragOverlay>,
                    document.body
                )}
            </DndContext>
        </Container>
    )
}