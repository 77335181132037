import React from 'react';
import styled from 'styled-components';

import { ContextMenu } from '../options'


import { display_options } from 'constants'
import _ from 'lodash'

const Container = styled.div`

`

export const DisplaySelector = props => {
    const {
        selected=[],
        options=Object.keys(display_options),
        onSelect
    } = props;
    
    const getOptions = () => {
        let output = {}
        options.forEach(option => {
            const is_selected = selected.includes(option)
            output[option] = {
                ...display_options[option],
                trailing: is_selected && 'Check',
                onClick: () => {
                    let new_selection = is_selected ? [...selected].filter(x => x !== option) : [...selected, option]
                    new_selection = _.intersection(options, new_selection)
                    onSelect?.(new_selection, (success,err_msg) => {
                        success && props.close()
                    })
                }
            }
        })

        return output
    }

    const data = getOptions()
    return (
        <ContextMenu
            options={options}
            data={data}
            />
    )
}