import React from 'react';
import styled from 'styled-components';

import { ListTile, TextInput, Pressable, Icon, Text } from 'components'
import { ContextMenu } from './ContextMenu'
import { GroupSelector, SortSelector, FilterSelector, DisplaySelector } from '../selectors'

import { VIEW_TYPES, sort_options as _sort_options, group_options } from 'constants'; 
import _ from 'lodash'

export const ViewOptions = props => {
    const {
        options=['direction','group','sort','display','filter'], // delete, archive,
        sort_options=['default','priority','start','create_time','update_time'],
        filter_options=['priorities','tags','schedule','lists']
    } = props;
    const [view, setView] = React.useState(props.view)

    const onEditView = (edits, callback) => {
        let updated_view = {...view, ...edits}
        props.setView?.(updated_view)
        setView(updated_view)
    }

    const getFilterSubtitle = () => {
        let output = []

        Object.values(['priorities','schedule','tags']).forEach(key => {
            if(key === 'schedule' && view[key]) {
                output.push(key)
            } else if (['priorities','tags'].includes(key) && view[key]?.length > 0) {
                output.push(key)
            }
        })

        if(output.length > 0) {
            output = output.join(', ')
            return output.charAt(0).toUpperCase() + output.slice(1)
        } else {
            return 'None selected'
        }
    }

    return (
        <ContextMenu
            options={options}
            data={{
                direction: {
                    content: <Row style={{backgroundColor: 'var(--surface)', borderRadius: 'var(--border-radius)', padding: 'var(--spacing-small)', margin: 'var(--spacing-small)'}}>
                                {['list','kanban'].map(view_type => {
                                    const { label, icon } = VIEW_TYPES[view_type]
                                    const is_selected = view_type === view['type']
                                    const color = !is_selected ? 'var(--on-surface-variant)' : 'var(--on-surface)'
                                    return (
                                        <Pressable 
                                            onClick={() => onEditView({ type: view_type })} 
                                            style={{
                                                backgroundColor: is_selected ? 'var(--surface-container)' : 'var(--surface)',
                                                padding: 'var(--spacing-small)', 
                                                borderRadius: 'var(--border-radius)',
                                                border: is_selected && '1px solid var(--outline-variant)',
                                            }}>
                                            <div style={{display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center'}}>
                                                <Icon icon={icon} color={color}/>
                                                <Text style={{color}}>{label}</Text>
                                            </div>
                                        </Pressable>
                                    )
                                })}
                            </Row>
                },
                sort: {
                    label: 'Sort',
                    icon: 'Sort',
                    trailing:'ChevronRight',
                    subtitle: _sort_options[view['sort'] || 'default']['label'],
                    popper: <SortSelector options={sort_options} selected={view['sort'] || 'default'} onSelect={(new_sort, callback) => onEditView({ sort: new_sort }, callback)}/>,
                },
                display: {
                    label: 'Labels',
                    icon: 'LabelMultipleOutline',
                    trailing:'ChevronRight',
                    subtitle: `${view['display']?.length > 0 ? view['display'].length : 'None'} selected`,
                    popper: <DisplaySelector onSelect={(new_display) => onEditView({ display: new_display })} selected={view['display']}/>
                },
                filter: {
                    label: 'Filter',
                    icon: 'FilterOutline',
                    trailing:'ChevronRight',
                    subtitle: getFilterSubtitle(),
                    popper: <FilterSelector options={filter_options} onSelect={(new_filters) => onEditView(new_filters)} selected={_.pick(view, ['priorities','schedule','tags','start','end'])}/>
                },
                group: {
                    label: 'Group',
                    icon: 'GridLarge',
                    trailing:'ChevronRight',
                    subtitle: group_options[view['group']]?.['label'],
                    divider: true,
                    popper: <GroupSelector onSelect={(new_group) => onEditView({ group: new_group })} selected={view['group']}/>
                },
            }}
            />
    )
}

const Row = styled.div`
    display: grid;
    grid-template-columns: 1fr 1fr;
    align-items: center;
`