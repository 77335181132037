import React from 'react';
import styled from 'styled-components';
import { useDispatch, useSelector, userDispatch } from 'react-redux';

import { Text, Button, TextInput, ItemBlock, ViewOptions, ListTile } from 'components'
import { Virtuoso } from 'react-virtuoso'

import { buildView, getLoadQuery } from 'utils'
import { useHotkeys } from 'react-hotkeys-hook';
import { SHORTCUTS } from 'constants'
import { AppContext } from 'screens';
import _ from 'lodash'
import * as actions from 'store/actions'

const Container = styled.div`
    display: grid;
    grid-template-rows: auto 1fr;
    height: 100vh;
`

const Divider = styled.div`
    border-bottom: 1px solid var(--outline-variant);
    width: 100%;
    margin: var(--spacing) 0;
`

const ShortcutsContainer = styled.div`

`

const { shortcuts, actions:shortuct_actions } = SHORTCUTS['SearchModule']
export const SearchModule = props => {
    const {

    } = props;
    const dispatch = useDispatch()
    const data = useSelector(state => state.db.data)
    const user = useSelector(state => state.db.user)
    const loaded_dates = useSelector(state => state.db.loaded_dates)
    const [view, setView] = React.useState({
        display: ['sub_items','list','schedule','complete_time','tags']
    })
    const [highlight, setHighlight] = React.useState(null)
    const [end_reached, setEndReached] = React.useState(false)
    const [page, setPage] = React.useState(null)
    const [loading, setLoading] = React.useState(false)
    const { module={}, setModule } = React.useContext(AppContext)
    const ref = React.createRef()
    const items_ref = React.createRef()
    useHotkeys(shortcuts, (event, handler) => {
        const key = [...Object.keys(_.pickBy(handler, x => x === true)), ...handler.keys].join('+')
        const action = shortuct_actions[key]
        switch(action){
            case 'Next item':
                items_ref?.current?.length > 0 &&
                setHighlight(highlight === null ? 0 
                            : highlight === items_ref.current.length - 1 ? 0
                            : highlight + 1)
                break
            case 'Previous item':
                items_ref?.current?.length > 0 &&
                setHighlight(highlight === null ? items_ref.current.length - 1
                            : highlight === 0 ? items_ref.current.length - 1
                            : highlight - 1)
                break
            case 'Select item':
                const item_id = items_ref.current?.[highlight]
                if(item_id) {
                    setModule(null)
                    setTimeout(() => {
                        setModule({target: 'ItemModule', extinfo: { item_id, type: 'module' }})
                    }, 300)
                }
                break
        }
    }, { enableOnFormTags: ['INPUT'] });

    React.useEffect(() => {
        setTimeout(() => {
            ref.current?.focus()
        },300) 
    },[])

    const onView = (edits) => {
        let updated_view = {...view, ...edits}
        setEndReached(false)
        setView(updated_view)
    }

    const onLoad = () => {
        setLoading(true)
        const query = getLoadQuery(view ,user, loaded_dates, page)
        dispatch(actions.loadData(user, 'by_pages', query, (success, end_reached, loaded_page) => {
            loaded_page && setPage(loaded_page)
            end_reached && setEndReached(true)
            setLoading(false)
        }))
    }

    const renderItem = (values, index) => {
        const { type, id, defaults={} } = values
        switch(true) {
            case type === 'item':
                return (
                    <Wrapper is_highlighted={highlight === index}>
                        <ItemBlock item_id={id} labels={view['display']} disableDND={true} disableHandle={true}/>
                    </Wrapper>
                )
            case type === 'load_more':
                if(end_reached) {
                    return <div/>
                } else {
                    return (
                        <Button
                            label='Load more'
                            loading={loading}
                            onClick={onLoad}
                            labelStyles={{color: 'var(--on-surface-variant)'}}
                            style={{backgroundColor: 'var(--surface-container)', width: 'auto', border: '1px solid var(--outline-variant)', margin: 'var(--spacing-small) 0'}}
                            />
                    )
                }
            default:
                return <div>{id}</div>
        }
       
    }

    const getSymbol = shortcut => {
        switch(shortcut){
            case 'ArrowRight':
                return '→'
            case 'ArrowLeft':
                return '←'
            case 'ArrowUp':
                return '↑'
            case 'ArrowDown':
                return '↓'
            default:
                return shortcut
        }
    }

    const getShortcuts = () => {
        let output = []
        for (const [key, value] of Object.entries(SHORTCUTS)) {
            const { title, shortcuts, actions } = value
            output.push(<Text type='bold' style={{marginLeft: 'var(--spacing-small)'}}>{title}</Text>)

            shortcuts.forEach(shortcut => {
                const action = actions[shortcut]
                const shortcut_symbol = getSymbol(shortcut)
                action && output.push(
                    <ListTile
                        label={<Text style={{color: 'var(--on-surface-variant)'}}>{action}</Text>}
                        trailing={<Text type='code'>{shortcut_symbol}</Text>}
                        disabled={true}
                        style={{padding: 'var(--spacing-smallest) var(--spacing-small)'}}
                        />
                )
            })

            output.push(<Divider/>)
        }
        return <ShortcutsContainer>{output}</ShortcutsContainer>
    }

    const getContent = () => {
        const { search } = view
        if(!search || search === '') {
            return getShortcuts()
        } else {
            const blocks = buildView(view, data, user, view['type'] === 'list', view['type'] === 'kanban')
            const output = !end_reached ? [...blocks[0], { type: 'load_more' }] : blocks[0]
            items_ref.current = output.map(x => x['type'] === 'item' && x['id'])
            if(output.length === 0) {
                return <Text type='placeholder' style={{marginLeft: 'var(--spacing-small)'}}>No available items</Text>
            } else {
                return (
                    <Virtuoso
                        data={output}
                        itemContent={(i) => renderItem(output[i],i)}
                        computeItemKey={(i) => {
                            const { id, type } = output[i]
                            return `${id}_${type}`
                        }}
                        totalCount={output.length}
                        style={{height: 'calc(100vh - 6rem)', overflowX: 'hidden'}} // - 5rem for the header -1rem for scrollbar
                        />
                )
            }
        }
    }

    const content = getContent()
    return (
        <Container>
            <Header>
                <TextInput 
                    ref={ref}
                    placeholder='Search' submit_icon='Magnify'
                    auto_focus={false}
                    border={false} clearOnSubmit={false} closeOnSubmit={false} disableSpinner={true}
                    onChange={search => onView({search})}
                    onClose={() => ref.current?.blur()}
                    trailing={
                        <Button key={`${view['search']}_options`}
                            icon='DotsHorizontal' icon_color='var(--on-surface-variant)'
                            style={{backgroundColor: 'var(--surface-container)'}}
                            context_menu={
                                <ViewOptions  
                                    view={view} setView={onView} 
                                    options={['sort','display','filter']} 
                                    sort_options={['priority','start','create_time','update_time','complete_time','archive_time']}
                                    filter_options={['priorities','tags','schedule','lists','status']}
                                    />}
                            anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
                            transformOrigin={{ vertical: 'top', horizontal: 'right' }}
                            />
                    }
                    />
                
            </Header>
            <Body>{content}</Body>
        </Container>
    )
}

const Header = styled.div`
    display: grid;
    grid-template-columns: 1fr auto;
    gap: var(--spacing-smallest);
    align-items: center;
    padding: var(--spacing);
    padding-right: 0;
`

const Body = styled.div`
    padding-left: var(--spacing);
    margin-bottom: var(--spacing);
    overflow: hidden auto;
`

const Wrapper = styled.div`
    border-radius: var(--border-radius);
    background-color: ${props => props.is_highlighted ? 'var(--surface-container)' : 'transparent'};
`