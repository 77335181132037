import React from 'react';
import styled from 'styled-components';

import { Text } from 'components'
import moment from 'moment';

const Container = styled.div`
    border: 1px solid red;
    position: absolute;
    word-wrap: break-word;
    overflow: hidden;
    text-overflow: ellipsis;
    height: 1rem;
    top: 0;
`



export const CalendarEvent = props => {
    const {
        event,
        title
    } = props;
    const { isAllDay, start, end } = event

    if(!isAllDay && moment(end).diff(moment(start),'minutes') <= 15) {
        return (
            <Container>
                 <div>{title}</div>
            </Container>
        )
    }
    return (
        <div>{title}</div>
    )
}