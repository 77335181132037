import React from 'react';
import styled from 'styled-components';
import { useNavigate } from 'react-router-dom'

import { EvervaultCard, Button, Text } from 'components'

const Container = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100vh;
    overflow: hidden;
    /* border: 1px solid red; */
`

const Wrapper = styled.div`
    /* background-color: rgba(24, 24, 26, 0.5); */
    /* background-color: var(--surface); */
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    /* padding: var(--spacing); */
    border-radius: var(--border-radius);
    gap: var(--spacing);
`

export const ErrorScreen = props => {
    const {

    } = props;
    const navigate = useNavigate()

    return (
        <Container>
            <EvervaultCard>
                <Wrapper>
                    <Text type='banner' style={{maxWidth: '50vw'}}>Oops! Something went wrong</Text>
                    <Button 
                        label='Send me back'
                        trailing='ArrowULeftTop'
                        onClick={() => navigate('/loading')} 
                        style={{ backgroundColor: 'var(--primary)', padding: 'var(--spacing-small) var(--spacing)'}}
                        labelStyles={{fontWeight: 'bold', fontSize: '1.5rem'}}
                         />
                </Wrapper>
            </EvervaultCard>
        </Container>
    )
}