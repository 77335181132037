import { api } from 'services'
import _ from 'lodash'
import moment from 'moment-timezone'
import { store } from 'store'
import { getNotiPermission } from './notifications'
import { setupSocket } from './sockets'
import * as actions from 'store/actions'

export const getPagination = async ({target, query, token}) => {
	let response
	switch(target){
		case 'views':
			response = await api.getViews(query, token)
			break
		case 'lists':
			response = await api.getLists(query, token)
			break
		case 'sections':
			response = await api.getSections(query, token)
			break
		case 'items':
			response = await api.getItems(query, token)
			break
		case 'tags':
			response = await api.getTags(query, token)
			break
        case 'integrations':
            response = await api.getIntegrations(query, token)
			break
        case 'calendars':
            response = await api.getCalendars(query, token)
            break
	}
    if (response.status !== 200) {
        throw new Error("HTTP error " + response.status);
    }
    const {results, page, limit, totalPages, totalResults} = await response.data;

    if (page < totalPages) {
        results.push(...await getPagination({target, query: {...query, page: page + 1}, token}));
    }
    return results;
};

export const initialLoad = async (user, filter) => {
    const { token } = user
    let data = {
        lists: {},
        sections: {},
        items: {},
        tags: {},
        views: {},
    }

    const start = performance.now()
    return Promise.all(['views','lists','sections','items','tags','integrations','calendars'].map(async target => {
        let query = {}
        if(filter) {
            query = filter
        } else {
            switch(target) {
                case 'items':
                    query = {
                        created_by: user['user_id'],
                        is_completed: false,
                        sortBy: 'create_time:desc',
                    }
                    break
                default: 
                    query = { created_by: user['user_id'], sortBy: 'create_time:desc' }
                    break
            }
        }
        return await getPagination({target, query, token}).then(res => { 
            const output = _.keyBy(res, '_id')
            data[target] = {...output}
        })
    }))
    .then(res => {
        process.env.REACT_APP_ENV === 'test' && console.log(`[initialLoad] Download took ${((performance.now() - start)/1000).toFixed(2)}s. ${Object.keys(data['items']).length} items`);
        return {...data, user}
    })
    .catch(err => {
        console.log(err)
        return 
    })
}

export const loadByDates = async(user, start, end) => {
    if(!start || !end || !user) { return }
    const { token, user_id } = user;
    let output;
    const query = {
        created_by: user_id,
        is_completed: true,
        complete_time: { gte: start, lte: end }
    }
    const initial = performance.now()
    return getPagination({ target: 'items', query, token })
    .then(res => {
        output = _.keyBy(res, '_id')
        process.env.REACT_APP_ENV === 'test' && console.log(`[loadByDates] Download took ${((performance.now() - initial)/1000).toFixed(2)}s. ${res.length} items`);
        return output
    })
    .catch(err => {
        return
    })
}

export const loadByPages = async(user, query) => {
    const { token } = user
    const response = await api.getItems(query, token)
    const {results, page, limit, totalPages, totalResults} = await response.data;
    return { loaded: _.keyBy(results, '_id'), end_reached: page >= totalPages, page }
}

export const getLoadQuery = (view, user, loaded_dates, page) => {
    let query = {}
    for (const [key, value] of Object.entries(_.pick(view, ['search','priorities','tags','lists']))) {
        switch(key) {
            case 'search':
                if(value && value !== '') {
                    query['title'] = { regex: value, options: 'i' }
                }
                break
            case 'priorities':
                if(value && value.length > 0) {
                    query['priority'] = { in: value }
                }
                break
            case 'tags':
                if(value && value.length > 0) {
                    query['tags'] = { in: value }
                }
                break
            case 'lists':
                if(value && value.length > 0) {
                    query['list_id'] = value
                }
                break
            default:
                query[key] = value
        }
    }
    if(_.isEmpty(query)) {
        query = {
            created_by: user['user_id'],
            is_completed: true,
            sortBy: 'complete_time:desc',
            complete_time: { gte: user['create_time'], lte: _.min(loaded_dates) },
        }
    } else {
        query = {
            ...query,
            created_by: user['user_id'],
            is_completed: true,
            sortBy: 'complete_time:desc',
            page: page ? page + 1 : null
        }
        if(!page) { delete query['page'] }
    }
    return query
}

export const onUserLoad = async() => {
    const data = store.getState().db.data
    const user = store.getState().db.user
    if(!data || !user) { return }
    const calendars = data.calendars

    // 1. Check for outdated calendars
    let outdated_calendars = _.pickBy(calendars, calendar => calendar['expiration'] && moment(calendar['expiration']).isBefore(moment()))
    if(!_.isEmpty(outdated_calendars) && user['is_pro']) {
        const outdated = _.groupBy(Object.values(outdated_calendars), calendar => calendar['integration_id'])
        for (const [integration_id, calendars] of Object.entries(outdated)) {
            const calendar_ids = calendars.map(calendar => calendar['calendar_id'])
            const calendar_watches = { user_id: user['user_id'], integration_id, calendars: calendar_ids }
            api.watchGcalCalendars(calendar_watches, user['token'])
            .then(res => {
                process.env.REACT_APP_ENV === 'test' && console.log(`calendars rewatched`, calendar_watches)
            })
            .catch(err => {
                console.log(err)
            })
        }
    }

    // 2. Setup notifications
    user?.preferences?.['enable_reminders'] && getNotiPermission(user)

    // 3. Setup sockets
    setupSocket(user)

    // 4. Downgrade user
    const grace_period = 1
    const need_downgrade = user['is_pro'] && moment(user['pro_expiry']).add(grace_period,'day').isBefore(moment(), 'second')
    if(need_downgrade) {
        store.dispatch(actions.updateUser({...user, is_pro: false}, success => console.log('user downgraded')))
    }

    // 5. Set timezone
    if(!user.preferences['timezone']) {
        const timezone = moment.tz.guess()
        store.dispatch(actions.updateUser({preferences: {...user['preferences'], timezone}}, success => console.log(`timezone updated to ${timezone}`)))
    }
    moment.tz.setDefault(user?.preferences?.['timezone'] || moment.tz.guess());
}

export const getLocalLastAction = () => {
    const data = store.getState().db.data
    const keys = ['views','lists','sections','items','tags','integrations','calendars']
    let dates = []
    keys.forEach(key => {
        const objects = Object.values(data[key])
        const last_createAt = _.max(objects.map(x => x['createdAt']))
        const last_updateAt = _.max(objects.map(x => x['updatedAt']))
        const last_update_time = _.max(objects.map(x => x['update_time'])) 
        dates.push(last_createAt)
        dates.push(last_updateAt)
        dates.push(last_update_time)
    })
    return _.max(dates)
}