import React from 'react'
import styled from 'styled-components'

import { Button } from '../base'

export const NumberInput = props => {
    const {
        initial=0,
        min=0,
        max,
        onChange,
        step=1
    } = props;
    const [value, setValue] = React.useState(initial)

    const onChangeHandler = new_value => {
        onChange?.(new_value)
        setValue(new_value)
    }

    return (
        <Container>
            <Input
                type="number"
                min={min}
                max={max}
                step={step}
                value={value}
                onChange={e => onChangeHandler(parseInt(e.target.value))}
                />
            <ButtonSection>
                <Button icon='ChevronUp' 
                    icon_size={0.5} 
                    icon_color={'var(--on-surface-variant)'}
                    style={{padding: 0, backgroundColor: 'transparent'}} 
                    onClick={() => {
                        const new_value = value + 1
                        const is_valid = !max || new_value <= max
                        is_valid && onChangeHandler(new_value)
                    }}/>
                <Button icon='ChevronDown' 
                    icon_size={0.5} 
                    icon_color={'var(--on-surface-variant)'}
                    style={{padding: 0, backgroundColor: 'transparent'}} 
                    onClick={() => {
                        const new_value = value - 1
                        new_value >= min && onChangeHandler(new_value)
                    }}/>
            </ButtonSection>
        </Container>
    )
}

const Container = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    border: 1px solid var(--outline-variant);
    border-radius: var(--border-radius);
`

const Input = styled.input`
    background-color: transparent;    
    outline: none;
    color: var(--on-surface);
    width: 2rem;
    border: none;
    padding: var(--spacing-small);
`

const ButtonSection = styled.div`
    padding: 0 var(--spacing-small);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
`