import React from 'react';
import styled from 'styled-components';

import { ListTile, Pressable, Text } from '../base'
import { Virtuoso } from 'react-virtuoso'

import moment from 'moment-timezone';
import { eachMinuteOfInterval } from 'date-fns'
import { useSelector } from 'react-redux';
import _ from 'lodash'

const Container = styled.div`
    padding: var(--spacing-smallest);
    height: calc(100% - 3rem);
`

const TabContainer = styled.div`
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    justify-content: space-between;
    padding-bottom: var(--spacing-small);
    margin-bottom: var(--spacing-small);
    border-bottom: 1px solid var(--outline-variant);
`

const getMinutes = (time, frequency) => {
    const minutes = eachMinuteOfInterval({
        start: moment(time).startOf('day').toDate(),
        end: moment(time).endOf('day').toDate(),
    })
    const minute_options = minutes.filter(x => moment(x).minute()%frequency === 0)
    return minute_options
}

export const TimeSelector = props => {
    const {
        initial=moment(),
        max,
        min,
        onSelect
    } = props;
    const user = useSelector(state => state.db.user)
    const { date_format, time_format } = user.preferences
    const [value, setValue] = React.useState(initial)
    const [frequency, setFrequency] = React.useState(15)
    const [options, setOptions] = React.useState(() => {
        return getMinutes(initial, frequency)
    })
    const ref = React.useRef(null)

    React.useEffect(() => {
        // const target_index = _.findIndex(options, x => moment(x).isSame(moment(value),'minute'))
        const filtered = _.filter(options, x => moment(x).isSameOrAfter(moment(value),'minute'))
        const target_index = options.indexOf(filtered[0])
        setTimeout(() => [
            target_index > -1 && ref.current && ref.current.scrollToIndex({ index: target_index, align: 'center', behavior: 'smooth' })
        ], 300)
    }, [frequency])

    React.useEffect(() => {
        const new_options = getMinutes(initial, frequency)
        setOptions(new_options)
        setValue(initial)
    }, [initial])

    const renderItem = (option) => {
        const label = moment(option).format(time_format)
        const is_valid = max ? moment(option).isBefore(moment(max), 'minute')
                        : min ? moment(option).isAfter(moment(min), 'minute')
                        : true
        return (
            <ListTile
                label={label}
                trailing={moment(option).isSame(moment(value),'minute') && 'Check'}
                textStyle={{ color: is_valid ? 'var(--on-surface)' : 'var(--on-surface-variant)' }}
                style={{ backgroundColor: 'var(--surface-container)' }}
                onClick={() => {
                    setValue(moment(option))
                    onSelect?.(moment(option))
                }}
                disabled={!is_valid}
                />
        )
    }

    const renderContent = () => {
        const initial_index = _.findIndex(options, x => moment(x).isSame(moment(value),'minute'))
        return (
            <Virtuoso 
                ref={ref}
                data={options}
                initialTopMostItemIndex={initial_index > 0 ? initial_index : 0}
                itemContent={(i) => renderItem(options[i])}
                computeItemKey={(i) => {
                    return moment(options[i]).format(`${date_format} ${time_format}`)
                }}
                totalCount={options.length}
                style={{height: '100%'}}
                />
        )
    }

    const getTabs = () => {
        const options = [15, 10, 5]
        let output = []
        options.forEach((option, index) => {
            output.push(
                <Pressable 
                    onClick={() => {
                        const new_options = getMinutes(initial, option)
                        setOptions(new_options)
                        setFrequency(option)
                    }}
                    style={{ borderLeft: index > 0 && '1px solid var(--outline-variant)', width: '100%', display: 'flex', justifyContent: 'center' }}
                    >
                    <Text style={{ color: frequency === option ? 'var(--on-surface)' : 'var(--on-surface-variant)', textAlign: 'center' }}>{`${option}m`}</Text>
                </Pressable>
            )
        })

        return <TabContainer>{output}</TabContainer>
    }

    const content = renderContent()
    const tabs = getTabs()
    return (
        <Container>
            {tabs}
            {content}
        </Container>
    )
}