import React from 'react';
import styled from 'styled-components';

const Container = styled.div`
    border: 1px solid red;
    position: absolute;
`

export const CalendarEventWrapper = props => {
    const {

    } = props;
    const { top, xOffset, width, height } = props.children.props.style
    return (
        <Container style={{top: `${top}%`, left: `${xOffset}%`, width: `${width}%`, height: `${height}%`}}>
            {props.children}
        </Container>
    )
}