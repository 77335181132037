import React from 'react';
import styled from 'styled-components';
import { useSelector } from 'react-redux';

import { Text, Icon, Button, ListTile, Collapsible } from '../base'
import { useParams } from 'react-router-dom'
import { useToast } from 'hooks';
import { api } from 'services'
import { STRIPE_PRICES, PLAN_OPTIONS } from 'constants';

const plan_options = {
    free: { 
        title: 'Free', 
        subtitle: 'The start of your productivity journey', 
        price: 0, 
        features_title: 'The Free plan includes:',
        features: [
            { label: 'Up to 100 items' },
            { label: 'Calendar' },
            { label: 'Drag and drop time blocking' },
            { label: 'Daily overview' },
            { label: 'Tags' }
            
        ]
    },
    pro: { 
        title: 'Pro', 
        subtitle: 'Unlock a new level of personal productivity',
        price: 4 ,
        features_title: 'Everything in Free, plus:',
        features: [
            { label: 'Unlimited items' },
            { label: 'Reminders', subtitle: 'Available on iOS also' },
            { label: 'Recurring items' },
            { label: 'Google Calendar sync' },
        ],
    },
}

export const PlanBlock = props => {
    const {
        type='free',
        frequency='monthly',
        upgrade=false
    } = props;
    const { id: outcome } = useParams()
    const { title, subtitle, price, features_title, features=[] } = PLAN_OPTIONS[`${type}${type === 'pro' ? `_${frequency}` : ''}`]
    const [loading, setLoading] = React.useState(false)
    const { showToast } = useToast()
    const user = useSelector(state => state.db.user)

    React.useEffect(() => {
        outcome === 'success' && showToast({title: '🎉 Welcome on board!', subtitle: "Cheers to scaling up your day"})
    }, [])

    const onStripe = async() => {
        if(!upgrade) { return window.open('/login','_self')}
        setLoading(true)
        const apple_url = 'https://apps.apple.com/account/subscriptions'
        const {data: portal_url} = user?.['stripe_id'] && await api.getStripePortal({ stripe_id: user['stripe_id'] })
        const {data: checkout_url} = await api.getStripeCheckout({ user_id: user?.['user_id'], price_id: STRIPE_PRICES[`${frequency}`], trial: !user?.['pro_expiry'] })
        const url = user?.['stripe_id'] ? portal_url 
                    : user?.['is_pro'] && !user['stripe_id'] ? apple_url
                    : checkout_url
        console.log({url, user})
        // url && window.location.replace(url);
        setLoading(false)
    }

    return (
        <Container>
            <Text style={{fontWeight: 'bold', fontSize: '1.5rem', color: 'var(--on-surface-variant)'}}>{title}</Text>
            <Text style={{fontWeight: 'bold', fontSize: '3rem', lineHeight: '3rem'}}>{`USD ${price}`}</Text>
            <Text type='placeholder'>{`per month`}</Text>
            <Collapsible collapsed={frequency !== 'yearly'}>
                <Text style={{color: type === 'free' && 'transparent'}}>That’s $36 a year!</Text>
            </Collapsible>

            <Divider/>

            <Text type='bold' style={{marginBottom: 'var(--spacing)'}}>{features_title}</Text>
            <Section>
                {features.map(feature => {
                    const { label, subtitle } = feature
                    return <ListTile label={label} subtitle={subtitle} leading='Check' style={{backgroundColor: 'transparent', padding: 0}} disabled={true}/>
                })}
            </Section>
            {type === 'pro' && 
            <Button 
                label={
                    user?.['is_pro'] || user?.['stripe_id'] ? 'Manage subscription' 
                    : !user?.['is_pro'] && user?.['stripe_id'] ? 'Upgrade today!'
                    : 'Free trial for 30 days!'} 
                onClick={onStripe} 
                style={{ backgroundColor: 'var(--primary-container)', marginTop: '1rem', borderRadius: '3rem', border: '2px solid var(--primary)', width: '100%', justifySelf: 'flex-end', boxSizing: 'inherit' }} 
                labelStyles={{fontWeight: 'bold'}}
                loading={loading}
                />}
        </Container>
    )
}

const Container = styled.div`
    border-radius: var(--border-radius);
    padding: 2rem;
    background-color: var(--surface-container);
    display: flex;
    flex-direction: column;
    gap: var(--spacing-smallest);
    /* align-items: center; */
    min-width: 23rem;
    height: 100%;
    border: 1px solid var(--outline-variant);
`

const Divider = styled.div`
    border-bottom: 1px solid var(--outline-variant);
    width: 100%;
    margin: 2rem 0;
`

const Section = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
    gap: var(--spacing);
`