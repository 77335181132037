export const spacing = {
    // '--font-size': 16,
    // '--title-size': 20,
    // '--subtitle-size': 14,
    // '--padding-small': 4,
    // '--padding': 8,
    // '--padding-large': 12,
    // '--margin-small': 4,
    // '--margin': 8,
    // '--margin-large': 12,
    // '--container-padding': 20,
    '--spacing': '16px',
    '--spacing-small': '0.5rem',
    '--spacing-smallest': '0.25rem',
    '--spacing-large': '1.5rem',
    '--border-radius': '5px',
    '--calendar-time-slot-height': 12
}

export const typography = {
    '--font-family': 'Space Grotesk',
    '--display-large-font': 'Space Grotesk',
    '--display-large-lineHeight': 64,
    '--display-large-fontSize': 57,
    '--display-large-letterSpacing': -0.25,
    '--display-large-fontWeight': 400,
    '--display-medium-font': 'Space Grotesk',
    '--display-medium-lineHeight': 52,
    '--display-medium-fontSize': 45,
    '--display-medium-letterSpacing': 0,
    '--display-medium-fontWeight': 400,
    '--display-small-font': 'Space Grotesk',
    '--display-small-lineHeight': 44,
    '--display-small-fontSize': 36,
    '--display-small-letterSpacing': 0,
    '--display-small-fontWeight': 400,
    '--headline-large-font': 'Space Grotesk',
    '--headline-large-lineHeight': 40,
    '--headline-large-fontSize': 32,
    '--headline-large-letterSpacing': 0,
    '--headline-large-fontWeight': 400,
    '--headline-medium-font': 'Space Grotesk',
    '--headline-medium-lineHeight': 36,
    '--headline-medium-fontSize': 28,
    '--headline-medium-letterSpacing': 0,
    '--headline-medium-fontWeight': 400,
    '--headline-small-font': 'Space Grotesk',
    '--headline-small-lineHeight': 32,
    '--headline-small-fontSize': 24,
    '--headline-small-letterSpacing': 0,
    '--headline-small-fontWeight': 400,
    '--title-large-font': 'Space Grotesk',
    '--title-large-lineHeight': 28,
    '--title-large-fontSize': 22,
    '--title-large-letterSpacing': 0,
    '--title-large-fontWeight': 400,
    '--title-medium-font': 'Space Grotesk',
    '--title-medium-lineHeight': 24,
    '--title-medium-fontSize': 16,
    '--title-medium-letterSpacing': 0.15,
    '--title-medium-fontWeight': 500,
    '--title-small-font': 'Space Grotesk',
    '--title-small-lineHeight': 20,
    '--title-small-fontSize': 14,
    '--title-small-letterSpacing': 0.1,
    '--title-small-fontWeight': 500,
    '--label-large-font': 'Space Grotesk',
    '--label-large-lineHeight': 20,
    '--label-large-fontSize': 14,
    '--label-large-letterSpacing': 0.1,
    '--label-large-fontWeight': 500,
    '--label-medium-font': 'Space Grotesk',
    '--label-medium-lineHeight': 16,
    '--label-medium-fontSize': 12,
    '--label-medium-letterSpacing': 0.5,
    '--label-medium-fontWeight': 500,
    '--label-small-font': 'Space Grotesk',
    '--label-small-lineHeight': 16,
    '--label-small-fontSize': 11,
    '--label-small-letterSpacing': 0.5,
    '--label-small-fontWeight': 500,
    '--body-large-font': 'Space Grotesk',
    '--body-large-lineHeight': 24,
    '--body-large-fontSize': 16,
    '--body-large-letterSpacing': 0.5,
    '--body-large-fontWeight': 400,
    '--body-medium-font': 'Space Grotesk',
    '--body-medium-lineHeight': 20,
    '--body-medium-fontSize': 14,
    '--body-medium-letterSpacing': 0.25,
    '--body-medium-fontWeight': 400,
    '--body-small-font': 'Space Grotesk',
    '--body-small-lineHeight': 16,
    '--body-small-fontSize': 12,
    '--body-small-letterSpacing': 0.4,
    '--body-small-fontWeight': 400,
}