import React from 'react';
import styled from 'styled-components';

import { Text } from './Text'

import { getShadeColor, rgbaToHex } from 'utils'

const Container = styled.div`
    background-color: ${props => props.color};
    border-radius: var(--border-radius);
    padding: var(--spacing-smallest) var(--spacing-small);
    border: ${props => `1px solid ${props.color}`};
    display: flex;
    align-items: center;
    justify-content: center;
`

export const Chip = props => {
    const {
        color='var(--surface-container)',
        label,
    } = props;
    let target = color
    if(target.includes('var')) {
        target = target.replace('var(','').replace(')','')
    }
    if(target.includes('--')) {
        target = document.documentElement.style.getPropertyValue(target)
    }
    if(target.includes('rgb')) {
        target = rgbaToHex(target)
    }

    return (
        <Container color={getShadeColor(target,1,0.2)}>
            <Text style={{fontSize: '0.9rem', color}}>{label}</Text>
        </Container>
    )
}