import React from 'react'
import styled from 'styled-components'

import { ListTile, Labels, IntegrationOptions } from 'components'
import GcalIcon from 'resources/icons/google_calendar_icon.png'

import { useSelector } from 'react-redux'
import moment from 'moment-timezone'
import { AppContext } from 'screens';
import { isMobile } from 'react-device-detect';
import { useNavigate } from 'react-router-dom';

export const IntegrationBlock = props => {
    const {
        integration_id,
        type,
        options=['group','sort','display','filter','calendars',,'unlink']
    } = props;
    const navigate = useNavigate()
    const { setModule, setNavigation } = React.useContext(AppContext)
    const user = useSelector(state => state.db.user)
    const { date_format, time_format } = user.preferences
    const data = useSelector(state => state.db.data)
    const integration = data.integrations[integration_id]
    if(!integration) { return }
    const { title, type: integration_type, last_sync_time, create_time } = integration
    
    let style = props.style
    let labels = []
    let icon_size = '1rem'
    let trailing
    let onClick
    switch(type) {
        case 'detailed':
            style = {...props.style, border: '1px solid var(--outline-variant)', padding: 'var(--spacing)', gap: 'var(--spacing)', zIndex: 1600}
            labels = [
                { label: `Linked ${moment(create_time).format(`${date_format} YYYY`)}` },
                last_sync_time && { label: `Last synced ${moment(last_sync_time).format(`${date_format} ${time_format}`)}` }
            ]
            icon_size = '1.5rem'
            trailing = 'DotsHorizontal'
            break
        default:
            onClick= () => {
                navigate(`/${integration_type}/${integration_id}`)
                setModule(null)
                isMobile && setNavigation(false)
            }
            break
    }
    return (
        <ListTile 
            key={integration_id}
            label={title}
            subtitle={<Labels labels={labels}/>}
            style={style}
            leading={<img src={GcalIcon} style={{width: icon_size}}/>}
            onClick={onClick}
            trailing={trailing}
            popover={<IntegrationOptions integration_id={integration_id} options={options}/>}
            anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'left',
            }}
            transformOrigin={{
                vertical: 'top',
                horizontal: 'left',
            }}
            />
    )
}

const Container = styled.div`
    padding: var(--spacing-small);
    border-radius: var(--border-radius);
`