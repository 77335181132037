import React from 'react'
import styled from 'styled-components'

import { Text, Button, ContextMenu, Collapsible, Pressable, DateSelector, Spinner } from 'components'

import moment from 'moment-timezone'

const Container = styled.div`
    display: grid;
    grid-template-columns: 1fr auto;
    align-items: center;
`

const Column = styled.div`
    display: flex;
    flex-direction: column;
`

const Section = styled.div`
    display: flex;
    align-items: center;
`

const CalendarOptions = props => {
    const {
        view,
        views,
        onSelectView
    } = props;
    const onSelectViewHandler = (new_view) => {
        onSelectView?.(new_view)
        props.close?.()
    }

    return (
        <ContextMenu
            options={views}
            style={{width: '13rem'}}
            data={{
                month: {
                    label: 'Month',
                    icon: 'CalendarMonth',
                    trailing: view === 'month' && 'Check',
                    onClick: () => onSelectViewHandler?.('month')
                },
                week: {
                    label: 'Week',
                    icon: 'CalendarWeek',
                    trailing: view === 'week' && 'Check',
                    onClick: () => onSelectViewHandler?.('week')
                },
                day: {
                    label: 'Day',
                    icon: 'ViewDayOutline',
                    trailing: view === 'day' && 'Check',
                    onClick: () => onSelectViewHandler?.('day')
                },
            }}
            />
    )
}

export const CalendarHeader = props => {
    const {
        view,
        date,
        views=['month','week','day'],
        onSelectView,
        onSelectDate,
        loading
    } = props;

    return (
        <Container>
            <Pressable 
                anchorReference='anchorEl'
                popover={
                    <DateSelector 
                        style={{backgroundColor: 'var(--surface-container)', border: '1px solid var(--outline-variant)', padding: 'var(--spacing-small)', borderRadius: 'var(--border-radius)'}}
                        initial={date}
                        onSelect={onSelectDate}
                        />
                }>
                <Column>
                    <Text type='bold'>{moment(date).format('MMMM YYYY')}</Text>
                    {view !== 'month' && <Text type='subtitle'>{view === 'week' ? `Week ${moment(date).format('w')}` : moment(date).format('ddd D')}</Text>}
                </Column>
            </Pressable>
            <Section>
                {loading && <Spinner size={'1rem'} margin={'0 var(--spacing)'} color={'var(--on-surface-variant)'}/>}
                {views.length > 1 && <Button key={view}
                    label={view.charAt(0).toUpperCase() + view.slice(1)}
                    context_menu={<CalendarOptions key={view} view={view} onSelectView={onSelectView} views={views}/>}
                    trailing='ChevronDown'
                    style={{
                        backgroundColor: 'var(--surface-container)', 
                        padding: 'var(--spacing-smallest) var(--spacing-small) var(--spacing-smallest) 12px',
                        gap: 0,
                        marginRight: 'var(--spacing-smallest)', 
                        border: '1px solid var(--outline-variant)'
                    }}
                    anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'right',
                      }}
                      transformOrigin={{
                        vertical: 'top',
                        horizontal: 'right',
                      }}
                    />}
                <Button
                    icon='ChevronLeft'
                    icon_color='var(--on-surface-variant)'
                    tooltip={{title: `Previous ${view}`, shortcut: '←'}}
                    onClick={() => onSelectDate?.(moment(date).subtract(1,`${view}s`))}
                    />
                <Button
                    icon='ChevronRight'
                    icon_color='var(--on-surface-variant)'
                    tooltip={{title: `Next ${view}`, shortcut: '→'}}
                    onClick={() => onSelectDate?.(moment(date).add(1,`${view}s`))}
                    />
            </Section>
        </Container>
    )
}