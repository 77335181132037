import React from 'react';
import styled from 'styled-components';
import { useSelector, useDispatch } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';

import { Button, Text } from 'components'
import { ItemModule } from './ItemModule'
import { CalendarModule } from './CalendarModule'
import { DayModule } from './DayModule'
import { SearchModule } from './SearchModule'
import { SettingsModule } from './SettingsModule'
import { TestModule } from './TestModule'
import { motion, AnimatePresence } from "framer-motion";

import { AppContext } from 'screens';
import { useToast } from 'hooks';
import { isMobile } from 'react-device-detect';
import { SHORTCUTS } from 'constants';

const { shortcut_keys } = SHORTCUTS['ContentModule']
const ref = {
    ItemModule: {
        icon: 'FileDocumentOutline',
        tooltip: 'New item',
        shortcut: shortcut_keys['New item']
    },
    CalendarModule: {
        icon: 'CalendarMonthOutline',
        content: <CalendarModule views={['day','month']}/>,
        tooltip: 'Calendar',
        shortcut: shortcut_keys['Calendar']
    },
    DayModule: {
        icon: 'ViewDayOutline',
        content: <DayModule/>,
        tooltip: 'Day',
        shortcut: 'shift+d',
        shortcut: shortcut_keys['Day']
    },
    SearchModule: {
        icon: 'Magnify',
        content: <SearchModule/>,
        tooltip: 'Search',
        shortcut: shortcut_keys['Search']
    },
    NotificationsModule: {
        icon: 'BellOutline',
        tooltip: 'Notifications'
    },
    SettingsModule: {
        icon: 'CogOutline',
        content: <SettingsModule/>,
        tooltip: 'Settings',
        shortcut: shortcut_keys['Settings']
    },
    TestModule: {
        icon: 'CodeJson',
        content: <TestModule/>,
        tooltip: 'Test'
    }
}

const module_ref = {
    list: process.env.REACT_APP_ENV === 'test' ? ['ItemModule','CalendarModule','DayModule','SearchModule','SettingsModule','TestModule'] : ['ItemModule','CalendarModule','DayModule','SearchModule','SettingsModule'],
    calendar: ['ItemModule','DayModule','SearchModule','SettingsModule'],
    past: ['ItemModule','CalendarModule','DayModule','SearchModule','SettingsModule']
}

const Container = styled.div`
    border-left: 1px solid var(--outline-variant);
    height: 100vh;
    display: flex;
    flex-direction: row;
`

const Column = styled.div`
    display: flex;
    flex-direction: column;
    gap: var(--spacing-small);
    background-color: var(--surface);
    padding: var(--spacing-small);
    padding-top: var(--spacing);
    z-index: 999; // Neede to prevent side module content from covering buttons
`

const Wrapper = styled.div`
    position: absolute;
    width: ${props => props.width};
    height: 100vh;
`

export const SideModule = props => {
    const {
        width=!isMobile ? '30rem' : 'calc(100vw - 3rem)'
    } = props;
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const { showToast } = useToast()
    const { type, id } = useParams()
    const data = useSelector(state => state.db.data)
    const user = useSelector(state => state.db.user)
    const { module, setModule } = React.useContext(AppContext)
    let modules = module_ref[type] || ['ItemModule','CalendarModule','DayModule','SearchModule','SettingsModule']
    if(!user) { return }

    const getModule = () => {
        let output = <div/>
        const { target, extinfo } = module || {};
        switch(target){
            case 'ItemModule':
                output = <ItemModule {...extinfo} key={extinfo?.item_id}/>
                break
            case 'CalendarModule':
                output = <CalendarModule views={['day','month']}/>
                break
            default:
                output = ref[target] && ref[target]['content']
                break
        }

        return output
    }

    const getOptions = () => {
        let output = []
        const { target } = module || {};
        modules.forEach(key => {
            const { icon, tooltip, shortcut } = ref[key]
            const is_selected = target === key
            output.push(
                <Button
                    icon={icon}
                    icon_color={is_selected ? 'var(--primary)' : 'var(--on-surface-variant)'}
                    tooltip={{title: tooltip, shortcut, placement:'left'}}
                    onClick={() => {
                        const extinfo = { defaults: { list_id : type === 'list' ? id || user['inbox_id'] : user['inbox_id'], tags: type === 'tag' ? [id] : [] } }
                        if(is_selected) {
                            setModule(null)
                        } else if (!is_selected && target) {
                            setModule(null)
                            setTimeout(() => {
                                setModule({ target: key, extinfo })
                            }, 300)
                        } else {
                            setModule?.(is_selected ? null : { target: key, extinfo })
                        }
                        
                    }}
                    />
            )
        })
        

        return output
    }

    const content = getModule()
    const options = getOptions()
    return (
        <Container>
            <AnimatePresence>
                {content && 
                <motion.div
                    initial="collapsed"
                    animate="open"
                    exit="collapsed"
                    transition={{ duration: 0.3, ease: [0.04, 0.62, 0.23, 0.98] }}
                    variants={{ open: { width, zIndex: 1 }, collapsed: { width: 0, zIndex: -1 } }}
                    >
                    <Wrapper width={width}>{content}</Wrapper>
                </motion.div>}
            </AnimatePresence>
            <Column>{options}</Column>
        </Container>
    )
}
