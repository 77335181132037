import * as actionTypes from '../actions/actionTypes';
import _ from 'lodash'

const updateObject = (oldObject, updatedProperties) => {
    return {
        ...oldObject,
        ...updatedProperties
    };
};

const initialState = {
    loaded_dates: [],
    user: null,
    data: {
        // lists: {},
        // sections: {},
        // items: {},
        // tags: {},
        // views: {},
        // reminders: {},
        // integrations: {}
    },
    new_integration_id: null,
    socket: null
};

const reducer = ( state = initialState, action ) => {
    let updatedState = {...state}

    switch ( action.type ) {
        case actionTypes.LOAD_DATA:
            return updateObject( state, { 
                user: action.user,
                data: action.data,
                loaded_dates: action.loaded_dates || []
            })
        case actionTypes.UPDATE_THEME:
            return updateObject(state, {theme: action.theme}) 
        case actionTypes.SET_PREFERENCES:
            return updateObject(state, {preferences: {...state.preferences, ...action.preferences}}) 
        case actionTypes.UPDATE_DATA:
            return updateObject(state, { data: !action.data ? null : {...state.data, ...action.data} })
        case actionTypes.UPDATE_USER:
            return updateObject(state, { user: action.user })
        case actionTypes.NEW_INTEGRATION:
            return updateObject(state, { new_integration_id: action.new_integration_id })
        case actionTypes.SET_SOCKET:
            return updateObject(state, { socket: action.socket })
        default:
            return updateObject(state)
    }
};

export default reducer;