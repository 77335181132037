// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAuth, GoogleAuthProvider, OAuthProvider, signInWithPopup } from "firebase/auth";

// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
    apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
    authDomain: "app.dayscaled.com",
    projectId: "dayscaled-prod",
    storageBucket: "dayscaled-prod.appspot.com",
    messagingSenderId: "996625098473",
    appId: "1:996625098473:web:cc273e739b6849f5d97278",
    measurementId: "G-QN8YYLK8LS"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const auth = getAuth(app);

export const googleLogin = async(callback) => {
    const provider = new GoogleAuthProvider();
    provider.setCustomParameters({ prompt : "select_account" });
    provider.addScope('email')
    
    return signInWithPopup(auth, provider)
    .then(res => {
        callback?.(true)
        return res
    })
    .catch(err => {
        console.log(err)
        callback?.(false)
        return
    })
}

export const appleLogin = async(callback) => {
    const provider = new OAuthProvider('apple.com');
    provider.addScope('email');
    provider.addScope('name');

    signInWithPopup(auth, provider)
    .then(res => {
        callback?.(true)
        return res
    })
    .catch(err => {
        callback?.(false)
        return
    })
}