import React from 'react';
import styled from 'styled-components';

import { ContextMenu } from '../options'

import { group_options } from 'constants'
import _ from 'lodash'

export const GroupSelector = props => {
    const {
        selected='sections',
        options=_.pullAll(Object.keys(group_options), ['complete_date','archive_date']),
        onSelect
    } = props;

    const getOptions = () => {
        let output = {}
        options.forEach(option => {
            const is_selected = selected === option
            output[option] = {
                ...group_options[option],
                trailing: is_selected && 'Check',
                onClick: () => {
                    onSelect?.(option, (success,err_msg) => {
                        success && props.close()
                    })
                }
            }
        })

        return output
    }

    const data = getOptions()
    return (
        <ContextMenu
            options={options}
            data={data}
            />
    )
}