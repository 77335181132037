import React from 'react'
import styled from 'styled-components'

import { Text, Button, IntegrationBlock, Icon, CalendarSelectorModal, Modal } from 'components'

import { useDispatch, useSelector } from 'react-redux'
import GcalIcon from 'resources/icons/google_calendar_icon.png'
import { api } from 'services'
import { popupCenter } from 'utils'
import { useToast } from 'hooks';
import { useNavigate } from 'react-router-dom'

const OptionBlock = props => {
    const {
        title,
        subtitle,
        value,
        icon,
        close
    } = props;
    const user = useSelector(state => state.db.user)
    const { showToast } = useToast()
    const navigate = useNavigate()

    const onClick = async() => {
        if(!user['is_pro']) {
            showToast({title: "You've discovered a pro feature!", subtitle: 'Click here to find out more', onClick: () => {
                navigate('/upgrade')
                close?.(null)
            }})
        } else {
            switch(value){
                case 'google_calendar':
                    const res = await api.getGcalAuthLink({user_id: user['user_id']})
                    popupCenter({url: res.data})
                    break
            }
        }
    }

    return (
        <OptionContainer onClick={onClick}>
            <Row style={{alignItems: 'flex-start'}}>
                <img src={icon} style={{width: '1.5rem'}}/>
                <div style={{display: 'flex', gap: 'var(--spacing-small)'}}>
                    <Icon icon='Link'/>
                    <Text>Link</Text>
                </div>
            </Row>
             
            <div>
                <Text type='bold'>{title}</Text>
                {/* <Text type='subtitle'>{subtitle}</Text> */}
            </div>
        </OptionContainer>
    )
}

export const LinkCalendarModal = props => {
    const {
        close
    } = props;
    const dispatch = useDispatch()
    const user = useSelector(state => state.db.user)
    const data = useSelector(state => state.db.data)
    const new_integration_id = useSelector(state => state.db.new_integration_id)
    const [modal, setModal] = React.useState(null)
    // const [modal, setModal] = React.useState(<CalendarSelectorModal integration_id={"66b5bd222cbba87f827750d0"} close={() => setModal(null)}/>)

    React.useEffect(() => {
        new_integration_id && setModal(
            <CalendarSelectorModal 
                integration_id={new_integration_id} 
                close={() => {
                    setModal(null)
                    dispatch({ type: 'NEW_INTEGRATION', new_integration_id: null })
                }}
                />
        )
    }, [new_integration_id])

    const getOptions = () => {
        let output = []
        const options = [
            { title: 'Google Calendar', subtitle: 'Some subtitle', value: 'google_calendar', icon: GcalIcon }
        ]
        options.forEach(option => {
            const { title, subtitle, value, icon } = option
            output.push(<OptionBlock {...option} close={close}/>)

        })
        return output
    }

    const getAccounts = () => {
        let output = []
        user['integrations']?.forEach(integration_id => {
            output.push(<IntegrationBlock integration_id={integration_id} type='detailed' options={['calendars','unlink']}/>)
            // output.push(<div>{integration_id}</div>)
        })

        if(output.length === 0) {
            output.push(<Text type='placeholder'>None connected</Text>)
        }

        return output
    }

    const options = getOptions()
    const accounts = getAccounts()
    
    return (
        <Container>
            <Row>
                <div style={{paddingTop: 'var(--spacing-small)'}}>
                    <Text type='title'>Calendars</Text>
                    <Text style={{color: 'var(--on-surface-variant)'}}>Link your accounts to import and sync all your events into Dayscaled</Text>
                </div>

                <Button
                    icon='Close'
                    onClick={close}
                    />
            </Row>
           
           <OptionSection>{options}</OptionSection>

           <Text type='bold'>Connected calendars</Text>
           <AccountSection>{accounts}</AccountSection>
           <Modal open={!!modal} onClose={() => setModal(null)} zIndex={1300}>{modal || <div/>}</Modal> 
        </Container>
    )
}

const Container = styled.div`
    padding: var(--spacing-large);
    border-radius: var(--border-radius);
    min-width: 50vw;
    min-height: 70vh;
    background-color: var(--surface);
    z-index: 1600;
`

const Row = styled.div`
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
`

const OptionSection = styled.div`
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    margin: var(--spacing) 0;
`

const AccountSection = styled.div`
    margin-top: var(--spacing-smallest);
    display: flex;
    flex-direction: column;
    gap: var(--spacing-small);
`

const OptionContainer = styled.div`
    padding: var(--spacing);
    border-radius: var(--border-radius);
    border: 1px solid var(--outline);
    width: 20rem;
    gap: var(--spacing-small);
    cursor: pointer;
    display: flex;
    flex-direction: column;
`