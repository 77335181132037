import React from 'react';
import { useSelector, userDispatch } from 'react-redux';

import { Text, Button, ItemOptions } from 'components'
import { CalendarDateCellWrapper } from './CalendarDateCellWrapper'; 
import { CalendarTimeSlotWrapper } from './CalendarTimeSlotWrapper'; 
import { CalendarEventWrapper } from './CalendarEventWrapper'
import { CalendarEventContainerWrapper } from './CalendarEventContainerWrapper'
import { CalendarEvent } from './CalendarEvent'
import Popover, { popoverClasses } from '@mui/material/Popover';

import moment from 'moment-timezone'
import { useDroppable, useDndMonitor } from '@dnd-kit/core';
import { priorities } from 'constants'
import { getShadeColor } from 'utils'
import _ from 'lodash'

import { Calendar, momentLocalizer } from "react-big-calendar";
import withDragAndDrop from "react-big-calendar/lib/addons/dragAndDrop";
import "./Calendar.css";
const DnDCalendar = withDragAndDrop(Calendar);
const localizer = momentLocalizer(moment)

export const CalendarContext = React.createContext({});

export const CustomCalendar = props => {
    const {
        step=15,
        renderSlotInput,
        renderEventInput,

        onSelectSlot,
        onSelectEvent,
        onReschedule,
        onDrillDown,
        events,
        selection=[],
        date=moment(),
        view='day'
    } = props;
    const user = useSelector(state => state.db.user)
    const data = useSelector(state => state.db.data)
    const { default_schedule_duration } = user['preferences']
    const [selected, setSelected] = React.useState(selection)
    const [isDraggingOver, setDragOver] = React.useState(false)
    const [limitAllDay, toggleAllDay] = React.useState(false)
    const [anchorPosition, setAnchorPosition] = React.useState(null)
    const [slotInput, setSlotInput] = React.useState(null)

    React.useEffect(() => {
        resetSelection()
    }, [events.length])

    const getEvents = () => {
        if(view === 'month') {
            return events
        } else if (!limitAllDay) {
            return events
        } else {
            let filtered = [...events.filter(x => !x['allDay'])]
            const all_day_dates = _.uniq(events.filter(x => x['allDay']).map(x => moment(x['start']).format('YYYY-MM-DD')))
            const all_day_events = events.filter(x => x['allDay'])
            all_day_dates.forEach(target_date => {
                let filtered_events = all_day_events.filter(x => moment(x['start']).format('YYYY-MM-DD') === target_date)
                if(filtered_events.length > 2) {
                    filtered_events = filtered_events.slice(0,2)
                }
                filtered.push(...filtered_events)
            })
            return filtered
        }
    }

    const eventPropGetter = (event, start, end, isSelected) => {
        const { allDay, resource } = event
        const { item_id, style:item_style } = resource
        let style = { 
            backgroundColor: 'var(--surface-container)', 
            padding: view === 'month' ? '0 var(--spacing-smallest)' : 'var(--spacing-smallest) var(--spacing-small)',
            borderRadius: 'var(--border-radius)',
            outline: 'none',
            border: '1px solid var(--outline-variant)',
            borderLeft: '5px solid var(--outline)',
        }

        if(item_id && data.items[item_id]) {
            const item = data.items[item_id]
            const color = document.documentElement.style.getPropertyValue(`${priorities[item['priority']]['color_key']}`)
            // const backgroundColor = getShadeColor(color,1,1,0.7)
            const backgroundColor = user.preferences['is_dark'] ? getShadeColor(color,1,1,0.7) : getShadeColor(color,0.8,1,1)
            const borderLeft = `5px solid ${color}`
            const outline = isSelected ? `1px solid ${color}` : 'none'
            const opacity = item['is_completed'] ? 0.6 : 1
            style = {...style, color, backgroundColor, borderLeft, outline, opacity, ...item_style}
        } 

        return { style }
    }

    ////////////////////
    // Event handlers //
    ////////////////////
    const onEventSelect = (event) => {
        selected.length > 0 && resetSelection()
        onSelectEvent?.(event)
        if(renderEventInput) {
            const event_input = renderEventInput?.(event, () => setSlotInput(null))
            event_input && setSlotInput(event_input)
        }
    }

    const getEventContext = (event={}) => {
        const { item_id } = event.resource
        if(!item_id) { return }
        return <ItemOptions item_id={item_id} options={['open','shift','priority','schedule','tags','delete','archive']}/>
    }

    const resetSelection = () => {
        setSelected([])
        // onSelectSlot?.(null)
    }

    const onSelectSlotHandler = ({ start, end, slots, action, bounds, box }) => {
        if(selected.length > 0) {
            resetSelection()
        } else {
            let new_selection = slots.map(x => moment(x).format('YYYY-MM-DD HH:mm'))
            view !== 'month' && new_selection.length > 1 && new_selection.splice(-1,1) // remember to add back the step to the last slot to get the end time
            const allDay = view === 'month' ? true : moment(start).hour() === 0 && moment(start).minute() === 0 && moment(end).hour() === 0 && moment(end).minute() === 0
            let output = {
                start, 
                end: allDay ? moment(end).subtract(1, 'day').endOf('day').toDate() : end, 
                allDay
            }
            const position = { top: bounds?.top || box?.y, left: bounds?.left || box?.x }
            onSelectSlot?.({...output, ...position})
            setSelected(new_selection)
            if(renderSlotInput) {
                const new_slot_input = renderSlotInput(output, setAnchorPosition)
                new_slot_input && setAnchorPosition(position)
                setSlotInput(new_slot_input)
            }
        }
    }

    ///////////////////////////
    // Internal DND handlers //
    ///////////////////////////
    const onDragStart = ({ event, action, direction }) => {

    }

    const onEventDrop = ({ event, start, end, allDay }) => {
        onReschedule?.({ event, start, end, allDay })
    }

    const onEventResize = ({ event, start, end }) => {
        onReschedule?.({ event, start, end, allDay: false })
    }

    ///////////////////////////
    // External DND handlers //
    ///////////////////////////
    const onDropFromOutside = (values) => {
        if(!values || !isDraggingOver) { return } // Need isDraggingOver if not will trigger when dnd in WorkspaceModule while CalendarModule is open
        setSelected([])
        setDragOver(false)
        onReschedule?.({
            allDay: view === 'month',
            start: values.over.data.current.value,
            end: view === 'month' ? values.over.data.current.value : moment(values.over.data.current.value).add('minutes', default_schedule_duration).toDate(),
            event: {
                resource: {
                    item_id: values.active.id
                }
            }
        })
    }

    const onDragOver = (slot) => {
        // Provide all the slots to be highlighted
        const n = Math.ceil(default_schedule_duration / step)
        let new_selection = []
        Array(n).keys().forEach(x => {
            new_selection.push(moment(slot).add(step*x, 'minutes').format('YYYY-MM-DD HH:mm'))
        })
        setSelected(new_selection)
        setDragOver(true)
    }
    

    useDndMonitor({ onDragEnd: onDropFromOutside })
    const context = {
        selected,
        onDragOver,
        step,
        view, 
        // setView,
        onEventSelect, getEventContext,
        date, 
        // setDate,
        isDraggingOver
    }

    const filtered_events = getEvents()
    const popper_element = slotInput && React.cloneElement(slotInput, {onClose: () => {
        setAnchorPosition(null)
        setSlotInput(null)
        setSelected([])
    }})

    return (
        <CalendarContext.Provider value={context}>
            <DnDCalendar style={props.style}
                view={view} // month|week|work_week|day|agenda
                // defaultDate={initial_date}
                date={date}
                scrollToTime={moment(date).toDate()}
                localizer={localizer}
                events={filtered_events}
                selected={selected}
                toolbar={false}
                resizable={true}
                selectable={selected.length > 0 ? false : true}
                // selectable={'ignoreEvents'}
                step={step}
                onDrillDown={onDrillDown}
                // drilldownView='day'

                onSelectEvent={onEventSelect}
                onSelectSlot={onSelectSlotHandler} 

                // Internal events
                onDragStart={onDragStart}
                onEventDrop={onEventDrop}
                onEventResize={onEventResize}

                // Rendering
                eventPropGetter={eventPropGetter}
                elementProps={{
                    onClick: (e) => {
                        const { pageX, pageY } = e
                        setAnchorPosition({top: pageY, left: pageX})
                        selected.length > 0 && resetSelection()
                    }
                }}
                components={{
                    // event: CalendarEvent, // Only controls the content (i.e. title onwards)
                    // eventWrapper: CalendarEventWrapper, // Doesn't support context menu for day/week view because the positioning is off
                    // eventContainerWrapper: CalendarEventContainerWrapper, // definitely not this one
                    dateCellWrapper: CalendarDateCellWrapper,
                    timeSlotWrapper: CalendarTimeSlotWrapper,
                    timeGutterHeader: () => {
                        const n = events.filter(event => event['allDay'] && moment(event['start']).isSame(date,'day')).length
                        return (
                            <div>
                                {n > 2 &&
                                <Button
                                    icon={limitAllDay ? 'ChevronDoubleDown' : 'ChevronDoubleUp'}
                                    icon_color='var(--on-surface-variant)'
                                    onClick={() => toggleAllDay(!limitAllDay)}
                                    tooltip={{title: limitAllDay ? 'Show more' : 'Hide'}}
                                    />}
                            </div>
                        )
                    },
                }}
                formats={{
                    timeGutterFormat: 'hA'
                }}
                />
            <Popover
                open={anchorPosition && slotInput}
                onClose={() => {
                    setAnchorPosition(null)
                    setSlotInput(null)
                    setSelected([])
                }}
                anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
                transformOrigin={{ vertical: 'top', horizontal: 'left' }}
                anchorReference={'anchorPosition'}
                anchorPosition={anchorPosition}
                slotProps={{
                    paper: {
                        sx: {
                            [`&.${popoverClasses.paper}`]: { backgroundColor: 'transparent', marginBlock: 'var(--spacing-small)' }
                        }
                    }
                }}
                >
                {popper_element}
            </Popover>
        </CalendarContext.Provider>
    )
}