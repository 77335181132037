import React from 'react';
import styled from 'styled-components';
import { useDispatch, useSelector } from 'react-redux';

import { Button, Text } from 'components';

import _ from 'lodash'
import { getLocalLastAction } from 'utils'
import * as actions from 'store/actions';
import moment from 'moment-timezone'

const Container = styled.div`
    display: flex;
    flex-direction: column;
    padding: var(--spacing);
    gap: var(--spacing-small);
`

export const TestModule = props => {
    const user = useSelector(state => state.db.user)
    const dispatch = useDispatch()
    const onTest = () => {
        console.log(moment('20240726T023000Z'))
        // dispatch(actions.loadData(user, 'sync', {date: '2024-09-25T00:11:17.834Z'}))
    }

    return (
        <Container>
            <Text type='title'>TestModule</Text>
            <Button
                label='Test'
                style={{backgroundColor: 'var(--primary)'}}
                onClick={onTest}
                />
        </Container>
    )
}