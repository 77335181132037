import React from 'react';
import { useSelector } from 'react-redux';
import styled from 'styled-components';

import { ListTile, Text } from '../base';
import { ListOptions } from '../options'
import { useNavigate } from 'react-router-dom';

import { getListItemCount } from 'utils'
import { AppContext } from 'screens';
import { isMobile } from 'react-device-detect';

const Container = styled.div`

`

export const ListBlock = props => {
    const {
        list_id,
        labels=[],
        mode='static', // static or active
        trailing,
        style
    } = props;
    const navigate = useNavigate()
    const { setModule, setNavigation } = React.useContext(AppContext)
    const data = useSelector(state => state.db.data)
    const list = data.lists[list_id]
    if(!list) { return }

    const { title, color='var(--on-surface-variant)' } = list
    const { incomplete } = getListItemCount(list_id, data)

    return (
        <ListTile key={list_id}
            label={title}
            leading='SquareRounded'
            leading_color={color}
            leading_size={0.6}
            onClick={() => {
                navigate(`/list/${list_id}`)
                setModule(null)
                isMobile && setNavigation(false)
            }}
            style={style}
            trailing={<Text type='subtitle'>{incomplete}</Text>}
            popover={<ListOptions list_id={list_id} options={['edit','color','delete','archive']}/>}
            anchorOrigin={{
                vertical: 'top',
                horizontal: 'right',
            }}
            transformOrigin={{
                vertical: 'top',
                horizontal: 'left',
            }}
            />
    )
}